.export-all-modal {
    .modal-dialog {
        width: 535px;

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }

    .modal-content {
        width: 535px;

        h2 {
            margin-bottom: 0;
        }

        .datepicker-section-wrap {
            max-width: 50%;
            margin: 0 auto;

            @media (max-width: $screen-xs-max) {
                max-width: 100%;
            }

            .datepicker-wrap {
                width: 216px;
                margin: auto;
            }
        }

        .error-box {
            margin-top: 0px;
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }


}

.md-datepicker-calendar-pane {
    z-index: 1060;
    @media (max-width: $screen-xs-max) {
        left: 12% !important;
    }
}

.md-datepicker-input-mask-opaque {
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}
.md-datepicker-input {
    // font-family: 'proxima';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
// .md-datepicker-input-mask {
//     max-width: 308px;
// }

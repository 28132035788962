.image-preview-modal {
    -webkit-overflow-scrolling: auto;

    .close {
        padding: 15px;
    }

    .img-holder {
        text-align: center;

        img {
            display: inline-block;
        }
    }
}

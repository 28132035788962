.rusc-wrapper {
    background: #f4f7f8;

    > .content {
        padding: 35px;

        @media (max-width: $screen-xs-max) {
            padding: 35px 22px;
        }
    }

    > footer {
        padding: 35px;
        background: #fff;

        > .img-holder {
            margin-bottom: 5px;

            img {
                width: 255px;

                @media (max-width: $screen-xs-max) {
                    width: 132px;
                }
            }
        }
    }
}

@import './locating/styles';
@import './listings/styles';

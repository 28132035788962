@import '../../../../styles/colors';
@import '../../bootstrap/variables';

.materials {
    header {
        h2 {
            @media (max-width: $screen-xs-max) {
                text-align: center;
            }
        }
    }

    table, tr, td, th {
        vertical-align: middle;
    }

    table {
        margin-bottom: -10px;
        margin-top: -21px;
        border-bottom: 1px solid $ice-ice-baby;
        border-collapse: separate;

        &:last-child {
            border-bottom: 0;
        }

        th {
            border-top: 0;
        }

        td {
            width: 1px;
            min-height: 60px;
            height: 100%;
            padding: 12px 16px 8px 16px !important;

            &:first-child {
                width: 100%;
            }

            &.full-width {
                width: 100%;
            }
        }
    }

    .subsection {
        margin-top: 20px;
        border-radius: 4px;
    }

    .subsection:first-of-type {
        margin-top: 0;
    }


    .lr-stack {
        font-size: 8px;

        i {
            color: $listreports-red;
        }
    }

    .lr-inverse {
        color: #fff !important;
    }

    .hide-visibly {
        pointer-events: none;
        opacity: 0;
    }

    .table-wrap {
        margin: 0 -20px;
    }

    .collapsable-section {
        border-top: 1px solid $ice-ice-baby;
        margin-bottom: 0;

        &:first-child {
            border-top: 0;
        }
    }

    .label-wrap {
        flex: 1;

        .btn {
            position: relative;
            top: -1px;
        }
    }

    .thumbnail-wrap {
        margin-right: 16px;
        min-width: 72px;

        img {
            display: block;
            height: 54px;
            max-width: 72px;
            margin: 0 auto;
        }
    }

    .new-badge {
        margin-left: 5px;
        top: 0;
        padding: 5px;
        color: #000;
        background: $listreports-gold;
        border-radius: 2px;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 10px;

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }
}

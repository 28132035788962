.route-plans-mbs,
.route-plans {
    .logged-out-wrapper {
        > div > div {
            max-width: 1020px;
            padding: 40px 80px;

            @media (max-width: $screen-xs-max) {
                padding: 40px 16px;
            }
        }
    }
}

.logged-out-wrapper plans header{
    margin-bottom: 24px;
}

plans {
    $breakPoint: 768px;

    input.form-control{
        @media (max-width: $screen-xs-max) {
            height: 42px;
        }
    }

    header{
        margin-bottom: 24px;
    }

    .plans-content{
        margin: 0 auto;
    }

    .title {
        h2 {
            margin: 0;
        }

        h3 {
            margin: 0;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 24px;
    }

    .green {
        color: $listreports-green;
    }

    .red {
        color: $listreports-red;
    }

    .plans {
        margin-bottom: 50px;

        .width-100-perc {
            width: 100%;
        }

        @media(min-width: 769px) {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
        }

        @media(max-width: $breakPoint) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .plans-form {
        @media(max-width: $breakPoint) {
            margin-left: -10px;
            margin-right: -10px;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .select-plan {
        @media(min-width: 769px) {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
        }

        .btn {
            text-align: left;

            .title {
                margin-top: -5px;

                @media(min-width: 769px) {
                    font-size: 24px;
                }
            }

            &:first-child {
                @media(max-width: $breakPoint) {
                    margin-bottom: 10px;
                }
            }

            @media (max-width: $screen-xs-max) {
                height: 64px;
            }
        }

        > span {
            display: none;

            @media(min-width: 769px) {
                display: inline;
            }
        }
    }

    .relative {
        position: relative;
    }

    .ccs {
        margin-top: -2px;
        margin-bottom: 10px;

        @media(min-width: 769px) {
            position: absolute;
            bottom: 9px;
            right: 9px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .group {
        @media(min-width: 769px) {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
        }

        span {
            @media(max-width: $breakPoint) {
                display: none;
            }
        }

        label span {
            @media(max-width: $breakPoint) {
                display: inline-block;
            }
        }
    }

    .submit {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .plans-wrapper {
        @media (max-width: $screen-xs-max) {
            margin-left: -10px !important;
            margin-right: -10px !important;
        }
    }

    .faq-wrap {
        @media (max-width: $screen-xs-max) {
            position: relative;
            margin: 0 -35px;
            padding: 0 25px;
            background: #ededee;
        }
    }

    plans-faq {
        margin: 26px auto;

        @media (max-width: $screen-xs-max) {
            margin: 0 0 20px;
        }
    }

    .looking-for-pro {
        margin-top: 5px;

        a {
            font-size: 16px;
        }
    }

    .btn-block+.btn-block {
        margin-top: 0;
        margin-left: 15px;

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }
    }

    .vgradient {
        height: 42px;
        background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
        margin: 0 -50px;

        @media (max-width: $screen-sm-max) {
            margin: 0 -35px;
        }
    }

    plan-feature-card {
        margin: 0 12px;

        @media (max-width: $screen-xs-max) {
            display: block;
            margin: 0 auto 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .demo-cta {
        margin-bottom: 50px;

        h3 {
            max-width: 525px;
            margin: 0 auto 20px;

            .btn-link {
                position: relative;
            }
        }
    }

    .display-description {
        font-size: 14px;
    }

    .plans-content-form{
        border-radius: 4px;
        margin-bottom: 24px;
    }

    .box-total{
        padding-top: 24px;

        display: flex;
        margin-bottom: 24px;
        // @media (max-width: $screen-xs-max) {
        //     border-top: 1px solid #D5DFE3;
        // }
        @media (max-width: $screen-xs-max) {
            justify-content: space-between;
        }

        .box-total-text{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            margin-right: 16px;
        }

        .box-total-result {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: right;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
            }

            .box-total-result-discount {
                font-weight: normal;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    .btn.btn-content-fit{
        min-width: auto;
        @media (max-width: $screen-xs-max) {
            flex: none;
        }
    }

    .box-info {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 24px;
    }

    .link{
        color: #007780;
        text-decoration: none;

        &:hover,
        &:focus{
            color: #007780;
            text-decoration: underline;
        }
    }

    .lock-icon{
        width: 24px;
        height: 24px;
        background-image: url('/images/icons/lock_fill.svg');
        background-repeat: no-repeat;
    }

    .box-alert{
        padding: 16px;
        background: #F8FAFB;
        border-radius: 4px;

        .lock-icon{
            margin-right: 16px;
            flex-shrink: 0;
            &.mbs-highway{
                color: #0D2121;
            }
        }

        &.mbs-btn{
            background: #F4F8F8;
        }

    }

    .box-alert-mbs-highway{
        color: #0D2121;
    }

    .plans-label{
        font-family: 'Verdana';
        font-weight: 700;
        font-size: 14px;
    }

    .plan-col-2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;

    }

    .content-ccs{
        margin-bottom: 16px;
        display: grid;
        grid-template-columns: 49px 49px 49px 49px;
        grid-column-gap: 6px;
    }

    .plan-card-check-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .plan-card-check-content{
        width: 16px;
        height: 16px;
        border: 1px solid #D5DFE3;
        box-sizing: border-box;
        padding: 2px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan-card-check{
        width: 8px;
        height: 8px;
        border: 1px solid #D5DFE3;
        background: #696969;
        border-radius: 100%;
        display: block;
    }

    .btn.btn-primary.mbs-btn{
        background-color: #8551DC;
        border-color: #8551DC;
        width: 102px;
        height: 48px;
        padding: 11px 0;
        text-align: center;

        &:hover{
            background-color: #6C41B5;
            border-color: #6C41B5;
        }
    }

    .mbs-link{
        color: #8551DC;
        &:hover {
            color: #6C41B5;
        }
    }

    .mbs-container{
        input.form-control{
            @media (max-width: $screen-xs-max) {
                height: 48px;
            }
        }

        .plans-label{
            color: #0D2121;
        }


        .box-total-result,
        .box-total-text,
        .plans-title,
        .box-info,
        .plans-title-form,
        .plans-title-desc {
            color: #113A3F;
        }

        .plans-title {
            margin-bottom: 12px;
            font-size: 31px;
        }

        .plans-title-desc {
            font-size: 12px;
        }

        .plans-content-form  input[type=text]:focus{
            border-color: #0D2121;
        }
    }

    .plans-title-desc,
    .plans-title {
        @media (max-width: $screen-xs-max) {
            text-align: left !important;
        }
    }



}

@import './faq/styles';
@import './feature-card/styles';

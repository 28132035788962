agent-offer-signup {

    .report-pdf-images {
        margin-bottom: 20px;

        > div {
            &:first-child {
                margin-right: 24px;

                @media (max-width: $screen-xs-max) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }

            &:last-child {
                margin-left: 24px;

                @media (max-width: $screen-xs-max) {
                    margin-left: 0;
                }
            }
        }

        img {
            width: 100%;
            max-width: 100%;
            border: 1px solid #C6C8CA;
            border-bottom: 0;
            display: inline-block;
            margin-bottom: -1px;
        }

        .shadow {
            height: 27px;
            background: url('/images/agent-offer-signup/shadow.png') no-repeat top center;
            background-size: 100%;
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    form {
        background: #fafafa;
        padding: 25px;

        h3 {
            margin: 0;
        }

        .ig {
            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                height: 50px;

                 @media (max-width: $screen-xs-max) {
                    height: 42px;
                }
            }

            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        p {
            margin-top: 0;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: -35px;
            margin-right: -35px;
            text-align: center;
        }
    }

    .agent-company-logos {
        img {
            width: 470px;
        }
    }

    .account-copy,
    .utils a {
        font-size: 14px;
    }
}

view-listing {
    display: block;
    max-width: 1040px;

    .breadcrumbs {
        margin-bottom: 20px;
    }

    .section {
        margin-bottom: 20px;

        @media(max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .agent {
        margin-right: 10px;
        min-width: 300px;

        follow-agent-button {
            .btn {
                min-width: 0 !important;
            }
        }

        .invite-status {
            margin-top: 14px;
            font-size: 10px;

            i {
                font-size: 17px;
                margin-right: 5px;
            }
        }

        .stats {
            margin: 40px -25px -25px;

            h2 {
                margin: 0;
            }

            .stats-row {
                &:first-child {
                    border-top: 1px solid $ice-ice-baby;
                    border-bottom: 1px solid $ice-ice-baby;
                }

                .stat:first-child {
                    border-right: 1px solid $ice-ice-baby;
                }

                .listings-months {
                    font-weight:600;
                }
                .listings-months-tooltip {
                    font-size:14px;
                }
            }

            .stat {
                padding: 25px;
            }
        }

        .no-stats {
            border-top: 1px solid $ice-ice-baby;
            padding: 25px;
            margin: 40px -25px -25px;
            position: relative;

            i {
                position: relative;
                top: 2px;
                font-size: 24px;
                margin-right: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .listing-info {
        margin-left: 10px;

        .bg-img {
            background: no-repeat center center;
            background-size: cover;

            > div {
                padding-top: 69%;

                    @media (max-width: $screen-xs-max) {
                    padding-top: 0;
                    height: 230px;
                }
            }
        }

        .gallery {
            position: relative;
            background: #fff;
            overflow: hidden;
            margin-bottom: 15px;

            slick {
                .move-right {
                    color: #fff;
                    right: 10px;
                    z-index: 2;
                    font-size: 20px;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                }

                .move-left {
                    color: #fff;
                    left: 10px;
                    z-index: 2;
                    font-size: 20px;
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                }
            }

            .slick-slider {
                margin: 0;
                max-width: 100%;
            }

            .num-imgs {
                position: absolute;
                right: 10px;
                bottom: 10px;
                background: rgba(0,0,0,0.75);
                color: #fff;
                padding: 5px 8px;
                font-size: 12px;
            }
        }

        hr {
            margin: 23px 0;
            border-color: $ice-ice-baby;
            display: block;
        }

        .info {
            > div,
            > h2,
            > h3 {
                margin-bottom: 13px;
                margin-top: 0;
            }

            h2 {
                line-height: 100%;
            }

            h3 {
                line-height: 24px;
            }

            .tiny-title {
                font-size: 10px;
                margin-bottom: 8px;
            }

            .dom {
                background-color: $smoke;
                padding: 5px 8px;
                border-radius: 2px;
            }
        }

        .listing-props,
        .gallery-ctas {
            span {
                position: relative;
                display: inline-block;
                padding-right: 5px;
                margin-right: 5px;

                &:before {
                    position: absolute;
                    content: '|';
                    right: -4px;
                    color: $ice-ice-baby;
                }

                &:last-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }
    }

    .open-houses {
        .open-house {
            > div {
                margin-right: 14px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

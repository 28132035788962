compliance-users {
    display: block;
    max-width: 1400px;

    @media (min-width: $screen-md) {
        min-height: 600px;
    }

    th {
        border-top: 0 !important;
    }

    .notification-banner {
        background: $listreports-blue;
        color: #fff;
        border-top: 0 !important;
        border-bottom: 1px solid $ice-ice-baby;
        height: 50px;
    }

    .btn-default {
        color: $defaultFontColor !important;
        text-transform: none !important;
        font-weight: normal !important;
        font-size: 14px !important;

        .lr-calendar {
            margin-right: 5px;
        }

        .lr-caret-down {
            margin-left: 10px;
        }
    }

    .filters-wrap {
        > div {
            margin-right: 12px;
        }
    }

    select {
        padding-right: 30px !important;
    }

    .subsomponent-title {
        display: none;
    }

    &[subcomponent] {
        title-bar,
        .filters-wrap,
        .filter-info {
            display: none !important;
        }

        .subsomponent-title {
            display: block;
        }
    }

    &[subcomponent="tabbar"] {
        .subsomponent-title {
            opacity: 0;
            cursor: default;
        }

        .section-group {
            border: 0;
            margin-bottom: 0;
        }
    }
}

.route-compliance-users {
    .md-datepicker-calendar-pane {
        .md-datepicker-input-mask {
            display: none;
        }
    }
}

.multi-file-uploader-modal {
    .modal-dialog {
        max-width: 600px;

        md-toolbar {
            color: #fff !important;

            i {
                color: #fff !important;
            }
        }

        .file {
            border-bottom: 1px solid $ice-ice-baby;
        }

        .preview {
            $size: 20px;
            width: $size;
            height: $size;
            margin-right: 10px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .uploads {
            max-height: 250px;
            overflow-y: auto;
        }

        .global-progress {
            box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
            margin-bottom: 5px;

            .cancel-all {
                margin-left: 20px;
            }
        }

        .success-check {
            font-size: 20px;
        }
    }
}

my-lead-detail {
    $pad: 35px;
    $padSmall: 25px;
    max-width: 1024px;
    font-size: 14px;

    .title {
        margin-left: 14px;
        margin-top: 16px;
        margin-bottom: 16px;
        @media (min-width: $screen-sm-min) {
            margin-left:0px;
            margin-top: 20px;
            margin-left: 0px;
        }
    }

    statsbar {
        h3 {
            line-height: 20px;
        }

        .stat {
            width: 170px;
            height: 110px !important;
        }
    }

    tabbar {

        &.rightTab {
            .label-holder {
                border: 0;
            }

            .card {
                border: 0 !important;
            }

            tablabel {
                width: 145px;
            }
        }
    }

    .event-entry {
        padding-left: 20px;

        h2 {
            text-align:center;
            margin-bottom: 0;
            @media (min-width: $screen-sm-min) {
                text-align: left;
            }
        }

        h3 {
            text-align:center;
            color: #b5bbbf;
            width:50%;
            @media (min-width: $screen-sm-min) {
                text-align: left;
            }
        }

        background-position: top 50% right 20%;
        background-size: 35px;
        background-repeat: no-repeat;
    }

    .sent {
        @media (min-width: $screen-sm-min) {
            background-image: url('/images/icon-stickers/sent.svg');
        }
    }

    .opened {
        @media (min-width: $screen-sm-min) {
            background-image: url('/images/icon-stickers/emails-opened.svg');
        }
    }

    .viewed {
        @media (min-width: $screen-sm-min) {
            background-image: url('/images/icon-stickers/viewed.svg');
            background-size: 30px;
        }
    }

    .new {
        @media (min-width: $screen-sm-min) {
            background-image: url('/images/icon-stickers/new.svg');
            background-size: 30px;
        }
    }

    .border-right {
        border-right: 1px solid $ice-ice-baby;
    }

    .border-bottom {
        border-bottom: 1px solid $ice-ice-baby;
    }

    .border-top {
        border-top: 1px solid $ice-ice-baby;
    }

    .view-lead-v2 {
        max-width: 1024px;
    }

    .btn {
        min-width: 100px;
    }

    .btn-secondary {
        min-width:0;
        width:80px;
        font-weight:normal;
        padding:0;
    }

    .breadcrumbs {
        margin-bottom: 17px;
    }

    .information-panels {
        margin-right: 0px;
        width:100%;

        @media (min-width: $screen-sm-min) {
            margin-right: 20px;
        }
    }

    .lead-profile {
        padding: 16px;
        position: relative;

        .headshot {
            width:80px;
            height:80px;
            vertical-align: top;
            display:inline-block;
            background-color: #ecf0f0;
            text-align:center;
            margin-bottom: 20px;

            h1 {
                margin:0;
                line-height: 80px;
            }
    
            @media (min-width: $screen-sm-min) {
                width:100px;
                height: 100px;
                margin-right:20px;
                h1 {
                    line-height:100px;
                }
            }
        }

        .lead-info {
            margin-bottom:10px;

            h2 {
                margin-bottom: 10px;
                line-height:20px;
                margin-top:0px;
            }

            .entry {
                select {
                    background-size: 10px;
                }

                margin-bottom: 3px;
            }

            @media (min-width: $screen-sm-min) {
                display: inline-block;
            }

            select {
                background-color: white;
                background-image: url("/images/icons/select-arrow-dark.png") !important;
                background-size: initial;
                font-weight: 600;
                padding-right: 15px !important;
            }
        }

        @media (min-width: $screen-sm-min) {
            padding: 20px;
        }

    }
    .no-alerts, .pending-alerts {
        padding-left:16px;
        padding-right:16px;
        padding-bottom: 32px;
        margin-top: 28px;
        @media (min-width: $screen-sm-min) {
            padding-left:44px;
            padding-right: 168px;
            padding-bottom:40px;
            margin-top:32px;
        }

        h2 {
            margin-bottom: 0;
        }

        p {
            font-size: 18px;
        }
    }

    .notes-header {
        height: 50px; 
        text-align: right;
        position:relative;

        .add-note-btn {
            position:absolute;
            top:50%;
            right:5px;
            transform: translate(0px, -50%);
        }
    }
    
    .list-alerts {
        font-size: 18px;
        height: 100%;

        h2 {
            margin: 10px 0;
        }

        .btn-link {
            font-size: 18px;

            @media (max-width: $screen-sm-max) {
                font-size: 14px;
            }
        }

        .icon-wrap {
            font-size: 18px;
        }

        .titles {
            min-width: 210px;
            border-right: 1px solid $ice-ice-baby;

            > div {
                padding: 30px;
                border-bottom: 1px solid $ice-ice-baby;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .disabled {
            color: rgba($listreports-gray, 0.5);
        }

        > .wrapper > div {
            border-bottom: 1px solid $ice-ice-baby;

            &:last-child {
                border-bottom: 0;
            }
        }

        .pad {
            padding: 30px;
        }

        @media (max-width: $screen-sm-max) {
            font-size: 14px;
        }
    }

    .notes-wrapper {
        margin-top: 20px;

        @media (max-width: $screen-sm-max) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

@import './just-listed/styles';
@import './owner-hub/styles';
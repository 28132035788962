.purchase-buyside-credits-modal  {
    .receipt {
        .divider {
            $margin: -21px;

            border-top: 1px solid $ice-ice-baby;
            margin: {
                left: $margin;
                right: $margin;
            }
        }
    }
}

@import './new-subscription-flow/styles';
@import './manage-subscription-flow/styles';
@import './video/styles';
@import './select-plan/styles';
@import './select-agents/styles';
@import './payment/styles';
@import './receipt/styles';
@import './manage/styles';
@import './deselect-agents/styles';
@import './cancellation/styles';
@import './cancellation-survey/styles';
@import './value-props/styles';

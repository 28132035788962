.photo-picker-modal {
    .modal-dialog {
        width: 950px;
        max-height: 100%;

        @media (max-width: $screen-sm-max) {
            max-height: none;
            min-height: 100%;
            overflow-y: scroll;
            margin: 0;
            padding-top: 20px;
            width: 100%;
        }

        .modal-content {
            padding: 0;
            border: none;
        }

        .modal-content-full {
            height: 100%;
        }

        .modal-content-empty {
            height: fit-content;
        }
    }

    .photo-picker-modal-wrapper {
        height: 90vh;
    }

    .photo-picker-modal-content {
        height: 100%;

        .heading {
            .title-container {

                @media (max-width: $screen-sm-max) {
                    margin-bottom: 14px;
                }

                .title {
                    margin-bottom: 0;
                    font-size: 28px;
                    font-weight: bold;

                    @media (max-width: $screen-sm-max) {
                        font-size: 20px;
                    }
                }
            }
            .button-spacer {
                margin-right: 5px;
            }
            .btn {
                font-weight: normal;
                font-size: 15px;
            }
            padding: 32px 56px 36px;

            @media (max-width: $screen-sm-max) {
                padding: 32px 28px 22px;
            }
        }
    }

    md-content {
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        .notification-bar-wrapper {
                min-height: 76px;
                background-image: linear-gradient(#f7f7f7, white);

                .notification-bar {
                    height: 100%;
                    font-size: 18px;
                }
            }

        .photo-grid-wrapper {
            overflow-y: auto;

            .photo-grid {
                margin: 0 46px;

                @media (max-width: $screen-sm-max) {
                    margin: 0 24px;
                }

                .photo-grid-item {
                    padding: 0 8px;
                    position: relative;

                    .remove-image-btn {
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        position: absolute;
                        background-color: $giggity;
                        // background-color: transparent;
                        color: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,0.4);
                        border-radius: 50%;
                        top: 16px;
                        right: 16px;
                        font-size: 24px;
                        cursor: pointer;
                        @include transition(all, 0.2s, $bezier-out);
        
                        &:hover {
                            background-color: $errorRed;
                            color: #fff;
                        }
                    }

                    .new-tag {
                        width: 62px;
                        height: 30px;
                        position: absolute;
                        top: 8px;
                        left: 8px;
                        background-color: $listreports-orange;
                        color: #fff;
                        border-radius: 2px;
                        font-size: 17px;
                    }
                }
            }
        }

        .img-tile {
            width: 100%;
            padding-top: 75%; // Hack to keep a 4:3 aspect ratio on a div.
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: 8px solid transparent;
            cursor: pointer;
            @include transition(all, 0.4s, $bezier-out);

            &:hover {
                border-color: $listreports-blue;
            }

            &.unavailable {
                opacity: 0.2;
                pointer-events: none;
            }

            &.selected {
                border-color: $listreports-blue;
            }

        }

        @media (max-width: $screen-sm-max) {
            overflow: hidden;
        }

        .uploader-wrapper {
            height: 430px;
            margin: 60px 56px;

            @media (max-width: $screen-sm-max) {
                height: 234px;
                margin: 26px 24px;
            }
        }
    }

    md-grid-list {
        overflow: hidden;
    }

    .cta-wrap {
        min-width: 100%;
        // margin-bottom: ;

        .btn-link {
            padding: 11px 32px;
            top: 0;
        }

        margin-top: 34px;
        padding-bottom: 32px;

        @media (max-width: $screen-sm-max) {
            margin-top: 24px;
        }
    }

    .close-btn {
        position: absolute;
        top: -3px;
        right: 15px;
        font-size: 36px;
        @media (max-width: $screen-xs-max) {
            top: -3px;
            right: 15px;
            font-size: 36px;
        }
    }
}

.resources-navbar {
    margin: 0 auto 20px;

    .nav-block {
        min-height: 190px;
        padding: 26px;
        border-right: 1px solid $ice-ice-baby;
        overflow: hidden;

        &:last-of-type {
            border-right: none;
        }

        @media (max-width: $screen-sm) {
            border-right: none;
            border-bottom: 1px solid $ice-ice-baby;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .icon-holder {
        display: flex;
        font-size: 34px;
        padding-right: 10px;
    }

    .nav-holder {

        height: 20px;
        text-align: left;

        a {
            font-size: 14px;
            font-weight: 600;
        }

    }

    .block-caption {
        height: 40px;
        margin: 18px 0;
        text-align: left;
    }
}

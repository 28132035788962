.notification-center {

    .progress-container {
        padding: 12px;
    }

    .notifications-bell-container {
        position: relative;
        padding: 0 22px;
        border-left: 1px solid #e0e0de;
        border-right: 1px solid #e0e0de;
        height: $applicationHeaderHeight + px;

        display: flex;
        align-items: center;

        @media (min-width: $screen-md-max) {
            border-left: 0;
        }

        .notifications-bell {
            display: flex;
            position: relative;
            align-items: center;
        }

        .notification-center-caret {
            display:none;

            @media (min-width: $screen-xs-max) {
                display: block;
                width: 50px;
                height: 50px;
                position: absolute;
                right: 10px;
                bottom: -20px;
                overflow: hidden;
                z-index: 1200;

                &:before {
                    content: "";

                    width: 18px;
                    height: 24px;
                    transform: rotate(45deg);
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
                    background-color: white;

                    position: absolute;
                    bottom: -15px;
                    right: 15px;
                }
            }

        }

    }

    .notifications-menu {
        position: absolute;
        width: 500px;
        height: 100%;

        @media (min-width: $screen-sm-min) {
            height: 650px;
        }

        @media (max-width: $screen-xs-max) {
            max-height: unset;
            width: 100%;
            right: 0px;
            top: 0px;
            bottom: 0px;
            position: fixed;
        }

        .notifications-close {
            display: none;
            @media (max-width: $screen-xs-max) {
                display: block;
            }
        }

        // Dropdown menu arrow
        &:before {
            // @media (min-width: $screen-sm-min) {
            //     right: 95px;
            // }
            // @media (min-width: $screen-md-min) {
            //     right: 106px;
            // }
            // @media (min-width: $screen-lg-min) {
            //     right: 180px;
            // }
            display: none;
        }

        .dropdown-menu-item {
            border-bottom: 1px solid $ice-ice-baby;
            padding: 16px;
        }

        li {
            cursor: unset;
            &:hover {
                color: $defaultFontColor;
            }
        }

        .separator {
            font-size: 14px;
            color: $defaultFontColor;
            line-height: 18px;
            font-weight: bold;

            .mark-all-as-read {
                color: #007780;
                font-size: 14px;
                line-height: 18px;
                float: right;
                align-self: flex-end;
                flex: unset;
                padding-right: 16px;
                @media (max-width: $screen-xs-max) {
                    flex: auto;
                }
            }

            &:first-of-type {
                .notifications-close {
                    margin-left: 20px;
                }
            }
        }

        .notifications-menu-header {
            font-size: 18px;
            line-height: 20px;
            padding: 16px;

            .btn-link {
                top: unset;
            }
        }

        .load-more {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: center;
            color: #007780;
            cursor: pointer;
        }

        .unread {
            background-color: #f8fafb;
        }
    }

    .show-notification-center {
        display: block;
    }
}

cancellation-shell {
    .side {
        background: $listreports-dark-blue;
        color: #fff;
        width: 270px;
        padding: 45px;

        .headshot {
            margin-bottom: 25px;

            > img {
                max-width: 80px;

                @media (max-width: $screen-sm-max) {
                    max-width: 80px;
                }
            }

            @media (max-width: $screen-sm-max) {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        @media (max-width: $screen-sm-max) {
            width: 100%;
            padding: 25px;
        }
    }

    .main-content {
        padding: 70px 50px;

        @media (max-width: $screen-xs-max) {
            padding: 50px 16px;
        }
    }
}

view-buyside-listing {
    display: block;
    max-width: 1040px;

    .breadcrumbs {
        margin-bottom: 20px;
    }

    .section {
        margin-bottom: 20px;

        @media(max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .blur-avatar{
        filter: blur(4px);
    }

    .blur-elem{
        color: transparent;
        text-shadow: 0 0 5px #aaaaaa;
    }
    .strong-blur-elem{
        color: transparent;
        text-shadow: 0 0 10px #aaaaaa;
    }
    .agents-wrap {
        margin-left: 10px;
        min-width: 350px;
        font-size: 16px;

        avatar {
            min-width: 50px;
            margin-right: 10px;
        }

        button {
            min-width: 70px;
            padding: 10px 0;
        }

        .listing-agent {
            margin: 20px 0;

            .name-wrap {
                margin-right: 10px;
            }
        }
        @media (max-width: $screen-sm-max) {
            min-width: 300px;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }
    }

    .listing-info {
        margin-right: 10px;

        .bg-img {
            background: no-repeat center center;
            background-size: cover;

            > div {
                padding-top: 69%;

                    @media (max-width: $screen-xs-max) {
                    padding-top: 0;
                    height: 230px;
                }
            }
        }


        hr {
            margin: 23px 0;
            border-color: $ice-ice-baby;
            display: block;
        }

        .info {
            > div,
            > h2,
            > h3 {
                margin-bottom: 13px;
                margin-top: 0;
            }

            h2 {
                line-height: 100%;
            }

            h3 {
                line-height: 24px;
            }

            .dom {
                background-color: $smoke;
                padding: 5px 8px;
                border-radius: 2px;
            }

            .tiny-title {
                margin-bottom: 8px;
            }
        }

        .listing-props,
        .gallery-ctas {
            span {
                position: relative;
                display: inline-block;
                padding-right: 5px;
                margin-right: 5px;

                &:before {
                    position: absolute;
                    content: '|';
                    right: -4px;
                    color: $ice-ice-baby;
                }

                &:last-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .tiny-title {
        font-size: 10px;
        letter-spacing: 1.5px;
        margin-bottom: 8px;
    }

    .title-company  .content{
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .footer{
        position: fixed;
        z-index: 999;
        padding: 16px;
        background : #ffffff;
        width: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        border-top: 1px solid #D5DFE3;
    }

    .name,
    .company {
        font-size: 14px;
    }
}


.ecomm-header {
  padding: 24px 0;

  .learn-more {
    vertical-align: unset;
    position: static;
    margin-left: 0;
    line-height: 1.3;
  }
}

.ecomm-header-logo {
  margin-right: 16px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 16px;
  }
}

.headshot-lineup {
    margin-left: -10px;

    .headshot-wrap {
        min-height: 45px;
        max-width: 35px;
        width: 35px;
    }

    .headshot {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        overflow: hidden;
        font-size: 16px;
        color: #fff;
        padding: 12px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .blank {
        .headshot {
            background-color: #fff;
            border: 1px dashed $listreports-gray;
        }
    }

    .additional-count-wrap {
        color: $listreports-blue;
        margin-left: 14px;
        font-size: 15px;
    }
}

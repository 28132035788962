copy-input {
    display: block;

    input {
        border-color: $ice-ice-baby !important;
        color: $listreports-gray !important;
    }
}

.sub-text {
    color: #76888f;
}

.copied-text-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1100;
    pointer-events: none;

    .main {
        width: 150px;
        height: 150px;
        padding: 15px;
        background: #1E2B35;
        color: #fff;
        border-radius: 14px;
    }
}

.order-prints-modal {
    padding: 42px;

    .container {
        min-width: 100%;
        min-height: 100%;

        @media (max-width: $screen-sm-max) {
            min-height: 800px;
        }
    }

    .preview-wrap {
        min-width: 620px;
        max-width: 620px;
        min-height: 500px;
        padding: 20px 42px 20px 0;

        @media (max-width: $screen-sm-max) {
            min-width: 100%;
            padding: 0;
            max-height: 100%;
            min-height: 380px;
        }
    }

    .zoom-in {
        position: absolute;
        bottom: 20px;
        right: 12px;
        cursor: pointer;
        background-color: black;
        border: 2px solid white;
        border-radius: 50%;
        width: 30px;
        height: 30px;

        @media (max-width: $screen-xs-max) {
            top: 0;
            right: 0;
        }
    }

    .slick-wrap {
        position: relative;
        max-height: 392px;
        overflow: hidden;

        @media (max-width: $screen-sm-max) {
            max-height: 300px;
        }
    }

    slick {
        &.slick-slider {
            margin-bottom: 10px;
        }

        padding: 2px 4px;
        border: 1px solid $ice-ice-baby;

        .move-left, .move-right {
            font-size: 24px;
            color: white;
            background-color: rgba(0, 0, 0, 0.2);
            padding: 6px 2px;
            z-index: 1053;

            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }

        .move-left {
            left: 0;
        }

        .move-right {
            right: 0;
        }
    }

    .thumbnail-wrap {
        position: relative;
        @media (max-width: $screen-sm-max) {
            margin-bottom: 25px;
        }
    }


    .thumbnail {
        max-width: 100px;
        min-height: 66px;
        margin-right: 6px;

        .thumbnail-img {
            width: 100%;
            cursor: pointer;
            border: 1px solid $ice-ice-baby;
            opacity: 0.6;

            &:hover {
                border: 2px solid $listreports-blue;
            }

            &.active {
                opacity: 1;
                cursor: default;
            }

            &.active:hover {
                border: 1px solid $ice-ice-baby;
            }
        }
    }

    .img-responsive {
        display: inline-block;
        z-index: 1052;
    }

    .bg-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 20px 16px;
    }

    h2 {
        margin: 16px auto;

        @media (max-width: $screen-xs-max) {
            margin: 14px auto;
        }
    }

    .box-select{
        .box-select-option {
            width: 100px;
            height: 100px;
            margin-right: 10px;
            color: $sidewalk;
            cursor: pointer;
            user-select: none;
            border: 1px solid $ice-ice-baby;
            border-radius: 3px;

            @media (max-width: $screen-xs-max) {
                margin-top: 10px;
            }

            &.selected {
                color: $defaultFontColor;
                border: 1px solid $defaultFontColor !important;
                cursor: default;
            }

            &:hover {
                border: 1px solid $listreports-blue;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        min-width: 100%;
        margin: 0 auto 20px;
    }

    .image-download-container {
        margin-left: 16px;

        &:first-of-type {
            margin-left: 0;
        }

        .image-download-preview {
            border: 1px solid $ice-ice-baby;
            margin-bottom: 16px;

            img {
                max-width: 150px;
                width: 150px;
            }
        }
    }

    .disclaimer {
        margin: 20px 0;
        font-size: 12px;
        .btn {
            font-size: 12px;
        }
    }

    .bottom-disclaimer {
        color: $listreports-grey;
        margin-top: 12px;
        font-size: 12px;
    }

    .go-back {
        margin-top: 12px;
    }

    .close-btn {
        font-size: 42px;
        position: absolute;
        top: 6px;
        right: 18px;
    }
}

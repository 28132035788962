.fullscreen-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .90);
    z-index: 99999;
    text-align: center;

    .loader {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -16px;

        &.has-title {
            margin-top: -56px;
        }
    }

    .title {
        margin: 16px auto 0;
        max-width: 500px;

        @media (max-width: $screen-xs-max) {
            max-width: 300px;
        }
    }
}

active-buyer-network-buyers {
    display: block;
    max-width: 1000px;

    .contact-buyers-btn-holder {
        @media (max-width: $screen-xs-max) {
            margin: -15px 15px 15px;
        }
    }

    .price-input {
        font-size: 28px;
        height: 65px;
    }

    .price-sliddr-holder {
        margin-bottom: 20px;
    }

    .buyers-title {
        padding: 30px 40px 0;

        h2 {
            margin-top: 0;
        }

        .standard-font-size {
            font-size: 14px;
        }

        .wiams {
            @media (max-width: $screen-xs-max) {
                position: relative;
                top: 10px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 30px 24px 0;
        }
    }

    .buyer-card-holder {
        .title {
            margin-bottom: 5px;
        }

        .content {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .spacer {
        width: 100%;
        max-width: 302px !important;
        margin: 0 12px;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .buyer-card {
        position: relative;
        width: 100%;
        max-width: 302px !important;
        min-height: 470px;
        border: 1px solid $ice-ice-baby;
        margin: 7px;

        &.disabled {
            opacity: 0.5;
        }

        header {
            padding: 25px;

            &.price {
                padding: 15px;
            }

            .match-score {
                width: 80px;
                height: 80px;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                margin-left: 10px;

                &.great {
                    background-color: $listreports-green;
                }

                &.good {
                    background-color: $listreports-gold;
                }

                &.poor {
                    background-color: $listreports-red;
                }

                &.na {
                    background-color: $sidewalk;
                }

                > div {
                    font-size: 10px;
                    line-height: 10px;
                }
            }

            .pre-approved {
                color: $defaultSecondaryTextColor;
            }
        }

        .body {
            padding: 25px 30px;

            ul {
                list-style: disc;
                padding-left: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
            max-width: 100% !important;
            margin-top: 15px;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .no-results {
        margin-top: 23px;
        padding: 35px;
        border-top: 1px solid $ice-ice-baby;
        min-height: 400px;

        h3 {
            margin: 30px auto;
            max-width: 575px;
        }

        .copy-holder {
            max-width: 507px;
            margin-right: 90px;

            h2 {
                margin-top: 0;
            }

            @media (max-width: $screen-xs-max) {
                max-width: 100%;
                margin-right: 0;
            }
        }

        .num-los-holder {
            width: 211px;
            border: 3px solid $smoke;
            padding: 33px;

            h1 {
                margin: 0;
                font-size: 68px;
            }

            .blue-separator {
                width: 37px;
                height: 3px;
                margin: 20px auto;
                background-color: $listreports-blue;
            }

            @media (max-width: $screen-xs-max) {
                width: auto;
                margin-top: 35px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 24px;
        }
    }
}

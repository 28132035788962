.low-coverage-area {
    padding: 48px;

    .info {
        max-width: 408px;
        margin-left: 28px;
    }

    .img-wrapper {
        margin-right: 28px;

        img {
            max-width: 265px;

            @media (max-width: $screen-xs-max) {
                margin: 0 auto;
                margin-bottom: 24px;
            }
        }
    }

    .ctas {
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }

    @media (min-width: $screen-sm) {
        > * {
            margin-left: 28px;
            margin-right: 28px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

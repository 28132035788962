.invite-tc-modal {
    .modal-content {
        .header-img {
            margin: 0 auto 30px;
        }

        .btn-wrap {
            max-width: 320px;
            margin: 12px auto 0;
        }

        .error-box {
            margin-top: 0px;
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

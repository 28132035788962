active-buyer-network-dashboard {
    display: block;
    max-width: 1010px;

    active-buyer-network-address {
        margin-bottom: 20px;
        display: block;
    }

    .property-image-holder {
        display: block;
        width: 132px;
        height: 87px;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: $screen-xs-max) {
            width: 113px;
            height: 75px;
        }
    };

    .mobile-table-wrap {
        margin: -1px;
    }

    .load-more-holder {
        border-top: 1px solid $ice-ice-baby;
    }

    .watch-video {
        position: relative;
        top: 4px;
        padding-left: 5px;

        @media (max-width: $screen-xs-max) {
            margin-top: -45px;
            margin-bottom: 30px;
            margin-left: 10px;
        }
    }
}

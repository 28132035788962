.value-prop-placeholder.ph-item {
    border-radius: 0;

    .ph-picture {
        min-height: 199px;
        margin-bottom: 0;
    }

    .ph-row {
        > div {
            width: 100%;
        }
    }

    .subtitle {
        height: 18px;
    }

    .h2 {
        height: 33px;
        margin: 24px 0;
    }

    .p {
        height: 17px;
    }

    .button {
        height: 42px;
    }

    .ctas {
        .ph-row {
            width: 150px;
        }
    }
}




.my-agents {
    max-width: 1400px;
    display: block;


    tabbar tablabel{
        text-transform: none;
    }

    tabbar tablabel:nth-child(1) .smalllabel{
        @media(max-width: $screen-xs-max) {
            max-width: 60px;
            margin: 0 auto;
        }
    }

    tabbar tablabel:nth-child(2) .smalllabel{
        @media(max-width: $screen-xs-max) {
            max-width: 70px;
            margin: 0 auto;
        }
    }

    tabbar tablabel:nth-child(3) .smalllabel{
        @media(max-width: $screen-xs-max) {
            max-width: 85px;
            margin: 0 auto;
        }
    }

    .tab-agent-following{
        content: url('/images/icons/following-agent.svg');
        vertical-align: sub;
    }

    .tab-agent-pending{
        content: url('/images/icons/pending-agent.svg');
        vertical-align: sub;
    }

    .tab-agent-paired{
        content: url('/images/icons/paired-agent.svg');
        vertical-align: sub;
    }


    tabbar tablabel.selected {
        .tab-agent-following{
            content: url('/images/icons/following-agent-selected.svg');
        }

        .tab-agent-pending{
            content: url('/images/icons/pending-agent-selected.svg');
        }

        .tab-agent-paired{
            content: url('/images/icons/paired-agent-selected.svg');
        }
    }

    .my-agent-header{
        margin-bottom: 20px;
        .title-holder h1 {
            margin-bottom: 16px;
        }

        .title-bar-subtitle {
            font-size: 18px;
            line-height: 24px;
        }

        .robin-banner-link{
            &:hover,
            &:focus {
                text-decoration: none;
                color: #ffffff;
            }
        }

        .robin-banner {
            padding: 14px 16px;
            background: #1E2B35;
            border-radius: 2px;
            color: #ffffff;
            max-width: 400px;
            position: relative;
            padding-left: 50px;
            cursor: pointer;
            display: block;

            a{
                color: #007780;
                &:hover{
                    color: #007780;
                }
            }

            &::after{
                content: '';
                background: url('/images/robin-logo-copyright.svg');
                width: 28px;
                height: 24px;
                position: absolute;
                top: 16px;
                left: 16px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }



    .banner-header {
        background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships.png');

        @media (max-width: $screen-sm) {
            background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships_Mobile.png');
        }
    }

    .tabs {
        background: transparent;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 5px solid $listreports-blue;
        max-width: 100%;
        overflow-x: hidden;

        .tab {
            text-transform: uppercase;
            padding: 10px 10px;
            margin-top: 10px;
            background: #fff;
            color: $listreports-blue;
            cursor: pointer;
            border: 1px solid #e0e0df;
            border-bottom-width: 0;
            margin-left: -1px;

            &:first-child {
                border-left-width: 1px;
                margin-left: 0;
            }

            &:last-child {
                margin-right: -1px;
            }

            &.selected {
                z-index: 2;
                background: $listreports-blue;
                color: #fff;
                padding-top: 20px;
                margin-top: 0;
                border-color: $listreports-blue;

                @media (max-width: $screen-xs-max) {
                    padding-top: 15px;
                    padding-bottom: 10px;
                    background: #fff !important;
                    color: $listreports-blue !important;
                    border-left-color: #e0e0df !important;
                    border-right-color: #e0e0df !important;
                    border-top-color: #e0e0df !important;
                    border-bottom: 5px solid $listreports-blue !important;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 0;
                color: #696969 !important;
                text-transform: none;
                padding-top: 15px;
                padding-bottom: 15px;
                border: 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
            border-bottom: 1px solid #e0e0df !important;
            border-top: 1px solid #e0e0df !important;
        }
    }

    .utilities {
        padding: 0 20px;

        .search {
            background: #fff;
        }
    }

    .banner-wrap {
        margin-bottom: 20px;
    }

    @media (min-width: $screen-sm) {
        margin-bottom: 50px;
    }

    button.hidden-input {
        border: 0;
        width: 0;
        height: 0;
        max-width: 0;
        max-height: 0;
        opacity: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    button.agent-intel {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 0 12px;
        border-radius: 4px;
        margin-bottom: 16px;

        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    // .enroll-stats {
    //     border-top: none;
    //     border-left: none;
    //     border-right: none;
    //     margin-bottom: 0;
    // }

    .tab-agent-intel{
        content: url('/images/agentfarm/insights.svg');
        vertical-align: sub;
    }
}

@import './my-agents-content/styles';
@import './following-agents-content/styles';
@import './paired-agents-content/styles';
@import './follow-agent-modal/styles';
@import './enroll-agent-modal/styles';
@import './pending-agents/styles';
@import './invite-agent/styles';
@import './edit-agent/styles';

.slider-holder {
    margin: 0;
}

.slider {
    position: relative;
    display: block;
    background: $ice-ice-baby;
    padding: 4px 0;
    margin: 20px 50px;
    border-radius: 20px;

    .percent {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #3cbeb2;
        border-radius: 20px;
    }

    .handle {
        position: absolute;
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #fff;
        top: 50%;
        left: 50%;
        margin-top: -17px;
        margin-left: -17px;
        border: 3px solid $ice-ice-baby;
        cursor: pointer;

        @media (max-width: $screen-xs-max) {
            width: 25px;
            height: 25px;
            margin-top: -13px;
            margin-left: -13px;
        }
    }

    .tick {
        position: absolute;
        left: 50%;
        top: -50%;
        width: 1px;
        height: 24px;
        margin-top: -3px;
        background-color: $listreports-blue;
    }

    @media (max-width: $screen-xs-max) {
        padding: 5px 0;
    }
}

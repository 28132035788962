.addon-purchase-modal  {
    .receipt {
        .divider {
            $margin: -21px;

            border-top: 1px solid $ice-ice-baby;
            margin: {
                left: $margin;
                right: $margin;
            }
        }
    }

    .modal-content {
        @media(max-width: $screen-xs-max) {
            padding: 16px;
        }
    }
}

.orderAddonSubscriptionModal {
    display: block;

    > *,
    .tear-middle > *,
    .payment-method > *,
    .purchase-form > * {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    .receipt {
        creditcard {
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: .12);
        }

        .payment-method {
            .ccholder {
                position: relative;
            }

            .check-icon-background {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #ffffff;
                position: absolute;
                top: -3px;
                right: -4px;
            }

            .check-icon {
                font-size: 24px;
                position: absolute;
                top: -6px;
                right: -6px;
            }
        }

        .plan-details {
            padding-top: 9px;
        }
    }

    .plan-details-name {
        font-size: 14px;
        margin-bottom: 0 !important;
    }

    .error-box {
        margin-top: 0;
    }

    .overview {
        padding: 32px;
        background-color: $smoke;

        h2 {
            margin-bottom: 0 !important;
        }

        .divider {
            margin: 24px 0;
        }

        .line-item {
            margin: 16px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .due-today {
            @media(max-width: $screen-xs-max) {
                margin-bottom: 10px;
            }
        }
    }

    .data-disclaimer {
        h3 {
            margin-bottom: 16px;
        }

        .fancy-checkbox {
            width: 24px;
            height: 24px;
            padding: 4px;
            text-align: center;
            position: relative;
            top: 3px;

            .icon {
                font-size: 12px;
            }
        }
    }

    .lock {
        .lr {
            margin-right: 4px;
            font-size: 18px;
        }
    }

    .new-card {
        border: 1px dashed $defaultFontColor;
        width: 132px;
        min-height: 112px;

        @media(max-width: $screen-xs-max) {
            margin-top: 16px;
        }
    }

    .new-card-form {
        padding: 24px;
        padding-bottom: 9px;
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba($color: #000000, $alpha: .12);
    }

    .place-order-button-container {
        text-align: left;
        @media(min-width: $screen-sm-min) {
            text-align: right;
        }
    }

    .place-order-button {
        width: 136px;
        padding: 0;
    }

    .lock-icon {
        margin-right: 10px;
        @media(min-width: $screen-sm-min) {
            margin-right: 0;
        }
    }

    .cancel-button-container {
        margin-top: 24px;
    }
}

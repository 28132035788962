photo-picker {
    display: block;

    .photo-selection-zone {
        background-color: #f9fafb;
        border: 2px dashed #c9c9c9;

        &.dragover {
            border: 2px dashed #696969;
        }

        > div {
            margin: 12px;
        }

        .thumbnail-wrap {
            position: relative;
            max-width: 108px;
            min-width: 100px;

            .remove-text-btn {
                font-size: 14px;
                color: $errorRed;
            }
        }

        .thumbnail {
            position: relative;
            width: 100px;
            height: 75px;
            margin-right: 0;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.no-image {
                background-color: #bababa;
                display: inline-block !important;
            }

            .remove-image-btn {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                position: absolute;
                background-color: $giggity;
                color: #fff;
                box-shadow: 0 0 3px rgba(0,0,0,0.4);
                border-radius: 50%;
                top: -4px;
                right: -8px;
                font-size: 24px;
                cursor: pointer;
                @include transition(all, 0.2s, $bezier-out);

                &:hover {
                    background-color: $errorRed;
                    color: #fff;
                }
            }
        }

        .main-content {
            @media (max-width: $screen-sm) {
                margin-left: 12px;
            }
            .btn {
                margin-top: 6px;
                width: 90%;
            }

            .title {
                .lr {
                    color: $defaultFontColor;
                }
                line-height: 18px;
            }

            .tip-icon {
                font-size: 14px;
            }

            margin-left: 4px;
        }

        .cta {
            max-width: 150px;
            margin-left: 0;

            .btn {
                width: 82px;
                min-width: 82px;
                height: 42px;
            }

            .caption {
                margin-top: 6px;
            }
        }

        .error-box-wrap {
            margin-top: 10px;
        }
    }

    .border-error {
        border-color: $listreports-red;
    }
}

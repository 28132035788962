td2017-splash {
    display: block;
    background-color: $listreports-blue;

    .content {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;

        &.main {
            padding: 25px 0;
        }
    }

    .video-wrap {
        position: relative;
    }

    #splash-video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 497px;
        max-height: 280px;
    }

    header {
        padding: 20px;
        background: #fff;

        .lr-logo-wrap {
            img {
                height: 30px;

                @media (max-width: $screen-md-max) {
                    max-height: 20px;
                }
            }
        }

        .company-logo-wrap {
            img {
                max-width: 300px;
                max-height: 60px;

                @media (max-width: $screen-md-max) {
                    max-height: 40px;
                    max-width: 130px;
                }
            }
        }
    }

    .user-and-video {
        padding: 15px 30px;

        @media (max-width: $screen-md-max) {
            text-align: center;
        }

        @media (max-width: $screen-xs-max) {
            padding: 15px;
        }
    }

    .user-info {
        padding-right: 50px;
        color: #fff;
        max-width: 482px;
        margin-left: auto;
        margin-right: auto;

        .face-wrap {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            // box-shadow: 0 0 2px rgba(0, 0, 0, .8);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 20px 0 0;

            @media (max-width: $screen-sm) {
                width: 80px;
                height: 80px;
            }
        }

        h2 {
            margin: 0;
        }

        .headshot-name-company {
            text-align: left;
        }

        @media (max-width: $screen-md-max) {
            padding-right: 0;
            padding-bottom: 35px;
        }
    }

    .video {
        padding-left: 50px;

        .video-dropshadow {
            max-height: 70px;
            max-width: 100%;
        }

        @media (max-width: $screen-md-max) {
            padding-left: 0;
            max-width: 497px;
            max-height: 280px;
            margin: 0 auto;
        }
    }
}

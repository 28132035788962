.set-password {

    .input-link {
        position: absolute; 
        right: 5px; 
        top: 5px; 
        background: white; 
        padding: 5px 10px;
    }

}
    



transactions-edit {
    display: block;
    max-width: 1400px;

    form {
        display: block;
        max-width: 465px;
        margin: 0 auto;
    }

    .lr-question-mark-o {
        position: relative;
        top: 1px;
    }
}

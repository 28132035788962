$ph-bg:                   #fff !default;
$ph-color:                #ced4da !default;
$ph-border-radius:        2px !default;

$ph-gutter:               30px !default;
$ph-spacer:               15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: .8s !default;

.route-view-report-material #main-view {

    @media (max-width: $screen-sm-max) {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #ffffff;

        & > div:first-child{
            height: calc(100% - 67px);
        }
    }
}

.material-asset-close--btn-wrap{
    display: none;
    @media (max-width: $screen-sm) {
        position: absolute;
        top: 15px;
        right: 15px;

        .lr {
            color: $defaultFontColor;
        }

        display: block;
    }
}

.download {
    padding: 100px;
    background: #f4f7f8;

    .img-holder {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: $screen-sm) {
            margin-bottom: 20px;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 20px;
    }

    .download-image-container.stamp-image{
        position: relative;

        &::after{
            position: absolute;
            width: 595px;
            height: 314px;
            background-image: url('/images/downloads/stamp.png');
            top: 50%;
            margin-top: -157px;
            margin-left: -297.5px;
            content: '';
            z-index: 2;
            left: 50%;
            background-repeat: no-repeat;
        }

        &.stamp-medium::after{
            background-image: url('/images/downloads/stamp_medium.png');
            margin-top: -75.5px;
            margin-left: -143.5px;
            width: 287px;
            height: 150px;
        }
    }

}

.header-content-wrap.download-wrap {
    padding: 0;
    @media (max-width: $screen-sm-max) {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #ffffff;
    }

    &.view-material {
        background: #fff;

        .download-more-link{
            font-weight: bold;
            color: $listreports-blue;
        }

        .download-share-icon{
            font-size: 11px;
        }

        .mobile-footer{
            background: #ffffff;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;

            .mobile-footer-unpair-promo{
                padding: 16px;
                background: $frosty;
                font-size: 14px;
                line-height: 18px;
                color: $defaultFontColor;
            }
            .download-btn-disabled{
                &.btn-secondary{
                    border-color: #B4BBBF;
                    color: #B4BBBF;
                    cursor: default;
                }

                &.btn.btn-share.facebook{
                    background: #B4BBBF;
                    cursor: default;
                }
            }

            .mobile-footer-actions{
                padding: 12px;
                border-top:  1px solid $ice-ice-baby;

                .btn {
                    @media (max-width: $screen-md) {
                        padding: 11px 16px;
                        margin-right: 16px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }

                .dropdown-toggle-large{
                    @media (max-width: $screen-md) {
                        display: none;
                    }
                }

                .dropdown-menu{
                    @media (max-width: $screen-md) {
                        top: -300px;
                        min-width: 184px;
                    }

                    &.sm{
                        @media (max-width: $screen-md) {
                            top: -178px;
                        }
                    }
                }

                .dropdown{
                    @media (max-width: $screen-md) {
                       flex: 1;
                       display: flex;
                       margin-right: 16px;
                    }

                    .btn {
                        @media (max-width: $screen-md) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .mobile-header {
            min-height: unset;
            .logo-holder {
                padding: 15px;
                border-bottom: 1px solid $smoke;
            }

            .mobile-header-content{
                padding: 16px;
            }

            .mobile-header-title{
                display: flex;
                font-size: 14px;
                line-height: 18px;
                color: $defaultFontColor;
                padding-bottom: 9px;
                font-weight: bold;
                align-items: center;
                flex-wrap: wrap;
            }

            .mobile-header-desc{
                font-size: 14px;
                line-height: 18px;
                color: $defaultFontColor;
            }

            .ctas {
                padding: 15px;

                a {
                    margin-right: 15px;
                    padding-left: 0;
                    padding-right: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }

        .download {
            background: #525659;

            @media (min-width: $screen-sm) {
                overflow: scroll;
            }

            @media (max-width: $screen-xs-max) {
                padding: 20px 0;
            }
        }

        .download-iframe-content {
            background: #525659;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            overflow: hidden;
            padding-top: 0;
            position: relative;


            @media (max-width: $screen-md-max) {
                background: #ffffff;
                flex: 1;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &.unpaired{
                @media (max-width: $screen-md-max) {
                    background: #ffffff;
                    height: calc(100% - 68px);
                }
            }
        }

        .download-iframe{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            @media (max-width: $screen-md) {
                position: absolute;
                // height:calc(100vh - 300px);
            }
        }

        @media (min-width: $screen-sm) {
            overflow: hidden;
        }
    }
}

.download-nav {
    width: 200px;

    .breadcrumbs {
        padding: 15px 20px;
        border-bottom: 1px solid $ice-ice-baby;
    }

    .logo-holder {
        padding: 45px;
    }

    .material-name {
        background: #f2f2f2;
        padding: 10px;
    }

    .links {
        padding: 10px 20px;

        .btn-link {
            padding: 10px 0;

            .lr {
                top: 0;
            }
        }

        .tooltip {
            margin-left: 20px;
        }
    }

    .box {
        padding: 16px 16px 0;
        font-size: 14px;
        line-height: 18px;
        color: $defaultFontColor;


        .unpair-logo {
            margin-left: -8px;
        }

        .unpair-promo {
            position: static;
            width: 168px;
            height: 104px;
            background-color: #F8FAFB;
            border-radius: 4px;
            padding: 16px;
        }
    }

    .divider-line{
        padding-top: 16px;
        border-bottom:1px solid #D5DFE3 ;
    }

    .download-btn-disabled{
        display: block;
        font-weight: bold;
        padding: 10px 0;
        font-size: 14px;
        line-height: 18px;
        color: #B4BBBF;
        &.btn-secondary{
            border-color: #B4BBBF;
            color: #B4BBBF;
        }
    }
}





// .route-report-details-view-material {
//     .md-sidenav-left,
//     .main-header {
//         display: none !important;
//     }

//     .content-wrap {
//         padding: 0 !important;
//         margin: 0 !important;
//     }
// }

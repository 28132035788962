.resubscribe-modal {
    .modal-dialog {
        max-width: 480px;

        ul {
            li {
                margin-bottom: 10px;
            }
        }
    }
}

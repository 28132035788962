settings-my-market {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 0 8px;
        color: $defaultFontColor;
        padding: 10px 0;
        border-top: 1px solid $smoke;
        border-bottom: 1px solid $smoke;
        margin-bottom: -1px;
    }

    .caption {
        margin: 14px 0 12px;
    }

    .marketing-areas-form-wrap {

        .subtitle {
            margin-bottom: 5px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 15px;
            }
        }

        .form-group {
            max-width: 320px;
        }

        .areas-search {
            position: relative;
            min-width: 100%;
            max-width: 256px;

            input {
                width: 100%;
            }

            .icon {
                position: absolute;
                top: 10px;
                font-size: 16px;
                right: 10px;
                color: #ddd;
            }

            .search-result {
                color: $defaultFontColor;
                margin-top: -1px;
                border-style: solid;
                border-color: #ddd;
                border-width: 1px;
                padding: 10px 20px;
                background: #fff;

                .info {
                    @media (max-width: $screen-sm) {
                        max-width: 60%;
                    }
                }

                img {
                    max-width: 60px;
                    margin-right: 20px;
                }

                &.invite {
                    background: #ddd;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                &:focus {
                    outline: 0;
                }
            }

            .error-box {
                margin-top: 20px;
            }

            @media (max-width: $screen-sm) {
                margin-left: 0;
                margin-right: 0;
                max-width: 100%;
            }
        }

        .my-current-market {
            margin-top: 24px;
        }
    }

    .why-do-this-wrap {
        .caption {
            margin: 8px 0 32px;
        }
    }



    .lr-exclamation-triangle {
        top: 1px !important;
    }
}

.create-drip-modal {
    padding-left: 20px;
    padding-right: 20px;
    .modal-dialog {
        max-width: 520px;
    }

    span {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 18px;
        a {
            color: $listreports-blue;
            line-height: 18px;
            cursor: pointer;
        }
    }

    .error-box:before {
        font-size: 14px;
    }

    .error-box {
        margin-top: 20px;
    }

    input[type=submit], button {
        display:block;
        margin:0 auto;
    }

    input[type=submit]{
        margin-top: 20px;
        margin-bottom:10px;
        width: 95px;
    }

    form {
        label {
            font-size: 14px;
        }
        text-align: left;

    }

    .unit-input-container {
        margin-left: 8px;
        width: 51px;
    }

    .unit-input {
        padding: 0 6px;
    }
}
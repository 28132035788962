signup-tc {
    .signup {
        padding: 0 25px;

        @media (max-width: $screen-sm-max) {
            padding: 0;
            margin: 0 -20px;
        }

        > header.signup-header {
            display: block;
            position: relative;
            text-align: left;
            margin-top: -50px;
            margin-bottom: 0;
            font-size: 14px;

            .wrap {
                padding: 30px 0;
            }

            .divider {
                margin: 0 75px;

                @media (max-width: $screen-xs-max) {
                    margin: 17px 0 0;
                }
            }

            .bar {
                width: 40px;
                height: 2px;
                background: $ice-ice-baby;
                margin-bottom: 17px;
            }

            h2,
            h3 {
                margin: 0;
            }

            h3 {
                margin-top: -5px;

                @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                }
            }

            .vgradient {
                height: 42px;
                background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
                margin: 0 -75px;

                @media (max-width: $screen-sm-max) {
                    margin: 0;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-top: -20px;
                text-align: center;
            }
        }

        .title {
            margin: 0 auto 50px;

            @media (max-width: $screen-sm-max) {
                margin: 12px auto 30px;
            }
        }

        .signup-wrap {
            margin: 0 auto 20px;

            @media (max-width: $screen-sm-max) {
                margin: 0 auto;
            }
        }

        .form-wrap {
            padding: 0 75px 0 0;

            @media (max-width: $screen-sm-max) {
                padding: 0 20px 30px;
            }
        }

        .agent-wrap {
            border-left: 1px solid $ice-ice-baby;
            padding: 0 0 0 75px;

            header {
                margin-bottom: 0;
            }

            .headshot {
                margin-right: 25px;

                > img {
                    width: 70px;
                }
            }

            .divider {
                height: 4px;
                width: 50px;
                margin: 35px auto;
                background-color: $smoke;

                @media (max-width: $screen-xs-max) {
                    margin: 40px auto;
                }
            }

            ul {
                list-style: disc;
                padding-left: 15px;

                li {
                    margin-bottom: 15px;
                }
            }

            @media (max-width: $screen-sm-max) {
                border-left: none;
                border-bottom: 1px solid $ice-ice-baby;
                padding: 0 20px 30px;
                margin-bottom: 40px;
                text-align: center;
            }
        }

        .video-wrap {

            margin-top: 25px;
            margin-bottom: 25px;

            #tc_signup_video {
                width: 316px;
                height: 175px;

                @media (max-width: $screen-xs-max) {
                    width: 293px;
                    height: 165px;
                }
            }
        }
    }

    .subtext {
        small {
            color: $sidewalk;
        }
    }

    .hsubtitle {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    .info {
        margin-bottom: 8px;
        font-size: 14px;
    }

    label {
        margin-bottom: 4px;
    }

    .label {
        margin: 0;
    }
}

agentfarm-no-results-listside {
    display: block;


    .container {
        min-height: 370px;
        padding: 24px;

        h2 {
            margin-bottom: 0;
        }
    }
}

agentfarm-no-results-buyside {
    display: block;

    .container {
        min-height: 370px;
        padding: 24px;

        h2 {
            margin-bottom: 0;
        }
    }

    .no-listings {
        height: 370px;

        img {
            width: 133px;

            @media (max-width: $screen-xs-max) {
                width: 90px;
            }
        }
    }
}

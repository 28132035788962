.forgot-password {
    h1 {
        font-size: 24px;
        margin-bottom: 5px;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }

    p {
        margin-top: 0;
    }

    .footer-text {
        font-size: 14px;

        .btn-link {
            top: -1px;
        }
    }
}


.cbaSweepstakeModal {
    .modal-dialog {
        max-width: 100%;
        width: 924px;
        padding: 16px;
        margin: auto;
    }
    .modal-content {
        padding: 0;
    }
}

.cbaSweepstakes-title {
    margin-bottom: 24px;
}

.cbaSweepstakes-text {
    font-size: 18px;    
}

.cbaSweepstakes-images {
    position: relative;
    width: 100%;
    height: 100%;
    top: 16px;
    
    @media (min-width: $screen-md-min) {
        display: flex;
        align-items: center;
        top: 0;
    }
}

.cbaSweepstakes-imageContainer {
    width: 100%;
    height: 290px;
    position: relative;
    overflow: hidden;

    @media (min-width: $screen-md-min) {
        height: initial;
        width: 380px;
        overflow: initial;
    }
}

.cbaSweepstakes-abstractBgContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
}

.cbaSweepstakes-abstractBg {
    max-width: 380px;
    width: 100%;
}

.cbaSweepstakes-cbaImageContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
}

.cbaSweepstakes-cbaImage {
    width: 258px;

    @media(min-width: $screen-md-min) {
        width: 296px;
    }
}

.cbaSweepstakes-formContainer {
   padding: 16px;
   
   @media(min-width: $screen-md-min) {
       padding: 42px;
   }
}
.invite-sent-call-log-modal {
    .modal-dialog {
        max-width: 520px;

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }

    .box-wrap {
        padding: 20px 32px;
        border: 1px solid $ice-ice-baby;
        margin: 12px auto;
    }
}

.statsbar {

    .tooltip {
        width: 200px;
    }

    .stat {
        position: relative;
        height: 91px;
        border-right: 1px solid $ice-ice-baby;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
            position: relative;
            z-index: 2;
            text-align: center;
        }

        .num {
            position: relative;
            text-align: center;
            padding-bottom: 10px;
            top: 2px;

            @media (max-width: $screen-sm-max) {
                font-size: 18px;
                margin-bottom: 3px;
                padding-bottom: 5px;
                margin-right: 0;
                text-align: center;
            }
        }

        h2 {
            margin: 0;
        }

        h3 {
            font-size: 18px;
            color: $defaultFontColor;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;

            @media (max-width: $screen-sm-max) {
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .icon {
            img {
                max-width: 55px;
            }
        }

        &:last-child {
            border-right: 0;
        }

        @media (max-width: $screen-md-max) {
            text-align: center;
        }

        @media (max-width: $screen-sm-max) {
            border-right: 0;
            padding: 0;
        }
    }
}

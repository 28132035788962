abn-banner {
    display: block;
    padding: 24px;
    background: $listreports-blue;
    color: white;

    banner {
        padding: 0;

        .title {
            @extend h2;
            margin: 0;
        }

        .coming-soon {
            @media (max-width: $screen-xs-max) {
                margin-bottom: 10px;
            }
        }
    }

    .body-text {
        font-weight: bold;

        @media (max-width: $screen-xs-max) {
            margin: 20px 0;
        }
    }

    .btns {
        margin-left: 24px;

        @media (max-width: $screen-xs-max) {
            max-width: 160px;
            margin: 0 auto;
        }

        .btn:first-child {
            margin-bottom: 10px;
        }
    }

    .abn-counter {
        margin-top: 6px;
    }

    .img-holder {
        max-width: 280px;
        margin: 0 auto 24px;

        img {
            max-width: 280px;

            @media (min-width: $screen-sm) {
                max-width: 140px;
            }
        }

        @media (min-width: $screen-sm) {
            max-width: 140px;
            margin: 0;
            margin-right: 24px;
        }
    }

    @media (max-width: $screen-xs-max) {
        text-align: center;
    }
}

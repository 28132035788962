@import '../bootstrap/variables';
@import '../../../styles/colors';

title-bar {
    display: block;

    h2 {
        margin-top: -32px;
        margin-bottom: 32px;

        @media (max-width: $screen-xs-max) {
            margin-top: -18px;
            margin-bottom: 18px;
        }
    }

    .title-holder {
        > * {
            display: inline-block;
        }

        h1 {
            margin-top: 0;
            
            @media (max-width: $screen-xs-max) {
                margin-bottom: 0;
            }
        }
    }

    .beta {
        font-size: 10px;
        color: #fff;
        background: $listreports-orange;
        padding: 4px 6px 3px;
        border-radius: 3px;
        position: relative;
        top: -19px;

        @media (max-width: $screen-xs-max) {
            top: -11px;
        }
    }

    .badge {
        font-size: 14px;
        padding: 6px 8px;
        border-radius: 4px;
        position: relative;
        top: -10px;
        @media (max-width: $screen-xs-max) {
            top: -7px;
        }
    }

    .badge-free-trial {
        color: white;
        background-color: #2F3C49;
    }

    .badge-plus {
        color: #000000;
        background-color: $listreports-orange;
    }

    .img-wrap {
        margin-right: 15px;
    }

    &.smaller-title {
        h1 {
            font-size: 36px;
            line-height: 36px;

            @media (max-width: $screen-xs-max) {
                font-size: 28px;
            }
        }

        h2 {
            font-size: 18px;
            margin-top: -24px;
            margin-bottom: 24px;

            @media (max-width: $screen-xs-max) {
                margin-top: -18px;
                margin-bottom: 18px;
            }
        }
    }

    .video-btn {
        font-size: 14px !important;
    }

    lead-status-symbol {
        display: block;
        margin-left: 10px;
    }

    .btn-single-action {
        @media (max-width: $screen-xs-max) {
            margin-top: 4px;


            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    .btnholder {
        @media (max-width: $screen-xs-max) {
            margin-top: 18px;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

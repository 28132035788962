agent-search {
    .autocomplete-search {
        width: 100%;
    }

    .search {
        position: relative;
        padding-right: 0;
        padding-left: 0;

        i.lr {
            position: absolute;
            right: 8px;
            top: 8px;
        }

        input {
            padding-right: 45px;

            &:focus {
                box-shadow: none !important;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            margin-top: -14px;
            right: 5px;
            color: $defaultSecondaryTextColor;
        }
    }

    .search-result {
        margin-top: -1px;

        border-style: solid;
        border-color: $ice-ice-baby;
        border-width: 1px;
        padding: 10px;

        &.listing-variant{
            cursor: pointer;
            &:hover{
                background-color: #F8FAFB;
            }
        }

        img {
            max-width: 35px;
            margin-right: 20px;
        }

        &.invite {
            background: $ice-ice-baby;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:focus {
            outline: 0;
        }

        .listing-info span {
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            color: $defaultSecondaryTextColor;
            &:before{
                content: '•';
                display: inline-block;
                padding-right: 4px;
            }

            &:first-child:before{
                display: none;
            }
        }
    }

    .sublabel {
        font-weight: normal;
    }

    .view-agent-details {
        margin: 20px 0 0;

        h1 {
            font-size: 21px;
            margin: 0;
            text-transform: uppercase;
        }

        .info {
            padding-left: 10px;

            @media (max-width: $screen-xs-max) {
                padding-left: 0;
                text-align: center;
            }
        }

        .edit-btn {
            margin-top: 2rem;
        }
    }

    .email {
        word-break: break-word;
    }

    .img-wrap {
        padding: 10px;
        text-align: center;
        width: 300px;
        height: 120px;
        background: $smoke;
        margin-right: 20px;
        max-width: 100%;

        @media (max-width: $screen-sm-max) {
            margin-bottom: 15px;
            margin-right: 0;
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }

    .stats {
        padding: 0 15px;
        overflow: hidden;
        line-height: 1.3;

        .stat {
            @media (max-width: $screen-sm-max) {
                font-size: 10px;
                color: $defaultFontColor;
                margin-top: 10px;
                border-right: 1px solid #9b9a9a;

                &:last-child {
                    border-right: 0;
                }
            }
        }

        &.locked {
            .stat {
                @media (max-width: $screen-sm-max) {
                    color: #cccccb;
                    border-right: 1px solid #cccccb;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            margin-left: -10px;
            margin-right: -10px;
            padding: 0;
        }
    }

    .collapsedAgent {
        margin-bottom: 10px;

        .collapsedAgentContent {
            margin-bottom: 20px;
        }

        .img-circle {
            width: 100px;
            margin: 15px;
        }

        .agent-name {
            font-weight: bold;
            color: $defaultFontColor;
            font-size: 20px;
        }

        .spacer {
            margin-top: 15px;
            padding-bottom: 15px;
            border-top: solid 2px;
            width: 60px;
            border-color: #DDD;
            border-radius: 1px;
        }
    }

    .caption {
        margin-bottom: 15px;
    }

    .btn.btn-sm.dropdown-toggle {
            height: 42px;
            @media (max-width: $screen-xs-max) {
                height: 36px;
            }
    }

}

.text-drip-alert-select-modal {
    padding: 36px 75px;

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 20px 32px;
    }

    header {
        h2 {
            margin: 14px auto;
            font-size: 24px;

            @media (max-width: $screen-xs-max) {
                margin: 12px auto;
            }
        }
    }

    .form-wrap {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;

        .caption {
            margin-bottom: 24px;
        }

        input[type="radio"] {
            margin-top: -1px;
        }

        label {
            font-size: 16px;
            margin: 0 0 0 8px;
        }
    }

    section.body {
        margin: 24px auto;
    }

    .submit-wrap {
        margin-top: 40px;

        .btn-cancel {
            margin-top: 12px;
        }
    }

}

.route-agentfarm-listing.modal-open {
    overflow: hidden !important;
}

.fullscreen-carousel-modal {
    overflow: hidden;

    .modal-dialog {
        padding: 0;
        background: rgba(0, 0, 0, .90);
        text-align: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .modal-content {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 14px;
        background: transparent;
    }

    .fullscreen-carousel,
    .carousel,
    slick,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide > div {
        height: 100%;
    }

    .image-container {
        height: 100%;
    }

    .slick-slider {
        max-width: none;
        margin-bottom: 0;
    }

    .carousel {
        position: relative;
        width: 100%;
        overflow: hidden;

        .img-holder {
            background: center center no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;

            @media (max-width: $screen-xs-max) {
                height: 100%;
            }
        }

        .slick-arrow {
            padding: 0;
        }

        .btn-prev,
        .btn-next {
            z-index: 1;
            position: absolute;
            display: block;
            cursor: pointer;
            top: 50%;
            margin-top: -10px\9; /*lte IE 8*/
            transform: translate(0, -50%);
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            color: #fff;

            &:hover {
                opacity: 0.7;
            }
        }

        .btn-prev {
            left: 2px;
            /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */

            [dir="rtl"] & {
                left: auto;
                right: -25px;
            }

            @media (max-width: $screen-xs-max) {
                font-size: 8px;
                left: 0;
            }
        }

        .btn-next {
            right: 2px;
            /* text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5); */

            [dir="rtl"] & {
                left: -25px;
                right: auto;
            }

            @media (max-width: $screen-xs-max) {
                font-size: 8px;
                right: 0;
            }
        }

        .slick-dots {
            margin-top: 20px;

            li {
                height: auto;
                width: 20%;
                max-width: 200px;

                .overlay {
                    background: rgba(0,0,0,0.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                a {
                    padding: 0;
                }

                &.slick-active {
                    .overlay {
                        display: none;
                    }

                    a {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .thumbnails {
        height: 150px;
        margin-left: -5px;
        margin-right: -5px;
        padding-top: 32px;
        padding-bottom: 12px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        > div {
            position: relative;
            width: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            padding-bottom: 20px;
        }

        .thumbnail {
            background: center center no-repeat;
            background-size: cover;
            width: 118px;
            height: 82px;
            position: relative;
            margin-left: 5px;
            margin-right: 5px;
            display: inline-block;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,0.6);
                z-index: 1000;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .overlay {
                    display: none;
                }
            }
        }
    }

    .close-modal {
        position: absolute;
        top: 3px;
        right: 14px;
        cursor: pointer;
        font-size: 54px;
        color: #fff;
        z-index: 9999;

        &:hover {
            // color: $searchresults-default-text;
        }
    }
}

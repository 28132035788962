agentfarm-listings {
    .no-listings {
        height: 370px;
        padding-bottom: 20px;

        img {
            width: 133px;

            @media (max-width: $screen-xs-max) {
                width: 90px;
            }
        }

        h1 {
            margin-bottom: 0;
            font-size: 24px;

            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }

        .btn-link {
            font-size: 18px;
            font-weight: normal;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
            height: 250px;
        }
    }

    .listings {
        padding: 20px;

        .listing-btn {
            color: $defaultFontColor;
            text-decoration: none;
        }

        @media (max-width: $screen-xs-max) {
            padding: 0;
            margin: 0;
            margin-bottom: 15px;
        }
    }

    .listing-wrap {
        position: relative;
        padding: 10px;

        @media (max-width: $screen-xs-max) {
            padding: 0;
        }
    }

    .pageit-wrapper {
        padding: 20px;
        padding-top: 0;

        @media (max-width: $screen-xs-max) {
            padding: 5px 15px 20px;
        }
    }

    .pageof {
        text-align: right;
        padding: 0 21px;
        padding-top: 23px;
        padding-bottom: 0;

        .listing-not-found {
            @media (max-width: $screen-xs-max) {
                padding: 0 0 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            text-align: right;
            padding: 16px 25px;
        }
    }
}

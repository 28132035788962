.invite-your-coworkers,
invite-your-coworkers {
    display: block;

    header {
        position: relative;
        margin-bottom: 0 !important;

        .limited-time-offer {
            position: absolute;
            top: -68px;
            left: 50%;
            margin-left: -158px;
            max-width: 80px;

            @media (max-width: $screen-xs-max) {
                margin-left: -150px;
                top: -52px;
            }
        }

        .main-image {
            margin-bottom: 24px;

            img {
                display: inline-block;
            }
        }

        h2 {
            max-width: 380px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: $screen-xs-max) {
                max-width: 275px;
            }
        }

        h3 {
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .separator {
        margin: 34px auto;
        width: 44px;
        height: 4px;
        background-color: $listreports-blue;
        border-radius: 4px;
    }

    .copy-unique-link {
        h3 {
            margin: 0 auto;

            &:first-child {
                margin-bottom: 18px;

                @media (max-width: $screen-xs-max) {
                    max-width: 240px;
                }
            }

            &:last-child {
                margin-top: 18px;

                @media (max-width: $screen-xs-max) {
                    max-width: 190px;
                }
            }
        }

        .copy-input {
            margin: 0 auto;
            max-width: 500px;
        }
    }

    .select-individuals-wrap {
        max-width: 555px;
        margin: 44px auto;
        border: 9px solid $ice-ice-baby;
        padding: 35px 0;

        @media (max-width: $screen-xs-max) {
            padding: 23px 0;
        }
    }

    .select-individuals {
        max-height: 300px;
        overflow: none;
        overflow-y: auto;

        > div {
            padding: 35px 35px 0;

            @media (max-width: $screen-xs-max) {
                padding: 23px 23px 0;
            }

            &:first-child {
                padding-top: 0;
            }
        }

        fancy-checkbox {
            display: block;
        }

        .label-content {
            position: relative;
            top: -3px;
        }
    }

    fancy-checkbox {
        h3 {
            position: relative;
            top: -3px;
        }
    }

    @media (max-width: $screen-xs-max) {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -20px;
    }
}

.select-lo-text {
    max-width: 500px;
}

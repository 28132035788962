.my-leads-content {

    table {
        img {
            width: 60px;
            height: 60px;

            @media (max-width: $screen-xs-max) {
                width: 45px;
                height: 45px;
            }
        }

        .title {
            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }
    }

    .textdrip {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0;
        border: 1px solid #7ba915;
        border-radius: 50%;

        &.yes {
            background: #7ba915;
        }
    }

    a {
        color: $listreports-blue;
        cursor: pointer;
        font-weight: bold;
    }

    .attention-banner {
        width: 30px;
    }

    .section-group:first-child {
        @media (max-width: $screen-xs-max) {
            border-bottom-width: 0px;
        }
    }

    .lr-caret-up {
        font-size: 10px !important;

        &.lr-selected {
            color: $listreports-blue;
        }
    }

    .info-row {
        height: 90px;
    }

    .source-column {
        max-width: 200px;
    }

    .btn-column {

        width: 160px;

        .btn-secondary {
            max-width: 120px;
        }

        .btn-group {
            max-width: 120px;
            width: auto;
            .btn-secondary {
                max-width: 80px;
                display: inline;

                &.dropdown-toggle {
                    max-width: 40px;
                }
            }
        }
    }

    .filter-btn {
        display: flex;
        border: 0;
        align-items: center;
        padding: 10px;
        width: 75px;
        min-width: 75px;
    }

    header {
        padding: 10px !important;
    }

    .just-listed-value-proposition {

        @media(min-width: $screen-xs-max) {
            margin: 40px;
        }

        margin: 15px;

        .just-listed-alert-image {
            height: 250px;
            width: unset;
        }

        .just-listed-alert-text {

            @media(min-width: $screen-xs-max) {
                margin-left: 60px;
            }

            max-width: 375px;
            min-height: 180px;

            h1 {
                font-size: 28px;
                margin: 16px 0;
            }

            h3 {
                font-size: 18px;
                margin-bottom: 5px;
            }

            .text {
                margin-bottom: 16px;
            }
        }

    }

    .header-row {
        height: 50px;

        .text {
            margin-right: 5px;
        }
    }

    .page-container {
        border-top: 1px solid #ddd !important;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .search-form {
        position: relative;

        input {
            padding-right: 38px;
        }

        .clear {
            position: absolute;
            top: 50%;
            right: 60px;
            margin-top: -6px;
            font-size: 12px;
            cursor: pointer;

            @media(max-width: $screen-xs-max) {
                font-size: 14px;
                margin-top: -7px;
            }
        }

        .input-group-btn {
            width: 45px;
        }

        .submit-btn {
            height: 44px;
            width: 45px;
        }
    }
    
    search-input {
        .form-control {
            width: 360px;
            @media(max-width: $screen-xs-max) {
                width: 100%;
            }
        }
    }

    .my-listreports-taken {
        opacity: 0.5
    }

    .cell-fit-content {
        width: fit-content;
    }
    
}

@import './lead-status-symbol/styles';

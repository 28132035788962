@import '../../../../styles/colors';
@import '../../bootstrap/variables';

select-lender-banner {
    .select-lender {
        background: $listreports-red;
        color: #fff;
        padding: 12px;
        margin-bottom: 15px;

        .lr-exclamation-triangle {
            width: 28px;
            min-width: 28px;
        }

        .banner-text {
            padding-left: 8px;

            .link {
                color: #fff;
                padding-bottom: 1px;
                border-bottom: 1px solid #fff;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .btn {
            min-width: 128px;
            border: 1px solid #fff;

            @media (max-width: $screen-xs-max) {
                margin-top: 12px;
            }

            &:hover {
                border: 1px solid #fff;
            }
        }
    }

}

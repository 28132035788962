.my-team {
    max-width: 1400px;

    title-bar {

        padding-left: 0;
        padding-right: 0;

        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .title {
        margin-right: 25px;
        margin-left: 25px;

        &.limit-width {
            max-width: 290px;
        }

        > h3 {
            border-bottom: 1px solid $ice-ice-baby;
            margin-bottom: 16px;
            padding-bottom: 12px;
        }
    }

    .card-wrapper {
        > div {
            margin-right: -20px;
            margin-left: -25px;
        }
    }

    .titles {
        margin-top: 20px;
    }

    user-info-card {
        margin: 60px 25px 25px !important;

        &[pending="true"] {
            > div > div > div {
                opacity: 0.5;

                &.pending,
                &.headshot-holder {
                    opacity: 1;
                }

                &.headshot-holder {
                    img {
                        opacity: 0.5;
                    }
                }
            }

            .ctas > div {
                opacity: 1;
            }
        }

        @media (min-width: $screen-sm) {
            max-width: 290px !important;
        }
    }

    .no-card-found {
        margin: 60px 25px 25px !important;
        border: 2px dashed $giggity;
        padding: 80px 40px 40px;

        > div {
            height: 100%;
        }

        h2,
        h3 {
            margin: 0;
            padding: 0;
        }

        .ctas {
            margin-top: 25px;
        }

        @media (min-width: $screen-sm) {
            max-width: 290px !important;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@import '../banners/invited-lender-banner/styles';

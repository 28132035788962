.covid-19-update-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: $listreports-orange;
    color: white;


    @media (min-width: $screen-sm-min){
        padding: 24px 40px;
    }

    .banner-title {
        width: 100%;
    }

    .crisis-text-box {
        max-width: 400px;
        margin-top: 16px;

        @media (max-width: $screen-xs-max) {
            text-align: center;
            margin-top: 24px;
        }
    }

    .video {
        position: relative;
        width: 100%;
        max-width: 285px;
        margin-left: 40px;
        @media (max-width: $screen-xs-max) {
            margin-left: 0;
            max-width: unset;
            margin-top: 24px;
        }

        .video-thumbnail{
            width: 100%;
            cursor: pointer;
        }
    }

    @media (max-width: $screen-xs-max) {
        flex-direction: column;
    }

}
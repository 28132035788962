.signup {
    padding: 0 27px;
    @media (max-width: $screen-md) {
        padding: 0;
    }

    > header.signup-header {
        display: block;
        position: relative;
        text-align: left;
        margin-top: -50px;
        margin-bottom: 0;
        font-size: 14px;

        .wrap {
            padding: 30px 0 10px;
            h2 {
                font-size: 28px;
                line-height: 18px;
            }
        }

        .divider {
            margin: 0 65px;

            @media (max-width: $screen-xs-max) {
                margin: 17px 0 0;
            }
        }

        .bar {
            width: 40px;
            height: 2px;
            background: $ice-ice-baby;
            margin-bottom: 17px;
        }

        h2,
        h3 {
            margin: 0;
        }

        h3 {
            margin-top: -5px;

            @media (max-width: $screen-xs-max) {
                margin-top: 0;
            }
        }

        .vgradient {
            height: 42px;
            background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
            margin: 0 -77px;

            @media (max-width: $screen-sm-max) {
                margin: 0 -50px;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-top: -20px;
            text-align: left;
        }
    }

    .hsub {
        font-size: 18px;
        margin-top: -15px;
        margin-bottom: 30px;

        @media (max-width: $screen-sm) {
            font-size: 16px;
        }

        a {
            font-size: 18px;
            @media (max-width: $screen-sm) {
                font-size: 16px;
            }
        }
    }

    .face-wrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0, 0, 0, .8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 10px;

        @media (max-width: $screen-sm) {
            width: 80px;
            height: 80px;
        }
    }

    .btn-upload {
        font-weight: bold;
    }

    .footer-text {
        font-size: 14px;
    }

    p.label, .label a {
        font-size: 14px;
        margin-top: 0;
    }

    .form-banner {
        margin-bottom: 30px;
        font-size: 18px;
        padding: 20px 52px;

        @media (max-width: $screen-md) {
            padding: 12px 24px;
            font-size: 16px;
        }

        .icon-wrap {
            margin-right: 16px;
            min-width: 34px;
            font-size: 26px
        }
    }

    .terms-conditions-wrap {
        margin-bottom: 10px;

        .label {
            margin-bottom: 0;
        }
    }

    .upload-label {
        margin-bottom: 6px;
    }

    .input-group {
        .input-group-btn {
            .btn-ice-ice-baby:active,
            .btn-ice-ice-baby:hover {
                border-color: $ice-ice-baby;
            }
        }

        .btn.dropdown-toggle {
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }

    .blue-bg {
        background-color: $listreports-blue;
        color: #fff;
    }

    .info {
        margin-bottom: 8px;
        font-size: 14px;
    }

    .info-account {
        margin-bottom: 24px;
    }

    .infobox {
        color: $defaultFontColor;
        background-color: #F8FAFB;
        border-radius: 8px;
        font-size: 12px !important;
        line-height: 18px;
        margin-bottom: 24px;
        margin-top: 27px;
        padding: 16px;
    }

    .content-wrap {
        h3 {
            font-size: 28px;
            line-height: 18px;
            margin: 16px 0;
        }

        > .divider {
            width: 1px;
            // background-color: $ice-ice-baby;
            margin: 0 65px;

            @media (max-width: $screen-xs-max) {
                width: auto;
                height: 1px;
                margin: 30px -20px;
            }
        }
    }

    .relative-div {
        position: relative;
    }

    .show-hide-password {
        position: absolute;
        font-size: 14px;
        line-height: 16px;
        right: 12px;
        top: 80%;
        transform: translate(0, -80%);
        color: $defaultFontColor;
        font-weight: 700;
        cursor: pointer;
    }
}

@import './agent-sidepanel/styles';

@import '../../styles/colors';

$bezier-out: cubic-bezier(.15,.25,.53,.96);

@import './bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/normalize';
@import 'bootstrap/scaffolding';
@import 'bootstrap/tables';
@import 'bootstrap/modals';
@import 'bootstrap/tooltip';
@import 'bootstrap/responsive-embed';
@import 'angular-material.scss';
@import '../../styles/utilities';
@import './services/placeholder-loading/styles';

$defaultFontFamily: 'proxima-soft';

* {
    // font-family: $defaultFontFamily;
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;

    &:focus {
        outline: 0 !important;
    }
}

// Set a default border style and color for all borders.
// This is useful when we want separator so that we only need to set the border-width
// The rules is set to a low specificity selector so it will not affect other border rules.
*, :after, :before {
    border: 0 solid $smoke;
}

html {
    font-size: 100%;
}

body {
    font-family: $defaultFontFamily;
    font-size: 14px;
    line-height: 1.3;
    color: $defaultFontColor;
    background-color: #ecf0f1;
    overflow: visible;
    height: auto;
    min-height: 100%;

    // Commented out because it scrolls back to the top
    // of the page on modal open and this may be unnecessary
    // Remove if still commented out in/after September 2022 - amish@listreports.com
    // &.modal-open {
    //     @media (max-width: $screen-xs-max) {
    //         position: fixed;
    //     }
    // }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1 {
    font-size: 48px;
    line-height: 1;
    font-weight: bold;

    .btn-link {
        font-size: 48px;
    }

    @media (max-width: $screen-xs-max) {
        font-size: 34px;
        margin-top: 18px;
        margin-bottom: 18px;

        .btn-link {
            font-size: 34px;
        }
    }
}

.full-v{
    height: 100%;
}

.button-link-primary{
    color: #007780;
    font-weight: 700;

    &:focus,
    &:hover{
        color: #0C949D;
    }
}

h2 {
    font-size: 28px;
    line-height: 1.2;

    .btn-link {
        font-size: 28px;
    }

    @media (max-width: $screen-xs-max) {
        font-size: 24px;

        .btn-link {
            font-size: 24px;
        }
    }
}

h3 {
    font-size: 18px;
    line-height: 1.3;
    margin: 0;

    .btn-link {
        font-size: 18px;
    }
}

textarea {
    resize: none;
}

$placeholderColor: #d6dcde;
::-webkit-input-placeholder {
    color: $placeholderColor !important;
}

:-moz-placeholder {
    color: $placeholderColor !important;
}

::-moz-placeholder {
    color: $placeholderColor !important;
}

:-ms-input-placeholder {
    color: $placeholderColor !important;
}

input[type=date]:invalid::-webkit-datetime-edit {
    color: $placeholderColor !important;
}

input[disabled] {
    // Fixes ios issue where disabled input color is imperceptible
    opacity: 1;
    -webkit-text-fill-color: $defaultFontColor;
}

md-content {
    background-color: #fff;
}

.unstyled-link, .unstyled-link:hover {
    color: inherit;
    text-decoration: none;
}

@import './buttons/styles';
@import './forms/styles';
@import './modals/styles';
@import './datetime-picker/styles';
@import './date-input/styles';
@import '../collapsable-section/collapsable-section-styles';
@import '../force-refresh/banner/styles';
@import '../../styles/slick';
@import 'tablesort';
@import '../core/fancy-checkbox/styles';
@import '../core/fullscreen-loading/styles';
@import '../core/sticky-tooltip/styles';
@import '../core/odometer/styles';
@import './header/styles';
@import './header/notification-center/styles';
@import './header/notification-center/notification/styles';
@import './footer/styles';
@import './footer-idp/styles';
@import './logged-out-wrapper/styles';
@import './logged-out-lr-mbs-wrapper/styles';
@import './loggedin-wrapper/styles';
@import './loggedin-out-wrapper/styles';
@import './dashboard/styles';
@import './tabbar/styles';
@import './login/styles';
@import './login-idp/styles';
@import './login-phone/styles';
@import './sign-up/styles';
@import './account-verification-code/styles';
@import './intro-video/styles';
@import './webinar-registration/styles';
@import './agent-offer-signup/styles';
@import './create-password/styles';
@import './forgot-password/styles';
@import './forgot-password-idp/styles';
@import './reset-password/styles';
@import './view-report/styles';
@import './tooltip/styles';
@import './photo-selection/styles';
@import './listing-card/styles';
@import './notes/styles';
@import './my-agents/styles';
@import './my-leads/styles';
@import './my-agents/view-agent/styles';
@import './my-agents/invite-agent-modal/styles';
@import './my-agents/invite-tc-modal/styles';
@import './my-agents/resend-invite-modal/styles';
@import './my-agents/robin-agent-modal/styles';
@import './my-agents/deactivate-robin-agent-modal/styles';
@import './my-leads/new-just-listed-alert/styles';
@import './my-leads/new-recently-sold-alert/styles';
@import './my-leads/add-lead-modal/styles';
@import './my-leads/additional-agent-info-modal/styles';
@import './users/styles';
@import './users/export-all-modal/styles';
@import './profile/styles';
// @import './shareables/styles';
@import './agentfarm/styles';
@import './agent-details/notes-agent-modal/styles';
@import './agent-details/providers-agent-modal/styles';
@import './profile/user-subscriptions/styles';
@import './profile/edit-payment-modal/styles';
@import './cancel-subscription/styles';
@import './pageit/styles';
@import './nestme/styles';
@import './nestme/share/styles';
@import './contact-us/styles';
@import './new-listreport-form/styles';
@import './preferred-vendor/styles';
@import './agent-details/styles';
@import './agent-listing-stats/styles';
@import './agent-search/styles';
@import './resources/styles';
@import './resources-navbar/styles';
@import './resources-university/styles';
@import './resources-university/resources-university-card/styles';
@import './download/styles';
@import './verify-compliance/styles';
@import './paywall-page/styles';
@import './view-listing/styles';
@import './view-buyside-listing/styles';
@import './interstitials/styles';
@import './error-pages/styles';
@import './rusc/styles';
@import './loan-scenario-approved/styles';
@import './loan-scenario-rejected/styles';
@import './edit-report/styles';
@import './edit-report-feedback-form/styles';
@import './plans/styles';
@import './credit-card-collection/styles';
@import './compliance/accounts/styles';
@import './compliance/reports/styles';
@import './compliance/shareables/styles';
@import './compliance/view-individual/styles';
@import './compliance/view-branch/styles';
@import './compliance/users/styles';
@import './compliance/button-change-billing-profile/styles';
@import './enroll-stats/styles';
@import './enroll-agents-stats/styles';
@import './headshot-lineup/styles';
@import './my-agents-stats/styles';
@import './my-lender/styles';
@import './my-team/styles';
@import './special-events/td2017-splash/styles';
@import './invite-your-coworkers/styles';
@import './invite-your-coworkers/message/styles';
@import './user-info-card/styles';
@import './activate-pals-confirm/styles';
@import './banners/styles';
@import './active-buyer-network/header/styles';
@import './active-buyer-network/address/styles';
@import './active-buyer-network/stats/styles';
@import './active-buyer-network/buyers/styles';
@import './active-buyer-network/buyer-details/styles';
@import './active-buyer-network/match-score-circle/styles';
@import './active-buyer-network/profile-block/styles';
@import './active-buyer-network/coc/styles';
@import './active-buyer-network/property-profile/styles';
@import './active-buyer-network/property-confirmation/styles';
@import './active-buyer-network/view-listing-buyers/styles';
@import './active-buyer-network/dashboard/styles';
@import './price-slider/styles';
@import './relationships/edit-agent/styles';
@import './relationships/pre-approved-qualified-info-form/styles';
@import './photo-picker/styles';
@import '../autocomplete-search/autocomplete-search-styles';
@import './corporate-approval-rejection/styles';
@import './vendor-search/styles';
@import './vendor-admin-for-dropdown/styles';
@import './cancellation/styles';
@import './success-landing-page/styles';
@import './settings/styles';
@import './transactions/styles';
@import './transactions/new-transaction/styles';
@import './avm-home-evaluation-request/styles';
@import './under-review/styles';
@import './multi-file-uploader/styles';
@import './copy-input/styles';
@import './area-manager/styles';
@import './statsbar/styles';
@import './avatar/styles';
@import './wistia-video-player/styles';
@import './pill/styles';
@import './paywall-buyside-data/styles';
@import './creditcard/styles';
@import './follow-plus-credits-pill/styles';
@import './credit-card-collection-terminal/styles';
@import './stripe-terminal/styles';
@import './home/home-agent/styles';
@import './home/home-lo/styles';
@import './flyer-preview/styles';
@import './photo-selection-preview/styles';
@import './simple-file-uploader/styles';
@import './activity-feed/styles';
@import './reports/styles';
@import './search-input/styles';
@import './states-dropdown/styles';
@import './title-bar/styles';
@import './arba-invite-message/styles';

// Everything below this should never be overriden

.ib {
    display: inline-block;
}

.clickable {
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.naturalcase {
    text-transform: none !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.strikethrough {
    text-decoration: line-through !important;
}

.strikeout {
    line-height: 1em;
    position: relative;
}
.strikeout::after {
    position: absolute;
    content: "";
    border-bottom: 0.125em solid $listreports-red;
    margin-top: calc(0.125em / 2 * -1);
    top: 50%;
    right: 0;
    left: 0;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-center-xs {
    @media (max-width: $screen-xs-max) {
        text-align: center !important;
    }
}

.text-center-gt-xs {
    @media (min-width: $screen-sm) {
        text-align: center !important;
    }
}

.text-right {
    text-align: right !important;
}

.bold {
    font-weight: bold !important;
}

.medium {
    font-weight: 500 !important;
}

.not-bold,
.regular {
    font-weight: normal !important;
}

.no-pad-bottom {
    padding-bottom: 0 !important;
}
.no-pad {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

slick {
    // center the arrows
    .move-left, .move-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 60px;
    }

    .move-left {
        left: -45px;
    }

    .move-right {
        right: -45px;
    }
}

.slick-dots li {
    margin: 0 2px;
}

// make the dots bigger and use our font
.slick-dots li button:before {
    font-size: 30px;
    font-family: proxima;
    color: #fff;
    content: '';
    opacity: 1 !important;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    left: 2px;
    bottom: 0;
    top: auto;
}

.slick-dots li.slick-active button:before {
    color: #fff;
    content: '';
    background: #fff;
    border: 1px solid #fff;
}

.exclamation {
    font-size: 10px;
    margin-left: 5px;

    i {
        color: $listreports-dark-blue;
    }

    .fa-inverse {
        color: #fff !important;
    }

    .tooltip {
        width: 200px;
        opacity: 1 !important;

        .tooltip-arrow {
            border-right-color: $listreports-dark-blue !important;
        }

        .tooltip-inner {
            padding: 15px;
            background: $listreports-dark-blue !important;
            border-radius: 0;
        }
    }
}

.visually-hidden {
    opacity: 0 !important;
    pointer-events: none !important;
    transition: none !important;
}

.hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    display: block;
}

.table {
    td, tr, th, div, p {
        vertical-align: middle !important;
    }

    thead {
        tr {
            th {
                border-bottom: 0;
            }
        }
    }

    td,
    th {
        line-height: 1.3 !important;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    .tablesort-asc,
    .tablesort-desc {
        background: #fff;
    }

    .tablesort-sortable:after {
        border-bottom-color: $listreports-blue;
        border-left-width: 6px;
        border-right-width: 6px;
        border-bottom-width: 8px;
        display: inline-block;
        position: relative;
        top: -1px;
        margin-left: 7px;
    }

    .tablesort-desc:after {
        border-top-color: $listreports-blue;
        border-top-width: 8px;
    }
}

.ie-flex-width-fix {
    @media (min-width: $screen-sm) {
        -ms-flex: 1 0 auto;
    }
}

.ie-flex-fill-height-fix {
    &.flex {
        -ms-flex: 1 1 auto;
    }
}

/*
    Google Remarketing Iframe
    Layout Fix
*/

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

.pac-container {
    z-index: 2000 !important;
    box-shadow: none;
    border: 1px solid $ice-ice-baby;
}

.search-input::-ms-clear {
    width : 0;
    height: 0;
    display: none;
}

md-progress-circular {
    &.listreports-blue-theme {
        background-color: transparent;

        .md-inner .md-gap {
            border-top-color: $listreports-blue;
            border-bottom-color: $listreports-blue;
        }

        .md-inner .md-left .md-half-circle,
        .md-inner .md-right .md-half-circle {
            border-top-color: $listreports-blue;
        }

        .md-inner .md-right .md-half-circle {
            border-right-color: $listreports-blue;
        }

        .md-inner .md-left .md-half-circle {
            border-left-color: $listreports-blue;
        }
    }
}

md-content {
    color: $defaultfontcolor;
}

.breadcrumbs {
    @media (max-width: $screen-xs-max) {
        padding: {
            top: 15px;
            left: 15px;
            right: 15px;
        }
    }
}

.image-round-xxl {
    width: 80px;
    height: 80px;
    border-radius: 50%;

    @media (max-width: $screen-xs-max) {
        width: 68px;
        height: 68px;
    }
}

.section-group {
    border: 1px solid $ice-ice-baby;
    background: #fff;

    header {
        border-bottom: 1px solid $ice-ice-baby;
        padding: 15px;

        h2 {
            margin: 0;
        }

        .form-control {
            margin-bottom: 0 !important;
        }
    }

    .content {
        padding: 25px;
    }

    &.borderless {
        border: 0;
    }

    &.borderless-top {
        border-top: 0;
    }

    &.border-top {
        border-top: 1px solid $ice-ice-baby;
    }
}

.input-group-addon {
    background: transparent;
    border-color: $ice-ice-baby;
    max-height: 40px;
}

.load-more-wrap,
.loading-circular-wrap {
    padding: 30px;
    border-top: 1px solid $ice-ice-baby;

    &.no-border-top {
        border-top: 0;
    }
}

a.learn-more {
    font-size: 12px;
    margin-left: 14px;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.bg-image {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;

    &.bg-cover {
        background-size: cover;
    }

    &.bg-contain {
        background-size: contain;
    }
}

.margin-top-0 {
    margin-top: 0;
}

// Align caret icons
.btn .lr.lr-caret-up {
    top: 1px;
}

.badge {
    display: inline-block;
    position: relative;
    padding: 3px 5px 2px;
    border-radius: 2px;
    color: #fff;
    margin-left: 2px;

    &.badge-yellow {
        background-color: #f3b204;
    }

    &.badge-f14 {
        font-size: 14px;
    }
}

.legal-font-size {
    font-size: 12px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

// Animations
.animate {
    &.fade {
        &.ng-enter,
        &.ng-leave {
            transition: 300ms ease-out all;
        }
        &.ng-enter {
            opacity: 0;
        }
        &.ng-enter.ng-enter-active {
            opacity: 1;
        }
        &.ng-leave {
            opacity: 1;
        }
        &.ng-leave.ng-leave-active {
            opacity: 0;
        }
    }
}

.space-between {
    > * {
        margin-left: 8px;
        margin-right: 8px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.space-between-30 {
        > * {
            margin-left: 15px;
            margin-right: 15px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// CSS classes made to attempt to solve
// the double border on several pages on mobile
// at the beginning of the tables
tr.hide-xs + tr {
    td {
        @media (max-width: $screen-xs-max) {
            border: 0 !important;
        }
    }
}

/*
 Removed because this is causing the border not to show up. Im leaving it commented
 out in case this causes an issue somewhere else. If it does DM Leland on slack to have a
 discussion about a fix for this.
 */
// tr:first-child {
//     td {
//         @media (max-width: $screen-xs-max) {
//             border: 0 !important;
//         }
//     }
// }

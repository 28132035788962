active-buyer-network-address-stats {
    display: block;
    max-width: 1000px;

    abn-header {
        margin-bottom: 20px;
    }

    .content {
        > h2 {
            max-width: 685px;
            margin: 20px auto;
        }
    }

    form {
        display: block;
        max-width: 620px;
        margin: 40px auto 0;

        .address-wrap {
            margin-bottom: 10px;
        }
    }

    .section-group {
        position: relative;
    }

    .secret-switch {
        position: absolute;
        top: 0;
        right: 10px;
    }

    .price-wrap {
        .sub-label {
            margin-top: -8px;
            margin-bottom: 8px;
        }

        input {
            font-size: 28px;
            height: 65px;
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 30px;
        }
    }

    .multi-input-wrap > div {
        @media (max-width: $screen-xs-max) {
            padding-right: 16px;
            padding-top: 0;
        }
    }
}

// Header Vars
$csHeaderBackgroundColor: #fff;
$csHeaderPadding: 20px;

// Bullet Vars
$csBulletHeight: 40px;
$csBulletBackgroundColor: $listreports-blue;

// Container
$csContainerPadding: 20px 20px 10px;

.collapsable-section {
    position: relative;
    margin-bottom: 10px;

    p {
        margin-top: 0;
        margin-bottom: 0;

        @media (max-width: $screen-sm) {
            font-size: 12px;
        }
    }

    .cs-header {
        padding: $csHeaderPadding;
        background-color: $csHeaderBackgroundColor;
        cursor: pointer;
        color: $defaultfontcolor;

        h1 {
            font-size: 18px;
            font-weight: bold;

            @media (max-width: $screen-sm) {
                font-size: 14px;
            }
        }

        .fa-caret-up,
        .fa-caret-down {
            color: $listreports-blue;
            margin-left: 10px;
        }
    }

    .cs-title {
        .exclamation {
            position: relative;
            top: -2px;
        }
    }

    .subtitle {
        color: $defaultSecondaryTextColor;
    }

    .cs-bullet {
        margin-right: 25px;

        i {
            font-size: 50px;
        }
    }

    .cs-helper-text {
        font-weight: 700;
        margin-bottom: 0;

        @media (max-width: $screen-sm) {
            font-size: 14px;
        }
    }
    
    .cs-container {
        padding: $csContainerPadding;
    }

    .new-badge {
        font-size: 14px;
        display: inline-block;
        padding: 1px 4px;
        background: $listreports-blue;
        color: #fff;
        border-radius: 2px;
        position: relative;
        top: -1px;
    }

    .caret-wrap {
        margin-left: 25px;
    }

    &.white {
        .cs-header {
            background: #fff;
        }
    }

    &.error {
        .cs-header {
            border: 2px solid $listreports-red;
        }

        .error-icon {
            position: absolute;
            right: -28px;
            top: 0;
            bottom: 0;
            cursor: default;

            i {
                color: $listreports-red !important;
                cursor: default;
            }
        }
    }
}

.logged-out-lr-mbs-wrapper {
    background: #ecf0f1;
    padding: 50px;

    header {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;

        .logo-text {
            img {
                height: 64px;
                max-width: 250px;

                @media (max-width: $screen-xs-max) {
                    max-width: 169px;
                }
            }
        }

        .highway-logo {
            img {
                max-height: 64px;

                @media (max-width: $screen-xs-max) {
                    max-width: 169px;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 25px;
        }
    }

    .box {
        background: #fff;
        padding: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        max-width: 900px;

        @media (max-width: $screen-xs-max) {
            padding: 35px;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: {
            top: 25px;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.pro.route-sign-up .box,
.route-lo-accept-lo-invite-sign-up .box,
.pro.route-agent-invite-sign-up-alt-route .box,
.pro.route-lo-accept-lo-invite-sign-up .box {
    max-width: 1122px;

    @media (max-width: $screen-xs-max) {
        padding: 20px;
    }
}

.route-sign-up .box,
.route-agent-invite-sign-up .box,
.route-agent-accept-tc-invite-sign-up .box,
.route-tc-accept-agent-invite-sign-up .box,
.route-sign-up-tc .box {
    max-width: 965px;

    @media (max-width: $screen-xs-max) {
        padding: 20px;
    }
}

.route-sign-up-idp, .route-sign-up-free-idp{
    .box{
        padding: 40px 80px;
        max-width: 915px;
        @media (max-width: $screen-sm-max) {
            padding: 32px 16px;
        }
    }

}

.route-lrbl-landing-page {
    .logged-out-wrapper {
        @media (max-width: $screen-sm-max) {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    .box {
        max-width: 900px;

        @media (max-width: $screen-xs-max) {
            padding: 16px;
        }
    }
}

.route-invite-coworkers {
    .logged-out-wrapper {
        @media (max-width: $screen-sm-max) {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    .box {
        max-width: 900px;

        @media (max-width: $screen-xs-max) {
            padding: 16px;
        }
    }
}

.route-lo-invite-agents {
    .box {
        max-width: 915px;
        padding: 42px 32px;

        @media (max-width: $screen-xs-max) {
            padding: 16px;
        }
    }
}

match-score-circle{
    min-width: 80px;
    min-width: 80px;

    .match-score-circle {
        width: 80px;
        height: 80px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        background-color: $ice-ice-baby;

        &.great {
            background-color: $listreports-green;
        }

        &.good {
            background-color: $listreports-gold;
        }

        &.poor {
            background-color: $listreports-red;
        }

        .match-score-label {
            font-size: 10px;
            line-height: 1.1;
        }

    }
}

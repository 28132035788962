listing-card {
    height: 100%;
    border: 1px solid $ice-ice-baby;
    border-radius: 4px;
    background: #fff;
    display: block;

    .header {
        padding: 16px;
    }

    .footer {
        padding: 16px;
    }

    .pairing-status-wrap {
        margin-top: 5px;
    }

    .pairing-status {
        i {
            font-size: 17px;
            margin-right: 5px;
        }
    }

    .img-holder {
        padding-top: 56.25%;
        position: relative;
        background: no-repeat center center;
        background-size: cover;
        border-top: 1px solid $ice-ice-baby;
        border-bottom: 1px solid $ice-ice-baby;

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 24%,rgba(0,0,0,1) 100%);
        }

        .top-row {
            position: absolute;
            top: 6px;
            right: 12px;
            left: 16px;

            > div {
                border-radius: 2px;
            }
        }

        .bottom-row {
            position: absolute;
            bottom: 16px;
            right: 12px;
            left: 16px;
            color: #fff;

            h2 {
                margin: 0;
            }

            .dom {
                padding-bottom: 4px;
            }
        }

        .new-listing {
            color: #fff;
            height: 20px;
            max-width: 100px;
            padding: 0 6px;
            margin-right: 6px;
            background-color: $listreports-blue;
        }

        .open-house {
            color: #fff;
            height: 20px;
            max-width: 150px;
            padding: 0 6px;
            background-color: rgba(0, 0, 0, 0.6);
            margin-top: 0;
        }
    }

    .compact-row {
        box-shadow: none;

        .img-holder {
            padding-top: 0;
            max-width: 100px;
            min-width: 72px;
            height: 86px;
            margin: 15px 15px 15px 0;
            border-radius: 2px;
            border-top: none;
            border-bottom: none;
        }

        .new-listing {
            width: 32px;
            padding: 0;
            margin: 0;
            background-color: $listreports-gold;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            position: absolute;
            right: 0;
            top: 0;
        }

        .open-house {
            height: 20px;
            width: 100%;
            padding: 0;
            background-color: $listreports-blue;
            margin: 0;
            position: absolute;
            bottom: 0;
        }

        .basic-info {
            padding: 16px;
            min-width: 120px;

            h3 {
                margin: 0;
            }

            .address {
                margin-top: 2px;
            }

            .specs, .dom {
                margin-top: 4px;
            }

            @media (max-width: $screen-md-max) {
                min-width: 80px;
            }
        }

        .smallcaps {
            font-size: 10px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
    }

    .column-wrap {
        height: 100%;
    }

    &.clickable {
        .compact-row:hover {
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        }
    }

    &.not-clickable {
        .column-wrap {
            opacity: 0.5;
        }
    }

    @media (max-width: $screen-xs-max) {
        border-left: 0;
        border-right: 0;
        border-radius: 0;
    }
}

@import '../../../styles/colors';
@import 'bootstrap/buttons';
@import 'bootstrap/button-groups';

// Width
$btnTableViewWidth: 160px;
$btnTableViewWidthSm: 100px;

// Heights
$btnHeight: 42px;
$btnHeightMd: 42px;
$btnHeightSm: 42px;
$btnHeightMobile: 42px;
$btnHeightMdMobile: 42px;
$btnHeightSmMobile: 36px;

// Paddings
$btnPadding: 11px 32px;
$btnPaddingMd: 11px 24px;
$btnPaddingSm: 10px 16px;
$btnPaddingSmMobile: 8px 16px;

.btn {
    font-family: 'proxima-soft', sans-serif;
    text-transform: none;
    border-radius: 2px;
    height: $btnHeight;
    padding: $btnPadding;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    box-shadow: none !important;
    transition: color 200ms ease-out, border 200ms ease-out, background-color 200ms ease-out;
    min-width: 150px;

    @media (max-width: $screen-xs-max) {
        height: $btnHeightMobile;
        padding: $btnPaddingMd;
    }

    &.btn-default {
        background-color: transparent;
        border-color: $ice-ice-baby;
        color: $ice-ice-baby;

        &:hover {
            border-color: $listreports-gray;
            color: $listreports-gray;
        }

        &:active {
            border-color: $listreports-gray;
            color: $listreports-gray;
        }
    }

    &.btn-primary {
        background-color: $listreports-red;
        border-color: $listreports-red;
        color: #fff;

        &:hover {
            background-color: $listreports-red-hover;
            border-color: $listreports-red-hover;
        }

        &:active {
            background-color: $listreports-red-hover;
            border-color: $listreports-red-hover;
        }
    }

    &.btn-secondary {
        background-color: transparent;
        border-color: $listreports-blue;
        color: $listreports-blue;

        &:hover {
            border-color: $listreports-blue-hover;
            color: $listreports-blue-hover;
        }

        &:active {
            border-color: $listreports-blue-hover;
            color: $listreports-blue-hover;
        }

        &.btn-selected {
            border-color: $listreports-blue;
            color: #fff;
            background-color: $listreports-blue;
        }
    }

    &.btn-follow,
    &.btn-unfollow {
        min-width: 0;
    }

    &.btn-unfollow {
        // transition: none;
        background-color: transparent;
        border-color: $ice-ice-baby;
        color: $defaultFontColor;
        overflow: hidden;

        & .hover-content {
            display: block;
            transition: top 200ms ease-out;
            position: relative;
            top: 30px;
        }

        & .non-hover-content {
            display: block;
            transition: top 200ms ease-out, opacity 100ms ease-out;
            position: relative;
            top: 0;
            opacity: 1;
        }

        &:hover {
            border-color: $listreports-blue;
            background-color: $listreports-blue;
            color: #fff;

            & .hover-content {
                top: -20px;
            }

            & .non-hover-content {
                top: -30px;
                opacity: 0;
                color: $defaultFontColor;
            }
        }

        &:active {
            border-color: $listreports-gray;
            color: $listreports-gray;
        }
    }

    &.btn-blue-solid,
    &.btn-blue-solid:hover {
        background-color: $listreports-blue;
        border-color: $listreports-blue;
        color: #fff;
    }

    &.btn-ice-ice-baby {
        background-color: transparent;
        border-color: $ice-ice-baby;
        color: $listreports-gray;

        &:hover {
            border-color: $listreports-gray;
            color: $listreports-gray;
        }

        &:active {
            border-color: $listreports-gray;
            color: $listreports-gray;
        }
    }

    &.btn-share {
        background-color: $share-red;
        color: #fff;
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.facebook {
            background-color: $facebook-blue;
        }

        &.twitter {
            background-color: $twitter-black;
        }

        .lr {
            margin-right: 5px;
        }
    }

    &.btn-facebook-share {
        text-align: left;
        color: #fff;
        border: 0;
        border-radius: 2px;
        line-height: 12px;
        min-width: 50px;
        height: 20px;
        font-size: 10px;
        display: inline-block;
        padding: 4px;
        text-transform: none;
        background: rgb(75,104,186);
        background: -moz-linear-gradient(top,  rgba(75,104,186,1) 0%, rgba(58,86,161,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(75,104,186,1) 0%,rgba(58,86,161,1) 100%);
        background: linear-gradient(to bottom,  rgba(75,104,186,1) 0%,rgba(58,86,161,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b68ba', endColorstr='#3a56a1',GradientType=0 );

        .lr {
            margin-right: 2px;
        }
    }

    &.btn-white-o {
        background-color: transparent;
        border-color: #fff;
        color: #fff;

        &:hover {
            border-color: darken(#ffffff, 10%);
            color: darken(#ffffff, 10%);
        }

        &:active {
            border-color: darken(#ffffff, 10%);
            color: darken(#ffffff, 10%);
        }
    }

    &.btn-blue-o {
        background-color: transparent;
        border-color: $listreports-blue;
        color: $listreports-blue;

        &:hover {
            border-color: darken($listreports-blue, 10%);
            color: darken($listreports-blue, 10%);
        }

        &:active {
            border-color: darken($listreports-blue, 10%);
            color: darken($listreports-blue, 10%);
        }
    }

    &.btn-primary-o {
        background-color: transparent;
        border-color: $listreports-red;
        color: $listreports-red;

        &:hover {
            border-color: darken($listreports-red, 10%);
            color: darken($listreports-red, 10%);
        }

        &:active {
            border-color: darken($listreports-red, 10%);
            color: darken($listreports-red, 10%);
        }
    }

    &.btn-white {
        background-color: #fff;
        border-color: #fff;
        color: $defaultFontColor;

        &:hover {
            background-color: darken(#ffffff, 10%);
            border-color: darken(#ffffff, 10%);
        }

        &:active {
            background-color: darken(#ffffff, 10%);
            border-color: darken(#ffffff, 10%);
        }

        &.red-text {
            color: $listreports-red;
        }

        &.blue-text {
            color: $listreports-blue;
        }
    }

    &.btn-gray-white,
    &.btn-grey-white {
        background-color: #fff;
        border-color: $ice-ice-baby;
        color: $defaultFontColor;

        &:hover {
            background-color: darken(#ffffff, 10%);
            border-color: darken($ice-ice-baby, 10%);
        }

        &:active {
            background-color: darken(#ffffff, 10%);
            border-color: darken($ice-ice-baby, 10%);
        }
    }

    &.btn-search {
        padding: 10px 15px;
        height: 44px;
    }

    &.btn-nav {
        text-align: left;
        padding: 20px;
        height: auto;
        color: #fff;
        background: $listreports-dark-blue;
        border: 0;
        font-weight: normal;
        text-transform: none;

        &:hover,
        &.active {
            background: $listreports-dark-blue-hover;
        }
    }

    &.btn-nav-secondary {
        text-align: left;
        padding: 20px;
        height: auto;
        color: #76888f;
        background: $listreports-dark-blue;
        border: 0;
        font-size: 14px;
        font-weight: normal;
        text-transform: none;

        &:hover,
        &.active {
            background: $listreports-dark-blue-hover;
            color: #fff;
        }
    }

    &.btn-round-icon {
        border-radius: 50%;
        width: auto;
        min-width: auto;
        text-align: center;
        background-color: #eee;
        border: 0;
        transition: color 200ms ease-out, border 200ms ease-out, background-color 200ms ease-out, opacity 200ms ease-out;

        &:hover,
        &:active {
            opacity: 0.6;
        }

        .lr {
            margin-right: 0;
        }

        &[disabled] {
            background-color: #F7F7F7;
            color: #fff;
        }
    }

    &.btn-icon {
        .lr {
            margin-right: 5px;
        }
    }

    &[disabled] {
        background-color: transparent;
        border-color: rgba($listreports-gray, 0.5);
        color: rgba($listreports-gray, 0.5);
        // border: 0;

        &:hover,
        &:active {
            background-color: transparent;
            border-color: rgba($listreports-gray, 0.5);
            color: rgba($listreports-gray, 0.5);
        }
    }

    &[readonly] {
        background-color: transparent;
        border-color: $ice-ice-baby;
        color: $defaultFontColor;
        cursor: default;
        // border: 0;

        &:hover,
        &:active {
            background-color: transparent;
            border-color: $ice-ice-baby;
        color: $defaultFontColor;
        }
    }

    &.btn-md {
        height: $btnHeightMd;
        padding: $btnPaddingMd;
        min-width: 0;

        @media (max-width: $screen-xs-max) {
            height: $btnHeightMdMobile;
        }
    }

    &.btn-sm {
        height: $btnHeightSm;
        padding: $btnPaddingSm;
        min-width: 0;

        @media (max-width: $screen-xs-max) {
            height: $btnHeightSmMobile;
            padding: $btnPaddingSmMobile;
        }
    }

    &.btn-single-action {
        height: $btnHeight;
        padding-left: 24px;
        padding-right:  24px;

        &.btn-md {
            height: $btnHeightMd;
        }

        @media (max-width: $screen-xs-max) {
            height: $btnHeightMdMobile;
            width: 100%;
        }
    }

    &.btn-table-view {
        width: $btnTableViewWidth;
        min-width: 0;
        padding-left:  5px;
        padding-right:  5px;

        &.btn-sm {
            width: $btnTableViewWidthSm;
        }
    }

    &.btn-link {
        position: relative;
        top: -2px;
        border: 0;
        padding: 0;
        height: auto;
        color: $listreports-blue;
        text-transform: none;
        min-width: 0;

        &.underline {
            text-decoration: underline;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }

        &.padded {
            padding: $btnPadding;

            &.btn-md {
                padding: $btnPaddingMd;
            }

            &.btn-sm {
                padding: $btnPaddingSm;
            }
        }

        &:hover {
            border-color: $listreports-blue-hover;
            color: $listreports-blue-hover;
        }

        &:active {
            border-color: $listreports-blue-hover;
            color: $listreports-blue-hover;
        }

        &.lr-green, &.listreports-green {
            color: $listreports-green;

            &:hover,
            &:active {
                color: darken($listreports-green, 10%);
            }
        }

        &.white {
            color: #fff;

            &:hover,
            &:active {
                color: darken(#ffffff, 10%);
            }
        }

        &.sidewalk {
            color: $sidewalk;

            &:hover,
            &:active {
                color: darken($sidewalk, 10%);
            }
        }

        &.default-text-color {
            color: $defaultfontcolor;

            &:hover,
            &:active {
                color: darken($defaultfontcolor, 10%);
            }
        }

        &[disabled],
        &[readonly] {
            color: $listreports-gray;
        }
    }

    &.dropdown-toggle {
        &.btn-ice-ice-baby {
            color: $defaultFontColor;
        }

        span[class*=caret] {
            font-size: 15px;
            margin: 0;
        }
    }

    &.btn-auto-height {
        height: auto;
    }

    &.btn-nominwidth {
        min-width: 0;
    }

    &.min-width-160 {
        min-width: 160px !important;
    }

    &.min-width-150 {
        min-width: 150px !important;
    }

    &.min-width-100 {
        min-width: 100px !important;
    }

    &.min-width-75 {
        min-width: 100px !important;
    }

    &.natural-case {
        text-transform: none;
    }

    // Modify Additional Elements below this
    .inside-btn {
        margin-right: 5px;
    }

    .lr {
        position: relative;
        display: inline-block;
        margin-right: 4px;
    }

    .lr-spin {
        position: relative;
        top: -2px;
    }

    .lr-caret-down,
    .lr-angle-left,
    .lr-angle-right {
        position: relative;
        top: 1px;
    }
}

.btn-group,
.link-dropdown {
    width: 160px;

    .btn:first-child {
        width: 110px;
        border-right: 1px solid;
        min-width: 0;
    }

    .dropdown-toggle {
        border-left: 1px solid;
        width: 51px !important;
        min-width: 0;
    }

    .dropdown-menu {
        width: 160px;
        min-width: 160px;
        max-width: 160px;
        font-size: 14px;
        top: 100%;
        border-color: $ice-ice-baby;
        padding: 0;
        overflow: hidden;

        li {
            a {
                min-height: 40px;
                padding: 11px 12px 10px;
                text-align: left !important;
                border-bottom: 1px solid $ice-ice-baby;
            }

            &:last-child {
                a {
                    border-bottom: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    &.btn-group-sm {
        width: 150px;

        .btn:first-child {
            width: 100px;
        }

        .dropdown-menu {
            width: 150px;
            min-width: 150px;
            max-width: 150px;
        }
    }
}

.btn-group {
    .dropdown-menu {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    &.single {
        .btn:first-child {
            width: 160px !important;
        }
    }
}

.link-dropdown {
    width: auto;

    .btn:first-child {
        width: auto;
        border: 0;
    }
}

.input-group-btn {
    .btn {
        margin-bottom: 8px;
    }

    .btn.dropdown-toggle {
        border-right-width: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        margin-bottom: 0;
    }

    .dropdown-menu {
        > li > a {
            color: $defaultFontColor;
            cursor: pointer;
        }
    }
}

.input-group {
    *:last-child.input-group-btn {
        .btn.dropdown-toggle {
            border-right-width: 1px;
        }
    }
}

new-just-listed-alert {
    .new-just-listed-alert {
        max-width: 1000px;
    }

    form {
        display: block;
        max-width: 640px;
        margin: 0 auto;

        > .form-group {
            .form-control {
                width: 200px;
                display: block;

                @media (max-width: $screen-xs-max) {
                    width: 100%;
                }
            }
        }
    }

    p {
        margin: -8px 0 10px;
        font-size: 14px;
    }

    .buttons-holder {
        margin: 15px 0;

        button {
            margin: 5px 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: $screen-xs-max) {
                margin: 5px 0;
            }
        }
    }

    .error-box {
        font-size: 16px;

        &.offsetme {
            margin-top: 0;
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }
}

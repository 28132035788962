active-buyer-network-view-listing-buyers {
    display: block;
    max-width: 1000px;

    .title-button-holder {
        margin-top: 32px;
    }

    .header-btn-holder {
        @media (max-width: $screen-xs-max) {
            margin: -15px 15px 15px;
        }
    }

    .buyer-stats {
        margin-bottom: 0;
    }

    .buyer-card-holder {
        .title {
            margin-bottom: 5px;
        }

        .content {
            padding: 7px;
        }

        .no-buyers {
            padding: 20px 35px;
        }
    }

    .buyer-card {
        position: relative;
        width: 100%;
        max-width: 312px !important;
        min-height: 470px;
        border: 1px solid $ice-ice-baby;
        margin: 7px;

        &.disabled {
            opacity: 0.5;
        }

        header {
            padding: 25px;

            .status {
                background: $smoke;
                margin: -25px -25px 25px;
                padding: 20px;

                &.interested {
                    background: $listreports-green;
                    color: #fff;
                }
            }

            .pre-approved {
                color: $defaultSecondaryTextColor;
            }

            .buyer-info {
                > * {
                    display: block;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .body {
            padding: 25px 40px;

            ul {
                list-style: disc;
                padding-left: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
            max-width: 100% !important;
            margin-top: 15px;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .spacer {
        width: 100%;
        max-width: 312px !important;
        margin: 0 7px;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
}

.member-benefits-banner {
    position: relative;
    border: 1px solid $ice-ice-baby;
    padding: 27px 16px;

    .benefit-logo {
        width: 92px;
        @media(min-width: $screen-sm-max){
            margin-right: 20px;
        }
    }

    .benefits-text{
        margin-top: 10px;

        @media(min-width: $screen-sm-max){
            margin-top: 0;
        }
    }

    .badge {
        position:absolute;
        top: 0;
        right: 0;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        background-color: #007780;
        width:118px;
        height: 20px;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
    }
}
.my-leads {
    max-width: 1400px;
    display: block;

    .banner-header {
        background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships.png');

        @media (max-width: $screen-sm) {
            background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships_Mobile.png');
        }
    }

    .tabs {
        background: transparent;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 5px solid $listreports-blue;
        max-width: 100%;
        overflow-x: hidden;

        .tab {
            text-transform: uppercase;
            padding: 10px 10px;
            margin-top: 10px;
            background: #fff;
            color: $listreports-blue;
            cursor: pointer;
            border: 1px solid #e0e0df;
            border-bottom-width: 0;
            margin-left: -1px;

            &:first-child {
                border-left-width: 1px;
                margin-left: 0;
            }

            &:last-child {
                margin-right: -1px;
            }

            &.selected {
                z-index: 2;
                background: $listreports-blue;
                color: #fff;
                padding-top: 20px;
                margin-top: 0;
                border-color: $listreports-blue;

                @media (max-width: $screen-xs-max) {
                    padding-top: 15px;
                    padding-bottom: 10px;
                    background: #fff !important;
                    color: $listreports-blue !important;
                    border-left-color: #e0e0df !important;
                    border-right-color: #e0e0df !important;
                    border-top-color: #e0e0df !important;
                    border-bottom: 5px solid $listreports-blue !important;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 0;
                color: #696969 !important;
                text-transform: none;
                padding-top: 15px;
                padding-bottom: 15px;
                border: 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
            border-bottom: 1px solid #e0e0df !important;
            border-top: 1px solid #e0e0df !important;
        }
    }

    .utilities {
        padding: 0 20px;

        .search {
            background: #fff;
        }
    }

    .banner-wrap {
        margin-bottom: 20px;
    }

    @media (min-width: $screen-sm) {
        margin-bottom: 50px;
    }

    button.hidden-input {
        border: 0;
        width: 0;
        height: 0;
        max-width: 0;
        max-height: 0;
        opacity: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .enroll-stats {
        border-top: none;
        border-left: none;
        border-right: none;
        margin-bottom: 0;
    }

}

@import './my-leads-content/styles';
@import './my-lead-detail/styles';
@import './edit-lead/styles';
@import './new-lead/styles';

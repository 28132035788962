edit-agent {
    display: block;
    max-width: 1024px;

    form {
        display: block;
        max-width: 700px;
        margin: 0 auto;
    }

    .face-wrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0,0,0,0.8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 10px;
    }

    .headshot {
        margin-right: 10px;
    }

    .headshot-select {
        margin-left: 10px;

        .btn {
            width: 150px;
        }
    }
}

.preferred-vendor-wrapper {
    max-width: 1048px;

    .preferred-vendor{
        padding: 0 50px;

        @media (max-width: $screen-xs-max) {
            padding: 0 16px;
        }
    }

    .subsection {
        background: #F9F9F9;
        flex: 1;
    }

}
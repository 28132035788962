.datetimepicker {
  border-radius: 4px;
  direction: ltr;
  display: block;
  margin-top: 1px;
  user-select: none;
  width: $abdtp-width;

  > div {
    display: none;
  }

  .hour,
  .minute {
    height: 34px;
    line-height: 34px;
    margin: 0;
    width: 25%;
  }

  .table {
    margin: 0;
  }

  .table td,
  .table th {
    border: 0;
    border-radius: 4px;
    height: 20px;
    text-align: center;
  }

  .minute:hover,
  .hour:hover,
  .day:hover,
  .switch:hover,
  .left:hover,
  .right:hover {
    background: $abdtp-color-hover;
    cursor: pointer;
  }

  .disabled,
  .disabled:hover {
    background: none;
    color: $abdtp-color-disabled;
    cursor: default;
  }

  .active,
  .active:hover,
  .active.disabled,
  .active.disabled:hover {
    background-color: $abdtp-active-background-color;
    background-image: linear-gradient(to bottom, $abdtp-background-color-start, $abdtp-background-color-end);
    background-repeat: repeat-x;
    border-color: $abdtp-active-border-top-color $abdtp-active-border-top-color $abdtp-active-border-bottom-color;
    color: $abdtp-active-color;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$abdtp-background-color-start}', endColorstr='#{$abdtp-background-color-end}', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  }

  // scss-lint:disable QualifyingElement
  .active:hover,
  .active:hover:hover,
  .active.disabled:hover,
  .active.disabled:hover:hover,
  .active:active,
  .active:hover:active,
  .active.disabled:active,
  .active.disabled:hover:active,
  .active.active,
  .active:hover.active,
  .active.disabled.active,
  .active.disabled:hover.active,
  .active.disabled,
  .active:hover.disabled,
  .active.disabled.disabled,
  .active.disabled:hover.disabled,
  td.active[disabled],
  td.active:hover[disabled],
  td.active.disabled[disabled],
  td.active.disabled:hover[disabled],
  span.active[disabled],
  span.active:hover[disabled],
  span.active.disabled[disabled],
  span.active.disabled:hover[disabled] {
    background-color: $abdtp-active-background-color;
  }

  span {
    border-radius: 4px;
    cursor: pointer;
    display: block;
    float: left;
    height: 54px;
    line-height: 54px;
    margin: 1%;
    width: 23%;

    &:hover {
      background: $abdtp-color-hover;
    }
  }

  .past,
  .future {
    color: $abdtp-color-past-future;
  }
}


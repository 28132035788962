.marketing-area-select-modal {
    padding: 36px 50px;

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 20px 32px;
    }

    header {
        h2 {
            margin: 14px auto;
            font-size: 24px;

            @media (max-width: $screen-xs-max) {
                margin: 12px auto;
            }
        }
    }

    section.body {
        margin: 24px auto;
    }

    .areas-search {
        position: relative;
        min-width: 100%;
        max-width: 256px;

        input {
            width: 100%;
        }

        .icon {
            position: absolute;
            top: 10px;
            font-size: 16px;
            right: 10px;
            color: #ddd;
        }

        .search-result {
            color: $defaultFontColor;
            margin-top: -1px;
            border-style: solid;
            border-color: #ddd;
            border-width: 1px;
            padding: 10px 20px;
            background: #fff;

            .info {
                @media (max-width: $screen-sm) {
                    max-width: 60%;
                }
            }

            img {
                max-width: 60px;
                margin-right: 20px;
            }

            &:focus {
                outline: 0;
            }
        }

        @media (max-width: $screen-sm) {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
        }
    }

    .dismiss-wrap {
        margin-top: 24px;
    }

}

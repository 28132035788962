.enroll-agent-stats {
    margin: 0 auto 20px;

    .btn.learn-more{
        margin-top: 18px;
    }

    > div {
        padding: 20px 0;
        border-right: 1px solid $ice-ice-baby;
        overflow: hidden;

        &:last-of-type {
            border-right: none;
        }

        @media (max-width: $screen-sm) {
            border-right: none;
            border-bottom: 1px solid $ice-ice-baby;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .cta-block {
        &.invite {
            button {
                margin-top: 12px;
            }
        }

        .cta-wrap {
            height: 100%;
            min-height: 100%;

            .watch-video-link {
                margin-top: 12px;
            }
        }
    }

    .stats-block {
        button {
            margin-top: 18px;
        }

        .count {
            margin: 0;
        }

        .count{
            font-size: 28px;
            line-height: 33px;
        }
        .label {
            font-size: 18px;
            line-height: 21px;
        }

        .headshot-lineup {
            margin-top: 16px;

        }

        .btn-small{
            min-width: 68px;
            padding: 11px 16px;
        }

        .headshot-lineup .additional-count-wrap{
            width: 45px;
            height: 45px;
            border-radius: 45px;
            border: 1px dashed #B4BBBF;
            margin-left: 0;
            background: #ffffff;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            line-height: 21px;
            color: $defaultFontColor;
            font-weight: bold;
        }
    }

    .stats-block-content_mobile {
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        align-items: center;

        .headshot-lineup .additional-count-wrap{
            flex-shrink: 0;
        }

        .stats-block-content_info {
            margin-right: 34px;
            justify-content: space-between;
        }

        .stats-block-container_headshots{
            display: flex;
            justify-content: center;
            width: 220px;
        }
    }
}

transactions-view-details {
    display: block;
    max-width: 1040px;

    .top-title {
        margin: 0 0 -14px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    .section-title {
        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
        }
    }

    title-bar {
        @media (max-width: $screen-xs-max) {
            margin-bottom: -20px;
        }
    }

    title-bar .subtitle {
        margin-top: -9px;

        h3 {
            @media (min-width: $screen-sm) {
                padding: 0 20px;
                border-right: 1px solid $ice-ice-baby;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 15px;
        }
    }

    .gallery {
        position: relative;
        background: #fff;
        overflow: hidden;
        width: 370px;
        min-height: 260px;
        border-right: 1px solid $ice-ice-baby;

        .bg-img {
            background: no-repeat center center;
            background-size: cover;
            min-height: 260px;
        }

        slick {
            .move-right {
                color: #fff;
                right: 10px;
                z-index: 2;
                font-size: 30px;
            }

            .move-left {
                color: #fff;
                left: 10px;
                z-index: 2;
                font-size: 30px;
            }
        }

        .slick-slider {
            margin: 0;
            max-width: 100%;
        }

        .num-imgs {
            position: absolute;
            right: 10px;
            bottom: 10px;
            background: rgba(0,0,0,0.75);
            color: #fff;
            padding: 5px 8px;
            font-size: 12px;
        }

        &.upload-img {
            background: $listreports-gray;
            color: #fff;

            i {
                font-size: 59px;
            }
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }

    .info {
        padding: 30px;

        .status {
            h2 {
                margin: 0;
            }

            select {
                display: block;
                width: auto;
                font-size: 28px;
                height: 51px;
                line-height: 33px;
                padding-bottom: 9px;
                margin-bottom: 20px;

                @media (max-width: $screen-xs-max) {
                    width: 100%;
                }
            }
        }

        .edit-transaction {
            .btn {
                @media (max-width: $screen-xs-max) {
                    display: block;
                    width: 100%;
                }
            }
        }

        label {
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $sidewalk;
            margin-bottom: 5px;
        }

        .dates {
            margin-bottom: 20px;

            > div {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            label {
                margin-bottom: 0;
            }
        }
    }

    .special-instructions {
        padding: 30px;
        margin: 0 -1px -1px;

        .title {
            margin-bottom: 5px;
        }
    }

    .cards {
        margin: -7px;

        > div {
            padding: 7px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: -1px;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        h3 {
            margin-bottom: 5px;
        }

        .card {
            padding: 25px;
            height: 100%;

            label {
                font-size: 10px;
                color: $sidewalk;
                margin-bottom: 0;
            }

            .img-holder {
                width: 60px;
                margin-right: 25px;
                font-size: 60px;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

            .ctas-wrapper {
                width: 100%;
                margin-top: auto;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding-top: 20px;
    }
}

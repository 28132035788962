@import '../../../../styles/colors';

inactive-listing-banner {
    display: block;

    banner {
        background: $ice-ice-baby;
        color: $listreports-gray;
    }

    .btn-link {
        position: relative;
        top: -1px;
    }
}

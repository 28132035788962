transactions-new-transaction-address {
    display: block;
    max-width: 1040px;

    h2 {
        margin-bottom: 20px;
    }

    .section-group {
        .content {
            max-width: 810px;
            margin: 0 auto;
        }
    }

    .separator {
        margin: 40px 0;

        h2 {
            margin: 0 20px;
        }

        hr {
            border-color: $ice-ice-baby;
        }
    }

    form {
        display: block;
        max-width: 530px;
        margin: 50px auto 0;

        .form-group.space-after {
            margin-right: 10px !important;
        }
    }

    .no-listings-holder {
        margin-bottom: 50px;

        h3 {
            margin-bottom: 20px;
        }

        div {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .listings {
        margin: -10px;

        .listing-wrapper {
            padding: 10px;
        }

        .listing {
            border: 1px solid $ice-ice-baby;

            > .img-holder {
                padding-top: 56.25%;
            }

            .listing-info {
                padding: 10px;

                .address {
                    margin-bottom: 5px;
                }

                .stats {
                    font-size: 10px;
                    margin-bottom: 10px;

                    > div {
                        padding-right: 5px;
                        margin-right: 5px;
                        border-right: 1px solid $ice-ice-baby;

                        &:last-child {
                            padding-right: 0;
                            margin-right: 0;
                            border-right: 0;
                        }
                    }
                }

                .agent {
                    font-size: 10px;

                    .label {
                        margin-bottom: 10px;
                    }

                    .img-holder {
                        width: 35px;
                        margin-right: 15px;
                        font-size: 35px;

                        img {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}

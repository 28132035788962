.marketing-checklist {
    width: 100%;
    padding: 35px 35px 0;

    .top-section {
        margin-bottom: 32px;
        h1,
        h2,
        h3 {
            margin: 0;

            @media (max-width: $screen-md-max) {
                text-align: center;
            }
        }

        h1 {
            margin-top: 5px;
        }

        .line {
            width: 50px;
            height: 2px;
            background-color: $listreports-blue;
            margin: 20px 0;

            @media (max-width: $screen-md-max) {
                margin: 20px auto;
            }
        }
    }

    .img-holder {
        max-width: 309px;
        margin-left: 60px;

        @media (max-width: $screen-md-max) {
            margin-right: auto;
            margin-left: auto;
            margin-top: 20px;

        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
            margin-top: 20px;
            max-width: none;
        }
    }

    .vgradient {
        height: 42px;
        margin: 0 -35px;
        background: rgb(255,255,255);
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(247,247,247,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 );
    }

    .info-cards-holder {
        padding-top: 35px;
        padding-bottom: 35px;

        > div   {
            h3 {
                margin-bottom: 15px;
            }

            &:first-child {
                margin-right: 60px;

                @media (max-width: $screen-md-max) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }

        p {
            margin: -3px 0 0;
        }

        img {
            display: inline-block;
            margin-right: 15px;
        }
    }

    .ecommission {
        padding-top: 35px;

        .logo-wrap {
            margin-right: 24px;

            @media (max-width: $screen-sm-max) {
                margin: 0 auto 16px;
            }
        }

        .cta-wrap {
            @media (max-width: $screen-sm-max) {
                text-align: center;
            }
        }

        h3 {
            margin-bottom: 6px;
        }

        p {
            margin: 0 auto 16px;
        }
    }
}

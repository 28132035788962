.accept-invite-success-modal {

    .modal-dialog {
        margin-top: 20px;
        max-width: 400px;
    }
    .modal-content {
        max-width: 400px;
    }
    .title {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .party-popper {
        height: 28px;
        width: 28px;
    }

    .close-button {
        margin: 15px 15px 0px 0px;
        color: $listreports-gray !important;
    }

    .close-icn {
        font-size: 15px;
    }

    .modal-separator {
        margin: 20px;
        width: 25px;
        min-width: 5%;
        height: 0;
        border-bottom: 2px solid $listreports-blue;
    }

    .modal-text {
        font-size: 18px;
    }

    .btn-container {
        margin: 20px 0px 30px;
    }

    .modal-btn {
        width: 85px;
        height: 35px;
        padding: 0px;
        min-width: 85px;
    }
}
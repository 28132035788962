.arba-invite-message {

    .horn-image {
        width: 29px;
    }
    .message {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;

        .refer-link {
            top: -1px;
        }
    }
}
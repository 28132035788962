@import '../../../../styles/colors';

processing-listing-banner {
    display: block;

    banner {
        background: $ice-ice-baby;
        color: $listreports-gray;
    }
}

.sticky-tooltip {
    position: relative;
    display:block;
    background: transparentize(#1d2b35, 0.1);
    max-width: 240px;
    z-index: 2;
    text-align: center;
    padding: 20px 32px 18px 28px;
    color: white;
    font-size: 14px;
    border-radius: 4px;

    &:before {
        content: '';
        position: absolute;
        top: -24px;
        left: calc(50% - 12px);
        width: 0;
        height: 0;
        border-bottom: 12px solid transparentize(#1d2b35, 0.1);
        border-top: 12px solid transparent;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }

    .fa-remove {
        position: absolute;
        right: 10px;
        top: 6px;
        font-size: 20px;
        cursor: pointer;
    }
}

.my-agents-stats {
    margin: 0 auto 20px;

    > div {
        padding: 30px;
        border-right: 1px solid $ice-ice-baby;
        overflow: hidden;

        &:last-of-type {
            border-right: none;
        }

        @media (max-width: $screen-sm) {
            border-right: none;
            border-bottom: 1px solid $ice-ice-baby;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .stats-block {

        .count {
            margin: 0;
        }

        .label {
            margin-top: 16px;
            font-size: 18px;
            color: $giggity;

            .lr {
                font-size: 14px;
            }
        }

        .headshot-lineup {
            margin-top: 12px;

        }

        @media (min-width: $screen-sm) {

            background-position: top 30px right 30px;
            background-repeat: no-repeat;
            background-size: 60px 55px;

            &.paired-agents {
                background-image: url('/images/icon-stickers/paired-agents.png');
            }

            &.total-asset-views {
                background-image: url('/images/icon-stickers/total-asset-views.png');
            }

            &.total-reports {
                background-image: url('/images/icon-stickers/total-reports.png');
            }

            &.shareables-view {
                background-image: url('/images/icon-stickers/shareables-view.png');
            }

            &.shareables-download {
                background-image: url('/images/icon-stickers/shareables-download.png');
            }
        }

        @media (max-width: $screen-sm) {
            text-align: center;

            .label {
                margin-top: 4px;
            }
        }

    }

    .invite-agent {
        .greeting {
            margin: 0 auto 12px;
            font-size: 36px;
        }

        .caption {
            margin: 0 auto 18px;
        }

        .btn-primary {
            margin: 0 auto 10px;
        }
    }

    .translucent {
        opacity: 0.6;
    }
}

.robin-agents-results {
    .select-agent-details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .select-agent-info{
        display: flex;
        margin-bottom: 10px;
    }

    .select-agent-img{
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }

    .select-agent-robin{
        font-size: 10px;
        color: $defaultSecondaryTextColor;
        line-height: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .select-agent-action{
        margin-bottom: 16px;
        .btn-small {
            min-width: 72px;
            padding: 11px 16px;
        }

        .btn[disabled] {
            background-color: #b8b8b8;
            border-color: #b8b8b8;
            color: #ffffff;
        }
    }

    .select-agent-img-content{
        position: relative;
        .user-robin {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 43px;
            top: 38px;
            background: url('/images/robin_logo_round.svg');
        }
    }

    .select-agent-detail{
        max-width: 214px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
}

.application-footer {
    $backgroundColor: #3c4c5b;
    $mobileBackgroundColor: #2f3b49;

    background: $backgroundColor;
    color: #fff;
    padding: 50px;
    font-size: 12px;

    @media (max-width: $screen-xs-max) {
        background: $mobileBackgroundColor;
    }

    a {
        color: #fff;
    }

    .social {
        font-size: 30px;

        a {
            color: #fff;
            transition: all 200ms ease-out;

            &:hover {
                color: rgba(255, 255, 255, .8);
            }
        }
    }

    .info {
        max-width: 392px;
        @media (max-width: $screen-sm) {
            margin-bottom: 10px;
            max-width: 100%;
        }
    }

    .footnote {
        $footnoteBackgroundColor: #374551;
        $mobileFootnoteBackgroundColor: #3b4956;

        margin: 50px -50px -50px;
        padding: 25px;
        text-align: center;
        background: $footnoteBackgroundColor;

        @media (max-width: $screen-sm) {
            margin: 25px -25px -25px;
            background: $mobileFootnoteBackgroundColor;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 25px;
        text-align: center;
    }
}

.card {
    border: 1px solid $ice-ice-baby;
    width: 100%;
    height: 100%;

    .card-content {
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .img-wrapper {
            cursor: pointer;

            img {
                display: block;
                width: 100%;
            }
        }

        .card-info {
            margin: 24px 0;
            padding: 0 20px;
        }

        .title {
            display:block;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;
        }

        .action-text {
            display: block;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin-top: 12px;
        }

        .description {
            display:block;
            text-align: center;
            margin-top: 10px;
        }

        .action-wrapper {
            margin-bottom: 24px;
            text-align:center;
        }

    }
}
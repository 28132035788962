.notes-agent-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .btn[disabled] {
        background-color: #b8b8b8;
        border-color: #b8b8b8;
        color: #ffffff;
    }

    .close-modal-btn{
        right: -41px;

        .btn.btn-link.sidewalk{
            color: #ffffff;
            &:hover{
                color: #ffffff;
            }
        }

        @media(max-width: $screen-xs-max) {

            right: 15px;
            top: 22px;
            z-index: 9999999;
            .btn.btn-link.sidewalk{
                color: #b4bbbf;
                &:hover{
                    color: #b4bbbf;
                }
            }
        }
    }

    .modal-dialog {
        border: none;
        height: 100%;
        margin: 0px auto;

        @media (max-width: $screen-xs-max) {
            overflow-y: scroll;
            width: auto;
        }
    }

    &.standard-modal{
        box-shadow: none;
        .modal-content {
            height: 100%;
            padding: 0;
            box-shadow: none;
            border: none
        }
        @media (max-width: $screen-xs-max) {
            border: none;
            box-shadow: none;
        }
    }

    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 100%;
        border-bottom: 1px solid #D5DFE3;
        font-size: 18px;
        font-weight: bold;
    }

    .content{
        padding: 16px 0px;

        notes .notes {
            font-size: 14px;
            min-height: 100px;
        }

        notes .section-group{
            border: none;
        }
        notes header h2 {
            display: none;
        }

        notes .section-group header{
            padding-left: 24px;

            .btn.btn-link.btn-icon{
                font-size: 18px;
            }

            .lr.lr-plus{
                font-size: 11px;
                font-weight: bold;
            }
        }

        notes .notes .note{
            padding: 24px;
        }
    }
}

.notification-center {

    .avatar {
        color: #FFF;
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        background: #2EBCCA;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-menu {

        li {
            padding: 0px !important;
        }

        .dropdown-menu-item {

            .notification-link {
                font-weight: bold;
                color: #007780;
                text-transform: none;
            }

            .notification-text {
                flex: 1;
                padding: 0 16px;
            }

            .unread {
                background-color: #F8FAFB;
            }

            .btn-secondary {
                width: 90px;
            }

            .image-container {
                min-width: 125px;
                text-align: right;
            }

            .image-16-9 {
                width: 128px;
                height: 72px;
            }

            .image {
                width: 125px;
                max-height: 85px;
                object-fit: contain;
            }

            .image-thumbnail {
                height: 64px;
            }

            .icon {
                font-size: 28px;
                padding-left: 15px;
                color: $giggity;
                line-height: 1;
                margin-top: 5px;

                &.icon-read {
                    color: $sidewalk;
                }
            }

            .avatar-icon {
                width: auto;
                height: auto;
                max-width: 28px;
                max-height: 28px;
                margin-left: 15px;
            }

        }

        .notification-date {
            font-size: 14px;
            color: $defaultSecondaryTextColor;
            line-height: 18px;
        }

        .user-headshot {
            min-width: 50px;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.autopilot-active-listings-modal {

    .button-holder.primary {
        margin-bottom: 10px;
    }

    .listings-list-wrap {
        margin: 30px 0 30px;

        @media (min-width: $screen-xs-max) {
            max-height: 225px;
            overflow: auto;
        }
    }

    .listings-checklist {
        .listing {
            padding: 0 0 8px;

            .checkbox-wrap {
                margin-left: 5px;
                min-width: 18px;
                max-width: 18px;
            }

            .hero {
                min-width: 60px;
                height: 45px;
                background-image: url("/images/listing-no-photo.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin: 0 8px 0 4px;
            }
        }
    }

    .bullet-checklist {
        margin-bottom: 20px;

        li {
            i {
                color: $listreports-green;
                font-size: 18px;
            }

            padding: 10px 0;
        }
    }

    .error {
        margin-top: 30px;
    }
}

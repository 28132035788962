flyer-preview {
    .flyer-preview {
        border: 1px solid $ice-ice-baby;
        height: 100%;
        width: 100%;
    }

    .around-margin {
        margin: 2.5%;
    }

    .around-horizontal-margin {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .around-vertical-spacer {
        height: 2%;
    }

    .flyer-header {
        height: 7%;
        width: 49%;
        // margin-top: 0;
        margin-bottom: 0;
    }

    .mock-text-color {
        background-color: $smoke;
    }

    .mock-text-1 {
        height: 30.88%;
    }

    .mock-text-2 {
        height: 12.85%;
    }

    .mock-text-3 {
        height: 16.31%;
    }

    .mock-text-4 {
        height: 4.79%;
    }

    .mock-text-5 {
        height: 20%;
    }

    .mock-text-spacer-1 {
        height: 23.13%;
    }

    .mock-text-spacer-2 {
        height: 19%;
    }

    .mock-text-spacer-3 {
        height: 3.58%;
    }

    .mock-text-spacer-4 {
        height: 14.29%;
    }

    .hero-image-container {
        height: 35.75%;
    }

    .mock-hero-image-container {
        height: 100%;
    }

    .image-holder {
        height: 100%;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .mock-image-color {
        background-color: $ice-ice-baby;
    }

    .mock-hero-image {
        height: 95%;
        width: 98%;
        border: 1px dashed $giggity;
    }

    .info-content {
        height: 36.5%;
    }

    .text-wall {
        width: 49%;
    }

    .text-tyles-spacer {
        width: 3%;
    }

    .tyles-container {
        width: 48%;
    }

    .tyle-row {
        height: 47.95%;
    }

    .tyle-container {
        width: 48.61%;
        height: 100%;
    }

    .mock-tyle-container {
        height: 100%;
    }

    .mock-sub-label {
        font-size: 10px;
    }

    .mock-tyle-image {
        height: 90%;
        width: 90%;
        border: 1px dashed $giggity;
    }

    .footer {
        height: 13.25%;
    }

    .footer-block {
        height: 66.38%;
        width: 45.07%;
    }

    .footer-circle {
        height: 100%;
        width: 25.55%;
        border-radius: 50%;
    }

    .footer-text {
        width: 70.8%;
    }

}
activity-in-your-area {
    .location-form {
        background-color: $listreports-blue;
        color: #fff;
        padding: 36px;
        margin-bottom: 24px;

        @media (max-width: $screen-sm-max) {
            padding: 36px 50px;
            margin-left: -50px;
            margin-right: -50px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 32px 20px;
            margin-left: -20px;
            margin-right: -20px;
        }

        .info {
            margin: 12px auto 20px;

            @media (max-width: $screen-xs-max) {
                margin: 6px auto 12px;
            }
        }

        .form-group {
            margin-bottom: 0;
        }

        input.form-control {
            margin: 0;
            height: 40px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-color: transparent;

            &:focus {
                border-color: $ice-ice-baby;
                border-right-color: transparent;
            }
        }

        &.ng-submitted {
            input.form-control.ng-invalid {
                border-right-color: $errorRed;

                &:focus {
                    border-color: $ice-ice-baby;
                    border-right-color: transparent;
                }
            }
        }

        .search-input-submit {
            min-width: 32px;
            max-width: 32px;
            background-color: #fff;
            color: $ice-ice-baby;
            min-height: 40px;
            height: 40px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border: 1px solid $ice-ice-baby;
            border-left-color: transparent;
            padding: 8px 0;

            @media (max-width: $screen-xs-max) {
                min-height: 36px;
                height: 36px;
                padding: 6px 0;
            }

            &:hover {
                color: $defaultfontcolor;
            }
        }

        .error-box {
            margin-top: 8px;
        }
    }

    .center-sm {
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }

    .location-results {
        .stats {
            margin-bottom: 40px;

            h2 {
                font-size: 36px;
                margin-top: 20px;
                margin-bottom: 0;
            }

            p {
                margin: 0 auto;
            }
        }
    }
}

lead-status-symbol {
    display: inline-block;

    .round {
        width: 45px;
        height: 45px;
        border: 1px solid $defaultFontColor;
        border-radius: 50%;
    }
}

.date-input {

    .input{
        background-image: url('/images/icons/select-arrow.jpg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        cursor:pointer;
    }

}
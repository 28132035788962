$abdtp-active-color:                #fff !default;
$abdtp-active-background-color:     #04c !default;
$abdtp-active-border-top-color:     #04c !default;
$abdtp-active-border-bottom-color:  #002a80 !default;
$abdtp-background-color-end:        #04c !default;
$abdtp-background-color-start:      #08c !default;
$abdtp-background-color:            #006dcc !default;
$abdtp-color-disabled:              #ebebeb !default;
$abdtp-color-hover:                 #eee !default;
$abdtp-color-past-future:           #999 !default;
$abdtp-width:                       320px   !default;

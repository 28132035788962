cancellation-what-happens-next {
    ul {
        li {
            display: block;
            margin-bottom: 30px;

            .icon-holder {
                width: 40px;
            }
        }
    }

    .ctas {
        .btn {
            @media (max-width: $screen-xs-max) {
                display: block;
            }
        }
    }
}

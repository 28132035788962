purchase-buyside-credits-modal-select-agents {
    display: block;

    > div > * {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    .follow-count {
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;

        > div {
            padding: 16px 24px;

            &:first-child {
                border-right: 1px solid $ice-ice-baby;
            }
        }

        h2 {
            margin: 0 8px 0 0 !important;
        }
    }

    .agent-box {
        max-height: calc(100vh - 650px);
        overflow: auto;
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;
        padding: 16px;

        .agent-select-box {
            margin-top: 16px;
            border: 1px solid $ice-ice-baby;
            border-radius: 4px;
            padding: 12px;
            cursor: pointer;

            &.disabled {
                opacity: 0.5;
                cursor: default;
            }

            &:first-child {
                margin-top: 0;
            }

            .avatar-holder {
                margin-right: 16px;
            }

            .selected-or-not {
                font-size: 24px;
            }
        }
    }

    .auto-follow {
        h3 {
            margin-bottom: 16px;
        }

        .fancy-checkbox {
            width: 24px;
            height: 24px;
            padding: 4px;
            text-align: center;
            position: relative;
            top: 3px;

            .icon {
                font-size: 12px;
            }
        }
    }
}

.interstitial-page {
    min-height: 100%;

    > div {
        padding: 30px 65px;
    }

    .interstitial-icon {
        margin-top: 80px;
        max-width: 300px;
        display: inline-block;
    }

    .interstitial-text {
        margin-bottom: 30px;

        h3 {
            font-size: 21px;
            margin-top: 50px;
        }

        p {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .btn {
        width: 300px;
    }

    @media (max-width: $screen-sm-min) {

        > div {
            padding: 30px;
        }

        .interstitial-icon {
            margin-top: 80px;
            max-width: 200px;
        }

        .interstitial-text {

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            line-height: 18px;
            }
        }

        .btn {
            width: 100%;
        }
    }
}

.purchase-buyside-credits-modal {
    .modal-dialog {
        max-width: 500px;

        .modal-content {
            padding: 24px 16px 80px;

            @media (min-width: $screen-sm-min){
                padding: 32px 37px;
            }
        }
    }
}
purchase-buyside-credits-modal-manage {
    > div > div > *,
    .tear-middle > * {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    .title {
        h2,
        h3 {
            margin: 0 !important;
        }
    }

    .auto-follow {
        h3 {
            margin-bottom: 16px;
        }

        .fancy-checkbox {
            width: 24px;
            height: 24px;
            padding: 4px;
            text-align: center;
            position: relative;
            top: 3px;

            .icon {
                font-size: 12px;
            }
        }
    }

    .subscription-details {
        height: 100px;
        border: 1px solid #D5DFE3;
        padding: 20px 16px;

        .cancel-subscription-btn {
            font-weight: 600;
        }
    }

    .gblock {
        padding: 16px;
        border-radius: 4px;

        h2 {
            margin-bottom: 0 !important;
        }
    }

    .line-item {
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .lock {
        .lr {
            margin-right: 4px;
            font-size: 18px;
        }
    }
}

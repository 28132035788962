agent-sidepanel {
    .video-wrap {
        position: relative;
    }

    #signup-video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 497px;
        max-height: 280px;
    }

    ul {
        list-style-type: disc;
        padding-left: 15px;

        li {
            line-height: 20px;
            margin-bottom: 20px;
        }
    }

    .inviter-info-wrapper {
        .hdivider {
            display: inline-block;
            width: 50px;
            height: 4px;
            background-color: $ice-ice-baby;
            margin-top: 18px;
            margin-bottom: 24px;
        }

        .face-wrap {
            display: inline-block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 20px 0 0;

            @media (max-width: $screen-xs-max) {
                margin: 0 0 20px 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }

    h4 {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .edgeless-xs {
        @media (max-width: $screen-xs-max) {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    .info {
        color: $defaultFontColor;
        background-color: #F8FAFB;
        border-radius: 8px;
        font-size: 12px !important;
        line-height: 18px;
        margin-top: 4px;
        padding: 16px;
    }
}

agentfarm-agents {
    display: block;

    .pageof {
        padding: 20px;

        .listing-not-found {
            @media (max-width: $screen-xs-max) {
                padding: 0 0 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 20px 20px 5px;
        }
    }

    .agents-wrapper {
        padding: 10px;
        margin-top: -20px;

        @media (max-width: $screen-xs-max) {
            margin-top: -10px;
        }
    }

    .agent-spacer {
        padding: 10px;
    }

    .agent {
        color: $defaultFontColor;
        text-decoration: none;
        height: 100%;
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;
        padding: 16px;

        .header {
            margin-bottom: -14px;

            avatar {
                margin-right: 16px;
            }

            .md-2-line {
                padding: 0;
            }

            h3 {
                margin: 0;
            }
        }

        .listing {
            width: 84px;
            height: 63px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            background: no-repeat center center;
            background-size: cover;

            .new {
                position: absolute;
                top: 0;
                right: 0;
                background-color: $listreports-orange;
                color: #fff;
                font-size: 10px;
                padding: 4px;
                border-radius: 4px;
            }

            .open-house {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $listreports-blue;
                color: #fff;
                font-size: 10px;
                padding: 4px;
                text-align: center;
            }
        }

        .info {
            > div {
                margin: 4px 0;
            }
        }

        .pairing {
            text-transform: uppercase;
            letter-spacing: 1.2px;

            .lr {
                margin-right: 5px;
                display: inline-block;
            }
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        }

        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    .divider {
        margin: 16px 0;
        border: 1px solid $ice-ice-baby;
    }

    .ctas {
        margin-top: 24px;

        .btn.btn-follow {
            text-transform: none;
            line-height: 1;
            padding: 6px;
        }

        .btn {
            width: 200px;
        }

        & > div,
        .cp-btn-holder {
            margin: 0 8px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .pageit-wrapper {
        padding: 20px;
        padding-top: 0;

        @media (max-width: $screen-xs-max) {
            padding: 5px 15px 20px;
        }
    }

    .ph-item {
        border-color: $ice-ice-baby;
        border-radius: 4px;
        padding: 16px;

        .cp-avatar {
            position: relative;
            width: 50px;
            height: 50px;
            background-color: $ph-color;
            margin-right: 16px;
            border-radius: $ph-avatar-border-radius;
            overflow: hidden;

            &::before {
                content: " ";
                display: block;
                padding-top: 100%;
            }
        }

        .cp-standard {
            width: 100%;
        }

        .ph-row {
            margin-bottom: 8px;
        }

        .header {
            padding: 8px 0;
            margin-bottom: 8px;
        }

        .name {
            width: 100%;
        }

        .company {
            width: 60%;
        }

        .listing-img {
            width: 84px;
            height: 63px;
            border-radius: 4px;
        }

        .info {
            margin-bottom: 24px;
        }

        .stats {
            margin-top: -6px;

            > div {
                padding: 10px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            .cp-standard {
                height: 8px;

                &.big {
                    height: 16px;
                }
            }
        }

        .cp-btn {
            height: 42px;
            width: 200px;
        }

        .ctas {
            margin-top: 8px;
        }
    }

    .cp-pageof {
        height: 58px;
    }
}

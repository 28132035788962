.agentfarm {
    max-width: 1400px;

    .credits-available-wrapper {
        > div {
            margin-left: 12px;
        }
    }

    .banner-header {
        background-image: url('/images/vendor-headers/background/LR_Headers_Dashboard_1024x200.png');

        @media (max-width: $screen-sm) {
            background-image: url('/images/vendor-headers/background/LR_Headers_Dashboard_700x180.png');
        }
    }

    .title {

        h1 {
            margin-bottom: 16px;
        }

        @media (max-width: $screen-sm) {
            margin-bottom: 16px;
        }
    }

    .title-banner-divider {
        display: flex; 
        flex-direction:row; 
        flex-wrap: wrap;
        justify-content:space-between;
        align-items:flex-start;
    }

    .column {
        flex-direction: column;
    }

    .agent-intel-standalone-trial {
        background-color: white;
    }

    .banner-button-separator {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        @media (max-width: $screen-sm) {
            width: 100%;
            display: inline;
        }
    }

    .btn-agent-search {
        display: flex;
        margin-left: 16px;
        @media (max-width: $screen-sm) {
            display: none;
        }
    }

    .area-manager-wrapper {
        background-color: #fff;
        padding: 2px 0;
        border: 1px solid #d5dfe3;
        border-top: none;

        @media (max-width: $screen-sm) {
            padding: 0;
        }
    }

    .agent-search-manage-areas {
        border: 1px solid #d5dfe3;
        border-top: none;
    }
    
    .insights-section-group {
        border-top: none;
        
        @media (max-width: $screen-sm) {
            border: none;
        }
    }

    .insights-eligible-section-group{
        position: relative;
        top: -2px;
    }

    .beta-badge {
        position: relative;
        background-color: $listreports-orange;
        padding: 4px 6px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        top: -12px;

        @media (max-width: $screen-sm) {
            top: -4px;
            padding: 2px 4px;
            font-size: 11px;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $ice-ice-baby;
        border: 0;
    }

    .agentfarm-area-manager {
        padding: 16px 20px;
    }

    .search-sort {
        margin-bottom: -1px;
        padding: 15px 0;

        .wrap {
            padding: 0 20px;

            &.search-box {
                min-width: 33%;
            }
        }

        .divider {
            height: 0;
        }

        .form-control {
            margin-bottom: 0;
        }
    }

    .table-section {
        margin-top: 12px;
    }

    .tab-agent-list-side{
        content: url('/images/icons/agent-list-side.svg');
        vertical-align: sub;
    }

    .tab-agent-insights{
        content: url('/images/agentfarm/insights.svg');
        vertical-align: sub;
    }

    .tab-agent-search{
        content: url('/images/agentfarm/bar-chart.svg');
        vertical-align: sub;
    }


    tabbar tablabel.selected {
        .tab-agent-insights{
            content: url('/images/agentfarm/insights-selected.svg');
        }

        .tab-agent-list-side{
            content: url('/images/icons/agent-list-side-selected.svg');
        }

        .tab-agent-search{
            content: url('/images/agentfarm/bar-chart-selected.svg');
        }
    }

    .agent-intel-wrapper {
        max-width: 600px;
    }
}

@import './stats/styles';
@import './listings/styles';
@import './agents/styles';
@import './no-results-buyside/styles';
@import './no-results-listside/styles';
@import './buyside-area-modal/styles';
@import './low-coverage-messaging/styles';

transactions-dashboard {
    display: block;
    max-width: 1040px;

    .content {
        margin-top: 50px;
        margin-bottom: 50px;

        @media(max-width: $screen-xs-max) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .ipnsd {
        .img-holder {
            max-width: 275px;

            @media(max-width: $screen-xs-max) {
                max-width: 220px;
                margin: 0 auto;
            }
        }

        .content {
            .text {
                max-width: 450px;

                h2 {
                    margin: 0;

                    @media(max-width: $screen-xs-max) {
                        text-align: center;
                    }
                }

                h3 {
                    @media(max-width: $screen-xs-max) {
                        text-align: center;
                    }
                }

                ul {
                    li {
                        margin-bottom: 20px;

                        h3 {
                            text-align: left;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .icon-holder {
                margin-right: 10px;
                position: relative;
                top: 2px;
            }
        }
    }

    .ipwsd {
        .img-holder {
            max-width: 275px;
            margin-right: 50px;
            position: relative;

            @media(max-width: $screen-xs-max) {
                margin: 0 auto;
                text-align: center;
                max-width: 200px;
            }
        }

        .content {
            .text {
                padding-top: 25px;
                max-width: 450px;

                h2 {
                    margin: 0;

                    @media(max-width: $screen-xs-max) {
                        text-align: center;
                    }
                }
            }

            .box-date {
                border: 2px solid $ice-ice-baby;
                padding: 25px 40px;

                h2 {
                    margin-top: 10px;
                }

                @media(max-width: $screen-xs-max) {
                    padding: 25px 20px;
                }
            }

            .footer-copy {
                @media(max-width: $screen-xs-max) {
                    text-align: center;
                }
            }
        }
    }

    .table-view {
        margin-bottom: 70px;

        header {
            border-bottom: 0;
        }

        .listing-details {
            .status {
                font-size: 10px;
            }
        }

        .img-holder {
            $width: 135px;
            $widthXs: 113px;

            width: $width;
            height: 96px;

            img {
                width: $width;

                @media(max-width: $screen-xs-max) {
                    width: $widthXs;
                }
            }

            @media(max-width: $screen-xs-max) {
                width: $widthXs;
                margin-right: 15px;
            }
        }

        .listing-info {
            @media(max-width: $screen-xs-max) {
                margin-bottom: 10px;
            }
        }
    }

    .loading {
        padding: 25px;
    }

    .no-transactions {
        border-top: 1px solid $ice-ice-baby;
        padding: 50px;

        h1 {
            margin-top: 0;
        }
    }

    transactions-dashboard-header-widget {
        margin-bottom: 20px;
    }
}

@import './header-widget/styles';

.profile {
    display: block;
    max-width: 1400px;

    .acquire-message-textarea {
        &::placeholder {
            color: dimgray !important;
            opacity: 1;
        }

        &:focus::placeholder {
            opacity: 0;
        }
    }

    section > form {
        max-width: 500px;
        margin: 0 auto;
    }

    .face-wrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0, 0, 0, .8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 20px 0 0;

        @media (max-width: $screen-sm) {
            width: 80px;
            height: 80px;
        }
    }

    .group {
        max-width: 500px;
        margin: 0 auto;

        header {
            margin-left: -60px;

            @media (max-width: $screen-sm) {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }

        h2 {
            margin-top: 0;
        }

        h4 {
            margin: 0;
            color: $defaultFontColor;

            @media (max-width: $screen-sm) {
                font-size: 16px;
            }
        }
    }

    .group-item {
        padding-left: 40px;

        @media (max-width: $screen-md) {
            padding: 0;
            border: none;
            padding-top: 14px;
        }

        h2 {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $ice-ice-baby;
            padding-bottom: 15px;

            &.no-line {
                border-bottom: 0;
                padding-bottom: 0;
            }

            @media (max-width: $screen-sm-max) {
                margin-top: 20px;
            }
        }

        .heading-icon-wrap {
            position: relative;
            top: -3px;
            margin-left: 2px;
        }
    }

    .number-encircled {
        display: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $listreports-blue;
        color: #fff;
        text-align: center;
        position: relative;
        margin-right: 20px;
        font-weight: bold;

        @media (max-width: $screen-sm) {
            margin-right: 10px;
            font-size: 16px;
        }
    }

    .banner-header {
        background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Profile_1024x200.png');
        background-size: cover;

        @media (max-width: $screen-sm) {
            background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Profile_700x180.png');
        }
    }

    .error-box {
        margin-top: 0;
    }

    section {
        padding: 75px 100px;

        @media (max-width: $screen-sm) {
            padding: 15px;
        }

        &.notifications {
            padding: 75px 50px;

            @media (max-width: $screen-sm) {
                padding: 24px;
            }
        }
    }

    .personal-info {
        form {
            .group:nth-child(1) {
                .number-encircled:after {
                    content: '1'
                }
            }

            .group:nth-child(2) {
                .number-encircled:after {
                    content: '2'
                }
            }

            .group:nth-child(3) {
                .number-encircled:after {
                    content: '3'
                }
            }
        }
    }

    .group {
        header {
            // margin-left: -60px;

            @media (max-width: $screen-sm) {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }

        h2 {
            margin-top: 0;
        }

        h4 {
            margin: 0;
            color: $defaultFontColor;

            @media (max-width: $screen-sm) {
                font-size: 16px;
            }
        }
    }

    .form-control-feedback {
        top: auto;
        bottom: 4px;
    }

    .aliases {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            padding: 0 8px;
            color: $defaultFontColor;
        }
    }

    md-toolbar {
        @media (max-width: $screen-sm) {
            padding: 0;
            font-size: 14px;
        }
    }

    label {
        @media (max-width: $screen-sm) {
            font-size: 14px;
        }

        &.has-bottom-margin {
            margin-bottom: 20px;
        }
    }

    a {
        color: $listreports-blue;
        margin-bottom: 8px;
    }

    a.btn-settings{
        margin-bottom: 0;
        font-weight: bold;
        &:hover {
            color: #0C949D;
        }
    }

    .btn-upload {
        > div {
            margin-bottom: 5px;
            font-weight: bold;
        }
    }

    .nest {
        color: $defaultFontColor;

        h1 {
            margin: 0;
            color: $listreports-blue;
            font-size: 36px;

            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }
        }

        .form-control[readonly] {
            background: #fff;
        }
    }

    .preloader {
        margin: 0 auto;
    }

    .state-mlo-license {
        .states {
            min-width: 63px;

            select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background-image: none !important;
                border-right: 0;
                padding-right: 6px !important;
            }
        }

        .caret {
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -2px;
        }

        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .notifications-wrap {

        .alias-marketing-zip-wrap.clamp-width {
            max-width: 500px;
        }

        .aliases {
            li {
                padding: 10px 0;
                border-top: 1px solid $smoke;
                border-bottom: 1px solid $smoke;
                margin-bottom: -1px;
            }

            .caption {
                margin: 20px 0 12px;
            }
        }

        &.autopilot-eligible {
            .group{
                @media (max-width: $screen-sm) {
                    max-width: 100%;
                }
            }

            .alias-marketing-zip-wrap {
                padding-right: 40px;

                @media (max-width: $screen-md) {
                    padding: 0;
                    padding-bottom: 40px;
                }
            }

            .autopilot-wrap {
                padding-left: 40px;
                border-left: 1px solid $ice-ice-baby;

                @media (max-width: $screen-md) {
                    padding: 0;
                    border: none;
                    border-top: 1px solid $ice-ice-baby;
                    padding-top: 14px;
                }

                h2 {
                    border-bottom: 1px solid $ice-ice-baby;
                    padding-bottom: 15px;

                    md-switch {
                        margin: 0;
                    }

                    @media (max-width: $screen-md) {
                        margin-top: 20px;
                    }
                }

                .heading-icon-wrap {
                    position: relative;
                    top: -3px;
                    margin-left: 2px;
                }

                &.paywall .disable-if-paywall{
                    opacity: 0.4;
                }

                .upgrade-wrap {
                    h3 {
                        margin: 24px 0 18px;
                    }
                }
            }
        }
    }

    .marketing-zip {
        margin-top: 30px;

        h2 {
            margin-bottom: 0;
        }

        .subtitle {
            margin-bottom: 15px;
        }
    }

    .rusc-block {
        background:#F8FAFB;
        margin: 40px 0px;

        .icon {
            font-size:40px;
        }
    }

    @media (max-width: $screen-sm) {
        font-size: 14px;
    }
}

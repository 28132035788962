.odometer-wrap {

    .odometer-character {
        width: 21px;
        margin-right: 1px;
        border-radius: 3px;
        padding: 2px 0 1px;
        text-align: center;
        background-color: #1f2b35;
        color: #fff;
        display: inline-block;
    }
}
purchase-buyside-credits-modal-cancellation-survey {
    display: block;

    .form-description {
        font-size: 18px;
    }
    > * {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        li {
            margin: 8px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            input {
                position: relative;
                top: -2px;
            }
        }
    }
}

simple-file-uploader {
    .photo-selection-zone {
        border: 1px dashed $giggity;
        background-color: transparent;

        .main-content {

            margin: 16px;

            .description {
                max-width: 370px;
                margin-bottom: 20px;
            }

            .btn {
                font-weight: normal;
                font-size: 15px;
            }
        }

    }

}

cancel-subscription {
    $subsectionPadding: 32px;
    $subsectionPaddingSm: 16px;

    display: block;
    max-width: 1000px;

    h2 {
        margin: 0;
    }

    .reason {
        .body {
            max-width: 490px;
            margin: 0 auto;
        }

        .btn-wrap {
            .btn {
                width: 100%;
                max-width: 240px;
                margin: 4px;
                text-transform: uppercase;

                @media (max-width: $screen-xs-max) {
                    max-width: 100%;
                }
            }
        }
    }

    .cancel {
        h3 {
            max-width: 490px;
            margin-right: auto;
            margin-left: auto;
        }

        .losses {
            p {
                max-width: 490px;
                margin: 15px auto;
                line-height: 1.5;

                .lg {
                    font-weight: bold;
                    // font-size: 20px;

                    //  @media (max-width: $screen-xs-max) {
                    //     font-size: 16px;
                    // }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .realtors {
            padding: $subsectionPadding;
            max-width: 490px;
            margin: 0 auto $subsectionPadding;
            border-bottom: 1px solid $ice-ice-baby;

            > div {
                max-width: 490px;
                margin: 0 auto;
            }

            .agent-info {
                padding: 12px;
                max-width: 50%;

                .headshot {
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                    min-height: 50px;
                    background: center center no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                    margin-right: 16px;
                }

                @media (max-width: $screen-xs-max) {
                    max-width: 100%;
                    padding-left: 0;
                }
            }

            .others {
                font-size: 20px;
                font-weight: bold;
                text-align: right;
                margin-top: 16px;

                @media (max-width: $screen-xs-max) {
                    font-size: 16px;
                }
            }

            @media (max-width: $screen-sm) {
                padding: 8px 0;
            }
        }

        .additional-copy {
            margin-bottom: 24px;

            p {
                max-width: 490px;
                margin: 5px auto;
                line-height: 1.2;
            }
        }

        .btn-wrap {
            max-width: 490px;
            margin: $subsectionPadding auto 0;
        }
    }

    .keep-my-account {
        .content > div {
            max-width: 490px;
            margin: 0 auto 32px;
        }

        .icon-wrap {
            margin-right: 15px;

            .lr {
                position: relative;

                &.lr-listing-presentation {
                    top: -5px;
                }

                &.lr-training {
                    top: -7px;
                }

                &.lr-phone-calling {
                    top: -2px;
                }
            }
        }

        .btn-wrap {
            margin-left: 15px;

            @media (max-width: $screen-xs-max) {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        .separator {
            border-bottom: 1px solid $ice-ice-baby;
            margin: $subsectionPadding 0;
        }

        .footer-btn-wrap {
            @media (max-width: $screen-xs-max) {
                text-align: center;
            }
        }
    }
}

.just-listed {
    min-height: 400px;

    .edit-alerts {
        padding: 20px 20px;
    }

    .header {
        padding: 10px 20px;
    }

    .line {
        padding: 20px;
    }

     .pageit-wrapper {
        padding: 20px;
    }
}
new-recently-sold-alert {
    .new-recently-sold-alert {
        max-width: 1000px;
    }

    .banner-header {
        background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships.png');

        @media (max-width: $screen-sm) {
            background-image: url('/images/agent-headers/background/LR_Realtor_Headers_Relationships_Mobile.png');
        }
    }

    form {
        display: block;
        max-width: 640px;
        margin: 0 auto;
    }

    p {
        margin: -8px 0 10px;
        font-size: 14px;
    }

    .legal {
        @media (max-width: $screen-xs-max) {
            font-size: 11px;
        }
    }

    .buttons-holder {
        margin: 15px 0;

        button {
            margin: 5px 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: $screen-xs-max) {
                margin: 5px 0;
            }
        }
    }

    .error-box {
        font-size: 16px;

        &.offsetme {
            margin-top: 0;
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }
}

cancellation-final-step {
    .form-group {
        margin-right: 20px;

        .form-control {
            width: 20px;
            height: 20px;
        }

        .label-copy {
            max-width: 270px;
        }
    }

    .opt-wrapper {
        .btn.btn-link {
            @media (max-width: $screen-xs-max) {
                margin-top: -10px;
                margin-bottom: 25px;
                margin-left: 30px;
                display: inline-block;
            }
        }
    }

    .ctas {
        .btn {
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
    }
}

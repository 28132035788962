.update-stale-report-modal {

    .before-after-wrap,
    .stale-profile-wrap,
    .stale-plo-wrap,
    .stale-rusc-wrap {
        position: relative;
        margin: 32px 0;

        @media (max-width: $screen-md-min) {
            margin: 24px 0;
        }
    }

    .stale-trial-access-wrap {
        position: relative;
        margin: 32px 0;

        @media (max-width: $screen-md-min) {
            margin: 24px 0;
        }
    }

    .icon-wrap {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);

        @media (max-width: $screen-md-min) {
            transform: translateY(-75%);
            -webkit-transform: translateY(-75%);
        }
        color: $listreports-dark-blue;
        font-size: 20px;
    }

    .profile-wrap {
        padding: 12px 0;

        @media (max-width: $screen-md-min) {
            padding: 60px 0;

            &.before {
                padding-top: 0;
            }

            &.after {
                padding-bottom: 0;
            }
        }
    }

    .profile-info-container {
        padding-top: 14px;
        user-info-card {
            display: block;
        }

        @media (max-width: $screen-sm-min) {
            padding-top: 0;
        }
    }

    .card-wrap {
        margin-bottom: 0;

        .img-col {
            @media (max-width: $screen-sm) {
                padding: 4%;
            }

            padding-right: 4%;
        }

        .light-text {
            &.margin-top {
                margin-top: 8px;
            }

            color: #a4aaaf;
        }

        .info {
            @media (max-width: $screen-sm) {
                text-align: center;
            }
            text-align: left;
        }
    }

    .img-holder {
        &.headshot {
            text-align: right;

            @media (max-width: $screen-sm) {
                text-align: center;
            }
        }

        &.branding {
            margin-top: 20px;
        }

        &.branding img {
            width: 120px;
        }

        &.headshot img {
            max-width: 100px;
            min-width: 100px;
        }

        img {
            max-width: 100%;
        }

        @media (max-width: $screen-sm) {
            text-align: center;
        }
    }

    .rusc {
        font-size: 24px;
        font-weight: normal;
    }

    .trial-access {
        font-size: 24px;
        font-weight: normal;
    }

    &.multi-layout {
        .img-holder {
            &.headshot {
                text-align: center;
            }

            &.branding {
                margin-top: 14px;
            }

            &.branding img {
                width: 120px;
            }

            &.headshot img {
                max-width: 80px;
                min-width: 80px;
            }
        }

        .rusc, .plo-name {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .hr-separator {
        height: 0;
        border-bottom: 1px solid $ice-ice-baby;
        @media (max-width: $screen-sm-min) {
            margin: 0 -24px;
        }
    }

    .button-holder {
        margin: 0 auto 28px;
        max-width: 500px;
        @media (max-width: $screen-sm-min) {
            max-width: 320px;
        }

        button {
            margin: 0 6px;
            @media (max-width: $screen-sm-min) {
                margin: 0 4px;
            }
        }
    }

    .subtitle {
        text-align: center;
        color: $sidewalk;
        font-weight: bold;
        margin-bottom: 4px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .caption {
        margin-bottom: 15px;
    }


    .disclaimer {
        p:last-of-type {
            margin: 0;
        }
    }

    .changed {
        background-color: $listreports-pale-yellow;
    }
}

// primary
$listreports-blue: #007780; // blue steel
$listreports-blue-hover: #0C949D; // blue steel hover
$listreports-dark-blue: #1e2b35; // before midnight
$listreports-dark-blue-hover: #35495E; // before midnight hover
$listreports-gray: #76888f; // giggity
$listreports-grey: #76888f; // giggity
$listreports-light-gray: #B3BBC0;
$listreports-red: #d51d4c; // front door red
$listreports-red-hover: #C21A45; // front door red hover
$listreports-gold: #F2B21C;
$listreports-pale-yellow: #FFE600;

// secondary
$listreports-green: #8cb63f; // lawn
$listreports-orange: #f3b204; // old towne orange

// tertiary
$midnight: #2f3c49;
$rainy-day: #3b4956;
$ice-ice-baby: #d5dfe3;
$smoke: #ecf0f1;
$facebook-blue: #3b5998;
$twitter-black: #000000;
$share-red: #e4234e;
$sidewalk: #b4bbbf;
$frosty: #f8fafb;
$giggity: #696969;
$stone: #525658;

// aliases
$blue-steel: $listreports-blue;
$before-midnight: $listreports-dark-blue;
$front-door-red: $listreports-red;
$lawn: $listreports-green;
$old-towne-orange: $listreports-orange;
$errorred: #d51d4c;
$errorRed: #d51d4c;


// bootstrap vars
$defaultfontcolor: $before-midnight;
$defaultFontColor: $before-midnight;
$defaultSecondaryTextColor: $stone;

//mbshighway colors
$mbshighway-body-link-btns: #8551DC;
$mbshighway-btn-text: #FFFFFF;
$mbshighway-header-text: #113A3F;
$mbshighway-form-text: #0D2121;

.listreports-blue {
    color: $listreports-blue;
}

.listreports-dark-blue {
    color: $listreports-dark-blue;
}

.listreports-light-gray {
    color: $listreports-light-gray;
}

.listreports-gray {
    color: $listreports-gray;
}

.listreports-grey {
    color: $listreports-grey;
}

.listreports-frosty {
    color: $frosty;
}

.listreports-frosty-bg {
    background-color: $frosty;
}

.listreports-giggity {
    color: $giggity;
}

.listreports-sidewalk {
    color: $sidewalk;
}

.listreports-red {
    color: $listreports-red;
}

.listreports-green {
    color: $listreports-green;
}

.listreports-orange {
    color: $listreports-orange;
}

.ice-ice-baby {
    color: $ice-ice-baby;
}

.sidewalk {
    color: $sidewalk;
}

.stone {
    color: $stone;
}

.default-font-color {
    color: $defaultfontcolor;
}

.mbshighway-body-link-btns-bg{
    background-color: $mbshighway-body-link-btns;
}

.mbshighway-body-link-btns-text{
    color: $mbshighway-body-link-btns!important;
    text-decoration: underline;
}

.mbshighway-btn-text{
    color: $mbshighway-btn-text!important;
}

.mbshighway-header-text{
    color: $mbshighway-header-text;
}

.mbshighway-form-text{
    color: $mbshighway-form-text;
}

.default-secondary-text-color {
    color: $defaultSecondaryTextColor;
}

.contact-us {
    .error {
        border-color: $errorRed;
    }

    .red {
        color: $errorRed;
    }

    h2 {
        margin-top: 0;
    }

    .time-wrap {
        @media (max-width: $screen-xs-max) {
            display: inline-block;
            margin: 10px auto 0;
        }
    }

    .time {
        display: block;
        font-size: 36px;
        font-weight: bold;
        margin: 5px 0;
    }

    .image-holder {
        border: 1px solid #ccc;
        padding: 30px;
        border-radius: 4px;
    }

    .agent-photo {
        width: 100px;

        @media (max-width: $screen-sm) {
            width: 75px;
        }
    }
}

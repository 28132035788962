.loggedin-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 200;
}

.agent-intel-header-mobile {
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    border-bottom: 1px solid #d5dfe3;
}

.loggedin-wrapper {
    background-color: #f4f7f8;

    > md-backdrop {
        @media (max-width: $screen-xs-max) {
            z-index: 10001;
        }
    }

    > md-sidenav {
        $sidebar-width: 200px;

        width: $sidebar-width;
        min-width: $sidebar-width;
        max-width: $sidebar-width;
        background: $listreports-dark-blue;
        padding-bottom: 50px;

        @media (max-width: $screen-sm) {
            height: 100vh;
        }

        @media (max-width: $screen-xs-max) {
            z-index: 10002;
        }
    }

    .logo-header {
        text-align: center;
        color: #fff;
        padding: 50px 15px;
        position: relative;

        img {
            max-width: 106px;
        }
    }

    .nav {
        padding: 0;
        margin: 0;

        .fa-lg {
            top: -1px;
        }

        .ls-icon-mail {
            top: -4px;
        }

        &.nav-stacked {
            width: 100%;
        }
    }

    .divider {
        border-bottom: 1px solid #172228;
    }

    .mobile-menu-close {
        color: #fff;
        position: absolute;
        top: 23px;
        left: 23px;
    }

    .signout-holder {
        margin-top: 50px;
        width: 100%;
    }

    .header-content-wrap {
        padding: 0;
        overflow: hidden;
        background: #ecf0f0;
    }

    .content-wrap {
        overflow: auto;
    }

    &.sticky-content {
        .header-content-wrap {
            overflow: unset;
        }

        .content-wrap {
            overflow: unset;
        }
    }

    .beta-badge {
        display: inline-block;
        position: relative;
        background-color: $listreports-orange;
        padding: 3px 5px 2px;
        font-size: 10px;
        border-radius: 2px;
        color: #fff;
        top: -2px;
        margin-left: 2px;
    }

    .robin-logo-badge {
        content: url('/images/robin-logo.svg');
        vertical-align: sub;
        height: 19px;
    }

    .premium-logo-badge {
        content: url('/images/premium-logo.svg');
        vertical-align: middle;
        height: 24px;
        position: relative;
        top: -2px;
    }

    > md-content {
        width: 100%;
    }

    md-content.content-wrap {
        width: 100%;
        padding: 48px;
        color: $defaultFontColor;
        background-color: #ecf0f0;

        @media (max-width: $screen-sm) {
            padding: 0;
        }
    }
    .secondary-menu-item {
        padding-left: 20px;
    }

    .shop-icon {
        margin-left: 8px;
        font-size: 10px;
    }

    .hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .side-nav-dropdown {
        padding: 15px;
    }
}
.typefrom_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999;
}

.typefrom_container {
    max-width: 800px;
    max-height: 400px;
    border-radius: 4px;
    margin: 5% auto 0;
}

.typefrom_close {
    position: relative;
    z-index: 9999;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.75rem;
    user-select: none;
    padding: 8px 16px;
    float: right;
    border: none;
    background-color: transparent;
}

.typefrom_iframe {
    border-radius: 4px;
    position: absolute;
    width: 800px;
    margin: 0;
    border: none;
}

@media screen and (max-width: 600px) {
    .typefrom_container {
      max-width: 100%;
      width: 100%;
      border-radius: 0;
      background-color: #fff;
      margin: 0;
      height: 100%;
    }
    .typefrom_iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
}
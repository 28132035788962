home-plo-value-prop {
    display: block;

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: auto !important;
        
        > div {
            height: 100% !important;
        }
    }

    .title {
        h2 {
            font-size: 18px;
            margin-bottom: 12px;
            margin-top: 37px;
        }
    }

    .avatar-container {
        display:flex; 
        align-items: center;
        justify-content: center; 
    }

    .avatar-holder {
        border-radius: 50%;
        margin-left: -8px;
        border: 2px solid #FFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }


    .image-holder {
        position: relative;
        background: linear-gradient(180deg, #ECF0F1 0%, rgba(236, 240, 241, 0) 100%);    

        slick {
            margin-bottom: 0;

            .move-left {
                font-size: 23px;
                left: 8px;
                z-index: 1;
            }

            .move-right {
                font-size: 23px;
                right: 8px;
                z-index: 1;
            }
        }

        .lock {
            position: absolute;
            top: 8px;
            right: 8px;
            background-color: $giggity;
            color: #fff;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

/**********************

    SASS VARIABLES

**********************/

/****  BLUE  ****/

$dark-blue-background: #1b303a;
$dark-blue-hatched-background: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAJElEQVQIW2NkAAJRRfX/r+/fZERmM2ITBCkAq8KmC0U7ZUYBANOzKr9wBtG7AAAAAElFTkSuQmCC';
$light-blue-hatched-background: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAI0lEQVQIW2OUNrD6//TCMUYGIEBmgwXQBUEKMFTCdDFSzygAakkrDzcNLa0AAAAASUVORK5CYII=';


$dark-blue-border-accent: #181A1B;
$light-blue-border-accent: #2F465A;
$inset-border-accent: #243748;

$primary-blue-accent: #1bbdc8;
$primary-blue-accent-highlight: #90e4ea;
$secondary-blue-accent: #152127;
$secondary-blue-accent-highlight: #283E4A;

$dark-blue-overlay-bg: rgba(21,33,39,0.8);
$pale-blue-bg: #F0F3F4;
$pale-blue-selection: #8ABBC2;
$navy-blue-bg: #151C1F;
$pipeline-form-label-color: #9BA4B0;
$pipeline-input-border-color: #323D49;

$pipeline-btn-complete-color: #8bb63f;
$pipeline-btn-complete-border-color: #3c763d;

$pipeline-btn-enough-images-color: #007c8f;
$pipeline-btn-enough-images-border-color: #2F465A;

$logo-blue: #26bec9;


/****  GRAY  ****/

$list-disabled-gray: #b8b8b8;
$dark-blue-gray: #444a51;
$list-item-label: #282b2d;
$list-item-link: #6d6d6d;
$list-item-link-highlight: #585858;
$background-gray: #EAECEB;

/**** BUTTON COLORS ****/

$btn-primary-bg: #007c8f;

/**** ACCENT COLORS ****/

$home-details-icon-bg: #cf5e0c;
$agent-details-icon-bg: #57343c;
$comarketed-details-icon-bg: #39789f;
$infographics-icon-bg: #77b8b8;
$pdf-icon-bg: #1b544b;
$property-website-icon-bg: #574B60;
$cba-icon-bg: #595d00;

$schools-bg: #d5A42e;
$schools-elementary-bg: #049dbf;
$schools-middle-bg: #03658c;
$schools-high-bg: #d5441e;

$restaurants-bg: #8c271b;
$area-bg: #292336;
$outdoors-bg: #41130e;

$pdf-flyer-bg: #67917e;
$pdf-detailed-bg: #075b59;


/**** CUSTOM COLORS ****/
$errorRed: #eb1c4b;
$listreports-blue: #26bec9;
$listreports-red: #e5224d;
$listreports-dark-blue: #1d303b;
$listreports-dark-green: #1b544b;
$listreports-gold: #eaac20;
$listreports-green: #8bb63f;
$listreports-charcoal: #3c4142;
$listreports-dark-blue: #7e3c8d;

$listreports-vendor-text-gray: #4b4b4b;

/**** NOTIFICATION LEVELS ****/
$feedback-success-text: #3c763d;
$feedback-success-bg: #d6e9c6;

$feedback-working-text: #3c763d;
$feedback-working-bg: #3c763d;

$feedback-error-text: #3c763d;
$feedback-error-bg: #3c763d;

$melon: #FAEEAC;
$dark-melon: #EEB624;


/**** EASING FUNCTIONS ****/

$bezier-out: cubic-bezier(.15,.25,.53,.96);


/**** DARKROOM ICON BGs ****/

$backyard-bg: '../images/icons/icon-backyard.png';
$bathroom-bg: '../images/icons/icon-bathroom.png';
$bedroom-bg: '../images/icons/icon-bedroom.png';
$external-bg: '../images/icons/icon-external.png';
$kitchen-bg: '../images/icons/icon-kitchen.png';
$other-bg: '../images/icons/icon-other.png';


abn-header {
    width: 100%;
    display: block;
    position: relative;

    title-bar {
        .subtitle {
            margin-top: -20px;

            .stat {
                padding-right: 20px;
                margin-right: 20px;
                border-right: 1px solid $ice-ice-baby;

                &:last-child {
                    border-right: 0;
                    padding-right: 0;
                }

                @media (max-width: $screen-md-max) {
                    padding-right: 10px;
                    margin-right: 10px;
                    font-size: 16px;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-top: -10px;
            }
        }
    }
}

.invite-agent-modal {
    .modal-content {
        width: 735px;

        .group {
            .form-group {
                padding: 0 5px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .error-box {
            margin-top: 0px;
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }


}

frequency-alert-setting {
    .title {
        margin: 0;
        font-weight: bold;
    }

    .description {
        margin: 0;
    }
    .checkbox-container{
        margin-top: 16px;

        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }

        .left-checkbox-container {
            margin-right: 16px;

            @media (min-width: $screen-md-min) {
                margin-right: 0;
                width:50px;
            }
        }

        .right-checkbox-container {
            @media (min-width: $screen-md-min) {
                width:50px;
            }
        }

        .checkbox-label {
            margin-right: 4px;

            @media (min-width: $screen-md-min) {
                margin-right: 0;
            }
        }

        .fancy-checkbox-v2 {
            @media (min-width: $screen-md-min) {
                margin-right: 0;
            }
        }
    }

    .frequency-options {
        margin-top: 24px;

        md-radio-group.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before, md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
            background-color: white;
        }

        md-radio-group {
            display: flex;
            justify-content: space-between;
            md-radio-button {
                margin-bottom: 0;
                .md-ink-ripple, .md-ripple-container {
                    color: white;
                }
                .md-on {
                    background-color: $giggity;
                }
                .md-off {
                    border-color: $giggity;
                    border-width: 1px;
                }
            }
        }
    }
}

pageit {
    $fontSize: 14px;
    $backgroundColor: $giggity;
    $fontColor: $defaultFontColor;
    $selectedBackgroundColor: $listreports-blue;
    $selectedFontColor: #fff;

    ul {
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: {
                left: 4px;
                right: 4px;
            }
            font-size: $fontSize;
            padding: 5px 0;
            border-radius: 4px;
            border: 1px solid $backgroundColor;
            color: $fontColor;
            width: 36px;
            height: 36px;
            text-align: center;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.selected {
                background-color: $selectedBackgroundColor;
                border-color: $selectedBackgroundColor;
                color: $selectedFontColor;
            }

            &.not-circle {
                background-color: transparent;
                border-color: transparent;
            }

            &.prev-next-controls {
                padding: 4px 0;
                border-color: $backgroundColor;

                a {
                    color: $fontColor;
                }

                i {
                    font-size: 10px;
                }

                &:last-of-type {
                    padding-left: 2px;
                }
            }

            &.dots {
                margin: 0 -5px;
            }

            .btn-link {
                padding: 0;
                margin: 0;
                min-width: 0 !important;
                font-size: $fontSize;
                font-weight: normal;
            }
        }
    }
}

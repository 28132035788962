.search-by-office-modal {
    .modal-dialog {
        text-align: center;
        max-width: 388px;

        .modal-content {
            padding: 32px;
        }

        .close-button {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .search-by-office-modal-main {
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .search-by-office-modal-ctas {
            margin-top: 24px;
        }
    }
}

settings-listreports-settings {
    display: block;
    padding: 75px 100px;

    .group {
        max-width: 500px;
        margin: 0 auto;

        header {
            margin-left: -60px;

            @media (max-width: $screen-sm) {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }

        h2 {
            margin-top: 0;
        }

        h4 {
            margin: 0;
            color: $defaultFontColor;

            @media (max-width: $screen-sm) {
                font-size: 16px;
            }
        }
    }

    .group-item {
        padding-left: 40px;

        @media (max-width: $screen-md) {
            padding: 0;
            border: none;
            padding-top: 14px;
        }

        h2 {
            border-bottom: 1px solid $ice-ice-baby;
            padding-bottom: 15px;

            &.no-line {
                border-bottom: 0;
                padding-bottom: 0;
            }

            @media (max-width: $screen-sm-max) {
                margin-top: 20px;
            }
        }

        .heading-icon-wrap {
            position: relative;
            top: -3px;
            margin-left: 2px;
        }
    }

    a.learn-more {
        font-size: 14px;
        margin-left: 5px;

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 15px;
    }
}

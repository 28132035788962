user-info-card {
    .break-word {
        word-wrap: break-word;
    }

    .img-column {
        padding-top: 2%;
    }

    .info-column {
        padding-top: 2%;
    }

    .img-holder {
        max-width: 100px;
        margin: 0 auto;

        img {
            max-width: 100%;
        }

        &.branding {
            margin-top: 20px;
        }
    }

    .br {
        height: 2px;
        background: $ice-ice-baby;
        margin: 18px auto;
        max-width: 70px;

        @media (max-width: $screen-sm-max) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (max-width: $screen-sm-max) {
        text-align: center;
    }

    text-align: center;

    .stacked-basic {
        .img-column {
            padding: 2%;
        }
        .info-column {
            padding: 4%;
        }
    }

    .marketing-material-preview-v2 {
        text-align: left;

        .info {
            margin-bottom: 4px;
        }

        .img-col > img {
            height: 56px;
        }

        .branding {
            margin-top: 8px;

            & img {
                height: 52px;
                max-width: 100%;
            }
        }
    }

    .marketing-material-preview {
        .card-wrap {
            margin-bottom: 0;

            .img-col {
                @media (max-width: $screen-sm) {
                    padding: 20px;
                }

                padding-right: 20px;
            }

            .light-text {
                &.margin-top {
                    margin-top: 8px;
                }

                color: #a4aaaf;
            }

            .info {
                text-align: left;
                padding-right: 24px;
                @media (max-width: $screen-sm) {
                    text-align: center;
                    padding-right: 0
                }
            }
        }

        .img-holder {
            &.headshot {
                text-align: right;
                margin-right: 0;

                @media (max-width: $screen-sm) {
                    text-align: center;
                    margin-right: auto;
                }
            }

            &.branding {
                margin-top: 20px;
                margin-right: 0;

                @media (max-width: $screen-sm) {
                    margin-right: auto;
                }
    
            }

            &.branding img {
                width: 120px;
            }

            &.headshot img {
                max-width: 100px;
                min-width: 100px;
            }

            img {
                max-width: 100%;
            }

            @media (max-width: $screen-sm) {
                text-align: center;
            }
        }
        
        &.compact .img-holder.headshot {
            margin: 0 0 0 auto;

            @media (max-width: $screen-sm-min) {
                margin: 0 auto;
            }
        }

        &.compact .img-holder.branding {
            margin: 0;
        }
    }

    .horizontal-mobile-stacked {
        .headshot {
            max-width: 60px;
            max-height: 60px;
            margin-right: 16px;
            margin-left: 0;

            img {
                max-width: 60px;
                max-height: 60px;
                min-width: 60px;
                min-height: 60px;
            }

            @media (max-width: $screen-sm) {
                margin: 0;
            }
        }

        .agent-info {
            text-align: left;

            @media (max-width: $screen-sm) {
                text-align: center;
            }
        }

        @media (max-width: $screen-sm) {
            flex-direction: column;
            align-items: center;
        }
    }

    &[template-type="shadow-stacked"] {
        $padding: 40px;

        margin-top: 25px;
        border-radius: 6px;
        box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
        background: #fff;
        display: block;

        .shadow-stacked {
            padding: $padding;
            height: 100%;

            h2,
            h3 {
                margin: 0;
                padding: 0;
            }

            .headshot-holder {
                margin-top: -$padding;
                margin-bottom: 20px;

                .img-bg {
                    margin-top: -50px;
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
                }

                img {
                    width: 100px;
                }
            }

            .bar {
                width: 25px;
                height: 2px;
                background: $listreports-blue;
                margin: 14px auto;
            }

            .pending {
                margin-top: 20px;
                padding: 7px 15px;
                background: $sidewalk;
                color: #fff;
            }

            .ctas {
                margin-top: 25px;
            }
        }

        @media (max-width: $screen-xs-max) {
            box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
        }
    }

    &[template-type="agentfarm"] {
        border-radius: 6px;
        background: #fff;
        display: block;

        .agentfarm {
            height: 100%;

            h2,
            h3 {
                margin: 0;
                padding: 0;
            }

            .headshot-holder {
                margin-bottom: 20px;

                .img-bg {
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
                }

                img {
                    width: 100px;
                }
            }

            .bar {
                width: 25px;
                height: 2px;
                background: $listreports-blue;
                margin: 14px auto;
            }

            .pending {
                margin-top: 20px;
                padding: 7px 15px;
                background: $sidewalk;
                color: #fff;
            }

            .ctas {
                follow-agent-button {
                    display: inline-block;
                    margin-top: 25px;
                }
            }
        }
    }
}

.vendor-user-list {
    display: block;
    max-width: 1400px;

    .md-avatar {
        $avatarSize: 65px;

        width: $avatarSize;
        height: $avatarSize;
        margin-top: 0;
        margin-bottom: 0;
    }

    tr {
        outline: none;
    }

    th,
    td {
        vertical-align: middle !important;
    }

    th {
        border-top: 0 !important;
        border-bottom: 1px solid #e0e0df;
        color: $defaultFontColor;
    }

    td {
        padding: 15px 8px;

        &:first-child {
            padding-left: 15px !important;
        }

        &:last-child {
            padding-right: 15px !important;
        }
    }

    .table.user-list {
        color: $listreports-gray;

        td {
            @media (max-width: $screen-sm) {
                line-height: 1.1;

                &.mobile-vertical-align-top {
                    padding-top: 16px;
                    vertical-align: top !important;
                }

                &.user-details .name {
                    padding-top: 10px;
                }
            }
        }

        .mobile-additional-info {
            overflow: hidden;

            .btn-wrap {
                padding: 8px 0 12px;

                .btn {
                    min-width: 125px;
                }
            }

            .metrics {
                span.top-offset {
                    position: relative;
                    top: -1px;
                }
            }
        }

        .accordion-toggle-wrap .fa{
            font-size: 28px;

            &.fa-caret-up {
                padding-top: 14px;
            }
        }
    }
}

verify-compliance {
    font-size: 16px;

    h1 {
        color: $listreports-blue;
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 30px;

        @media (max-width: $screen-xs-max) {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 15px;
        }
    }

    .hsubtitle {
        font-size: 20px;
        line-height: 22px;
        font-weight: bold;
        margin-bottom: 20px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    label {
        font-weight: normal;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    textarea.form-control {
        height: 200px;
    }

    .btn-holder {
        @media (max-width: $screen-xs-max) {
            button:first-child {
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        font-size: 14px;
    }
}

.get-it-now-modal {
    .button-holder.primary {
        margin-bottom: 10px;
    }

    .bullet-checklist {
        margin-bottom: 20px;

        li {
            i {
                color: $listreports-green;
                font-size: 18px;
            }

            padding: 10px 0;
        }
    }
}

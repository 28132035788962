.error-page {
    > div {
        padding: 30px 0;
    }

    .error-icon {
        max-width: 175px;
        display: inline-block;
    }

    .error-text {
        margin-bottom: 30px;

        h1 {
            font-size: 40px;
            color: $listreports-blue;
            margin-bottom: 0;
        }

        h3 {
            font-size: 21px;
            color: $defaultFontColor;
            margin-top: 10px;
        }

        p {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .btn {
        width: 300px;
    }

    @media (max-width: $screen-sm-min) {
        .error-icon {
            max-width: 135px;
        }

        .error-text {

            h1 {
                font-size: 30px;
            }

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 16px;
            line-height: 18px;
            }
        }

        .btn {
            width: 100%;
        }
    }
}

.shop-now-banner {
    padding: 25px;
    background: $listreports-dark-blue !important;
    color: #fff;

    .fa-inverse {
        color: $listreports-dark-blue;
    }

    .icon {
        margin-right: 15px;
    }

    h1 {
        margin: 0;
        font-size: 24px;

        .yellow-label {
            position: relative;
            top: -4px;
            font-size: 12px;
            font-weight: normal;
        }
    }

    p {
        margin: 0;
    }

    .btn {
        color: $listreports-dark-blue !important;
        margin-left: 15px;
    }
}

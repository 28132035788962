.enroll-stats {
    margin: 0 auto 20px;

    > div {
        padding: 20px 0;
        border-right: 1px solid $ice-ice-baby;
        overflow: hidden;

        &:last-of-type {
            border-right: none;
        }

        @media (max-width: $screen-sm) {
            border-right: none;
            border-bottom: 1px solid $ice-ice-baby;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .cta-block {
        &.invite {
            button {
                margin-top: 12px;
            }
        }

        .cta-wrap {
            height: 100%;
            min-height: 100%;

            .watch-video-link {
                margin-top: 12px;
            }
        }
    }

    .stats-block {
        button {
            margin-top: 18px;
        }

        .count {
            margin: 0;
        }

        .label {
            color: $defaultSecondaryTextColor;
        }

        .headshot-lineup {
            margin-top: 12px;

        }

    }

    .translucent {
        opacity: 0.6;
    }
}

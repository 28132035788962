.waae-confirmation-modal {

    .modal-dialog {
        max-width: 500px;

        .mailbox {
            display: block;
            font-size: 48px;
            text-align: center;
            margin-top: 8px;
        }

        .title {
            width: 300px;
            margin: 0 auto;
            margin-top: 25px;
            margin-bottom: 0;
            font-size: 28px;
            text-align: center;
        }

        .button-container {
            margin-top: 38px;
            text-align: center;
        }

        .waae-features {
            margin-top: 34px;
            @media (min-width: $screen-xs-max) {
                padding: 19px 26px;
            }
            padding: 19px 16px;
            background-color: #F9F9F9;

            .feature-list {
                margin-top: 10px;
                
                .feature-item {
                    margin-top: 8px;
                    font-weight: 500;
                    display: flex;

                    .icon {
                        img {
                            margin-right: 6px;
                            width: 14px;
                            height: 14px;
                        }
                    }

                    .label {
                        flex: 1;
                    }
                }
            }
        }

        .unsubscribe-container {
            text-align: center;
            margin-top: 16px;
            @media (min-width: $screen-xs-max) {
                margin-top: 20px;
            }
        }
    }
}
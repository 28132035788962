.edit-agent-modal {
    font-size: 16px;

    .body {
        padding: 30px;

        @media (max-width: $screen-xs-max) {
            padding: 15px;
        }
    }

    .face-wrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0,0,0,0.8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 10px;
    }

    .headshot {
        margin-right: 10px;
    }

    .headshot-select {
        margin-left: 10px;

        .btn {
            width: 150px;
        }
    }

    .input-group {
        .form-control[readonly] {
            background-color: #fff;
            border: 0;
        }

        .input-group-addon {
            padding: 0;

            .btn {
                position: relative;
                top: -1px;
                margin-bottom: -2px;
                margin-right: -2px;
                height: 46px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

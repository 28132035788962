.duplicate-lead-modal {

    .button-wrapper {
        width: 142px;
    }

    .warning-color {
        color: #F3B204;
    }

    .warning-icon {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
    }

    .modalContent {
        font-size: 18px;
    }

    .paragraph {
        margin-bottom: 24px;
        text-align: center;
    }

    .link {
        font-size: 18px;
    }
}
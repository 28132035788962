home {
    display: block;
    // max-width: 1040px;

    .arba-invite-message-wrapper {
        margin-top: 13px;
        margin-left: 20px;
    }

    .daae-info {
        border: 1px solid $ice-ice-baby;
        padding: 18px;
        width: 100%;
        margin-bottom: 20px;
        background-color: #fff;

        .icon {
            min-width: auto;
            margin-right: 16px;
        }

        a {
            color: #007780;
            cursor: pointer;

            &:focus {
                text-decoration: none;
            }

            &:hover {
                text-decoration: underline;
                color: #0c949d;
            }
        }
    }

    .daae-info:nth-of-type(1) {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    .value-props {
        margin: -10px;
    }

    #home-value-props-holder {
        @media (max-width: $screen-xs-max) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }

            > * {
                min-width: 240px;
                width: 100%;
            }
        }
    }

    .value-prop {
        position: relative;
        padding: 10px;
        width: 25%;

        > div,
        listreports-live-value-prop > div {
            border: 1px solid $ice-ice-baby;
            border-radius: 2px;
            overflow: hidden;
            height: 100%;
        }

        .content {
            .label {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 10px;
                letter-spacing: 1.5px;
            }

            h2 {
                margin-top: 14px;
                margin-bottom: 14px;
                font-size: 18px;
            }

            padding: 16px;

            p {
                margin-bottom: 0;
            }
        }

        .ctas {
            padding-top: 0;
        }

        .new {
            position: absolute;
            top: 11px;
            right: 11px;
            background: $listreports-orange;
            color: #fff;
            padding: 6px 11px;
            border-radius: 2px;
            text-transform: uppercase;
        }

        @media (max-width: $screen-md-max) {
            width: 33.3%;
        }

        @media (max-width: $screen-sm-max) {
            width: 50%;
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
            max-width: 100%;
        }

        &:empty {
            display: none;
        }
    }
}

@import './marketing-kit-value-prop/styles';
@import './shareable-value-prop/styles';
@import './plo-value-prop/styles';
@import './cba-value-prop/styles';
@import './homeiq-value-prop/styles';
@import './listpack-value-prop/styles';
@import './property-search-prop/styles';
@import './listreports-live-value-prop/styles';
@import './value-prop-placeholder/styles';
@import './resubscribe-value-prop/styles';
@import './member-benefits-prop/styles';
@import './robin-panel-prop/styles';

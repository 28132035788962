purchase-buyside-credits-modal-select-plan {
    display: block;

    .headshots-holder {
        margin-top: 24px;

        h3 {
            margin-top: 24px;
        }
    }

    .plans {
        margin-top: 24px;

        .plan-spacer {
            padding: 6px;
        }

        .plan {
            border: 1px solid $ice-ice-baby;
            border-radius: 4px;
            padding: 8px;
            min-height: 135px;
            height: 100%;
            cursor: pointer;

            &.custom {
                cursor: default;
            }

            &.selected {
                border-color: $listreports-blue;
            }

            h3 {
                margin: 12px 0;
            }

            .custom-plan-select {
                margin: 12px 0;
            }

            .recomended {
                letter-spacing: 1.25px;
            }

            .price {
                margin-top: 24px;
                background-color: $frosty;
                padding: 8px;
                border-radius: 4px;
            }
        }
    }
}

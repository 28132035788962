@import '../../../../styles/colors';
@import '../../bootstrap/variables';

invited-lender-banner {
    .invited-lender {
        background: $listreports-blue;
        color: #fff;
        padding: 16px 12px;
        margin-bottom: 15px;

        .lr-gear {
            width: 28px;
            min-width: 28px;
        }

        .banner-text {
            padding-left: 8px;
        }

    }

}

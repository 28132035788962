@import 'bootstrap/input-groups';
@import 'bootstrap/dropdowns';
@import 'bootstrap/forms';

textarea,
input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=tel] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-control {
    border-radius: 2px;
    height: 44px;
    border-color: $ice-ice-baby;
    box-shadow: none !important;
    outline: none !important;
    color: $defaultFontColor;

    &:focus {
        border-color: $listreports-blue;
    }

    &[readonly] {
        background-color: #fff;
        border-color: #ccc;
        box-shadow: none;
        color: $defaultSecondaryTextColor !important;
    }

    &::placeholder {
        color: $defaultSecondaryTextColor !important;
    }
}

input.form-control,
select.form-control {
    height: 42px;
    margin: 0 0 8px;

    &.auto-complete,
    &.no-bottom-margin {
        margin-bottom: 0;
    }

    @media (max-width: $screen-xs-max) {
        height: 36px;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('/images/icons/select-arrow-dark.jpg') !important;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 45px !important;
    margin-right: 0;
    background-size: contain;
    cursor: pointer;

    &:focus {
        border-color: $ice-ice-baby !important;
    }

    &:hover {
        border-color: $ice-ice-baby !important;
    }
}

select::-ms-expand {
    display: none;
}

.table {
    margin-bottom: 0;
}

.form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: $defaultSecondaryTextColor !important;

    &.pad-alt:nth-of-type(odd) {
        padding-right: 4px;
    }

    &.pad-alt:nth-of-type(even) {
        padding-left: 4px;
    }

    @media only screen and (max-width: 480px){
        &.pad-alt {
            padding: 0;
        }
    }
}

.multi-input-wrap {
    > div {
        padding-left: 16px;

        &:first-of-type {
            padding-left: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        > div  {
            padding-left: 0;
            // padding-top: 15px; // Commented out by LC. It breaks the standard spacing between .form-group's

            &:first-of-type {
                padding-top: 0;
            }
        }
    }
}

label {
    color: $defaultFontColor;
    margin-bottom: 8px;
}

.form-body {
    padding-left: 25px;
    border-left: 10px solid $listreports-blue;

    > .form-group {
        &:last-child {
            margin-bottom: 0;
        }
    }
}


button, select, html, textarea, input {
    font-family: $defaultFontFamily;
}

.input-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.form-horizontal .form-group-lg .form-control {
    font-size: 14px;
    border-radius: 2px;
}

.input-helper-text {
    margin-top: 8px;
}

.error-box {
    color: $errorRed;
    position: relative;
    margin-top: -7px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.just-text {
        color: #eb1c4b;
        background: none;
        font-size: 12px;

        &:before {
            content: "\E959";
            font-size: 12px;
        }
    }

    &:before {
        content: "\E959";
        font-family: "portal-icons";
        font-size: 10px;
        margin-right: 5px;
    }

    &.text-center {
        justify-content: center;
    }

    &.offsetme {
        margin-top: -15px;
    }

    &.pad-top {
        margin-top: 10px;
    }
}

.lr-input-btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;

    > :first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > :last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input {
        &:focus {
            z-index: 5;
        }
    }

    .input-group-btn {
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 4;

        &:hover {
            z-index: 5;
        }
    }

    .input-group-text {
        flex: 1;
    }

    .input-text {
        width: 100%;
    }

    .btn {
        margin-bottom: 0;
        font-size: 14px;
        padding-top: 8px;

        .lr-caret-up.lr-rotate-180 {
            top: 1px;
        }

        &:hover {
            z-index: 4;

            &[disabled],
            &[readonly] {
                z-index: 1;
            }
        }
    }

    .btn.dropdown-toggle {
        border-right-width: 1px;
    }

    input {
        flex: 1;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 3;

        &[disabled] {
            z-index: 1;
        }
    }

    &.align-start {
        align-items: start;
    }
}

.state-mlo-license {
    .states {
        min-width: 63px;

        select {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-image: none !important;
            border-right: 0;
            padding-right: 6px !important;
        }
    }

    .lr-caret-up {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -14px;
        font-size: 15px;
    }

    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

form.ng-submitted {
    input.ng-invalid,
    select.ng-invalid,
    textarea.ng-invalid,
    .md-form-control.ng-invalid {
        border-color: $errorRed;
    }
}

.dropdown-menu {
    border-color: $ice-ice-baby;
    box-shadow: 0 4px 10px rgba(0,0,0,0.15);
    border-radius: 2px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 0;
    padding: 0;

    > li > a {
        color: $defaultFontColor;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $ice-ice-baby;

        &:active,
        &:hover {
            background-color: $frosty;
            color: $defaultFontColor;
        }

    }

    &.limit-height {
        max-height: 240px;
        overflow: auto;
    }

    > li:last-of-type > a {
        border-bottom: none;
    }
}

md-datepicker.md-form-control {
    display: block;
    border: 1px solid $ice-ice-baby;
    border-radius: 2px;
    margin-bottom: 8px;
    padding: 11px 12px;

    .md-datepicker-input-container {
        margin-left: 0;
        border: 0;
        width: 100%;
    }

    &._md-datepicker-has-triangle-icon {
        margin-right: 0;

        .md-datepicker-triangle-button {
            top: 50%;
            margin-top: -18px;
        }
    }

    .md-datepicker-expand-triangle {
        border-top-color: $listreports-blue;
    }

    input {
        display: block;
        padding-bottom: 0;
        width: 100%;
        color: $defaultFontColor;
    }
}

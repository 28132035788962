notes {
    $pad: 35px;
    $padSmall: 25px;
    $fontSize: 14px;

    display: block;

    header {
        h1 {
            font-size: 24px;
        }
    }
    .padme {
        padding: $padSmall $padSmall 0;
    }

    .add-note {
        padding: $pad;
        border-bottom: 1px solid #e0e0df;

        @media (max-width: $screen-sm) {
            padding: $padSmall;
        }
    }

    .notes {
        font-size: $fontSize;
        min-height: 100px;

        .note {
            position: relative;
            padding: $pad;
            border-bottom: 1px solid #e0e0df;

            .corner {
                display: none;
                position: absolute;
                top: -17px;
                right: -5px;
                width: 0;
                height: 0;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $listreports-dark-blue;
                transform: rotate(-45deg);
            }

            &:last-child {
                border-bottom: 0;
            }

            &.another-user {
                .blue {
                    color: $listreports-dark-blue;
                }

                .corner {
                    display: block;
                }
            }

            @media (max-width: $screen-sm) {
                padding: $padSmall;
            }
        }

        .editing-btn-holder {
            .btn {
                @media (max-width: $screen-sm) {
                    margin: 5px 0;
                }
            }
        }

        .more,
        .less {
            font-weight: bold;
            padding: 15px;

            i {
                position: relative;
                top: -1px;
            }
        }

        .less {
            i {
                position: relative;
                top: -2px;
            }
        }
    }

    .blue {
        color: $listreports-blue;
    }
}

.waae-success-modal {

    .modal-dialog {
        max-width: 500px;
        text-align: center;

        .thumbsup{
            img {
                width: 48px;
                height: 48px;
            }
        }

        .title {
            margin-top: 25px;
            font-size: 28px;
            font-weight: bold;
        }

        .subtitle {
            margin-top: 12px;
        }
    }
}
pill {
    position: relative;
    display: inline-block;
    height: 40px;
    background: $smoke;
    border-radius: 25px;
    padding: 9px 15px;

    .pill-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .close-btn {
        background: #fff;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        font-size: 11px;
        position: relative;
        right: -5px;
        margin-left: 8px;
    }

    &[color="white"] {
        background: #fff;

        .close-btn {
            background: $smoke;
            color: #fff;
        }
    }

    &[hide-close-button] {

        .close-btn {
            display: none;
        }
    }
}

.owner-hub {

    .report-info {
        height:100%;

        .home-report {
            padding: 20px;

            .home-value-title {
                margin-bottom: 5px;
                @media (min-width: $screen-xs-max){
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }
        }

        .line {
            padding: 10px 20px;
        }

        .disclaimer {
            margin-top: 30px;
            padding: 0 20px 20px 20px;
        }

        .calculations-btn-wrapper {
            padding: 20px;
            text-align: right;
        }

        .calculations-btn {
            width: auto;
        }

        h3 {
            margin-top: 0;
        }

        .table-head {
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-end;
        }

        h5 {
            margin-top: 0;
            margin-bottom: 3px;
            color: $defaultSecondaryTextColor;
            font-weight: 600;
            letter-spacing: 1px;
            font-size: 12px;
        }

        .home-info {
            margin-top: 20px;
            margin-left: -12px;
            margin-right: -12px;
            & > * {
                padding-right: 12px;
                padding-left: 12px;
            }
        }

        .data {
            font-weight: 600;
        }

        .positive-balance {
            color: #8CB63E;
        }

        .negative-balance {
            color: #D61C4B;
        }

        .label {
            font-size: 14px;
            margin-bottom: 5px;
        }

        // actions

        .actions {
            @media only screen and (max-width: $screen-xs-max) {
                display: block;
            }

            display: flex;
            justify-content: space-between;
            padding: 20px 20px 0 20px;
            flex-wrap: wrap;
            align-items: center;
        }

    }

    .extra {
        @media only screen and (max-width: $screen-xs-max) {
            width: 100%;
            margin-top: 16px;
        }
        margin-bottom: 16px;
    }

    .primary-actions {
        display: inline-flex;
        border: 1px solid #D5DFE3;
        border-radius: 3px;
        margin-bottom: 16px;
    }

    .primary-action {
        background-color: white;
        color: $defaultFontColor;
        font-weight: bold;
        height: 42px;
        padding: 0 14px;
        border: none;
        border-right: 1px solid #D5DFE3;
        display: flex;
        align-items: center;
        &:last-child {
            border-right: none;
        }
    }

    .homeiq-message {
        margin-top:0px;
        margin-bottom:24px;
    }

    .no-alerts {
        .btn {
            font-size: inherit;
        }
    }
}

@import './edit-ownerhub-modal/styles'
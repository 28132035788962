nestme-share {
    display: block;

    .btn-single-action {
        width: 170px;
    }

    .form-control[readonly] {
        background: #fff;
    }

    .side-bar {
        width: 370px;

        section {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 10px;
            }
        }

        .body {
            padding: 15px;
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
            margin-bottom: 10px;
        }
    }

    .video {
        header {
            margin-bottom: -15px;
        }

        h1 {
            font-size: 18px;
        }

        .body {
            @media (max-width: $screen-xs-max) {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
            }
        }
    }

    .reasons {
        p {
            padding: 0;
            margin: 0;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }

        .icon {
            margin-right: 15px;

            .lr {
                position: relative;
                top: -1px;
            }

            .lr-house-check {
                top: -4px;
            }
        }
    }

    .main {
        nestme-share-engagement {
            margin-top: 20px;

            @media (max-width: $screen-xs-max) {
                margin-top: 10px;
            }
        }

        section {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 10px;
            }
        }

        .body {
            padding: 15px;

            // @media (max-width: $screen-xs-max) {
            //     padding: 15px;
            // }
        }

        header .close {
            position: relative;
            right: -20px;

            @media (max-width: $screen-xs-max) {
                right: 0;
            }
        }

        .buttons-holder {
            > div {
                margin-bottom: 10px;

                &:last-child {
                    @media (max-width: $screen-xs-max) {
                        margin-bottom: 0;
                    }
                }
            }

            .btn-single-action {
                @media (max-width: $screen-sm-max) {
                    width: 100%;
                }
            }

            > a {
                margin-bottom: 10px;
                margin-right: 10px;

                &:last-child {
                    @media (max-width: $screen-xs-max) {
                        margin-bottom: 0;
                    }
                }
            }

            @media (max-width: $screen-sm-max) {
                margin-top: 15px;
            }
        }

        @media (min-width: $screen-sm) {
            margin-right: 20px;
        }
    }

    .img-responsive {
        display: inline-block;
    }
}

@import './engagement/styles';

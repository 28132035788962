.forgot-password-idp {
    h1 {
        font-size: 25px;
        margin-bottom: 5px;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }


    input.ng-pristine{
        border-color: #0D2121;
    }

    input.ng-dirty.ng-invalid{
        border-color: #D72638;
    }

    p {
        margin-top: 0;
        font-size: 16px;
    }

    .mbs-label{
        color:#0D2121;
    }

    .sign-up-btn{
        color:$mbshighway-body-link-btns;
        font-size: 16px;
    }

    .form-label{
        color:#0D2121;
        font-weight: bold;
        font-size: 14px;
    }

    .footer-text {
        font-size: 16px;
        font-weight: 500;
        .btn-link {
            top: -1px;
        }
        a{
            font-weight: 500;
            text-decoration: underline;
        }
        a:hover{
            color:#6C41B5;
        }
    }

    .submit-btn{
        width: 98px;
        height: 48px;
        border-radius: 2px;
        color:#FFF!important;
        font-weight: bold!important;
        
    }

    .btn-hover{
        button:hover{
            background-color: #6C41B5;
        }
    }
    
    .errorField{
        font-size: 14px;
        color:#D72638;
    }
}

paywall-buyside-data {
    display: block;

    .container {
        padding: 48px;

        @media (max-width: $screen-md-max) {
            padding: 20px;
        }
    }

    .video-holder {
        margin-bottom: 20px;

        @media (min-width: $screen-md) {
            width: 400px;
            margin-right: 21px;
        }
    }

    .info {
        h2 {
            margin: 0 0 20px;
        }

        h3 {
            margin: 0 0 20px;
            font-weight: normal;
        }

        ul {
            li {
                list-style-type: disc;
                margin-left: 18px;
            }
        }

        .ctas {
            .btn:first-child {
                margin-right: 16px;

                @media (max-width: $screen-sm-max) {
                    margin-right: 0;
                }
            }

            @media (max-width: $screen-sm-max) {
                text-align: center;
            }
        }

        @media (min-width: $screen-md) {
            width: 400px;
            margin-left: 21px;
        }
    }
}

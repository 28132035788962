$reportSpacing: 20px;
$reportSpacingHalf: 10px;

.view-report {
    max-width: 1040px;

    &.no-max-width {
        max-width: none;
    }

    &.new-view {
        background-color: white;
        max-width: 100%;

        @media (max-width: $screen-sm) {
            margin: 0;
        }
    }

    .content-padding {
        padding: 24px 48px 0 48px;

        @media (max-width: $screen-sm) {
            padding: 0;

            &.breadcrumbs {
                padding: 15px 15px 0;
            }
        }
    }

    .subsection {
        border: 1px solid #e0e0df;
        background: #fff;

        header {
            padding: 15px;
            border-bottom: 1px solid #e0e0df;

            h2 {
                margin: 0;
                margin-left: 16px;
            }

            button {
                text-transform: capitalize;
            }
        }
    }

    .section {
        margin-top: $reportSpacing;

        @media (max-width: 600px) {
            margin-top: $reportSpacingHalf;
        }
    }

    .sub-title-bar {
        margin-top: -24px;
        margin-bottom: 24px;

        @media (max-width: $screen-xs-max) {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }

    .new-photos-found-banner-wrap {
        margin-bottom: 16px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .user-info-wrapper,
    .agent-plo-info {
        margin-right: 15px;
        max-width: 317.2px;
        // min-height: 460px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: 320px;
            min-width: 320px;
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 10px;
            max-width: 100%;
        }
    }

    .user-info {
        margin-right: $reportSpacingHalf;
        padding: 25px 15px;

        .user-info-title{
            white-space: break-spaces;
        }

        h3 {
            margin-top: -23px;
        }

        ul {
            list-style: none;
            padding: 0;
            // margin-top: 20px;
            margin-top: 0;
            margin-bottom: 0;
        }

        .text {
            margin-bottom: 18px;
        }

        .btn-change-lender {
            color: $listreports-orange;
            font-size: 16px;
            padding: 15px 30px;
        }

        .mobile-contact-info {
            margin-top: 15px;
            padding: 15px;
            border-top: 1px solid #fff;

            > div {
                text-align: center;

                &:last-child {
                    text-align: right;
                }

                &:first-child {
                    text-align: left;
                }

                .ls-icon-mail {
                    top: -3px;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 18px;
                }
            }
        }

        .headshot {
            max-width: 100px;
        }

        .buttons-holder {
            .btn {
                i {
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: 600px) {
            margin-right: 0;
            margin-bottom: $reportSpacingHalf;
        }
    }

    .offer-email {
        // IE Hack Changes
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
             > div:first-child {
                min-height: 315px;
            }
        }

        .seperator {
            height: 18px;
            border-right: 1px solid $listreports-gray;
            padding-left: 17px;
            display: inline-block;
        }

        .status {
            padding: 35px;

            .todo {
                color: $ice-ice-baby;
            }

            .lr {
                margin-right: 15px;
            }
        }

        .btn.send {
            padding: 16px;
        }

        .status-text {
            padding: 10px 15px;
            min-height: 130px;

            @media (max-width: 600px) {
                min-height: 80px;
            }
        }

        .send-buttons-wrapper {
            padding: 15px 15px;
            border-top: 1px solid $ice-ice-baby;

            a {
                font-size: 18px;
                font-weight: bold;
            }

        }

        // &.inactive {
        //     color: #b6c0c2;

        //     .status {
        //         background: #b6c0c2 !important;

        //         &:after {
        //             border-left-color: #b6c0c2 !important;
        //         }
        //     }
        // }

        @media (max-width: 600px) {
            margin-left: 0;
        }
    }

    .marketing-checklist {
        // IE Hack Changes
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height: 460px;
        }
    }

    text-to-lead-code-banner {
        margin-top: $reportSpacing;

        @media (max-width: $screen-xs-max) {
            margin-top: $reportSpacingHalf;
        }
    }

    .top-title {
        margin: 24px 0 -14px;

        @media(min-width: $screen-sm) {
            padding-bottom: 20px;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    title-bar {
        .display-address {
            margin: -20px 0 20px;
        }

        .subtitle {
            margin-top: -9px;

            h3 {
                @media (min-width: $screen-sm) {
                    padding: 0 20px;
                    border-right: 1px solid $ice-ice-baby;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        border-right: 0;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }

            a:link, a:active, a:hover, a:visited{
                color: #007780;
            }

            @media (max-width: $screen-xs-max) {
                margin-bottom: 15px;
            }
        }

        .title-bar-subtitle {
            @media (max-width: $screen-xs-max) {
                margin-top: 15px;
            }
        }
    }

    processing-listing-banner {
        display: block;
        margin-top: 20px;
    }

    .listreports-gold {
        color: $listreports-gold;
    }
}

.view-report-guest {
    @media(max-width: $screen-xs-max) {
        .top-section {
            padding: 20px 15px;
        }

        .top-title {
            font-size: 14px;
            margin-top: 0;
            margin-left: 0;
        }

        .learn-more-btn {
            width: 100%;
        }

        title-bar {
            padding: 0;
            margin-bottom: 16px;

            .title-holder h1 {
                font-size: 18px !important;
                line-height: 1.2;
                font-weight: 500;
            }

            .title-holder a {
                display: none;
            }

            .subtitle {
                margin-bottom: 0;

                h3 {
                    font-size: 14px;
                }
            }
        }

        .materials {
            margin-top: 0;

            header h2 {
                margin-bottom: 0 !important;
            }

            .guest-edit-report-btn-container {
                padding: 12px;
            }
        }
    }
}

.loggedin-out-wrapper {
    .application-header {
        height: auto;

        > div {
            max-width: 1040px;
            margin: 0 auto;
        }
    }

    .view-report {
        margin: 0 auto;
    }
}

@import './marketing-checklist/styles';
@import '../fast-blast-banner/styles';
@import '../shop-now-banner/styles';
@import './invite-comarketer/styles';
@import './materials/styles';

login-phone-verification {
    .hsub {
        margin: -20px auto 20px;
        max-width: 400px;
    }

    .code-input-wrap {
        margin-bottom: 40px;

        input {
            @extend .form-control;
            @extend .bold;

            text-align: center;
            font-size: 30px;
            margin: 0 6px;
            width: 60px;
            height: 60px;
        }

        input.code-input {
            width: 240px;
        }
    }

    .error-box {
        margin-bottom: 20px;
    }
}

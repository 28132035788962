paywall-page {
    display: block;
    max-width: 950px;

    .section-group {
        > header {
            padding: 45px;
            background-color: $listreports-blue;
            color :#fff;

            h1 {
                max-width: 610px;
                margin: 0 auto;
            }

            h2 {
                max-width: 705px;
                margin: 20px auto 40px;
            }
        }

        .content {
            padding: 45px;

            h1 {
                max-width: 630px;
                margin: 0 auto;
            }

            .info {
                margin-bottom: 60px;

                h2 {
                    margin-top: 0;
                }

                .line {
                    width: 50px;
                    height: 2px;
                    background-color: $listreports-blue;
                    margin-bottom: 25px;
                }

                .spacer {
                    width: 60px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: $screen-xs-max) {
                    margin-bottom: 30px;
                }
            }
        }

        footer {
            padding: 45px;
            background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);

            h2 {
                margin: 0 auto 40px;
                max-width: 735px;
            }
        }
    }

    .cta-buttons {
        .btn {
            @media(max-width: $screen-xs-max) {
                display: block;
                width: 100%;
            }
        }
    }
}

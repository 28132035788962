.user-subscriptions {
    color: $listreports-gray;
    color: $defaultFontColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;

    @media (max-width: $screen-sm) {
        padding: 0 16px;
    }

    .spacer {
        width: 100%;
        border-bottom: 1px solid $ice-ice-baby;
        margin: 18px 0 20px;
    }

    .page-spacer {
        width: 100%;
        border-bottom: 1px solid $ice-ice-baby;
        margin-top: 26px;
    }

    .page-title {
        margin-top: 32px;
        margin-bottom: 10px;
    }

    .page-sub-title {
        font-size: 18px;
        margin-bottom: 26px;
    }

    .attempts-box {
        max-width: 536px;
        display: flex;
        justify-content: center;
        background-color: $errorRed;
        color: white;
        padding: 12px 16px;
        margin-bottom: 16px;

        .alert-icon {
            font-size: 30px;
            margin-right: 12px;
        }
    }

    .box {
        width: 260px;
        border: 1px solid $ice-ice-baby;
        margin-bottom: 16px;
        margin-right: 16px;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $screen-sm) {
            width: 100%;
            // padding-bottom: 24px;
        }

        .box-title {
            font-size: 14px;
            margin-bottom: 4px;
        }

        .box-field {
            font-size: 14px;
        }
        .little-space-top {
            margin-top: 2px;
        }

        a {
            margin-bottom: 0;
        }
    }

    .boxes-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (max-width: $screen-sm) {
            flex-direction: column;
        }

        .summary-box {
            height: 274px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .summary-highlight {
                font-size: 28px;
                font-weight: bold;
            }
        }

        .subscription-box {
            height: 129px;
            writing-mode: horizontal-tb;

            .subscription-highlight {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    .subscription-boxes-wrapper {
        display: inline-flex;
        writing-mode: vertical-lr;
        flex-wrap: wrap;
        align-content: flex-start;

        max-height: 290px;
        @media (max-width: $screen-sm) {
            width: 100%;
            max-height: none;
        }

        .pair-wrap {
            @media (max-width: $screen-sm) {
                width: 100%;
            }
        }
    }

    .overview {
        border-bottom: 1px solid $ice-ice-baby;

        .summary {
            margin: 30px auto 0;
            max-width: 560px;

            .btn.btn-link {
                top: 1px;
            }
        }

        .field {
            padding: 8px;

            @media (max-width: $screen-sm) {
                padding-left: 0;
                padding-right: 0;
            }

            .large-value {
                font-size: 28px;
                font-weight: bold;

                @media (max-width: $screen-sm) {
                    font-size: 22px;
                }
            }

            .medium-value {
                font-size: 18px;
                font-weight: bold;

                @media (max-width: $screen-sm) {
                    font-size: 16px;
                }
            }

            .cc-brand-icon {
                font-size: 20px;
            }

            .error-red {
                color: $errorRed;
            }
        }
    }

    .separator {
        width: 102%;
        height: 16px;
    }

    .transactions {
        width: 100%;
        padding: 0;

        @media (max-width: $screen-sm) {
            border-bottom: 1px solid $ice-ice-baby;
            padding: 0;
        }

        .table-header {
            padding: 12px 24px;

            h2 {
                margin: 8px 0;
            }

            &:first-of-type {
                @media (max-width: $screen-sm) {
                    border-bottom: 1px solid $ice-ice-baby;
                }
            }
        }

        .transactions-table {
            th {
                text-transform: uppercase;
            }

            th,
            td {
                font-size: 14px;
                padding-top: 22px;
                padding-bottom: 22px;
            }

            td.successful {
                color: $listreports-green;
            }

            td.failed {
                color: $listreports-red;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    .error-red {
        color: $errorRed;
    }

    select {
        -webkit-appearance: none;
        background-image: url(/images/icons/dropdown-menu-handle.png);
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 15px;
        margin-right: 0;
        background-size: contain;
    }

    h1,
    h2 {
        margin: 0;
    }
}

compliance-view-branch {
    display: block;
    max-width: 1000px;

    .section-group {
        margin-bottom: 15px;
    }

    .compliance-text {
        background: #edf0f1;
        border: 1px solid #d5dfe3;
        padding: 10px;
    }

    .user-info {
        ul {
            li {
                margin-bottom: 20px;

                span {
                    width: 90px;
                    display: inline-block;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .compliance-stats {
        ul {
            li {
                border-bottom: 1px solid $ice-ice-baby;
                padding: 10px 0;

                h2 {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .logo {
        max-height: 100px;
        max-width: 200px
    }
}

.follow-agent-modal {
    .modal-dialog {
        max-width: 520px;
        height: 100%;

        @media (max-width: $screen-xs-max) {
            overflow-y: scroll;
            width: auto;
        }
    }

    .modal-content {
        max-width: 520px;

        p {
            margin-top: 20px;
        }

        .subtitle {
            margin-top: -10px;
        }

        // .group {
        //     .form-group {
        //         padding: 0 5px;

        //         &:first-child {
        //             padding-left: 0;
        //         }

        //         &:last-child {
        //             padding-right: 0;
        //         }
        //     }
        // }

        // p {
        //     font-size: 16px;
        // }

        // h2 {
        //     margin-bottom: -10px;
        //     font-size: 24px;

        //     @media (max-width: $screen-xs-max) {
        //         font-size: 20px;
        //     }
        // }

        // .error-box {
        //     margin-top: 0px;
        // }

        // .hint {
        //     padding-bottom: 0;
        // }

        // agent-search {
        //     display: block;
        //     clear: both;
        //     position: relative;
        //     width: 100%;

        //     &.search-hidden {
        //         margin-top: -50px;
        //     }
        // }

        // .autocomplete-search {
        //     width: 100%;
        // }

        // .search {
        //     position: relative;
        //     padding-right: 0;
        //     padding-left: 0;

        //     i.fa {
        //         position: absolute;
        //         right: 8px;
        //         top: 8px;
        //     }

        //     input {
        //         border: 1px solid #ccc !important;
        //         border-radius: 3px !important;
        //         padding-right: 45px;

        //         &:focus {
        //             box-shadow: none !important;
        //         }
        //     }

        //     .icon {
        //         position: absolute;
        //         top: 50%;
        //         margin-top: -18px;
        //         right: 10px;
        //         color: #ddd;
        //     }
        // }

        // .search-result {
        //     margin-top: -1px;
        //     border-style: solid;
        //     border-color: #ccc;
        //     border-width: 1px;
        //     padding: 10px;
        //     color: #979797;

        //     img {
        //         max-width: 60px;
        //         margin-right: 20px;
        //     }

        //     &.invite {
        //         background: #ddd;
        //         border-bottom-left-radius: 3px;
        //         border-bottom-right-radius: 3px;
        //     }

        //     &:focus {
        //         outline: 0;
        //     }
        // }

        // .follow-agent-btn {
        //     width: 50%;

        //     @media (max-width: $screen-xs-max) {
        //         width: 100%;
        //     }
        // }

        // .form-body {
        //     border: 0;
        //     padding-left: 0;
        // }

        .view-agent-details {
            margin-top: 16px;
            padding: 20px 20px 0;
            border: 6px solid $smoke;
            border-bottom: 0;

            .info-column {
                text-align: center;
            }
        }

        .cta-wrap {
            padding: 6px 20px 20px;
            border: none;

            &.border {
                border: 6px solid $smoke;
                border-top: none;
            }
        }

        .invite-checkbox-wrap {
            position: relative;
            display: block;
            padding: 15px 30px;

            fancy-checkbox {
                display: inline-block;
                max-width: 300px;
            }

            &.border {
                border-left: 6px solid $smoke;
                border-right: 6px solid $smoke;
            }
        }

        .agent-invite-count {
            font-size: 12px;
            margin: 16px auto 12px;

            &.red {
                color: $listreports-red;
                cursor: pointer;
            }

            &.green {
                color: $listreports-green;
            }
        }

        .test-invite-email-wrap {
            margin: 15px auto 25px;

            .test-invite-email-btn {
                max-width: 168px;

                i.lr {
                    font-size: 18px;
                    top: 2px;
                }

                @media (max-width: $screen-xs-max) {
                    max-width: none;
                }
            }

            .view-agent-experience-btn {
                border-left: 1px solid $ice-ice-baby;

                @media (max-width: $screen-xs-max) {
                    border-left: none;
                }
            }

            .btn {
                @media (max-width: $screen-xs-max) {
                    padding: 4px 0;
                }
            }
        }

        .invite-link-tooltip {
            font-size: 14px;
            position: relative;
            top: 0;
        }

        .separator {
            margin: 4px 0;
            border-top: 1px solid $ice-ice-baby;
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }
}

active-buyer-network-property-profile {
    display: block;
    max-width: 1000px;

    .header-holder {
        margin-top: 32px;
    }

    abn-header {
        h1 {
            margin-top: 0;
        }
    }

    .content {
        @media (min-width: $screen-md) {
            padding: 100px !important;
        }
    }

    .title-holder {
        &.pad-bottom {
            margin-bottom: 40px;
        }

        h2 {
            margin: 0 0 10px;
        }
    }

    .dividing-line {
        margin: 40px 0;
        height: 1px;
        background-color: $ice-ice-baby;
    }

    .button-holder{
        margin-top: 40px;
    }

    fancy-checkbox {
        display: inline-block;
    }

    .btn .lr-plus {
        top: 1px;
    }

    .keywords-holder {
        input.form-control {
            max-width: 370px;
        }

        .pill-holder {
            margin-left: -10px;
            margin-right: -10px;
        }

        .pill-wrap {
            padding: 10px;

            .pill {
                height: 40px;
                background: $smoke;
                border-radius: 25px;
                padding: 9px 15px;

                > div {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}

.my-lender-checklist {
    width: 100%;
    min-height: 100%;
    padding: 30px;

    @media (max-width: $screen-xs-max) {
        padding: 20px;
    }

    header {
        border-bottom: 0 !important;
        padding: 15px 0;
    }

    h2 {
        margin: 0;
    }

    .valid-plo {
        min-width: 100%;
    }

    .no-plo-graphic {
        min-width: 199px;
        max-width: 300px;
        width: 100%;
        max-height: 100%;
        margin-right: 24px;

        @media (max-width: $screen-sm-max) {
            margin: 0;
        }
    }

    .no-plo {
        .checklist-items {
            list-style-type: disc;
            padding: 0 0 0 15px;

            li {
                color: $ice-ice-baby;
            }

            li > p {
                color: $defaultfontcolor;
                font-size: 18px;
                padding-left: 12px;
                margin: 14px 0;
            }
        }
    }

    .checklist-item {
        margin-bottom: 20px;

        @media (max-width: $screen-xs-max) {
            margin-bottom: 16px;
        }
    }

    .bullet {
        padding: 0 20px 0 0;
        font-size: 18px;
        font-weight: bold;

        span {
            display: block;
            text-align: center;
            width: 36px;
            height: 36px;
            line-height: 36px;
            border: 1px solid $defaultfontcolor;
            border-radius: 50%;

            @media (max-width: $screen-sm) {
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-size: 14px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px 0 0;
        }
    }

    .text {
        font-size: 18px;
        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }

    }
}

.stripe-terminal {
    .discovered-readers-wrap {
        border: 1px solid #EEE;
    }

    .btn {
        width: 100%;
    }
    .readers-list {
        width: 100%;
    }
}
.resources {
    max-width: 1024px;
    margin-bottom: 50px;

    banner {
        margin-bottom: 20px;
    }

    table {
        width: 100%;
    }

    th {
        header {
            border-bottom: 0;
        }

        h2 {
            margin: 0;
            margin-bottom: -5px;

            @media (max-width: $screen-sm) {
                font-size: 18px;
            }
        }

        p {
            margin: 0;
            font-weight: normal;
        }

        .bullet {
            margin-right: 15px;
        }
    }

    .section-heading {
        header {
            padding: 24px;
        }
        .icon-wrap {
            max-width: 50px;
        }

        .lr {
            font-size: 36px;
        }
    }

    .resources-links {
        margin-bottom: 20px;
    }

    .mobile-btn-wrap {
        margin-top: 12px;
    }

    tr {
        border-top: 1px solid #e0e0df;

        &:first-child {
            border-top: 0;
        }
    }

    td {
        padding: 15px 18px;
    }

    h2,
    p {
        margin: 0;
    }

    .ls-icon-paper-search {
        top: 0;
    }

    .btn-link {
        font-size: 18px;

        &.original {
            font-size: unset;
        }
    }

}

tabbar {
    display: block;
    text-align: left;
    background: transparent;

    .label-holder {
        border-bottom: 1px solid $ice-ice-baby;
        z-index: 2;
    }

    tablabel {
        position: relative;
        display: inline-block;
        padding-left: 32px;
        padding-right: 32px;
        color: $listreports-blue;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        border: 1px solid transparent;
        font-weight: bold;
        position: relative;
        top: 1px;
        height: 64px;

        .block-bar {
            border-bottom: 1px solid #fff;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            display: none;
        }

        &.normalcase-text {
            text-transform: none;
        }

        &.selected {
            z-index: 2;
            background: #fff;
            color: $defaultfontcolor;
            border-color: $ice-ice-baby;

            .block-bar {
                display: block;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .tabbar-content {
        background: #fff;
        border: 1px solid $ice-ice-baby;
        border-top: 0;
    }

    .badge {
        position: relative;
        background-color: $listreports-orange;
        padding: 4px 6px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        top: -2px;
    }

    tablabel > .icon {
        position: relative;
        font-size: 25px;
        margin-top: 4px;

        .badge {
            position: absolute;
            color: $listreports-orange;
            background-color: transparent;
            padding: 0;
            font-size: 10px;
            border-radius: 0;
            top: -8px;
            right: -30px;
            letter-spacing: 1.5px;
        }
    }

    .smalllabel {
        text-transform: uppercase;
        font-size: 10px;
        margin-top: 4px;
        letter-spacing: 1.5px;
    }

    &.tabs-hidden {
        .tabbar-content {
            border: 1px solid $ice-ice-baby;
        }
    }
}

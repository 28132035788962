.edit-payment-modal {
    .modal-dialog {
        width: 735px;

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }

    .modal-content {
        width: 735px;
        $breakPoint: 768px;


        p {
            font-size: 16px;
        }

        h2 {
            margin-top: 0;
            font-size: 24px;
        }

        .error-box {
            margin-top: 0px;
        }

        .relative {
            position: relative;
        }

        .form-group + .error-box {
            margin-top: -15px;
        }

        .ccs {
            margin-top: -2px;
            margin-bottom: 10px;

            @media(min-width: 769px) {
                position: absolute;
                bottom: 9px;
                right: 9px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .group {
            @media(min-width: 769px) {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
            }

            span {
                @media(max-width: $breakPoint) {
                    display: none;
                }
            }

            label span {
                @media(max-width: $breakPoint) {
                    display: inline-block;
                }
            }
        }


        .cta {
            max-width: 500px;
            margin: 20px auto;

            .btn-wrap {
                padding: 6px 4px;
            }
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }


}

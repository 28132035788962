.add-lead-modal{
    .modal-dialog {
        max-width: 425px;
    }

    .title {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .field-name {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .field-input {
        border: solid 1px #D5DFE3;
        border-radius: 2px;
        height: 42px;
        width: 100%;
        margin-bottom: 16px;
        padding: 0 12px;
    }

    .field-description {
        margin-bottom: 8px;
    }

    .button-wrapper {
        margin: 0px auto;
        width: 95px;
    }

    .btn-secondary {
        border: none;
    }

    .error-icon {
        font-size: 14px
    }
    .error-box {
        margin-bottom: 24px;
        margin-top: 0;
    }

    .error-box:before {
        font-size: 20px;
    }

    .error-message {
        padding-left: 6px;
        font-size: 14px;
    }

    .field-input-error {
        border-color: #D51D4C;
    }

    .unit-input-container {
        margin-left: 8px;
        width: 51px;
    }

    .unit-input {
        padding: 0 6px;
    }
}

@import './duplicate-lead-modal/styles';
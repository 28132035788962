.cba-banner {
  display: block;
  background: $ice-ice-baby;
  color: $defaultFontColor;

  @media (min-width: $screen-xs-max) {
    padding-right: 48px;
  }

  banner {
      background: $ice-ice-baby;
      color: $defaultFontColor;
      padding: 0;

      .title {
          @extend h2;
          margin-bottom: 15px;
          max-width: 500px;
      }

      .body {
          font-weight: bold;
          a {
              color: $listreports-blue;
          }
      }

      .coming-soon {
          @media (max-width: $screen-xs-max) {
              margin-bottom: 10px;
          }
      }
  }

  .body-text {
      font-weight: bold;

      @media (max-width: $screen-xs-max) {
          margin: 20px 0;
      }
  }

  .btns {
      margin-left: 24px;

      @media (max-width: $screen-xs-max) {
          max-width: 160px;
          margin: 0 auto;
      }
  }

  .btn.btn-white-o {
      font-size: 14px;
      color: $listreports-blue;
      border-color: $listreports-blue;
      padding: 7px 25px;
  }

  .img-holder {
      max-width: 280px;
      margin: 0 auto 24px;

      img {
          max-width: 280px;

          @media (min-width: $screen-sm) {
              max-width: 140px;
          }
      }

      @media (min-width: $screen-sm) {
          max-width: 140px;
          margin: 0;
          margin-right: 24px;
      }
  }

  @media (max-width: $screen-xs-max) {
    .icon-image-holder {
        display:none;
    }

    .btns {
        margin-top: 20px;
    }

    .body {
        div {
            width: 200px;
            margin: auto;
        }
    }

    padding: 30px 20px;
  }

  @media (max-width: $screen-xs-max) {
      text-align: center;
  }
}

.daily-unsubscribe-survey-modal {
    width: 100%;

    .text {
        font-size: 16px;
    }

    .survey-option{
        margin-top: 10px;
    }

    .submit-btn-container {
        margin-top: 20px;
    }

    .error-box {
        margin-top: 20px;
    }
}
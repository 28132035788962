pending-agents {

    .headshot {
        $size: 60px;

        width: $size;
        height: $size;
        border-radius: 50%;
        overflow: hidden;
        font-size: 16px;
        color: #fff;
        padding: 12px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 15px;
    }

    tr.first td {
        @media(max-width: $screen-xs-max) {
            border-top: 0;
        }
    }

    .no-items-found {
        border-top: 1px solid $ice-ice-baby;
    }
}

active-buyer-network-buyer-details {
    display: block;
    max-width: 1000px;

    .section-group {
        padding: 60px;

        header {
            padding: 0;
            padding-bottom: 40px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 25px;

            header {
                padding-bottom: 25px;
            }
        }
    }

    .preference-list {
        list-style: disc;
        padding-left: 15px;
    }

    .buyer-name-stats-wrap {
        padding-left: 32px;

        .buyer-name {
            margin: 8px 0;
        }

        .buyer-stat {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid $ice-ice-baby;

            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 20px;
        }
    }

    .locked-message {
        margin-top: 20px;
    }

    .separator {
        margin: 36px 0;
        width: 100%;
        min-width: 100%;
        height: 0;
        border-bottom: 1px solid $smoke;
    }

    .spacer {
        width: 100%;
        max-width: 312px !important;
        margin: 0 7px;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

}

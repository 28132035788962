.subscriptionModal-planChoiceStep {
    .subscriptionModal-planChoiceStep-titleSection {
        margin-bottom: 16px;
    }

    .plansContainer {
        flex-direction: column-reverse;
        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }

    .planBox-container {
        padding: 12px;
        border: 1px solid #D5DFE3;
    }

    .planBox-recommendedLabel {
        color: #F3B204;
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 12px;
        text-align: center;
    }

    .planBox-name {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #F8FAFB;
        margin-bottom: 16px;
    }

    .planBox-currentPrice {
        font-size: 28px;
        margin-bottom: 8px;
    }

    .planBox-originalPrice {
        font-size: 18px;
        margin-bottom: 8px;
        color: $defaultSecondaryTextColor;
        text-decoration: line-through;
    }

    .planBox-discountTag {
        color:white;
        width: 61px;
        max-width: 100%;
        padding: 4px 8px;
        background-color: #F3B204;
        margin: auto;
        font-size: 10px;
        margin-bottom: 16px;    
    }

    .planBox-selectBtn{
        padding: 0 16px !important;
        min-width: 0 !important;
        margin-bottom: 12px;
    }

}

rusc-listings {
    display: block;
    max-width: 1300px;
    margin: 0 auto;
    font-size: 14px;

    h1 {
        font-size: 36px;
        line-height: 1.2;

        @media (max-width: $screen-xs-max) {
            font-size: 24px;
        }
    }

    .listings {
        margin-left: -10px;
        margin-right: -10px;
    }

    .listing-wrap {
        padding: 5px;
        max-width: 33%;

        .listing {
            border: 5px solid transparent;

            &:hover {
                @media (min-width: $screen-lg) {
                    border-color: $listreports-blue;
                }
            }

            .bg-img {
                background: no-repeat center center;
                background-size: cover;

                &:after {
                    content: "";
                    display: block;
                    padding-top: 56%;
                }
            }

            .info {
                background: #fff;
                padding: 15px;

                > div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            max-width: 50%;
        }

        @media (max-width: $screen-xs-max) {
            max-width: 100%;
        }
    }

    .loading {
        padding: 30px;
    }


    .rusc-error {
        font-size: 24px;

        h1 {
            font-size: 36px;
            margin: 0;
            margin-bottom: 5px;
            line-height: 1.2;

            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }
        }

        p {
            margin: 0;
        }

        .icon-holder {
            img {
                width: 200px;
                margin-left: 49px;

                @media (max-width: $screen-xs-max) {
                    width: 140px;
                    margin-left: 35px;
                }
            }
        }

        .spacer {
            height: 45px;

            @media (max-width: $screen-xs-max) {
                height: 20px;
            }
        }

        .split {
            color: #b6b6b6;

            .text {
                padding: 0 20px;
                font-weight: bold;
            }
        }

        .divider {
            border-top: 2px solid #b6b6b6;

            @media (max-width: $screen-xs-max) {
                border-top-width: 1px;
            }
        }

        .btn-recheck {
            padding: 15px 30px;

            @media (max-width: $screen-xs-max) {
                padding: 10px 30px;
            }
        }

        .btn {
            text-transform: none;
        }

        .ig {
            margin-top: 30px;

            .btn-default {
                background: #fff;
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }
    }

    .no-properties-found {
        h1 {
            font-size: 26px;
            margin-top: 40px;
            margin-bottom: 5px;
        }

        div {
            font-size: 18px;
        }
    }

}

relationships-invite-agent {
    form {
        max-width: 540px;
        display: block;
        margin: 0 auto;
    }

    p {
        margin-top: 20px;
    }

    h2 {
        margin: 0;
    }

    .view-agent-details {
        margin-top: 16px;
        padding: 20px 20px 0;
        border: 6px solid $smoke;
        border-bottom: 0;

        .info-column {
            text-align: center;
        }
    }

    .cta-wrap {
        padding: 20px 20px 50px;
        border: none;

        &.border {
            border: 6px solid $smoke;
            border-top: none;
        }

        @media (max-width: $screen-xs-max) {
            padding: 20px;
        }
    }

    .test-invite-email-wrap {
        margin: 15px;

        > div {
            padding: 5px 15px;
        }
    }

    .agent-invite-count {
        font-size: 12px;
        margin: 16px auto 12px;

        &.red {
            color: $listreports-red;
            cursor: pointer;
        }

        &.green {
            color: $listreports-green;
        }
    }

    .separator {
        margin: 4px 0;
        border-top: 1px solid $ice-ice-baby;
    }

    .copy-paste-wrap {
        margin-top: 36px;
    }

    .cancel-link-wrap {
        margin-top: 8px;
    }

    .invite-link-tooltip {
        font-size: 14px;
        position: relative;
        top: 0;
    }

    .already-paired-wrap {
        .error-box {
            margin: 12px auto;
            text-align: center;
            justify-content: center;
        }
    }

    agent-search {
        .view-agent-details {
            @media (min-width: $screen-sm) {
                padding-top: 50px;
            }
        }
    }

    .incomplete-info {
        agent-search {
            .view-agent-details {
                @media (min-width: $screen-sm) {
                    padding-top: 20px;
                }
            }
        }

        .cta-wrap {
            padding: 20px;
        }
    }

    .invite-completed {
        .header {
            margin-bottom: 50px;
        }

        h1 {
            margin: 10px 0;
        }

        p {
            margin-top: 0;
        }

        .divider {
            height: 42px;
            margin: 0 -50px -42px;
            background: rgb(247,247,247);
            background: -moz-linear-gradient(top,  rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(247,247,247,1) 0%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom,  rgba(247,247,247,1) 0%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=0 );

            @media (max-width: $screen-xs-max) {
                margin: 0 -35px -42px;
            }
        }

        .cta {
            margin: 0 -50px -50px;

            > div {
                padding: 70px 40px;

                &:first-child {
                    border-right: 1px solid $smoke;

                    @media (max-width: $screen-sm-max) {
                        border-right: 0;
                        border-bottom: 1px solid $smoke;
                    }
                }

                @media (max-width: $screen-xs-max) {
                    padding: 40px 20px;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin: 0 -35px -35px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        width: auto;
    }
}

.video-modal {
    -webkit-overflow-scrolling: auto;

    .close {
        padding: 15px 0 15px 15px;

        @media (max-width: $screen-xs-max) {
            padding: 15px;
        }
    }

    .video-wrap {
        position: relative;
    }

    #modal-video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .cta {
        margin: 15px;
    }
}

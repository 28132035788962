alert-setting {
    .title {
        margin: 0;
        font-weight: bold;
    }

    .description {
        margin: 0;
    }
    .checkbox-container{
        margin-top: 16px;

        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }

        .left-checkbox-container {
            margin-right: 16px;

            @media (min-width: $screen-md-min) {
                margin-right: 0;
                width:50px;
            }
        }

        .right-checkbox-container {
            @media (min-width: $screen-md-min) {
                width:50px;
            }
        }

        .checkbox-label {
            margin-right: 4px;

            @media (min-width: $screen-md-min) {
                margin-right: 0;
            }
        }

        .fancy-checkbox-v2 {
            @media (min-width: $screen-md-min) {
                margin-right: 0;
            }
        }
    }
}

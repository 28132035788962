.value-props-modal {
    .modal-content {
        background: $frosty;
        padding: 0 0 48px;

        .img-holder {
            height: 330px;
            background: #fff;

            @media (max-width: $screen-xs-max) {
                height: 226px;
                padding: 22px 16px 0;
            }

            img {
                max-height: 100% !important;
            }
        }

        .text-holder {
            padding: 34px 80px 31px;

            .text-body-copy {
                margin: 16px 0;
            }

            @media (max-width: $screen-xs-max) {
                padding: 26px 17px 31px;
            }
        }

        .ctas {
            margin-bottom: -71px;
        }

        .slick-dots li button:before {
            background: $sidewalk;
        }

        .slick-dots li.slick-active button:before {
            background: $giggity;
        }

        slick {
            .move-left {
                top: 28%;
                left: 36px;
                z-index: 1;
                font-size: 24px;

                @media (max-width: $screen-xs-max) {
                    top: 26%;
                    left: 12px;
                }
            }

            .move-right {
                top: 28%;
                right: 36px;
                z-index: 1;
                font-size: 24px;

                @media (max-width: $screen-xs-max) {
                    top: 26%;
                    right: 12px;
                }
            }

            .slick-dots {
                bottom: -38px;
            }

            .slick-dots li {
                $size: 8px;

                width: $size;
                height: $size;

                button {
                    width: $size;
                    height: $size;

                    &:before {
                        width: $size;
                        height: $size;
                    }
                }
            }

            &.viewing-first .move-left {
                opacity: 0;
                pointer-events: none;
            }

            &.viewing-last .move-right {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

.title-holder h1 {
    margin-bottom: 16px;
}

.title-bar-subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 32px;
}

.route-new-report.modal-open {
    overflow: hidden !important;
}

.banner-placer{
    margin-top: 24px;
}

.error {
    border-color: $errorRed;
}

.red {
    color: $errorRed;
}

.create-report {
    header {
        max-width: 900px;
    }

    md-content > div {
        max-width: 900px;
        padding: 40px;
        border: 1px solid #e0e0df;
        color: $defaultFontColor;

        @media (max-width: $screen-xs-max) {
            padding: 0 0 20px;
        }
    }

    .buttons-holder {
        margin: 0 10px;

        @media (max-width: $screen-xs-max) {
            margin: 0 20px;
        }
    }

    .caption {
        font-size: 14px;
    }

    .reference-url::placeholder {
        color: $defaultSecondaryTextColor !important;
    }

    .error-box-wrap.inline-field {
        @media (min-width: $screen-sm) {
            margin-bottom: 0;
            margin-top: 15px;
        }
    }

    .agent-verification {
        margin: 0 auto 30px;
        max-width: 580px;

        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
        }
    }

    fancy-checkbox.not-bold label {
        font-size: 14px;
        font-weight: normal;
    }

    .fancy-checkbox-wrap {
        position: relative;
    }

    .img-wrap {
        padding: 10px;
    }

    .vendor-header {
        img {
            @media (max-width: $screen-sm) {
                height: 40px;
            }
        }

        h1 {
            @media (max-width: $screen-sm) {
                font-size: 24px;
            }
        }
    }

    .subtext {
        padding: 20px 20px 10px;
    }

    .view-agent-details {
        font-size: 14px;
        margin: 30px 0 10px;

        h1 {
            font-size: 18px;
        }
    }

    .line-separator {
        position: relative;
        width: 100%;
        height: 1px;
        margin: 36px auto;
        border-bottom: 1px solid #ced1d5;

        .line-separator-text {
            position: relative;
            width: 50px;
            margin: 0 auto;
            text-align: center;
            transform: translateX(0);
            transform: translateY(-50%);
            background-color: #fff;
        }
    }

    .collapsable-section {
        .cs-header {
            background: $frosty;
        }

        .cs-bullet {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: $listreports-blue;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            border-radius: 50%;
            margin-right: 20px;
            color: #fff;
        }

        .label-title {
            font-size: 18px;
        }

        .label-body {
            font-weight: normal;
            margin-bottom: 8px;
        }
    }

    .lr-question-mark-o-filled {
        color: $listreports-blue;
    }

    .property-description-wrap {
        .invalid.char-limit-validation {
            color: $listreports-orange !important;
        }
    }

    .extra-instructions {
        padding: 0px 56px;

        @media (max-width: $screen-sm) {
            padding: 0px;
        }
    }

    .helper {
        border-top: 1px solid $ice-ice-baby;
        padding: 20px 0;

        > div:first-child {
            padding-right: 10px;
        }

        &.block {
            margin: 30px 0;
            padding-top: 30px;

            > div {
                padding: 25px 40px;
                background: $frosty;
                max-width: 580px;
                margin: 0 auto;

                h3 {
                    margin: 10px 0;
                }

                ul {
                    li {
                        padding-left: 1em;
                        text-indent: -1em;

                        &::before {
                            content: '•';
                            padding-right: 10px;
                            color: $sidewalk;
                            font-size: 17px;
                        }

                        a {
                            display: inline-block;
                            margin-left: 20px;
                            margin-top: -1px;

                            @media(max-width: $screen-xs-max) {
                                margin-left: 17px;
                                display: block;
                                text-align: left;
                            }
                        }
                    }
                }
            }

            @media(max-width: $screen-xs-max) {
                margin: 15px;
            }
        }
    }

    .la-helper {
        border: 1px solid $ice-ice-baby;
        padding: 17px;
        margin-bottom: 16px;

        .lr {
            font-size: 39px;
            margin-right: 14px;

            @media (max-width: $screen-xs-max) {
                margin-right: 0;
                margin-bottom: 11px;
            }
        }
    }

    agent-search {
        .form-body {
            padding-left: 0;
            border-left: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 0;
    }

    .color-themes-select {
        .dropdown-toggle {
            padding: 12px;
            border-radius: 2px;
            height: 42px;
            border: 1px solid $ice-ice-baby;
        }

        .dropdown-btn {
            height: 14px;
        }

        .dropdown-menu {
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        .dropdown-menu-item {
            padding: 12px;

            &:hover {
                background-color: $frosty;
            }
        }

        .color-theme-palette {
            margin-right: 4px;

            .color-theme-palette-dot {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                border-radius: 50%;
            }
        }
    }
}

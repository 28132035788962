signup-pro {
    font-size: 16px;



    .signup {
        padding: 0 25px;

        @media (max-width: $screen-sm-max) {
            padding: 0;
        }

        > header.signup-header {
            display: block;
            position: relative;
            text-align: left;
            margin-top: -50px;
            margin-bottom: 0;
            font-size: 14px;


            h2.signup-title {
                color: $defaultFontColor;
                font-weight: 700;
                font-size: 34px;
                line-height: 28px;
                margin-bottom: 16px;
            }

            .signup-info {
                max-width: 275px;
                margin-bottom: 16px;
                text-align: left;
            }

            .wrap {
                padding: 30px 0;
            }

            .divider {
                margin: 0 75px;

                @media (max-width: $screen-xs-max) {
                    margin: 17px 0 0;
                }
            }

            .bar {
                width: 40px;
                height: 2px;
                background: $ice-ice-baby;
                margin-bottom: 17px;
            }

            h3 {
                margin-top: -5px;

                @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                }
            }

            .vgradient {
                height: 42px;
                background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
                margin: 0 -75px;

                @media (max-width: $screen-sm-max) {
                    margin: 0 -20px;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-top: -20px;
                text-align: center;
            }
        }

        .title {
            margin: 0 auto 50px;

            @media (max-width: $screen-sm-max) {
                margin: 12px auto 30px;
            }
        }

        .signup-wrap {
            margin: 0 auto 20px;

            @media (max-width: $screen-sm-max) {
                margin: 0 auto 30px;
            }
        }

        .form-wrap {
            padding: 0 47px 0 0;

            @media (max-width: $screen-sm-max) {
                padding: 0;
            }
        }

        .info-wrap {
            justify-content: space-between;

            @media (max-width: $screen-sm-max) {
                border-left: none;
                padding-top: 24px;
            }
        }

        .lrbl {
            margin: 0 0 0 75px;

            .headshot-wrapper {
                margin-bottom: 24px;

                img {
                    width: 70px;
                    height: 70px;
                }
            }

            .caption {
                font-size: 14px;
                padding-bottom: 5px;
            }

            h3 {
                max-width: 330px;
                margin: 0 auto;
            }

            .separator {
                margin: 24px auto;
                width: 30px;
                height: 2px;
                background-color: $listreports-blue;
                border-radius: 4px;
            }

            @media (max-width: $screen-sm-max) {
                margin: 0;
            }
        }

        .book-demo-wrap {
            padding: 0 0 0 75px;

            @media (max-width: $screen-sm-max) {
                padding: 0;
                text-align: center;
            }
        }

        .video-wrap {
            margin-top: 25px;
            margin-bottom: 25px;

            #pro_signup_video {
                width: 316px;
                height: 175px;

                @media (max-width: $screen-xs-max) {
                    width: 293px;
                    height: 165px;
                }
            }
        }

        .legal {
            max-width: 328px;
            text-align: justify;

            @media (max-width: $screen-sm-max) {
                margin: 0;
            }
        }

        input.form-control{
            margin-bottom: 0;
        }

        select{
            background-image: url('/images/icons/select-arrow-dark.png') !important;
            background-position: 97% center;
            background-size: auto;
        }
    }

    .hsubtitle {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    .info {
        margin-bottom: 8px;
        font-size: 14px;
    }

    label {
        margin-bottom: 4px;
        font-size: 14px;
    }

    .label {
        margin: 0;
    }

    .password-content{
        position: relative;
        min-height: 71px;
    }

    .show-password-content{
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        height: 42px;
        z-index: 8;
    }
    .show-password{
        top: 37px;
        right: 12px;
        position: absolute;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        z-index: 9;
        cursor: pointer
    }

    .lr-input-btn-group .btn .lr-caret-up.lr-rotate-180{
        top: -2px;
        color: $defaultFontColor;
        font-size: 10px;
    }

    .lr-input-btn-group .btn.dropdown-toggle{
        height: 40px;
    }

    .btn-small{
        min-width: initial;
        width: 78px;
        padding: 11px 0;
    }

    .more-info{
        font-size: 14px;
        display: flex;
        justify-content: end;
        margin-bottom: 24px;

        .more-info-wrap {
            max-width: 328px;
        }
    }
    .more-info-box{
        background: #F8FAFB;
        border-radius: 4px;
        padding: 16px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;

        @media (max-width: $screen-sm-max) {
           display: none;
        }

        p {
            margin: 0 0 24px
        }
    }

    .more-info-affiliate-mobile{
        display: none;
         @media (max-width: $screen-sm-max) {
           display: block;
        }
    }

    .more-info-affiliate{
        display: block;
         @media (max-width: $screen-sm-max) {
           display: none;
        }
    }

    .more-info-agent{
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        padding: 16px;
        padding-left: 46px;
        margin-bottom: 24px;
        background-repeat: no-repeat;
        background-position: 16px 20px;
        background-image: url('/images/icons/feature-icon-bell-red-dot.svg');
    }

    .more-info-btn{
        color: #007780;
        font-weight: bolder;
        cursor: pointer;
    }

    .signup-tooltip{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        font-size: 12px;
        color: #FFFFFF;
        background-color: $defaultFontColor;
        line-height: 10px;
        font-weight: 100;
        cursor: pointer;

        &::after{
            content: '?';
        }

    }
}

@import './activity-in-your-area/styles';

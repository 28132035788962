transactions-new-transaction-additional-details {
    display: block;
    max-width: 1040px;

    .top-title {
        margin: 0 0 -14px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    title-bar {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .content {
        .title  {
            margin-bottom: 50px;

            h2 {
                margin-bottom: 25px;
            }
        }

        form {
            display: block;
            margin: 0 auto;
            max-width: 550px;

            .offset-type {
                position: relative;
                top: 10px;
                margin-bottom: 8px;

                .lr {
                    position: relative;
                    top: 2px;
                    left: 5px;
                }

                @media (max-width: $screen-xs-max) {
                    top: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.login {
    h1 {
        font-size: 24px;
        margin-bottom: 5px;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }

    h3 a {
        @media (max-width: $screen-sm) {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .footer-text {
        font-size: 14px;
    }
}

.preview-offer-modal {
    padding: 48px 60px;

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 24px 32px;
    }

    header {
        h2 {
            margin: 8px auto 14px;
            font-size: 24px;

            @media (max-width: $screen-xs-max) {
                margin: 6px auto 12px;
            }
        }

        .step {
            color: $sidewalk;
        }
    }

    section.body {
        margin: 24px auto;
    }

    .buttons-wrap {
        margin: 20px 0 0;
    }

    .button-wrap {
        button {
            margin: 4px;
        }
    }

    .text-wrap {
        padding: 0 20px 0 0;
        @media (max-width: $screen-sm) {
            padding: 0;
        }
    }

    .img-wrap {
        text-align: right;
        @media (max-width: $screen-sm) {
            text-align: center;
        }

        img {
            max-width: 100%;
        }
    }

    .dismiss-wrap {
        margin-top: 24px;
    }

}

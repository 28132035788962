.force-refresh-banner {
    background: $listreports-red;
    color: #fff;
    padding: 30px;
    font-size: 18px;
    margin: -20px -20px 20px;

    .fa-close {
        color: $listreports-red;
    }

    .fa-stack {
        margin-right: 15px;

        @media (max-width: $screen-sm) {
            margin-right: 0;
        }
    }

    p {
        padding: 0;
        padding-right: 15px;
        margin: 0;

        @media (max-width: $screen-sm) {
            padding-right: 0;
            padding-top: 15px;
            margin-bottom: 5px;
        }
    }

    @media (max-width: $screen-sm) {
        text-align: center;
    }
}

.buyside-area-modal {
    h3 {
        max-width: 430px;
        margin-left: auto;
        margin-right: auto;
    }

    .pills {
        text-align: left;
        width: 100%;
        margin: 24px 0;

        .pill-wrap {
            display: inline-block;
            padding: 4px;
        }
    }
}

view-agent {
    max-width: 1200px;

    .my-agent {
        max-width: 1200px;
    }

    .agent-info {
        margin-right: 15px;

        .btn-link {
            @media (min-width: $screen-sm) {
                font-size: 18px;
            }
        }

        ul {
            li {
                margin-bottom: 25px;

                span {
                    display: inline-block;
                    width: 100px;

                    @media (max-width: $screen-xs-max) {
                        width: auto;
                        display: block;
                    }
                }

                @media (max-width: $screen-xs-max) {
                    margin-bottom: 15px;
                }
            }
        }

        .relationship-details {
            i {
                font-size: 12px;
                position: relative;
                top: -1px;
                display: inline-block;
                margin-right: 20px;

                @media (max-width: $screen-xs-max) {
                    font-size: 8px;
                    margin-right: 10px;
                }
            }
        }

        @media (min-width: $screen-sm) {
            font-size: 18px;
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .agent-stats {
        .column-wrap {
            margin-left: -1px;
            margin-top: -1px;
            margin-bottom: -1px;

            &:last-child {
                margin-right: -1px;
            }

            > div:last-child {
                @media (min-width: $screen-sm) {
                    margin-bottom: -2px;
                }
            }
        }

        .square {
            border: 1px solid $ice-ice-baby;

            &:first-child {
                margin-bottom: -1px;
            }

            @media (max-width: $screen-xs-max) {
                padding: 25px;
            }
        }

        h2,
        h3 {
            margin: 0;
        }

        .lr {
            font-size: 25px;

            &.lr-lock {
                font-size: 35px;
                margin-bottom: 10px;
            }
        }

        @media (max-width: $screen-xs-max) {
            min-height: 340px;
        }
    }

    .listings {
        background: #fff;
        padding: 30px;

        @media (max-width: $screen-xs-max) {
            padding: {
                left: 0;
                right: 0;
            }
        }
    }

    .listing-wrap {
        position: relative;
        padding: 10px;

        @media (max-width: $screen-xs-max) {
            padding: 0;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .embed-responsive-wrap {
        @media (min-width: $screen-sm) {
            padding: 25px 25px 0;
        }
    }

    notes {
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;

        header {
            h2 {
                height: 1px;
                overflow: hidden;
            }
        }
    }

    activity-feed {
        margin-bottom: 30px;
    }

    .no-listings {
        padding: 15px;

        > h3 {
            max-width: 505px;
            margin: 0 auto;

            @media (max-width: $screen-xs-max) {
                max-width: 340px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
        }
    }

    .listing-not-found {
        margin: 30px 10px 10px;

        h3 {
            margin-right: 20px;
            max-width: 480px;

            @media (max-width: $screen-xs-max) {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin: 30px 15px 15px;
            text-align: center;
        }
    }
}

.manage-subscription-modal-backdrop + .modal{


    .modal-dialog {
        max-width: 500px;

        .modal-content {
            padding: 24px 16px;

            @media (min-width: $screen-sm-min){
                padding: 32px 37px;
                max-width: 500px;
            }
        }

    }
}

.manage-subscription-modal {

    h2 {
        margin: 0;
    }
    .subscription-info {
        margin-top: 23px;
        border: 1px solid #D5DFE3;
        height: 100px;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cancel-subscription-btn {
            font-weight: 600;
        }

        .subscription-name {
            font-weight: bold;
            font-size: 18px;
        }

        .subscription-value {
            font-size: 18px;
        }
    }

    .reason-form {
        margin-top: 14px;

        @media (min-width: $screen-sm-min){
            margin-top: 17px;
        }

        .form-information {
            font-size: 18px;
        }

        .reason-selector {
            margin-top: 24px;
        }
        .reason-information {
            margin-top: 15px;
            text-align: left;
        }

        .wants-to-talk-to-you {
            margin-top: 24px;
        }
        .button-wrapper {
            margin-top: 24px;
            text-align: center;

            .cancel-btn {
                margin-top: 15px;
            }
        }

        .error-box {
            margin-top: 10px;
        }
    }

    .confirmation-message {
        text-align: center;

        margin-top: 14px;

        @media (min-width: $screen-sm-min){
            margin-top: 17px;
        }

        .final-date-information {
            font-size: 18px;
        }

        .got-it-btn {
            margin-top: 28px;
        }
    }

    .cancel-btn {
        margin-top: 24px;
        text-align: center;
    }
}

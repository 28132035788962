cancellation-select-reason {
    input[type="radio"], input[type="checkbox"] {
        display: inline-block;
        margin-right: 10px;
    }

    label {
        margin-bottom: 0;
    }
}

.my-following-content {
    border: 0 !important;

    .search-content{
        margin-right: 16px;
    }
    .arrow{
        display: inline-block;
        margin-left: 4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #696969 transparent transparent transparent;
    }

    header {
        padding: 15px;
        border-bottom: 1px solid #d5dfe3;
    }

    .none-found {
        h1 {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 32px;
        }
    }

    table {
        tbody {
            tr:first-child {
                td {
                    @media(max-width: $screen-xs-max) {
                        border-top: 0;
                    }
                }
            }
            tr {
                td:first-child {
                    width: 72px;
                    @media(max-width: $screen-xs-max) {
                        width: 60px;
                    }
                }
            }
        }

        .preferred-vendor-tooltip {
            position: relative;
        }

        img {
            width: 60px;
            height: 60px;

            @media (max-width: $screen-xs-max) {
                width: 45px;
                height: 45px;
            }
        }

        .email {
            word-wrap: break-word;

            @media (max-width: $screen-xs-max) {
                max-width: 180px;
            }
        }

        .ball {
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid $listreports-gray;
            border-radius: 50%;

            &.yes {
                background: $listreports-green;
                border-color: $listreports-green;
            }

            @media(max-width: $screen-xs-max) {
                width: 9px;
                height: 9px;
            }
        }
    }

    .locked {
        color: #b9b9b9;
    }

    .locked-message {
        padding: 20px;
        text-align: left;

        a {
            white-space: normal;
            text-align: left;

            i {
                min-width: 24px;
                margin-right: 10px;

                @media (max-width: $screen-xs-max) {
                    font-size: 22px;
                    margin-right: 5px;
                }
            }

            @media (max-width: $screen-xs-max) {
                font-size: 12px;
            }
        }
    }

    .following-status {
        @media (max-width: $screen-xs-max) {
            margin: 10px 0 4px;
        }
    }

    .btn-link {
        .fs-10 {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .user-info-details{
        display: flex;
        margin-bottom: 16px;

        .user-info-details-avatar{
            margin-right: 19px;
        }
    }

    .user-info-actions{
        margin-bottom: 8px;
    }

    .date-label{
        margin-bottom: 0;
        font-weight: normal;
        margin-right: 22px;
    }
}

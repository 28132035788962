transactions-new-transaction-review {
    display: block;
    max-width: 1040px;

    .content {
        max-width: 810px;
        margin-left: auto;
        margin-right: auto;

        > .title {
            max-width: 510px;
            margin: 0 auto 50px;

            h2 {
                margin-bottom: 20px;
            }
        }
    }

    .top-title {
        margin: 0 0 -14px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    title-bar .subtitle {
        margin-top: -9px;

        h3,
        > div {
            @media (min-width: $screen-sm) {
                padding: 0 20px;
                border-right: 1px solid $ice-ice-baby;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    border-right: 0;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 15px;
        }
    }

    .cards {
        margin: 0 -7px;

        > div {
            margin: 7px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: -1px;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        h3 {
            margin-bottom: 5px;
        }

        .card {
            padding-right: 25px;

            label {
                font-size: 10px;
                color: $sidewalk;
                margin-bottom: 0;
            }

            .img-holder {
                width: 60px;
                margin-right: 25px;
                font-size: 60px;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

            .ctas-wrapper {
                width: 100%;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }

    .partner,
    .marketplace-vendors-wrap,
    .special-instructions {
        > .title {
            margin-bottom: 20px;
        }
    }

    .marketplace-vendors {
        margin: -10px;
    }

    .marketplace-vendor {
        padding: 10px;

        small {
            font-size: 10px;
        }
    }
}

.transactions-review-confirmation-modal {
    .modal-content {
        .bullet-checklist {
            margin: -10px 0;

            li {
                i {
                    color: $listreports-green;
                    font-size: 18px;
                    margin-right: 10px;
                }

                padding: 10px 0;
            }
        }
    }
}

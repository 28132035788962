blacklist-resubscribe-banner {
    display: block;
    position: relative;
    margin-bottom: 20px;

    banner {
        background: $listreports-gray;
    }

    .close-visible {
        banner {
            padding-right: 40px;
        }
    }

    .close-wrap {
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

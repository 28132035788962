.lo-home {
    max-width: 1040px;

    .bottom-home-news {
        margin-top: 20px;

        @media (min-width: $screen-sm-min) {
            margin-top: 0px;
        }
    }

    .home-news {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $ice-ice-baby;
        background: #ffffff;
        padding: 20px;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            max-width: 510px;
        }

        .icon {
            width: 35px;
            margin-right: 21px;
            img {
                width: 35px;
            }
        }

        .home-text {
            flex: 1;
            a {
                font-size: 18px;
            }
        }
    }

    .section-group {
        margin-top: 18px;
        .cards-wrapper {
            flex-wrap: wrap;
            padding: 10px;

            .card-wrapper {
                flex-grow: 0;
                max-width: 100%;
                flex-basis: 100%;
                padding: 10px;

                @media (min-width: $screen-sm-min) {
                    max-width: 50%;
                    flex-basis: 50%;
                }

                @media (min-width: $screen-md-max) {
                    max-width: 33.3%;
                    flex-basis: 33.3%;
                }
            }
        }
    }

    .welcome-container{
        padding: 40px 16px;
        text-align: center;
        max-width: 635px;
        margin: 0 auto;
        h2{
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            margin: 0  0 16px;
        }

        p{
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 30px;
            & + p{
                margin-bottom: 0;
            }
        }
    }
}

@import './card/styles';

.tooltip {
    opacity: 1 !important;
    z-index: 1050;

    .tooltip-inner {
        border-radius: 0;
        padding: 15px;
        background: #1c303a;
        font-weight: normal;

        b {
            text-transform: uppercase;
        }
    }

    &.top {
        .tooltip-arrow {
            border-top-color: #1c303a;
        }
    }

    &.right {
        .tooltip-arrow {
            border-right-color: #1c303a;
        }
    }

    &.bottom {
        .tooltip-arrow {
            border-bottom-color: #1c303a;
        }
    }

    &.left {
        .tooltip-arrow {
            border-left-color: #1c303a;
        }
    }
}

md-tooltip.listreports {
    font-size: 14px;
    background-color: #1c303a !important;
    opacity: 1 !important;

    &.multiline {
        height: auto;
    }

    &.set-width {
        font-size: 14px;
        line-height: 16px;
        padding: 10px;
        max-width: 300px;
        white-space: normal;
    }
}

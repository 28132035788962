compliance-accounts {
    display: block;
    max-width: 1400px;

    title-bar h1 {
        margin-top: 0;
    }

    title-bar .subtitle {
        margin-top: -30px;
        margin-bottom: 20px;

        h3 {
            padding: 0 20px;
            border-right: 1px solid $ice-ice-baby;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: 0;
            }

            span {
                font-size: 14px;
            }
        }
    }

    .stats-holder {
        margin-bottom: 20px;
        margin-top: -20px;

        .stats {
            border: 1px solid $ice-ice-baby;
            background: #fff;
            padding: 20px;
            max-width: 230px;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            > header {
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid $ice-ice-baby;

                h1 {
                    margin: 0;
                    font-size: 36px;
                }

                > div div {
                    margin-top: -5px;
                }
            }

            .subtext {
                line-height: 1;
                margin-bottom: 15px;

                small {
                    font-size: 10px;
                }
            }

            .icon-holder {
                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .value {
                width: 50px;
            }
        }
    }

    th {
        border-top: 0 !important;
    }

    .lr-caret-down {
        position: relative;
        top: -1px;
        margin-right: 5px;
    }

    .lr-caret-right {
        position: relative;
        top: -1px;
        margin-right: 5px;
    }

    .branch {
        padding-left: 50px !important;
    }

    .user {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 77px !important;
        position: relative;

        > div:first-child {
            position: relative;
            border-left: 1px solid $ice-ice-baby;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 28px;

            .border-hider {
                width: 29px;
                top: -3px;
                left: -1px;
            }

            .marker {
                position: absolute;
                top: 16px;
                left: 0;
                width: 20px;
                height: 1px;
                background: $ice-ice-baby;
            }

            .left-border {position: absolute;
                top: -5px;
                left: -1px;
                width: 1px;
                height: 22px;
                background: $ice-ice-baby;
                display: block;
            }
        }

        .border-hider {
            border: 0;
            border-top: 1px solid #fff;
            width: 77px;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }

    .first .user > div:first-child {
        .left-border {
            display: none;
        }
    }

    .last .user > div:first-child {
        border-left-color: transparent;

        .left-border {
            display: block;
        }
    }

    .search .user {
        padding-left: 0 !important;

        > div {
            border: 0;
            padding-left: 8px;
        }

        .border-hider,
        .marker,
        .left-border {
            display: none !important;
        }
    }

    .loading-circular-wrap {
        border-top: 1px solid $ice-ice-baby;
    }
}

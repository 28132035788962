transactions-select-marketplace-vendors {
    display: block;
    max-width: 1040px;

    .top-title {
        margin: 0 0 -14px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    title-bar {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .content {
        > .title {
            max-width: 510px;
            margin: 0 auto 50px;

            h2 {
                margin-bottom: 20px;
            }
        }
    }

    .marketplace-vendors {
        max-width: 820px;
        margin: 0 auto;

        .mv {
            margin-bottom: 50px;
        }

        .title {
            color: $sidewalk;
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid $ice-ice-baby;
        }

        .options {
            .option {
                padding: 25px;
                border: 1px solid $ice-ice-baby;
                border-bottom: 0;

                &:last-child {
                    border: 1px solid $ice-ice-baby;
                }

                > * {
                    margin-right: 25px;

                    @media (max-width: $screen-xs-max) {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }

                .logo-holder {
                    width: 150px;

                    @media (max-width: $screen-xs-max) {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                &.option-selected {
                    .lr-check {
                        top: 2px;
                        display: inline-block;
                        margin-left: 5px;
                        font-size: 16px;
                    }

                    .logo-holder.text-center {
                        img {
                            margin: 0 auto;
                            display: inline-block;
                        }
                    }
                }
            }

            .option-custom {
                .logo-holder {
                    img {
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

agent-listing-stats {
    &.border {
        .agent-listing-stats {
            border-right: 6px solid $smoke;
        }
        .stats-block {
            border-left: 6px solid $smoke;
            border-bottom: 6px solid $smoke;
            padding: 12px 6px;

            .num {
                font-size: 24px;
            }
        }
    }

}


.ph-item {
  position: relative;
  overflow: hidden;
  background-color: $ph-bg;
  border: 1px solid darken($ph-bg, 10%);
  border-radius: $ph-border-radius;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation $ph-animation-duration linear infinite;
    background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, .35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
  }
}

.ph-row {
  display: flex;
  flex-wrap: wrap;

  div {
    height: 10px;
    background-color: $ph-color;
  }
  .big,
  &.big div {
    height: 20px;
  }
  .empty {
    background-color: rgba($ph-bg, 0);
  }
}

.ph-col-2 {
  flex: 0 0 percentage(2 / 12);
}
.ph-col-4 {
  flex: 0 0 percentage(4 / 12);
}
.ph-col-6 {
  flex: 0 0 percentage(6 / 12);
}
.ph-col-8 {
  flex: 0 0 percentage(8 / 12);
}
.ph-col-10 {
  flex: 0 0 percentage(10 / 12);
}
.ph-col-12 {
  flex: 0 0 percentage(12 / 12);
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
  border-radius: $ph-avatar-border-radius;
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: $ph-color;
  margin-bottom: $ph-spacer;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

purchase-buyside-credits-modal-payment {
    display: block;

    > *,
    .tear-middle > *,
    .payment-method > *,
    .purchase-form > * {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }

    .receipt {
        creditcard {
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: .12);
        }

        .payment-method {
            .ccholder {
                position: relative;
            }

            .check-icon {
                font-size: 24px;
                position: absolute;
                top: -6px;
                right: -6px;
            }
        }
    }

    .overview {
        padding: 32px;
        background-color: $smoke;

        h2 {
            margin-bottom: 0 !important;
        }

        .divider {
            margin: 24px 0;
        }

        .line-item {
            margin: 16px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .data-disclaimer {
        h3 {
            margin-bottom: 16px;
        }

        .fancy-checkbox {
            width: 24px;
            height: 24px;
            padding: 4px;
            text-align: center;
            position: relative;
            top: 3px;

            .icon {
                font-size: 12px;
            }
        }
    }

    .lock {
        .lr {
            margin-right: 4px;
            font-size: 18px;
        }
    }

    .new-card {
        border: 1px dashed $defaultFontColor;
        width: 132px;
        min-height: 112px;

        @media(max-width: $screen-xs-max) {
            margin-top: 16px;
        }
    }

    .new-card-form {
        padding: 24px;
        padding-bottom: 9px;
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba($color: #000000, $alpha: .12);
    }
}

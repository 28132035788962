edit-report-category-selection {
    display: block;

    .category-group {
        margin-bottom: 65px;

        > .category:first-child {
            margin-right: 65px;

            @media (max-width: $screen-xs-max) {
                margin-right: 15px;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 0;
        }
    }

    .category {
        header {
            padding: 15px 0 !important;
            margin-bottom: 15px;
        }

        .lr {
            display: inline-block;
            position: relative;
            top: 3px;
            margin-right: 10px;
        }

        .ctas {
            > div {
                font-size: 14px;
                margin-bottom: 5px;
            }

            .box {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-radius: 2px;
                height: 20px;
                padding-left: 8px;
                padding-right: 8px;
                background-color: $listreports-blue;
                margin-left: 5px;
                color: inherit;
                text-decoration: inherit;

                :hover {
                    color: inherit;
                    text-decoration: inherit;
                }

                .text {
                    color: white;
                    font-weight: bold;
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }

    .option-not-listed {
        @media (max-width: $screen-xs-max) {
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

photo-selection-preview {

    .photo-selection-title {
        margin-bottom: 32px;

        @media (max-width: $screen-xs-max) {
            margin-bottom: 8px;
        }
    }

    .large-lightweight {
        font-size: 24px;

        @media (max-width: $screen-xs-max) {
            font-size: 20px;
        }
    }

    .large {
        font-size: 24px;
        font-weight: 700;
        width: 100%;

        @media (max-width: $screen-xs-max) {
            font-size: 20px;
        }
    }

    .sub-title-lightweight {
        font-size: 18px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    .photo-picker-container {
        max-width: 440px;
    }

    .view-sample-preview-container {
        width: 100%;
        text-align: right;
    }

    .picker-spacer {
        margin: 15px 0;;
    }

    .flyer-preview-container {
        max-width: 321px;

        .enlarge-link {
            margin-top: 16px;

            .lr-search {
                font-size: 18px
            }
        }
    }

    .flyer-preview {
        width: 320px;
        height:400px;
    }

    .preview-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .photo-selection-spacer {
        width: 40px;
    }

    .picker-spacer {
        margin: 15px 0;;
    }

    .expand-photos-button {
        font-size: 18px;
        color: $listreports-blue;

        .minimize-icon {
            position: relative;
            top: -7px;
        }
    }

    .photos-error {
        margin-top: 6px;
        margin-bottom: 24px;
    }


}

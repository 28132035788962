$applicationHeaderHeight: 60;

.application-header {
    background: #fff;
    color: $defaultFontColor;
    padding: 15px 25px;
    /* padding-left: 5px; */
    height: $applicationHeaderHeight + px;
    border-top: 1px solid #e0e0de;
    border-bottom: 1px solid #e0e0de;

    .logo-wrap {
        text-align: center;
    }

    .become-user-button {
        position: absolute;
        top: 0px;
        right: 30%;
        padding: 0px;
        color: white;
        background-color: #f44336;
        width: 40%;
        text-align: center;
        cursor: pointer;
    }

    .vendor-admin-become-user-button {
        position: absolute;
        top: 0px;
        right: 30%;
        padding: 0px;
        color: white;
        background-color: #f44336;
        width: 50%;
        height: 60px;
        cursor: pointer;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media (max-width: $screen-md) {
            width: 60%;
            right: 24%;
            font-size: 18px;
        }
    }

    .resources-link {
        padding: 0 22px;
        border-left: 1px solid #e0e0de;
        border-right: 1px solid #e0e0de;
        height: $applicationHeaderHeight + px;
    }

    .resources-link:hover {
        text-decoration: none;
    }

    .resources-link:focus {
        text-decoration: none;
    }

    .resources-hover:hover {
        text-decoration: none;
    }

    .resources-hover:focus {
        text-decoration: none;
    }

    .avatar-wrap {
        padding-left: 22px;
    }

    .user-headshot {
        position: relative;

        min-width: 35px;

        img {
            width: 35px;
            height: 35px;
        }
    }

    .user-details {
        margin-left: 12px;
        font-size: 14px;
        line-height: 1.1;
        max-width: 200px;

        > div {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .user-name {
            font-weight: bold;
        }
    }

    .dropdown-btn {
        margin-left: 6px;
        margin-top: 0;

        .lr {
            font-size: 8px;
            position: relative;
            top: -2px;
        }

        @media (max-width: $screen-md) {
            margin-left: -5px;
            margin-top: 26px;
        }
    }

    .dropdown-menu-wrap {
        position: absolute;
        width: 210px;
        min-height: 330px;
    }

    .dropdown-menu {
        background-color: #fff;
        width: 210px;
        top: ($applicationHeaderHeight + 20) + px;
        right: 8px;
        left: auto; /* important to override bootstrap's default left float */
        border: none;
        border-radius: 6px;
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.3);

        li {
            padding: 10px 24px;
            color: $defaultFontColor;
            cursor: pointer;

            &:hover {
                color: $listreports-blue;
            }

            &:first-of-type {
                padding-top: 20px;
            }

            &:last-of-type {
                padding-top: 16px;
                padding-bottom: 18px;
            }

            &:nth-last-child(2) {
                padding-bottom: 20px;
                border-bottom: 1px solid $ice-ice-baby;
            }

            &.logout {
                /* border-top: 1px solid $listreports-pale-blue-gray; */
            }
        }

        .dropdown-caret {
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0;
            top: -50px;
            overflow: hidden;

            &:before {
                content: "";

                width: 18px;
                height: 24px;
                transform: rotate(45deg);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
                background-color: white;

                position: absolute;
                bottom: -15px;
                right: 15px;
            }
        }
    }

    .notification-badge {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d51d4c;
    }
}

.route-download {
    .download-logo-holder {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

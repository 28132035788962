active-buyer-network-address {
    .title {
        h2 {
            margin: 0;
        }
    }

    .stats {
        h2 {
            margin: 0;
        }

        .stat:first-child {
            padding-right: 30px;
            margin-right: 30px;
            border-right: 1px solid $defaultfontcolor;
        }
    }

    .address-form-holder {
        padding: 40px;
        background: $ice-ice-baby;

        @media (max-width: $screen-xs-max) {
            padding: 15px;
        }
    }

    form {
        margin-top: 10px;

        .unit {
            max-width: 120px;
        }

        .spacer {
            width: 10px;
        }

        .btn-holder {
            @media (max-width: $screen-xs-max) {
                margin-top: -10px;
            }
        }
    }
}

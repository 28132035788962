receipt {
    display: block;

    .tear-top {
        height: 15px;
        background: url('/images/purchase-buyside-credits-modal/tear-top.png') no-repeat top center;
        background-size: 100%;

        @media (max-width: $screen-xs-max) {
            height: 10px;
        }
    }

    .tear-middle {
        padding: 0 24px 24px;
        background-image: url('/images/purchase-buyside-credits-modal/tear-middle.png');
        background-repeat: repeat-y;
        background-size: 100%;

        @media (max-width: $screen-xs-max) {
            padding-top: 5px;
        }
    }

    .tear-bottom {
        height: 27px;
        background: url('/images/purchase-buyside-credits-modal/tear-bottom.png') no-repeat bottom center;
        background-size: 100%;
    }
}

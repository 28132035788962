.credit-card-collection {

    .form-group {
        width: 100%;
        padding-left: 10px !important;
    }

    .payments-header {
        margin-bottom: 0;
    }

    .vgradient {
        height: 42px;
        background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
        margin: 0 -50px;

        @media (max-width: $screen-sm-max) {
            margin: 0 -50px;
        }
    }

    h1 {
        font-size: 24px;
        // color: $listreports-blue;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }

    .relative {
        position: relative;
    }

    .footer-text {
        font-size: 14px;
    }

    .ccs {
        margin-top: -2px;
        margin-bottom: 10px;

        @media(min-width: 769px) {
            position: absolute;
            bottom: 18px;
            right: 9px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.webinar-select-modal {
    padding: 36px 50px;

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 20px 32px;
    }

    header {
        h2 {
            margin: 14px auto;
            font-size: 24px;

            @media (max-width: $screen-xs-max) {
                margin: 12px auto;
            }
        }
    }

    section.body {
        margin: 24px auto;
    }

    .dismiss-wrap {
        margin-top: 24px;
    }

}

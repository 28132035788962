activate-pals-confirm {
    display: block;
    margin: 0 auto;
    max-width: 455px;

    .title {
        h1 {
            margin: .25em 0 .05em;
        }

        h2 {
            margin: 0 0 50px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 40px;
            }
        }
    }

    .body-content {
        .divider {
            height: 1px;
            width: 140px;
            margin: 50px auto;
            background-color: $smoke;

            @media (max-width: $screen-xs-max) {
                margin: 40px auto;
            }
        }
    }
}

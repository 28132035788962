plan-feature-card {
    width: 285px;
    min-height: 415px;
    background-color: #fafbfb;
    border-top: 6px solid $listreports-blue;
    padding-bottom: 30px;

    .price {
        margin-bottom: 40px;

        h1 {
            margin: -8px 0;
        }

        h2 {
            margin: 0;
        }
    }

    .divider {
        height: 2px;
        width: 50px;
        background-color: $smoke;
        margin: 25px auto;
    }

    .features {
        line-height: 2.1;
    }
}

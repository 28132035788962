active-buyer-network-coc {
    display: block;
    max-width: 1000px;

    .content {
        padding-top: 70px !important;
        padding-bottom: 70px !important;

        h2 {
            margin: 0;
        }

        > div {
            width: 533px;
            margin-top: 30px;
        }

        ul {
            list-style: disc;
            padding-left: 44px;

            li {
                padding-left: 14px;
                margin: 5px 0;
            }
        }
    }

    .seal-holder {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 20px;
            text-align: center;
        }
    }
}

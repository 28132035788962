.reset-password {
    h1 {
        color: $listreports-blue;
        font-size: 20px;

        @media (max-width: $screen-sm) {
            font-size: 16px;
        }
    }

    &.mbs-highway{
        h1 {
            color: #113A3F;
        }

        .form-group label{
            color: #0D2121;
        }

        input.form-control:focus{
            border-color: #0D2121;
        }

        .btn.btn-primary{
            background-color: #8551DC;
            border-color:#8551DC;
            &:hover {
                background-color: #6C41B5;
                border-color:#6C41B5;
            }
        }
    }
}

.offer-sent-confirmation-modal {
    padding: 24px 50px;

    @media (max-width: $screen-xs-max) {
        min-width: 100%;
        padding: 20px 32px;
    }

    header {
        h3 {
            margin: 10px auto;

            @media (max-width: $screen-xs-max) {
                margin: 8px auto;
            }
        }

        .caption {
            margin-bottom: 18px;
        }
    }

    .box-wrap {
        padding: 20px 32px;
        border: 6px solid #f6f6f6;
        margin: 12px auto;
    }

    .tip-wrap {
        font-size: 12px;
        margin-bottom: 12px;

        .icon-wrap {
            min-width: 40px;
            max-width: 42px;
            font-size: 32px;
            color: #e2e2e2;
        }
    }

    .btn-primary {
        margin-top: 12px;
    }

}

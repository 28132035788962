@import "variables";

shareables-banner {
    display: block;

    banner {
        background-color: #1E2B35;
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 0;

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .image-wrapper {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            bottom: -6px;
        }
    }

    .shareable-image {
        margin-right: 24px;

        width: 200px;
        height: 123px;

        @media (min-width: $screen-md-min) {
            width: 265px;
            height: 164px;
        }
    }

    .shareable-image--top-left-star {
        top: 9px;
        left: 37px;

        @media (min-width: $screen-md-min) {
            top: 12px;
            left: 50px;
        }
    }

    .shareable-image--bottom-right-star {
        bottom: 6px;
        right: 19px;

        @media (min-width: $screen-md-min) {
            bottom: 12px;
            right: 22px;
        }
    }

    .title-wrapper {
        margin-bottom: 10px;
    }

    .title {
        font-size: 24px;
        padding-left: 12px;
        padding-right: 12px;
        @media(min-width: $screen-md-min) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $screen-lg-min) {
            font-size: 28px;
        }
    }

    .title--top-left-star {
        top: -8px;
        left: -18px;
    }

    .title--bottom-right-star {
        bottom: -8px;
        right: -18px;

        @media (min-width: $screen-sm-min) {
            bottom: -8px;
            right: -18px;
        }

        @media (min-width: $screen-lg-min) {
            bottom: -8px;
            right: -18px;
        }
    }

    .main-wrapper {
        @media (max-width: $screen-sm-max) {
            flex-direction: column-reverse;
            padding-top: 28px;
        }
    }

    .view-button-wrapper {
        margin-top: 18px;
        margin-bottom: 10px;
    }

    .body-wrapper {
        text-align: center;
        @media (min-width: $screen-md-min) {
            text-align: left;
            margin-right: 10px;
        }
    }

    .main-text{
        margin-bottom: 4px;
        font-size: 14px;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;

        a {
            font-size: 14px;
        }

        @media (min-width: $screen-md-min) {
            font-size: 18px;
            max-width: none;
            a {
                font-size: 18px;
            }
        }


    }

    .btn.shareable-primary-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        border-color: $primary-blue-accent;
        color: $primary-blue-accent;
        padding: 8px 20px;
        @media (min-width: $screen-md-min) {
            padding: 15px 32px;
        }
    }

    .dismiss-button {
        position: absolute;
        line-height: 1;
        font-size: 14px;
        top: 10px;
        right: 10px;
        color: white;
    }
}

.deactivate-robin-agent-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .close-modal-btn{
        .btn.btn-link.sidewalk{
            color: $defaultFontColor;
        }
    }

    .modal-dialog {
        border: none;
        margin: 0px auto;
        height: 100%;
        max-width: 438px;

        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }

    &.standard-modal{
        .modal-content {
            top: 50%;
            transform: translateY(-50%);
            padding: 32px 42px 42px;
            max-width: 438px;
        }
        @media (max-width: $screen-xs-max) {
            border: none;
            box-shadow: none;
        }
    }

    .title{
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        margin: 0 0 16px;
    }

    .text{
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin: 0 0 16px;
    }

    .action{
        width: 170px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .btn-small{
        padding-left: 16px;
        padding-right: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: auto;
    }

    .deactivate-agent-check{
        color: $defaultFontColor;
        font-size: 32px;
        margin-bottom: 16px;
    }

}

@import '../../../modals/select-robin-agent/_select-robin-agents-styles';

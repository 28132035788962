creditcard {
    display: inline-block;

    .container {
        width: 220px;
        height: 132px;
        padding: 20px;
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;

        .cclogo-holder {
            img {
                max-width: 53px;
            }
        }

        .lastfour {
            margin-bottom: 4px;
        }
    }
}

transactions-new-transaction-select-partner {
    display: block;
    max-width: 1040px;

    .top-title {
        margin: 0 0 -14px;

        @media (max-width: $screen-xs-max) {
            margin-left: 15px;
        }
    }

    title-bar {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }

    .content {
        .title {
            margin-bottom: 50px;

            h2 {
                margin-bottom: 20px;
            }
        }
    }

    .partners {
        max-width: 815px;
        margin: 0 auto;
    }

    .partner-wrapper {
        padding: 8px;
    }

    .partner {
        border: 1px solid $ice-ice-baby;
        border-radius: 4px;
        padding: 34px 20px;
        transition: all .2s ease-in-out;
        height: 100%;

        &:hover {
            @media (min-width: $screen-md) {
                transform: scale(1.05);
                z-index: 100;
            }
        }

        &.selected {
            transform: scale(1.05);
            z-index: 101;
        }

        .headshot-wrapper {
            img {
                max-width: 100px;
                margin: 0 auto 20px;
            }
        }

        .cta {
            margin-top: auto;
            padding-top: 30px;

            .lr-check {
                top: 2px;
                display: inline-block;
                margin-left: 5px;
                font-size: 16px;
            }
        }
    }
}

@import '../../bootstrap/variables';

production-hours-banner {
    display: block;
    position: relative;

    banner {
        background-color: #76888f;
        margin-bottom: 20px;

        .lr-house-night {
            position: relative;
            top: -3px;
        }
    }

    .close-wrap {
        position: absolute;
        top: 12px;
        right: 10px;
    }

    @media(max-width: $screen-xs-max) {
        margin-bottom: 15px;
    }
}

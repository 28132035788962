banner {
    display: block;
    padding: 24px;
    background: $listreports-blue;
    color: white;

    &[video-html] {
        padding: 15px 24px 15px 15px;
    }

    .embed-responsive-wrap {
        margin-right: 24px;

        @media (min-width: $screen-sm) {
            width: 215px;
        }

        @media ($screen-xs-max) {
            margin-left: 15px;
        }
    }

    .btns {
        margin-left: 24px;
        margin-top: 0;

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .secondary-btn-wrap {
        @media (max-width: $screen-xs-max) {
            margin-top: 8px;

            .btn-sm {
                font-size: 14px;
            }
        }

    }

    .icon-image-holder {
        margin-right: 24px;

        @media (max-width: $screen-xs-max) {
            margin-right: 15px;
        }
    }

    .icon-holder {
        font-size: 60px;
        margin-right: 24px;

        @media (max-width: $screen-xs-max) {
            font-size: 36px;
            margin-right: 15px;
        }
    }

    .coming-soon {
        display: inline-block;
        background: $listreports-gold;
        color: #fff;
        font-size: 10px;
        border-radius: 2px;
        padding: 3px 5px 2px;
        margin-bottom: 5px;
    }

    .row-layout-buttons {
        .btns {
            @media (max-width: $screen-xs-max) {
                margin-left: 0;
                margin-top: 12px;
            }
        }

        .secondary-btn-wrap {
            margin-left: 4px;

            @media (max-width: $screen-xs-max) {
                margin-top: 0;

                .btn-sm {
                    font-size: 12px;
                }
            }
        }
    }

    .title-badge {
        display: inline-block;
        position: relative;
        background-color: $listreports-orange;
        padding: 1px 5px;
        font-size: 10px;
        border-radius: 2px;
        color: #fff;
        top: -2px;
        margin-left: 2px;
        text-transform: uppercase;
    }

    &.bigger-text {
        .title {
            font-size: 28px;

            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }
        }

        .body {
            font-size: 18px;
        }
    }

    &.white-bg {
        background: #fff;
        color: $defaultFontColor;
    }
}


banner.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px 24px;
    z-index: 65;

    .icon-holder {
        font-size: 24px;
        margin-right: 18px;

        @media (max-width: $screen-xs-max) {
            margin-right: 15px;
        }

        &.lr-stack {
            font-size: 16px;
        }

        i.lr-stack-1x {
            margin-left: 2px;
        }
    }

    .title {
        font-size: 14px;
    }
}

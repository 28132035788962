.resend-invite-modal {
    .modal-content {
        .btn-wrap {
            .btn {
                @media (max-width: $screen-xs-max) {
                    margin-bottom: 10px !important;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}

.edit-report-feedback-form.accordion {

    section {
        font-size: 14px;

        header {
            border: none;
        }
    }

    .accordion-container {
        max-width: 712px;
        margin: auto;
    }

    .profile-description {
        margin-top: -8px;
    }

    .profile-update {
        margin-top: 24px;

        .info-container {
            cursor: pointer;
            border: 1px solid $ice-ice-baby;
            border-radius: 8px;
            padding: 16px;

            .checkmark {
                visibility: hidden;
            }

            .info {
                margin-top: 8px;

                .changed {
                    background-color: $listreports-pale-yellow;
                }
            }

            &.selected {
                border: 2px solid $listreports-blue;

                .checkmark {
                    visibility: visible;
                }
            }

            &.left {
                margin-right: 8px;

                @media (max-width: $screen-sm-max) {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }

            &.right {
                margin-left: 8px;

                @media (max-width: $screen-sm-max) {
                    margin-left: 0;
                    margin-top: 8px;
                }
            }
        }
    }

    .photo-selection-wrap {
        margin-top: 32px;
    }

    .collapsable-section {
        .cs-bullet i {
            font-size: 24px;
        }

        .cs-header {
            background-color: $frosty;
        }
    }

    .mobile-footer {
        position: fixed;
        z-index: 999;
        background-color: #fff;
        border-top: 1px solid  $ice-ice-baby;
        left: 0;
        right: 0;
        padding: 16px;
        bottom: 0;

        .form-group {
            margin-bottom: 0;
        }
    }

    .line-separator {
        margin: 0 auto 16px;
    }

    .photo-selection-wrap.no-photos {
        margin-top: 0;
    }

    .no-photos-block {
        margin-top: 16px;
        .photo-count {
            font-size: 24px;
            font-weight: 700;
    
            @media (max-width: $screen-xs-max) {
                font-size: 20px;
            }
        }
    
        .sub-title {
            margin: 8px 0;
            font-size: 18px;
    
            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }
    }

    .target-link {
        margin: 0 32px 0 0;
        padding-bottom: 4px;

        &:last-of-type {
            margin-right: 0;
        }

        &.active {
            border-bottom: 2px solid $giggity;
        }
    }

    .body {
        padding-left: 0;
        padding-right: 0;
    }
}

@import '../../../styles/colors';

search-input {
    display: inline-block;
    position: relative;

    input.form-control {
        border-color: $ice-ice-baby;
        position: relative;
        padding-right: 36px;
        display: inline-block;
        margin-bottom: 0;
        margin-right: -36px;
        height: 40px;

        &::placeholder {
            color: $defaultSecondaryTextColor !important;
        }
    }

    .btn {
        border: 0 !important;
        background-color: #fff !important;
        padding-left: 10px !important;
        padding-right: 10px !important;

        .lr {
            margin-right: 0;
        }
    }

    .input-group {
        display: flex !important;
    }

    .input-group-addon {
        background: #fff;
        border-color: $ice-ice-baby;
        color: $ice-ice-baby;
    }

    .lr-times {
        position: absolute;
        top: 26%;
        right: 35px;
        z-index: 2;
        font-size: 10px;
        display: none;
    }

    &.block {
        display: block;
    }
}

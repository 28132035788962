.cancellation-wrapper {
    header {
        margin-bottom: 20px;
    }

    .box {
        max-width: 900px;
        padding: 0;
    }

    .text-center-xs {
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }
}

@import 'shell/styles';
@import 'select-reason/styles';
@import 'agent-stats/styles';
@import 'leaving-the-industry/styles';
@import 'what-happens-next/styles';
@import 'final-step/styles';

invite-your-coworkers-message {
    display: block;

    .img-wrapper {
        width: 50px;
        margin-right: 8px;
    }

    .text-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .text {
            top: 0;
            line-height: 1;
        }
    }
}

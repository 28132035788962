under-review {
    display: block;
    padding: 75px;
    // max-width: 800px;
    // margin: 0 auto;

    .section-1 {
        > div {
            margin: 0 24px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .img-holder {
            img {
                margin: 0 auto;
            }
        }

        .separator {
            height: 3px;
            width: 30px;
            background: $listreports-blue;
            margin: 30px 0;

            @media (max-width: $screen-xs-max) {
                margin: 30px auto;
            }
        }

        h1 {
            margin-top: 0;
        }

        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }

    .section-2 {
        .title {
            max-width: 235px;

            @media (max-width: $screen-xs-max) {
                max-width: none;
                margin-bottom: 32px;
            }
        }

        .arrow-holder {
            padding: 0 30px;
            max-width: 138px;

            @media (max-width: $screen-sm-max) {
                padding: 0 20px;
                max-width: 118px;
            }
        }

        .bullets {
            max-width: 372px;

            li {
                padding: 13px 0;
            }

            .bullet {
                width: 32px;
                height: 32px;
                border: 2px solid $listreports-blue;
                text-align: center;
                line-height: 28px;
                border-radius: 50%;
                margin-right: 15px;
            }

            @media (max-width: $screen-xs-max) {
                max-width: none;
            }
        }
    }

    .line {
        height: 2px;
        background: #F7F7F7;
        margin: 58px 0;

        @media (max-width: $screen-xs-max) {
            margin: 32px 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 25px;
    }
}

purchase-buyside-credits-modal-new-subscription-flow {
    .ctas,
    .root.error-box {
        margin-top: 24px;
    }

    .error-box {
        margin-top: 8px;
    }
}

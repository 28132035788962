profile-block{
    min-width: 220px;
    min-height: 240px;
    margin-right: 72px;

    @media (max-width: $screen-xs-max) {
        margin-bottom: 36px;
    }

    &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
    }

    .profile-block {
        min-width: 220px;
        margin-top: 20px;

        .headshot {
            height: 80px;
        }

        .name {
            margin-top: 25px;
        }

        &.locked {
            background-color: $smoke;
            height: 203px;
        }
    }

    .title {
        text-transform: uppercase;
        color: $ice-ice-baby;
    }
}

.paywall-modal {
    header {
        margin-top: 60px;
        margin-bottom: 8px;
        padding: 0 12px;

        .header-icon {
            max-height: 65px;
        }

        h2 {
            font-size: 36px;
            margin: 12px auto -8px;
            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }

            &.price {
                font-size: 48px;
                margin: 8px auto 0;
                @media (max-width: $screen-xs-max) {
                    font-size: 36px;
                }
            }
        }

        .caption {
            font-size: 24px;
            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }
    }

    .feature-list-wrap {
        background-color: #efefef;
        padding: 20px 58px;
        margin-bottom: 32px;
        max-width: 100%;

        @media (max-width: $screen-xs-max) {
            padding: 16px 30px;
        }

        .feature-item {
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .feature-icon-wrap {
            min-width: 42px;
            text-align: center;

            @media (max-width: $screen-xs-max) {
                min-width: 100%;
                margin-bottom: 12px;
            }

            .feature-icon {
                width: 42px;
            }
        }

        .feature-details {
            font-size: 16px;
            padding-left: 36px;
            max-width: calc(100% - 42px);
            text-align: left;

            @media (max-width: $screen-xs-max) {
                padding-left: 0;
                min-width: 100%;
                max-width: 100%;
                text-align: center;
            }
        }
    }

    .btn-primary {
        font-size: 18px;
        @media (max-width: $screen-xs-max) {
            font-size: 16px;
        }
    }

    .view-all-plans {
        margin-top: 10px;
    }

    .close-btn {
        position: absolute;
        top: -18px;
        right: 12px;
        font-size: 72px;
        @media (max-width: $screen-xs-max) {
            top: -10px;
            right: 8px;
            font-size: 48px;
        }
    }

    a {
        font-size: 16px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }
}

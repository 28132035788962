.invite-agent-modal-v2 {
    .custom-message {
        margin-top: 13px;
        span {
            margin-top: 13px;
        }
        textarea {
            height: 81px;
        }
    }
    .modal-dialog {
        max-width: 520px;

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }

    .agent-search {
        margin-top: 24px;
    }

    .modal-content {
        .view-agent-details {
            margin-top: 16px;
            padding: 16px 16px;
            border: 1px solid $ice-ice-baby;
        }

        agent-listing-stats {
            .agent-listing-stats {
                margin-top: -1px;
                border: 1px solid $ice-ice-baby;

                .agent-listing-stats {
                    border: 0;
                }

                .stats-block {
                    border: 0;
                }
            }
        }

        .form-body {
            padding-left: 0;
            border-left: 0;
        }

        .state1 {
            text-align: center;
            margin-bottom: 16px;
        }

        .state2 {
            label {
                input {
                    margin-right: 5px;
                }
            }

            .ctas {
                > div {
                    margin-bottom: 10px;
                }
            }

            .copy-paste-wrap {
                background: #f7f7f7;
                margin: 16px -56px -32px;
                padding: 22px 56px;

                @media (max-width: $screen-xs-max) {
                    margin: 16px -24px -24px;
                    padding: 22px;
                }
            }
        }
    }

    .char-limit-validation {
        font-size: 10px;
    }

    .vendor-relationship-disclaimer {
        margin-left: 20px;
    }

    &.standard-modal .modal-content {
        padding-bottom: 24px;
    }
}

@import './wrapper/styles';
@import '../../modals/select-lender/select-lender-styles';
@import './loan-officer-card/styles';

.preferred-vendor {
    padding: 0;

    &.signup {
        padding: 0px;
        margin-top: -50px;
        @media (max-width: $screen-xs-max) {
            padding: -40px;
            margin-top: -35px;
            margin: -20px;
        }
    }

    header {
        margin-bottom: 0px;
        border: 0;

        h2 {
            margin-top: 0;
        }

        p {
            margin: 0;
        }

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    .reasons {
        ul {
            margin-top: 16px;
            list-style-type: disc;
            margin-right: -10px;
            display: block;
            max-width: 100%;
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            margin-right: 0;
        }
    }

    .footer-section .pair-with-a-loan-officer {
        padding-bottom: 50px;
        border-bottom: 1px solid #D5DFE3;
    }

    .search-section {
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }
        .select-lender-modal {
            .lender-search {
                margin: 0;
            }
            
            input {
                background: #fff;
            }
        }
    }


    .button-holder {
    
        > div {
            margin: 5px 0;

            .btn-link {
                margin: 10px 0;
                font-weight: 700;
            }

            button p {
                font-weight: 700;
            }
        }
    }

    .thanks-v2 {
        min-height: 500px;
        background: #FFF;
        margin-left: -48px;
        margin-right: -48px;

        @media (max-width: $screen-xs-max) {
            min-height: 360px;
            margin-left: -16px;
            margin-right: -16px;
        }
        
    }

    .review-v2 {

        .chosen-lender {
            margin-top: 42px;

            @media (max-width: $screen-xs-max) {
                margin-top: 32px;
            }
        }

        .wrapper {
            background: #FFF;
            padding-top: 32px;
            padding-bottom: 32px;
            margin-top: 46px;            
            margin-left: -48px;
            margin-right: -48px;

            @media (max-width: $screen-xs-max) {
                margin-left: -16px;
                margin-right: -16px;
                padding: 16px;
            }
        }

        .main {
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media (max-width: $screen-xs-max) {
                flex-direction: column;
            }
            
        }

        .relationship {
            margin-left: 32px;

            @media (max-width: $screen-xs-max) {
                margin-left: 0;
            }

            .title {
                @media (max-width: $screen-xs-max) {
                    margin-top: 20px;
                    text-align: center;
                }    
            }

            .cta-wrapper {
                width: fit-content;

                @media (max-width: $screen-xs-max) {
                    width: auto;
                    text-align: center;
                }    
            }

        }

        .terms {
            text-align: center;
            color: $defaultSecondaryTextColor;
            font-size: 12px;
            line-height: 16px;
            margin: 32px auto;
            max-width: 500px;
        }
    }

    .review {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;

        .no-portal-user {
            p {
                font-size: 14px;
                max-width: 350px;
            }
        }

        .chosen-lender {
            padding: 0 32px 0;

            h2 {
                margin-top: 0;
            }

            @media (max-width: $screen-xs-max) {
                margin: 0 -15px;
                padding: 0 15px 0;
            }
        }

        .legal {
            padding: 0 32px 0;

            @media (max-width: $screen-xs-max) {
                margin: 0 -15px;
                padding: 0 15px 0;
            }
        }
    }

    &.signup {
        .review {
            .chosen-lender {
                // margin-top: 40px;

                // @media (max-width: $screen-xs-max) {
                //     margin: 0 -20px;
                // }
            }
        }
    }

    .invite {
        padding: 42px 30px;

        header {
            h2 {
                margin-bottom: 0;
            }

            p {
                margin: 20px 0 25px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 32px 0;
        }
    }

    .thank-you-message {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .face-wrap {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 2px rgba(0, 0, 0, .8);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 10px;

        @media (max-width: $screen-xs-max) {
            width: 80px;
            height: 80px;
        }
    }

    .subtext {
        padding-bottom: 10px;
    }

    .company-logo-image-holder {
        margin-bottom: 20px;

        &.selected {
            border: 1px solid #bdc1c5;
            padding: 20px;
        }
    }

    .error-box {
        margin-top: 0;
    }

    .examples {
        margin: 32px 0;

        .example {
            > div {
                max-width: 175px;
                display: inline-block;

                @media (max-width: $screen-xs-max) {
                    max-width: 100%;
                }
            }

            .btn-link {
                margin-top: 8px;
            }

            .img-wrapper {
                height: 87px;
                margin-bottom: 12px;

                @media (max-width: $screen-xs-max) {
                    height: 130px;
                }
            }

            img {
                max-height: 87px;

                @media (max-width: $screen-xs-max) {
                    max-height: 130px;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-bottom: 36px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .thanks {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;

        user-info-card {
            margin-top: 60px;
        }

        .profile-wrap {
            text-align: left;

            .img-wrapper {
                @media (max-width: $screen-xs-max) {
                    margin-bottom: 16px;
                }
            }

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }

            > div {
                margin: 0 16px;
            }

            @media (max-width: $screen-xs-max) {
                text-align: center;
            }
        }
    }

    .blue-bg {
        background-color: #1E2B35;
        color: #fff;
    }

    .vgradient {
        height: 42px;
        background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
        margin: 0 -100px;

        @media (max-width: $screen-sm-max) {
            margin: 0 -35px;
        }
    }

    .preferred-vendor-suggested-los {
        margin: 0 -100px;
        
        @media (max-width: $screen-xs-max) {
            margin: 0 -35px;
        }
    }

    .suggested-los-wrapper {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin: 32px auto;

        @media (max-width: $screen-xs-max) {
            flex-wrap: wrap;
            justify-content: center;
            max-width: calc(100% - 40px);
        }

        .slick-arrow {
            font-size: 20px !important;
        }

        .move-left {
            left: -24px !important;
        }

        .slick-track {
            height: 299px;
        }

        .slick-slide > div {
            height: 100%;
        }

        .slick-current > div > div {
            margin-left: 0 !important;
        }

        .move-right {
            right: -24px !important;
        }

        .card-wrapper {
            margin: 0 8px;

            @media (max-width: $screen-xs-max) {
                margin: 8px 0;
            }   
        }

    }

    .invite-logged-out-with-suggested-invites {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $screen-xs-max) {
            flex-direction: column;
        }

        .invite-footer {
            display: flex;
        
            @media (max-width: $screen-xs-max) {
                flex-direction: column;
                padding: 0;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 35px;
            }
    
            div .licensed-loan {
                text-align: start;
                font-size: 14px;
    
                @media (max-width: $screen-xs-max) {
                    text-align: start;
                }
            }
    
            div .invite-footer-your-account {
                display: none;
            }
    
            div .invite-footer-button-no-thanks {
                margin: 35px;
    
                @media (max-width: $screen-xs-max) {
                    margin: 8px;
                }
    
                div button {
                    @media (max-width: $screen-xs-max) {
                        text-align: start;
                    }
                }
            }
        }

        .iframe {
            width: 70%;
            height: 248px;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: 184.67px;
            }
        }
    }

    .invite-logged-out-no-invites {
        display: flex;
        flex-direction: column;
        align-items: center;

        .invite-footer {
            max-width: 570px;
        
            @media (max-width: $screen-xs-max) {
                flex-direction: column;
            }
    
            div .licensed-loan {
                text-align: center;
                margin: 0;
                margin-bottom: 24px;
    
                @media (max-width: $screen-xs-max) {
                    text-align: start;
                    margin: 0;
                    margin-bottom: 24px;
                }
            }
    
            div .invite-footer-your-account {
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
                font-size: 12px;
            }
    
            div .invite-footer-button-no-thanks {

                @media (max-width: $screen-xs-max) {
                    margin: 0px;
                    margin-left: 8px;
                }
    
                div button {
                    @media (max-width: $screen-xs-max) {
                        margin: 0px;
                        text-align: start;
                    }
                }
            }
        }

        .iframe {
            width: 100%;
            height: 480px;

            @media (max-width: $screen-xs-max) {
                height: 184.67px;
            }
        }
    }

    .invite-logged-in {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $screen-xs-max) {
            padding-bottom: 42px;
        }

        .invite-footer {
            display: flex;
            flex-direction: row;
            width: 475px;
            gap: 70px;
            padding-bottom: 42px;

            @media (max-width: $screen-xs-max) {
                flex-direction: column;
                width: 100%;
            }
    
            div .licensed-loan {
                text-align: start;
    
                @media (max-width: $screen-xs-max) {
                    text-align: start;
                    
                }
            }
    
            div .invite-footer-your-account {
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
                font-size: 12px;
            }

            div .invite-footer-button-no-thanks {
                margin: 35px;
    
                @media (max-width: $screen-xs-max) {
                    margin: 8px;
                }
    
                div button {
                    @media (max-width: $screen-xs-max) {
                        text-align: start;
                    }
                }
            }
        }

        .iframe {
            border-top: 1px solid #D5DFE3;
            width: 110.5%;
            height: 554px;

            @media (max-width: $screen-xs-max) {
                border-top: none;
                width: 100%;
                height: 184.67px;
            }
        }
    }

    .invite-logged-in-with-invited-los {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 32px 0;
        margin-left: -50px;
        margin-right: -50px;
        border-top: 1px solid #D5DFE3;

        @media (max-width: $screen-xs-max) {
            flex-direction: column;
            border-top: none;
            margin-left: -15px;
            margin-right: -15px;
        }

        .invite-footer {
            display: flex;
            flex-direction: row;
            width: 475px;
            gap: 70px;
            padding: 0 32px;

            @media (max-width: $screen-xs-max) {
                flex-direction: column;
                width: 100%;
                padding: 0 16px;
            }

            .search-section .invite-someone-else {
                margin: 0;
            }
    
            div .licensed-loan {
                text-align: start;
    
                @media (max-width: $screen-xs-max) {
                    text-align: start;
                }
            }

            div .invite-footer-dont-worry-text {
                text-align: start;

                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }
    
            div .invite-footer-your-account {
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
                font-size: 12px;

                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }

            div .invite-footer-button-no-thanks {
                margin: 35px;
    
                @media (max-width: $screen-xs-max) {
                    margin: 8px;
                }
    
                div button {
                    @media (max-width: $screen-xs-max) {
                        text-align: start;
                    }
                }
            }
        }

        .iframe {
            width: 440px;
            height: 248px;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: 184.67px;
            }
        }
    }

    .invite-logged-in-with-suggested-los {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 32px 0;
        margin-left: -50px;
        margin-right: -50px;
        margin-top: 24px;
        border-top: 1px solid #D5DFE3;

        @media (max-width: $screen-xs-max) {
            flex-direction: column;
            border-top: none;
            margin-left: -15px;
            margin-right: -15px;
        }

        .invite-footer {
            display: none;
        }

        .iframe {
            width: 110.5%;
            height: 554px;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: 184.67px;
            }
        }
    }

    .invite-footer-preferred-vendor {
        flex-direction: column;
        margin: auto;
        max-width: 570px;
        display: flex;
        align-items: center;

        @media (max-width: $screen-xs-max) {
            padding: 0;
            align-items: flex-start;
        }
    }

    
    .instant-pair-sucess {


        // <div class="chosen-lender" style="padding-top: 42px; max-width: 435px; margin: 0 auto;">

        .chosen-lender {
            margin: 42px auto 0 auto;
            max-width: 435px;

            @media (max-width: $screen-xs-max) {
                margin: 32px auto 0 auto;
            }        
        }

        .iframe {
            margin: 42px -48px 0 -48px;
            height: 542px;

            @media (max-width: $screen-xs-max) {
                height: 200px;
                margin: 42px -16px 0 -16px;
            }
        }
    }

    

    .pair-with-my-lender {
        h2 {
            margin-top: 0;
        }

        .box {
            border: 2px solid $ice-ice-baby;
            padding: 30px;

            > header {
                letter-spacing: 1.5px;
                color: #afb8bd;

                @media (max-width: $screen-xs-max) {
                    margin-bottom: 20px;
                }
            }

            .cta-wrapper {
                margin-top: 10px;

                @media (max-width: $screen-xs-max) {
                    margin-top: 20px;
                }
            }

            @media (max-width: $screen-xs-max) {
                margin-left: -20px;
                margin-right: -20px;
            }
        }
    }

    .value-props {
        h2 {
            @media (max-width: $screen-xs-max) {
                margin: 0;
            }
        }
    }

    .legal {
        font-size: 12px;
    }

    .mt-1 {
        margin-top: 8px;
    }

    .relationship {
        label {
            input {
                margin-right: 5px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 15px;
        font-size: 14px;
    }
}

.route-select-my-lender,
.route-report-details-preferred-vendor {
    .preferred-vendor {
        .pair-with-my-lender {
            .box {
                @media (max-width: $screen-xs-max) {
                    margin-left: -15px;
                    margin-right: -15px;
                    border-left: 0;
                    border-right: 0;
                }
            }
        }

        .vgradient {
            margin: 0 -50px;

            @media (max-width: $screen-xs-max) {
                margin: 0 -15px;
            }
        }
    }
}


.route-select-my-loan-officer,
.route-report-details-preferred-vendor {
    .preferred-vendor {
        .pair-with-my-lender {
            .box {
                @media (max-width: $screen-xs-max) {
                    margin-left: -15px;
                    margin-right: -15px;
                    border-left: 0;
                    border-right: 0;
                }
            }
        }

        .vgradient {
            margin: 0 -50px;

            @media (max-width: $screen-xs-max) {
                margin: 0 -15px;
            }
        }
    }
}

.error-modal {
    h2 {
        margin-bottom: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 20px;
        padding-left: 70px;
        padding-right: 70px;

        @media (max-width: $screen-xs-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .icon-wrap {
        margin-bottom: 20px;
    }

    .message {
        white-space: pre-wrap;
    }
}

.paired-agents-content {
    border: 0 !important;



    &.section-group header {
        padding: 15px;
        border-bottom: none;
    }

    .search-content{
        margin-right: 16px;
    }

    .arrow{
        display: inline-block;
        margin-left: 4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #696969 transparent transparent transparent;
    }

    .user-info{
        position: relative;
    }

    .user-robin {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 63px;
        top: 46px;
        background: url('/images/robin_logo_round.svg');
    }

    .user-robin-access{
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
    }

    .none-found {
        h1 {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 32px;
        }
    }

    table {
        tbody {
            tr:first-child {
                td {
                    @media(max-width: $screen-xs-max) {
                        border-top: 0;
                    }
                }
            }
            tr {
                td:first-child {
                    width: 72px;
                    @media(max-width: $screen-xs-max) {
                        width: 60px;
                    }
                }

                td:nth-child(2) {
                    width: 39%;
                    @media(max-width: $screen-xs-max) {
                        width: auto;
                    }
                }
            }
        }

        .preferred-vendor-tooltip {
            position: relative;
        }

        img {
            width: 60px;
            height: 60px;

            @media (max-width: $screen-xs-max) {
                width: 45px;
                height: 45px;
            }
        }

        .email {
            word-wrap: break-word;

            @media (max-width: $screen-xs-max) {
                max-width: 180px;
            }
        }

        .ball {
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid $listreports-gray;
            border-radius: 50%;

            &.yes {
                background: $listreports-green;
                border-color: $listreports-green;
            }

            @media(max-width: $screen-xs-max) {
                width: 9px;
                height: 9px;
            }
        }

        .btn-group .btn.btn_large_view {
            width: 161px;
        }
    }

    .locked {
        color: #b9b9b9;
    }

    .locked-message {
        padding: 20px;
        text-align: left;

        a {
            white-space: normal;
            text-align: left;

            i {
                min-width: 24px;
                margin-right: 10px;

                @media (max-width: $screen-xs-max) {
                    font-size: 22px;
                    margin-right: 5px;
                }
            }

            @media (max-width: $screen-xs-max) {
                font-size: 12px;
            }
        }
    }

    .following-status {
        @media (max-width: $screen-xs-max) {
            margin: 10px 0 4px;
        }
    }

    .btn-link {
        .fs-10 {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .user-info-details{
        display: flex;
        margin-bottom: 16px;

        .user-info-details-avatar{
            margin-right: 19px;
            position: relative;

            .user-robin{
                left: 30px;
                top: 25px;
            }
        }
    }

    .user-info-actions{
        margin-bottom: 8px;
    }

    .user-robin-area{
        text-align: center;
        font-weight: normal;
    }
    .user-robin-activate{
        color: #007780;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
    }
    .user-robin-status{
        display: block;
        font-size: 14px;
        line-height: 16px;
        color:#696969;
        font-weight: bold;
        @media(max-width: $screen-xs-max) {
            display: inline-block;
        }
    }
}

md-tooltip.listreports.md-tooltip-arrow{
    overflow: visible;

    .arrow{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 16px 15px;
        border-color: transparent transparent #1e2b35 transparent;
        position: absolute;
        z-index: 9999;
        top: -11px;
        left: 50%;
        transform: translateX(-50%)
    }

}

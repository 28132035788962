.deactivate-user-modal {
    header {
        .modal-title {
            letter-spacing: 2px;
        }

        h2 {
            margin: 15px 0 0 !important;
        }
    }
}

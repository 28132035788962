vendor-admin-for-dropdown {
    display: block;
    padding: 15px 15px 0;

    label {
        font-weight: normal;
        font-size: 10px;
        color: #fff;
    }
}

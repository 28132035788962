.enroll-agent-modal {
    .modal-dialog {
        max-width: 520px;
        height: 100%;

        @media (max-width: $screen-xs-max) {
            overflow-y: scroll;
            width: auto;
        }
    }

    .modal-content {
        max-width: 520px;

        p {
            margin-top: 20px;
        }

        .subtitle {
            margin-top: -10px;
        }

        .invite-checkbox-wrap {
            position: relative;
            display: inline-block;
            padding: 15px 30px;

            fancy-checkbox {
                display: inline-block;
                max-width: 300px;
            }
        }

        .view-agent-details {
            margin-top: 16px;
            padding: 20px 20px 0;
            border: 6px solid $smoke;
            border-bottom: 0;
        }

        .cta-wrap {
            padding: 20px;
            border: none;

            &.border {
                border: 6px solid $smoke;
                border-top: none;
            }
        }

        .test-invite-email-wrap {
            margin: 15px auto 25px;

            .test-invite-email-btn {
                max-width: 168px;

                i.lr {
                    font-size: 18px;
                }

                @media (max-width: $screen-xs-max) {
                    max-width: none;
                }
            }

            .view-agent-experience-btn {
                border-left: 1px solid $ice-ice-baby;

                @media (max-width: $screen-xs-max) {
                    border-left: none;
                }
            }

            .btn {
                @media (max-width: $screen-xs-max) {
                    padding: 4px 0;
                }

                .lr {
                    top: 2px;
                }
            }
        }

        .agent-invite-count {
            font-size: 12px;
            margin: 16px auto 12px;

            &.red {
                color: $listreports-red;
                cursor: pointer;
            }

            &.green {
                color: $listreports-green;
            }
        }

        .separator {
            margin: 4px 0;
            border-top: 1px solid $ice-ice-baby;
        }

        .copy-paste-wrap {
            margin-top: 36px;
        }

        .cancel-link-wrap {
            margin-top: 8px;
        }

        .invite-link-tooltip {
            font-size: 14px;
            position: relative;
            top: 0;
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
        }

        .already-paired-wrap {
            .error-box {
                margin: 12px auto;
                text-align: center;
                justify-content: center;
            }
        }
    }
}

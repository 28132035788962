cancellation-agent-stats {
    h3 {
        @media (max-width: $screen-xs-max) {
            text-align: center;
            max-width: 240px;
            margin: 0 auto;
        }
    }

    .stats {
        border: 1px solid $ice-ice-baby;

        .stat-box {
            border-right: 1px solid $ice-ice-baby;
            padding: 15px;

            &:last-child {
                border-right: 0;
                border-bottom: 0;
            }

            h2 {
                margin: 0;
            }

            header {
                margin: 0 0 10px;

                @media (max-width: $screen-xs-max) {
                    text-align: left;
                }
            }

            .headshots-holder {
                padding-top: 5px;
            }

            @media (max-width: $screen-xs-max) {
                border-right: 0;
                border-bottom: 1px solid $ice-ice-baby;
            }
        }

        .headshot-lineup {
            margin-left: 0;

            .headshot-wrap {
                width: 25px;
            }

            .headshot {
                width: 30px;
                height: 30px;
                font-size: 15px;
                padding: 6px 0;
            }

            .additional-count-wrap {
                color: $listreports-blue;
                margin-left: 10px;
                font-size: 15px;
                position: relative;
                top: -7px;
            }
        }
    }
}

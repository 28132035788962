.avm-home-evaluation-request {
    display: block;

    > h1 {
        margin-top: 0;
    }

    .lead-info {
        padding: 45px;
        border: 4px solid $frosty;
        margin: 32px 0;

        @media (max-width: $screen-xs-max) {
            margin-right: -35px;
            margin-left: -35px;
            border-right: 0;
            border-left: 0;
            padding: 25px;
        }
    }

    .infographic {
        margin-top: 30px;

        img {
            margin: 0 auto;

            @media (min-width: $screen-sm) {
                max-width: 343px;
            }
        }
    }

    .affiliate-wrapper {
        margin-top: 20px;

         @media (max-width: $screen-xs-max) {
            margin-top: 40px;
        }
    }

    .affiliate {
        margin-top: 40px;
        margin-right: 9px;
        margin-left: 9px;

        .headshot {
            margin-top: -85px;
            margin-bottom: 20px;
            margin-right: auto;
            margin-left: auto;
            width: 80px;
            height: 80px;

            img {
                width: 80px;
                height: 80px;
            }

            @media (max-width: $screen-xs-max) {
                margin-top: 0;
            }
        }

        .title {
            margin-bottom: 10px;
            color: $sidewalk;
            text-transform: uppercase;
        }

        &:first-child {
            @media (min-width: $screen-sm) {
                 margin-left: 0;
            }
        }

        &:last-child {
            @media (min-width: $screen-sm) {
                 margin-right: 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-right: -35px;
            margin-left: -35px;
            margin-bottom: -1px;
            margin-top: 0;
            border: 0;
            border-top: 1px solid $frosty;
        }
    }
}

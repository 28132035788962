.min-h-full {
    min-height: 100% !important;
}

.centered {
    text-align: center;
}

// font-size

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.125rem; // 18px;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.font-normal {
    font-weight: normal !important;
}

.font-semi-bold {
    font-weight: 600;
}

.font-bold {
    font-weight: bold;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear {
    clear: both;
}

.hidden {
    display: none;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.show-block {
    display: block;
}

// position

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.top-0 {
    top: 0;
}

.right-10 {
    right: 10%;
}

.cursor-pointer {
    cursor: pointer;
}

.outline-none {
    outline: none;
}

.allcaps {
    text-transform: uppercase;
}

//Margin

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0!important;
}

.mb-0 {
    margin-bottom: 0!important;
}

.border-0 {
    border: 0 !important;
}

.border-b-0 {
    border-bottom-width: 0 !important;
}

.border-t-0 {
    border-top-width: 0 !important;
}

// Text

.no-underline {
    text-decoration: none;
}

.hover\:no-underline:hover {
    text-decoration: none;
}

.overflow-break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.hyphens-auto {
    hyphens: auto;
}

.break-word {
    word-break: break-word;
}

.break-all {
    word-break: break-all;
}

//Flex

.display-flex {
    display: flex;
}

.flex-col {
    flex-direction: column !important;
}

//Justify Content
.justify-around {
	justify-content: space-around !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.justify-end {
    justify-content: flex-end !important;
}

//Align Items

.items-center {
    align-items: center !important;
}

//Justify Content

.justify-center {
    justify-content: center;
}

//Height

.h-40 {
    height: 40px !important;
}

.h-full {
    height: 100% !important;
}

.h-min {
    height: min-content;
}

// Overflow

.overflow-auto {
    overflow: auto !important;
}

// min-width

.min-w-0 {
    min-width: 0 !important;
}

// space-between

.space-x-4 > * + * {
    margin-left: 1rem;
}

.space-y-4 > * + * {
    margin-top: 1rem;
}

.space-x-6 > * + * {
    margin-left: 1.5rem;
}

.space-y-6 > * + * {
    margin-top: 1.5rem;
}

.full-width {
    width: 100%;
}

.mr-4 {
    margin-right: 1rem;
}

.ml-4 {
    margin-left: 1rem;
}

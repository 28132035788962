.invite-status-modal {
    .table {
        > thead > tr > th {
            padding: 12px 0;
        }

        th {
            font-weight: normal;
            font-size: 12px;
            letter-spacing: 1.25px;
        }

        th:first-child,
        td:first-child {
            padding-left: 0;
        }

        th:last-child,
        td:last-child {
            padding-right: 0;
        }

        td {
            padding: 16px 0;
        }
    }
}
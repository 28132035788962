@import '../bootstrap/variables';

.edit-report {
    .video-wrap {
        margin-top: -20px;
        margin-bottom: 20px;

        @media (max-width: $screen-sm) {
            margin-top: -45px;
            margin-bottom: 32px;
            padding: 0 15px;
        }
    }

    md-content {
        padding: 0 !important;
        border: 1px solid #e0e0df;
        overflow: visible;
    }

    section {
        header {
            padding: 15px;
            border-bottom: 1px solid #e0e0df;

            h2 {
                margin: 0;
            }
        }
    }

    .body {
        margin-top: 40px;

        @media (max-width: $screen-sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .titles {
        h2 {
            margin-top: 50px;
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 30px;

            @media (max-width: $screen-xs-max) {
                max-width: 265px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .breadcrumbs {
        margin-bottom: 24px;
    }

    .accordion {
        .titles {
            h3 {
                margin-top: 50px;
                text-align: center;

                @media (max-width: $screen-xs-max) {
                    margin: 16px;
                    max-width: 100%;
                    text-align: left;
                }
            }
        }
    }

    .skeleton-container {
        padding: 60px 16px;
        max-width: 712px;
        margin: auto;
    }

    .skeleton {
        background-color: rgba(0,0,0,0.15);
        animation: 1.5s ease-in-out 0.5s infinite normal none running pulse;
        height: 40px;
        margin: 0 auto 12px;

        &.title {
            max-width: 250px;
            height: 24px;
            margin: 0 auto 50px;
        }
    }

    @keyframes pulse {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.4;
        }

        100% {
            opacity: 1;
        }
    }
}

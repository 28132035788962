@import '../status-badge/styles';

$noReportsMargin: 35;

.dashboard {
    max-width: 1400px;
    margin-bottom: 50px;

    md-content {
        border: 1px solid #e0e0df;
        background: #fff;
        overflow: visible;
    }

    md-list {
        border-bottom: 1px solid #e0e0df;
    }

    .banner-wrap {
        margin-bottom: 20px;
    }

    span + .banner-separator {
        @media (min-width: $screen-xs-max) {
            display: block !important;
        }
    }

    invite-your-coworkers-message {
        @media (max-width: $screen-xs-max) {
            margin: 15px;
        }
    }

    @media (max-width: $screen-xs-max) {
        margin-bottom: 0;
    }
}

.new-account-card {
    font-size: 18px;
    border: 1px solid #e0e0df;
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;

    h1 {
        font-size: 24px;
        font-weight: bold;
    }

    p {
        margin-bottom: 30px;
    }

    .btn-link {
        font-size: 14px;
    }

    .step {
        max-width: 200px;
        margin: 10px 15px;
        font-size: 14px;

        h3 {
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.view-material-download {
    @media (max-width: $screen-md) {
        height: 100%;
    }



    .material-close-btn-wrap {
        position: absolute;
        top: 20px;
        right: 20px;

        .lr {
            font-size: 14px;
            // text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);

            @media (max-width: $screen-md) {
                color: $giggity;
            }
        }


        .dark  .lr{
            color: $giggity;
        }

        @media (max-width: $screen-md-max) {
            top: 15px;
            right: 15px;
        }
    }

    .mobile-header .ctas {
        @media (max-width: $screen-md-max) {
            padding-top: 50px !important;
        }
    }
}

.view-material-download-content{
    @media (max-width: $screen-md) {
        display: flex;
        height: calc(100% - 95px);
    }
}

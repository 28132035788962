ecommission-banner {
    .ecommission-banner-wrap {
        background-color: #fff;
        position: relative;
    }

    .promotion {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        color: #FFFFFF;
        background: $listreports-blue;
        border-radius: 2px;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .logo-wrap {
        padding: 15px;
        max-width: 130px;
        min-width: 130px;

        @media (max-width: $screen-xs-max) {
            max-width: 100%;
        }
    }

    .logo {
        height: 80px;
    }

    .banner-content-wrap {
        background-color: #fff;
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }
}

.modal-dialog {
    @media (max-width: $screen-sm) {
        margin: 0;
    }
}

.modal-content {
    border-radius: 0;
    a.btn-link:link,
    a.btn-link:active,
    a.btn-link:visited,
    a.btn-link:hover {
        color: $listreports-blue;
    }
}

.standard-modal-backdrop {
    // @media (max-width: $screen-xs-max) {
    //     display: none;
    // }
}

.standard-modal {
    .modal-dialog {
        @media (max-width: $screen-xs-max) {
            margin: 10px;
        }
    }

    .modal-content {
        padding: 32px 56px;

        h2 {
            margin-top: 0;
            margin-bottom: 20px;
        }

        p {
            margin-top: 0;
            margin-bottom: 20px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 24px;
        }
    }

    .close-modal-btn {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.confirmation-modal {
    .modal-dialog,
    .modal-content {
        max-width: 480px;

        .icon-img-holder {
            margin-bottom: 20px;
        }

        .text-p {
            .btn-link {
                position: relative;
                top: -2px;
            }
        }

        .flex-50 {
            max-width: 50% !important;
        }

        .vgradient {
            background: linear-gradient(to top, rgba(247,247,247,1) 0%,rgba(247,247,247,0) 100%);
            margin: -32px -56px;
            padding: 32px 56px;

            @media (max-width: $screen-sm) {
                margin: -24px;
                padding: 24px;
            }
        }

        .ecommission {
            padding-top: 30px;
            margin: 30px auto 0;

            .img-wrap {
                margin-bottom: 12px;
            }


            @media (max-width: $screen-sm) {
                padding-top: 24px;
                margin: 24px auto 0;
            }
        }

        .btn-min-width {
            min-width: 130px;
        }

        .break-word {
            word-wrap: break-word;
        }
        .member-benefits {
            margin-top: 32px;
            padding-top: 24px;
            border-top: solid 1px #D5DFE3;

            p {
                padding: 0 16px;
                margin-bottom: 0;

                a {
                    cursor: pointer;
                    color: $listreports-blue;
                }
            }

            img {
                min-width: auto;
            }
        }
    }

    .close-button {
        position: absolute;
        top: 14px;
        right: 18px;
        font-size: 32px;
        line-height: 20px;
    }
}

.apa-verify-modal,
.autopilot-verify-modal {
    .modal-dialog,
    .modal-content {
        max-width: 450px;

        .border-me {
            border: 9px solid $ice-ice-baby;
            padding: 15px;
            margin-right: -24px;
            margin-left: -24px;
        }
    }
}

.get-it-now-modal,
.get-it-now-select-photos-modal {
    .modal-dialog,
    .modal-content {
        max-width: 450px;
    }

    .icon-wrap {
        margin-bottom: 14px;
    }
}

.autopilot-active-listings-modal {
    .modal-dialog,
    .modal-content{
        max-width: 450px;
    }

    .modal-content {
        padding: 32px 42px;
    }
}

.order-prints-modal-wrap, .update-stale-report-modal-wrap.wide {
    z-index: 10000 !important;

    @media (min-width: $screen-md-min) {
        .modal-dialog,
        .modal-content {
            width: 1056px;
        }
    }
}

.padded-modal {
    background: transparent !important;

    .close {
        font-size: 25px;
        line-height: 1;
        color: #fff;
        z-index: 10000;
        position: absolute;
        right: 10px;
        top: 10px;
        font-weight: bold;
        opacity: 1;

        &:hover {
            opacity: .7;
        }
    }

    .modal-dialog {
        @media (max-width: $screen-xs-max) {
            margin: 10px;
        }
    }

    .modal-content {
        border: none;
    }

    header {
        position: relative;
        background: url('/images/confirmation-modal-header-bg.jpg') no-repeat center center;
        background-size: cover;
        height: 145px;

        label {
            padding: 10px 30px;
            color: #000;
            background: #fff;
            font-size: 32px;
            font-weight: normal;

            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }
        }
    }

    .pad-content {
        padding: 40px 60px;

        @media (max-width: $screen-xs-max) {
            padding: 20px 30px;
        }
    }

    @media (max-width: $screen-xs-max) {
        background: #fff;
        font-size: 16px;
    }
}

.transparent-modal {
    .modal-content {
        background: transparent;
        box-shadow: none;
        border: 0;
        color: #fff;
    }

    .modal-dialog {
        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.image-carousel {
    .close {
        cursor: pointer;

        @media (max-width: $screen-xs-max) {
            padding: 10px;
            padding-bottom: 0;
        }
    }

    .img-holder {
        img {
            display: inline-block;
        }
    }
}

.profile-incomplete-modal {
    .lr-stack {
        i {
            color: #fff;
        }

        i:first-child {
            color: $listreports-blue;
        }

        [class^="ls-icon-"], [class*=" ls-icon-"] {
            top: 9px;
        }

        margin-bottom: 20px;
    }

    p {
        padding-left: 50px;
        padding-right: 50px;

        a {
            color: #666b6d;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .btn-wrap {
        padding: 0 50px;

        .btn:first-child {
            @media (max-width: $screen-xs-max) {
                margin-bottom: 10px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
        }
    }

    fancy-checkbox {
        @media (max-width: $screen-xs-max) {
            display: block;
            margin: 0 auto;
            padding: 0 15px;
        }
    }
}

.assign-rusc-modal {
    header {
        position: relative;
        background: url('/images/confirmation-modal-header-bg.jpg') no-repeat center center;
        background-size: cover;
        height: 145px;

        label {
            padding: 10px 30px;
            color: #000;
            background: #fff;
            font-size: 32px;
            font-weight: normal;

            @media (max-width: $screen-xs-max) {
                font-size: 22px;
            }
        }

        .fa-stack {
            i {
                color: $brand-primary;
            }

            i:first-child {
                color: #fff;
            }

            [class^="ls-icon-"], [class*=" ls-icon-"] {
                top: 9px;
            }
        }
    }

    .title {
        text-transform: uppercase;
        font-size: 18px;
        color: $brand-primary;
        padding-left: 25px;
        padding-right: 25px;
    }

    p,
    form {
        padding-left: 50px;
        padding-right: 50px;

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .hints {
        font-size: 14px;
        margin-bottom: 25px;

        ul {
            list-style: disc;
            margin-left: 15px;
        }
    }
}

.follow-top-agents {
    padding: 25px 0;
    // font-size: 20px;

    .close-button {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .lr-stack {
        i {
            color: #fff;
        }

        i:first-child {
            color: $listreports-blue;
        }

        [class^="ls-icon-"], [class*=" ls-icon-"] {
            top: 9px;
        }
    }

    .title {
        font-size: 28px;
        padding-left: 35px;
        padding-right: 35px;
        margin-top: 15px;
        line-height: 1.2;

        @media (max-width: $screen-xs-max) {
            font-size: 21px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    p,
    form {
        padding-left: 50px;
        padding-right: 50px;

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .button-group {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 44px;
        }
    }

    @media (max-width: $screen-xs-max) {
        font-size: 14px;
    }
}

.archive-report-confirmation {
    p {
        padding-left: 50px;
        padding-right: 50px;

        a {
            color: #666b6d;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .btn-wrap {
        padding: 0 50px;

        .btn:first-child {
            @media (max-width: $screen-xs-max) {
                margin-bottom: 10px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
        }
    }

    fancy-checkbox {
        @media (max-width: $screen-xs-max) {
            display: block;
            margin: 0 auto;
            padding: 0 15px;
        }
    }
}

.archive-confirmation {
    h2 {
        font-size: 18px;
    }

    .btn {
        margin: 10px;

        @media (max-width: $screen-sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    p {
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: $screen-xs-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.preview-offer-modal-wrap {
    .modal-dialog {
        @media (min-width: $screen-sm-max) {
            width: 700px;
        }
    }
}

.preview-enroll-modal-wrap {
    .modal-dialog {
        @media (min-width: $screen-sm-max) {
            width: 700px;
        }
    }
}

.route-relationships-viewlead.modal-open.md-datepicker-is-showing,
.route-relationships-newlead {
    .md-datepicker-calendar-pane {
        .md-datepicker-input-mask {
            display: none;
        }
    }
}

.view-material-modal {
    .modal-dialog {
        width: 95%;
        height: 100%;
        overflow: hidden;
        margin: 60px auto;

        @media (max-width: $screen-md) {
            margin: 0;
            overflow: auto;
            width: 100%;
            height: 100%;
        }
    }
    .material-asset-close--btn-wrap{
        @media (max-width: $screen-sm) {
            display: none;
        }
    }


    .modal-content {
        padding: 0;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        border: none;

        .logo-holder,
        .breadcrumbs {
            display: none;
        }

        .close-btn-wrap {
            position: absolute;
            top: 20px;
            right: 20px;

            .lr {
                font-size: 20px;
                text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);

                @media (max-width: $screen-xs-max) {
                    color: $listreports-grey;
                }
            }

            @media (max-width: $screen-xs-max) {
                top: 15px;
                right: 15px;
            }
        }

        .mobile-header .ctas {
            @media (max-width: $screen-xs-max) {
                padding-top: 50px !important;
            }
        }

        .download {
            padding: 60px 20px 280px;
        }

        @media (max-width: $screen-xs-max) {
            overflow: auto;
            height:  100%;
        }
    }
}

.iframe-modal {
    .modal-content {
        padding: 30px 0 0;

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            @media (max-width: $screen-xs-max) {
                padding: 10px;
                padding-bottom: 0;
            }
        }

        iframe {
            display: block;
            width: 100%;
            height: 500px;
        }
    }
}

.view-nhd-modal,
.view-pdf-material-modal {
    .modal-dialog {
        width: 100%;
        padding: 0 25px;

        @media (max-width: $screen-xs-max) {
            padding: 0;
            width: auto;
        }
    }

    .modal-content {
        position: relative;
        padding: 0;
        background-color: #525659;

        .close {
            position: absolute;
            top: 10px;
            right: 15px;
            color: #fff;
            cursor: pointer;
            text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);

            @media (max-width: $screen-xs-max) {
                padding: 10px;
                padding-bottom: 0;
            }
        }

        .mobile-header {
            .logo-holder {
                padding: 15px;
                border-bottom: 1px solid $smoke;
            }

            .ctas {
                padding: 15px;

                a {
                    margin-right: 15px;
                    padding-left: 0;
                    padding-right: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .sidebar {
            width: 200px;
            background: #fff;

            .material-name {
                background: #f2f2f2;
                padding: 10px;
            }

            .body {
                padding: 15px;
            }

            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }

        .pdf-viewer {
            position: relative;
            -webkit-overflow-scrolling: touch;
  	        overflow-y: hidden;

            iframe {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding-top: 40px;
            }
        }
    }
}

@import './copy-text/styles';
@import './error-modal/styles';
@import './paywall-modal/styles';
@import './image-preview-modal/styles';
@import './edit-agent-modal/styles';
@import './get-it-now-modal/styles';
@import './photo-picker-modal/styles';
@import './offer-sent-confirmation-modal/styles';
@import './order-prints-modal/styles';
@import './update-stale-report-modal/styles';
@import './text-drip-alert-select-modal/styles';
@import './webinar-select-modal/styles';
@import './preview-enroll-modal/styles';
@import './preview-offer-modal/styles';
@import './invite-sent-confirmation-modal/styles';
@import './additional-profile-info/styles';
@import './autopilot-active-listings-modal/styles';
@import './video-modal/styles';
@import './deactivate-user/styles';
@import './resubscribe-modal/styles';
@import './marketing-area-select-modal/styles';
@import './invite-agent-modal/styles';
@import './invite-status-modal/styles';
@import './invite-sent-call-log-modal/styles';
@import './fullscreen-carousel/styles';
@import './unsubscribe-all-modal/styles';
@import './purchase-buyside-credits-modal/styles';
@import './create-drip-modal/styles';
@import './value-props-modal/styles';
@import './flyer-preview-modal/styles';
@import './daily-unsubscribe-survey-modal/styles';
@import './manage-subscription-modal/styles';
@import './order-addon-subscription-modal/styles';
@import './set-password-modal/styles';
@import './cba-sweepstakes-modal/styles';
@import './waae-confirmation-modal/styles';
@import './waae-success-modal/styles';
@import './view-material-modal/styles';
@import './accept-invite-success-modal/styles';

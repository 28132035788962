.select-lender-modal {
    // min-height: 400px;

    .title {
        text-transform: uppercase;

        @media (max-width: $screen-sm) {
            font-size: 28px;
        }

        @media (max-width: 320px) {
            font-size: 22px;
            margin-bottom: 0;
        }
    }

    h2 {
        margin: 0 10px 10px;
        color: $listreports-blue;
        font-weight: bold;
    }

    // p {
    //     margin-bottom: 2px;
    // }

    button {
        margin-top: 0;
        text-transform: uppercase;
        min-width: 100px;
    }

    &.lg {
        h2 {
            font-size: em(34);
        }
    }

    .lender-search {
        margin: 20px 30px;
        position: relative;

        .search {
            position: relative;
            margin-top: 15px;
            padding: 0;
        }

        button {
            margin-top: 0;
            text-transform: uppercase;
        }

        .icon {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 10px;
            color: #ddd;
        }

        .search-result {
            color: $defaultFontColor;
            margin-top: -1px;
            border-style: solid;
            border-color: #ddd;
            border-width: 1px;
            padding: 10px 20px;
            background: #fff;

            .info {
                @media (max-width: $screen-sm) {
                    max-width: 60%;
                }
            }

            .text {
                padding-right: 20px;
            }

            img {
                max-width: 60px;
                margin-right: 20px;
            }

            &.invite {
                background: #ddd;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &:focus {
                outline: 0;
            }
        }

        @media (max-width: $screen-sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .selected-vendor {
        color: $defaultFontColor;
        img {
            max-width: 120px;
            margin-right: 30px;
        }

        .info {
            text-transform: uppercase;
        }

        > div {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        label {
            color: $defaultFontColor;
        }
    }
}

.resources-university {
    margin: 0 auto 20px;

    .card-container {
        margin: 10px;
    }

    .card-wrapper {
        width: 33.3%;
        padding: 10px;
        @media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
            width: calc(50% - 10px);
        }

        @media (max-width: $screen-sm) {
            width: 100%;
        }
    }

    .header {
        padding: 26px;
        border-bottom: 1px solid $ice-ice-baby;
    }

    .university-info-header {
        text-align:left;
        padding-left:10px;
        align-items: start;
    }
}

@import '../../../styles/colors';
@import '../bootstrap/variables';

vendors-reports,
agents-reports {
    $noReportsMargin: 35;

    display: block;

    header {
        padding: 15px;
        font-weight: normal;
        border-bottom: 1px solid $ice-ice-baby;

        @media (max-width: $screen-sm-max) {
            border-bottom: 0;
        }
    }

    .search-wrap {
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }

    .mobile-click-icon-holder {
        i {
            position: relative;
        }

        .lr-gear {
            right: -10px;
        }

        .lr-angle-right {
            right: -7px;
        }
    }

    .md-avatar {
        $avatarSize: 65px;

        width: $avatarSize;
        height: $avatarSize;
        margin-top: 0;
        margin-bottom: 0;
    }

    tr {
        outline: none;
    }

    th,
    td {
        vertical-align: middle !important;
    }

    th {
        border-top: 0 !important;
        border-bottom: 1px solid $ice-ice-baby;
        color: $defaultFontColor;
        font-size: 14px;
    }

    td {
        padding: 15px 8px;
        font-size: 14px;
        border-color: $ice-ice-baby;
    }

    .no-reports {
        padding: $noReportsMargin + px;
        font-size: 24px;
        background: #fff;
        margin-top: 0;

        i {
            color: #349196;
        }

        h1 {
            font-size: 48px;
            font-weight: bold;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        p {
            line-height: 30px;
        }

        .tip {
            margin-left: -$noReportsMargin + px;
            margin-right: -$noReportsMargin + px;
            padding: 13px $noReportsMargin + px;
            background: #349196;
            font-size: 18px;
            margin-top: 15px;
        }

        @media (max-width: $screen-xs-max) {
            margin-top: 10px;
        }
    }

    .not-available {
        td {
            $opacity: .5;

            color: rgba(118, 136, 143, $opacity) !important;

            i,
            img {
                opacity: $opacity;
            }

            .btn {
                opacity: $opacity;
                border-color: $listreports-gray;
                color: $listreports-gray;

                i {
                    opacity: 1;
                }
            }
        }

        &.inactive {
            i,
            .status-badge,
            .btn {
                opacity: 1;
            }
        }
    }

    .status {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 5px 15px 5px 0;
        border-radius: 50%;

        &.accepted {
            background: #7ba915;
        }

        &.not-accepted {
            background: #9e9f9e;
        }

        &.archived {
            background: $defaultFontColor;
        }
    }

    .loading {
        @media (max-width: $screen-sm-max) {
            border-top: 1px solid #e0e0df;
        }
    }

    .under-review {
        height: 1px;
        min-height: 500px;
        padding: 15px;

        h2 {
            margin: 10px 0;
        }

        @media (max-width: $screen-xs-max) {
            border-top: 1px solid $ice-ice-baby;
            min-height: 500px;
        }
    }

    .new-label {
        background-color: $listreports-gold;
        text-align: center;
        color: white;
        height: 20px;
        margin-top: 8px;

        @media (min-width: $screen-xs-max) {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 30px;
            height: 100%;
            margin-top: 0;
            background-image: url('/images/new_label_rotated.png');
            background-position: center center;
            background-repeat: no-repeat;
        }

        .new-label-copy {
            margin-top: 2px;
            display: inline-block;
        }
    }

    .table td.avatar-holder {
        position: relative;

        @media (min-width: $screen-xs-max) {
            padding-left: 20px;
        }
    }

    .checkmark {
        font-size: 16px;
        vertical-align: bottom;
        @media(min-width: $screen-xs-max){
            font-size: 20px;
            vertical-align:middle;
        }

        font-weight: 700;
    }

    .reports-listing-mobile {
        li {
            margin-bottom: 5px;
        }
    }
}

agents-reports {
    .avatar-holder {
        img {
            max-width: 135px;

            @media (max-width: $screen-xs-max) {
                max-width: 110px;
            }
        }

        .new-label {
            margin-top: 0;
        }
    }
}

.webinar-registration {
    padding: 75px;
    font-size: 16px;

    .headshot-wrap {
        img.img-circle {
            height: 80px;
        }
    }

    &.guest {
        padding: 0;

        header {
            margin-bottom: 30px;

            h2 {
                margin: 0 auto;
            }

            .headshot-wrap {
                margin: 0 auto 30px;
            }

            .separator {
                width: 37px;
                height: 3px;
                margin: 24px auto 0;
                background-color: $ice-ice-baby;
            }
        }

        .registration-form-wrap {
            .caption {
                margin: 0 auto 30px;
            }
        }

        .schedule-selection {
            h3 {
                margin-bottom: 12px;
            }
            label {
                max-width: 360px;
                margin: 0 auto;

            }
            input.form-control[type='radio'] {
                max-width: 20px;
                height: 34px;
                margin: 0;
            }
        }
    }

    &.signup, &.loggedin {
        padding: 0 25px;

        header {
            margin-bottom: 40px;
        }

        .hsub {
            margin: 20px auto 0;
        }

        .headshot-wrap {
            margin: 0 auto;
            max-width: 360px;
            padding-bottom: 20px;
            background-image: url('/images/webinar/shadow-kebab-skewer.png');
            background-repeat: no-repeat;
            background-position: center 40px;
            background-size: 50%;
        }

        .schedule-title {
            margin: 0 auto 30px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 0;
        }
    }

    header {
        border: 0;

        p {
            margin: 0;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 15px;
        font-size: 14px;
    }
}

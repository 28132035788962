follow-plus-credits-pill {
    .credits-pill {
        margin-left: 16px;

        h3 {
            margin-left: 5px;
        }

        .pill-label {
            position: relative;
            top: -2px;
        }

        @media (max-width: $screen-xs-max) {
            margin-left: 0;
        }
    }

    .buy-button-holder {
        margin-left: 16px;
    }
}

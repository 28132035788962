transactions-dashboard-header-widget {
    display: block;

    > .section-group > div {
        position: relative;
        padding: 25px;
        border-right: 1px solid $ice-ice-baby;

        &:last-child {
            border: 0;
        }

        h1 {
            margin: 0;
        }

        @media (max-width: $screen-xs-max) {
            border-bottom: 1px solid $ice-ice-baby;
            border-right: 0;

            &:last-child {
                border: 0;
            }
        }
    }

    .icon-holder {
        h1 {
            font-size: 44px;
        }
    }

    .paired-agents {
        .ctas {
            .btn-link {
                &:after {
                    content: '|';
                    color: $ice-ice-baby;
                    display: inline-block;
                    margin-left: 3px;
                    position: relative;
                    top: 0;
                }

                &:last-child:after {
                    content: none;
                }
            }
        }
    }

    .loading-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
    }
}

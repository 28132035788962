avatar {
    .avatar {
        color: #fff;
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        background: $smoke;

        .md-avatar {
            width: 50px;
            height: 50px;
            margin: 0;
            border: none;
        }
    }

    &[size="large"] {
        $size: 65px;

        .avatar {
            width: $size;
            height: $size;

            .md-avatar {
                width: $size;
                height: $size;
            }

            h3 {
                font-size: 22px;
            }
        }
    }

    &[size="medium"] {
        $size: 35px;

        .avatar {
            width: $size;
            height: $size;

            .md-avatar {
                width: $size;
                height: $size;
            }

            h3 {
                font-size: 12px;
            }
        }
    }

    &[size="small"] {
        $size: 25px;

        .avatar {
            width: $size;
            height: $size;

            .md-avatar {
                width: $size;
                height: $size;
            }

            h3 {
                font-size: 10px;
            }
        }
    }
}

.autocomplete-search {
    .formsearch {
        margin-bottom: -9px;

        .btn {
            height: 40px;

            @media (max-width: $screen-xs-max) {
                // height: 36px;
            }
        }
    }
}

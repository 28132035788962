.my-lender {
    max-width: 1400px;

    .subsection {
        border: 1px solid #e0e0df;
        background: #fff;
    }

    .agent-plo-info {
        margin-right: 15px;
        max-width: 320px;
        min-width: 320px;
        // min-height: 460px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: 320px;
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 0;
            margin-bottom: 10px;
            max-width: 100%;
        }

        &.no-lender.no-invited {
            background-color: transparent;
            border: 2px dashed $defaultfontcolor;

            @media (max-width: $screen-xs-max) {
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }

    .user-info {
        margin-right: 10px;
        padding: 25px 15px;

        .text {
            font-size: 16px;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }

        h2 {
            margin-top: 16px;
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            // margin-top: 20px;
            margin-top: 0;
            margin-bottom: 0;
        }

        .separator {
            width: 65px;
            border-bottom: 2px solid $ice-ice-baby;
            height: 0;
            margin: 20px auto;
        }

        .btn-change-lender {
            margin-top: 18px;
        }

        .mobile-contact-info {
            margin-top: 15px;
            padding: 15px;
            border-top: 1px solid #fff;

            > div {
                text-align: center;

                &:last-child {
                    text-align: right;
                }

                &:first-child {
                    text-align: left;
                }

                .ls-icon-mail {
                    top: -3px;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 18px;
                }
            }
        }

        .headshot {
            max-width: 100px;
        }

        .buttons-holder {
            .btn {
                i {
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .no-user-info {
        h2 {
            margin: 15px 0 0;
        }

        .caption {
            margin: 4px 0 24px;
            font-size: 16px;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }

        .btn-change-lender {
            margin-bottom: 15px;
        }
    }

    .my-lender-checklist {
        // IE Hack Changes
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height: 460px;
        }
    }

}

@import './my-lender-checklist/styles';
@import '../banners/invited-lender-banner/styles';
@import './select-lender-banner/styles';

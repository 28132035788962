settings-notifications {
    display: block;
    padding-top: 32px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;

    @media screen and (min-width: $screen-sm-min){
        padding-top: 42px;
        padding-right: 42px;
        padding-bottom: 42px;
        padding-left: 42px;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .aliases {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            padding: 0 8px;
            color: $defaultFontColor;
            padding: 10px 0;
            border-top: 1px solid $smoke;
            border-bottom: 1px solid $smoke;
            margin-bottom: -1px;
        }

        .caption {
            margin: 14px 0 12px;
        }
    }

    .notify-column {
        padding-bottom: 24px;
        border-bottom-width: 1px;

        @media (min-width: $screen-sm-min) {
            padding-right: 42px;
            padding-bottom: 0;
            border-bottom-width: 0;
            border-right-width: 1px;
        }
    }

    .aliases-column {
        padding-top: 24px;

        h2 {
            margin-top: 0;
        }
    
        @media (min-width: $screen-sm-min) {
            padding-left: 36px;
            padding-top: 0;
        }
    }

    .notifify-me-about {
        h2 {
            margin-bottom: 0;
        }

        .notification-options {
            > div {
                padding: 10px 0;
                border-bottom: 1px solid $smoke;

                @media (max-width: $screen-xs-max) {
                    margin-right: -25px;
                    margin-left: -25px;
                    padding: 15px 25px;
                }
            }

            .final-notification-options {
                border-bottom: none;
            }

            .cbox {
                @media (min-width: $screen-sm) {
                    width: 45px;
                    text-align: center;
                }

                @media (max-width: $screen-xs-max) {
                    margin-top: 10px;
                    margin-right: 10px;
                }

                .fancy-checkbox {
                    @media (min-width: $screen-sm) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .marketing-zip-form {
        .subtitle {
            margin-bottom: 5px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 15px;
            }
        }

        .form-group {
            max-width: 320px;
        }
    }

    .lr-exclamation-triangle {
        top: 1px !important;
    }
}



.notify-settings {
    h2 {
        margin: 0;
    }

    .alert-settings {
        margin-top: 16px;
        @media (min-width: $screen-sm) {
            margin-top: 21px;
        }
    }

    .alert-setting-container {
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom-width: 1px;

        .setting-column {
            text-align: center;
            width: 50px;
            font-weight: bold;
        }
    }

    .setting-wrapper{
        margin-top:24px;
    }

    .subscription-container {
        margin-top: 24px;

        .subscription-manage {
            margin-top: 32px;
        }
    }

    .external-unsubscribe-container {

        .checkbox-container {
            margin-bottom: 32px;
        }

        margin: auto;
        max-width: 480px;
        padding: 16px 0;
    }
}

@import './alert-setting/styles';
@import './frequency-alert-setting/styles';

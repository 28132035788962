new-lead {
    .new-lead {
        max-width: 1000px;
    }

    form {
        display: block;
        max-width: 680px;
        margin: 30px auto 0;

        @media (max-width: $screen-xs-max) {
            margin: -25px;
        }
    }

    .buttons-holder {
        margin: 25px 0 15px;

        @media (max-width: $screen-xs-max) {
            margin-right: 20px;
            margin-left: 20px;
        }
    }

    .collapsable-section {
        .cs-header {
            background: #e0e0df;
        }

        .cs-bullet {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: $listreports-blue;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            border-radius: 50%;
            margin-right: 20px;
            color: #fff;
        }
    }

    .abnlisting {
        position: relative;
        background: #fff;
        border: 1px solid $ice-ice-baby;
        border-bottom: 0;
        margin-bottom: -1px;
        z-index: 2;

        .hero {
            display: block;
            width: 140px;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 20px;

            @media (max-width: $screen-xs-max) {
                width: 116px;
                margin-right: 10px;
            }
        }

        .listing-info {
            .inline-listing-details {
                > div {
                    border-right: 1px solid $smoke;
                    height: 10px;
                    line-height: 8px;
                    padding-right: 5px;
                }

                > div:last-child {
                    border-right: 0;
                }
            }

            @media (max-width: $screen-xs-max) {
                padding: 15px;
            }
        }

        .listing-details {
            .box {
                min-width: 106px;
                padding: 20px 15px;
                border-left: 1px solid $smoke;

                .lr-2x {
                    font-size: 1.8em;
                }
            }
        }

        small {
            font-size: 10px;
        }

        .vgradient {
            height: 42px;
            background: rgb(255,255,255);
            background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(247,247,247,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 );
        }

        .subtitle {
            @media (max-width: $screen-xs-max) {
                margin-top: -20px;
                font-size: 14px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding-bottom: 20px;
        }
    }

    .section-group {
        .subtitle {
            max-width: 585px;
            margin: 0 auto;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
                max-width: 100%;
                padding-bottom: 50px;
            }
        }
    }

    .select-lender-modal .lender-search {
        margin: 0;

        form {
            margin-top: 0;
        }
    }

    .just-listed-alert {
        .form-control {
            width: 175px;
        }
    }
}

// .route-relationships-newlead {
//     .md-datepicker-calendar-pane.md-pane-open {
//         left: 5%;
//     }
// }

fancy-checkbox {
    &:focus,
    *:focus {
        outline: none !important;
    }

    &[disabled] {
        .fancy-checkbox, .fancy-checkbox-v2 {
            border-color: #eee;
        }

        .fancy-checkbox, .fancy-checkbox-v2 {
            cursor: default;
        }

        .fancy-checkbox-label {
            color: #ddd;
        }

        .fancy-checkbox-label-v2 {
            color: $giggity;
        }
    }

    &[disabled][disabled-style="grey"] {
        .fancy-checkbox {
            border-color: #D2D2D2;
            background-color: #EAECEB;
        }
        .fancy-checkbox-v2 {
            background-color: rgba(196, 196, 196, 0.6);
            border-color: #ccc;
        }

        .fancy-checkbox, .fancy-checkbox-v2 {
            cursor: default;
        }

        .fancy-checkbox-label {
            color: $defaultSecondaryTextColor;
        }

        .fancy-checkbox-label-v2 {
            color: $giggity;
        }
    }
    
}

.fancy-checkbox {
    @include border-top-radius(2px);
    @include border-bottom-radius(2px);
    border: 1px solid #ccc;
    padding: 2px 4px;
    display: inline-block;
    box-shadow: none;
    cursor: pointer;
    margin-right: 8px;
    width: 18px;
    min-width: 18px;
    height: 18px;

    .icon {
        opacity: 0;
        color: $listreports-blue;
        font-size: 9px;
        position: relative;
        top: 2px;
    }
}

.fancy-checkbox-v2 {
    @include border-top-radius(2px);
    @include border-bottom-radius(2px);
    border: 1px solid #ccc;
    padding: 2px 4px;
    display: inline-block;
    box-shadow: none;
    cursor: pointer;
    margin-right: 8px;
    width: 24px;
    min-width: 24px;
    height: 24px;

    .icon {
        opacity: 0;
        color: $giggity;
        font-size: 14px;
        position: relative;
        top: 2px;
    }
}

.fc-checked {
    .icon {
        opacity: 1;
    }
}

.fancy-checkbox-label {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: $screen-sm) {
        font-size: 14px;
    }
}

.fancy-checkbox-label-v2 {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: $screen-sm) {
        font-size: 14px;
    }
}

.has-error {
    .fancy-checkbox {
        border-color: $errorRed;
    }
}

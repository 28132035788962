.account-verification-code {
    padding: 75px;
    font-size: 16px;

    &.signup {
        padding: 0 50px;

        @media (max-width: $screen-xs-max) {
            padding: 0;
        }
    }

    header {
        margin-bottom: 40px;
        border: 0;

        p {
            margin: 0;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }
    }

    .code-input-wrap {
        margin-bottom: 40px;

        input {
            @extend .form-control;
            @extend .bold;

            text-align: center;
            font-size: 30px;
            margin: 0 6px;
            width: 60px;
            height: 60px;
        }

        input.code-input {
            width: 240px;
        }
    }

    .error-box {
        margin-bottom: 20px;
    }

    @media (max-width: $screen-xs-max) {
        padding: 15px;
        font-size: 14px;
    }
}

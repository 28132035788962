@import '../../../styles/colors';
@import '../bootstrap/variables';

activity-feed {
    display: block;

    title-bar {
        padding-left: 0;
        padding-right: 0;
    }

    .content {
        max-width: 875px;
        padding-top: 0;

        &.standalone {
            margin: 0 auto;
            padding-top: 60px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px 15px;
            margin: 0;
        }
    }

    .shareable-image-container {
        width: 160px;
        text-align: center;
        cursor: pointer;

        @media (max-width: $screen-xs-max) {
            text-align: left;
            margin-top: 20px;
        }

        .shareable-image {
            @media (max-width: $screen-xs-max) {
                max-height: 60px;
            }

            @media (min-width: $screen-xs-max) {
                max-width: 125px;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }

    .subheading {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 12px;
        }

        h3 {
            font-size: 22px;
        }
    }

    .body {
        overflow-wrap: break-word;
        a {
            white-space: unset;
            display: inline;
        }
    }

    .my-agents-stats-wrap {
        .subheading {
            margin-bottom: 20px;
        }
    }

    .utils {
        margin-bottom: 20px;

        .filter-wrap {
            select {
                margin: 0;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 50px;
        }
    }

    .ball-wrapper {
        display: inline-block;
        margin-right: 30px;
        margin-left: -10px;
        position: relative;
        top: -6px;

        .ball {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 2px;
            position: relative;
            top: 5px;
            margin-right: 2px;

            &.yellow {
                background: $listreports-blue;
            }

            &.green {
                background: $listreports-green;
            }

            @media (max-width: $screen-xs-max) {
                width: 14px;
                height: 14px;
                top: 3px;
            }
        }

        @media (max-width: $screen-xs-max) {
            margin-right: 20px;
            margin-left: 0;
        }
    }

    .feed {
        margin: 0 auto;

        .load-queued-wrapper {

            .btn-blue-solid {
                text-transform: none;
            }

            @media (max-width: $screen-xs-max) {
                padding-left: 0;
                margin-bottom: 25px;
            }
        }

        .group {
            &.first {
                border-top: 1px solid #e0e0df;
            }
        }

        .date {
            pointer-events: none;
            position: relative;
            font-size: 12px;
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: bold;

            > div {
                display: inline-block;
                transform: translateX(-50%);

                @media (max-width: $screen-xs-max) {
                    transform: translateX(0);
                    transform: translateY(-50%);
                    padding: 3px 10px;
                }
            }

            @media (max-width: $screen-xs-max) {
                text-align: center;
                height: 1px;
                border-bottom: 0;
                margin: 18px 0;
            }
        }

        .card-wrapper {

            > div {
                width: 100%;
            }

            .user-icon {
                width: 48px;
                margin-right: 20px;

                img {
                    display: inline-block;
                    position: relative;
                    width: 45px;
                    height: 45px;
                }

                .initials {
                    position: relative;
                    width: 45px;
                    height: 45px;
                    color: #fff;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 18px;
                }
            }

            .icon {
                margin-right: 15px;
                width: 20px;
            }

            .card {
                padding: 24px 24px 24px 24px;
                background-color: #fff;
                border: 1px solid #e0e0df;
                border-top: none;

            }

            .avatar-label {
                margin-top: 4px;
                font-size: 11px;
                padding: 2px 4px 1px;
                border-radius: 2px;

                &.paired {
                    background-color: $listreports-blue;
                    color: #fff;
                }
            }

            &.sparkle {
                .card {
                    background-image: url('/images/activity-feed/sparkle-desktop.png');
                    background-size: contain;
                    background-position: left;
                    background-repeat: no-repeat;

                    @media (max-width: $screen-xs-max) {
                        background-image: url('/images/activity-feed/sparkle-mobile.png');
                    }
                }
            }

            .when {
                margin-top: 4px;
                color: $defaultSecondaryTextColor;
            }

            report-button {
                button {
                    height: 42px;
                    width: 160px;
                }

                .ib {
                    @media(max-width: $screen-xs-max) {
                        display: block;
                        width: 100%;
                    }
                }

                .btn .lr-spin {
                    top: 1px;
                }
            }

            .initials {
                background-color: #92a3a8;
            }

            .body-wrap {
                margin-right: 15px;

                @media (max-width: $screen-xs-max) {
                    margin-right: 0;
                }
            }

            &.realtor {
                .card {
                    position: relative;

                    /* &:before {
                        content: '';
                        position: absolute;
                        top: -1px;
                        left: 0;
                        bottom: -1px;
                        width: 4px;
                        background: $listreports-blue;
                    } */
                }

                .icon {
                    color: $listreports-blue;
                }

                .card .body .btn-link {
                    display: inline;
                    white-space: pre-wrap;
                }

                .card .body span {
                    font-weight: bold;
                }

                .secondary-btn-wrap {
                    @media (max-width: $screen-xs-max) {
                        margin-top: 15px;
                    }
                }

                a {
                    font-weight: bold;
                    position: relative;
                    top: -1px;
                }
            }

            &.lead {
                .card {
                    position: relative;

                    /* &:before {
                        content: '';
                        position: absolute;
                        top: -1px;
                        left: 0;
                        bottom: -1px;
                        width: 4px;
                        background: $listreports-green;
                    } */
                }

                .icon {
                    color: $listreports-green;
                }

                .card .body span {
                    font-weight: bold;
                }

                a {
                    font-weight: bold;
                    position: relative;
                    top: -1px;
                }
            }

            @media (max-width: $screen-xs-max) {
                border: 0;
            }
        }

        &.standalone {
            margin-top: -30px;
        }
    }

    .nocolor {
        font-weight: normal !important;
    }

    .no-activities {
        .card-wrapper {
            border: 0;

            .card {
                @media (max-width: $screen-xs-max) {
                    text-align: center;
                }
            }

            .icon {
                width: 101px;
                margin-right: 50px;

                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }

        h2 {
            font-size: 24px;
            line-height: 28px;

            @media (max-width: $screen-xs-max) {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    &.compact {
        .user-icon,
        .subtitle,
        report-button,
        .view-agent-btn,
        .view-property-website-btn,
        .view-report-btn {
            display: none;
        }

        .content.standalone {
            padding-top: 30px;
        }
    }

    @media (max-width: $screen-xs-max) {
        font-size: 14px;
    }
}

.activity-feed {
    &.shareables-image-modal {
        .modal-content {
            padding: 40px;
        }

        i {
            margin: 0;
        }

        .image {
            max-width: 518px;
        }
    }
}

plans-faq {
    display: block;
    width: 250px;
    background: #ededee;
    padding: 25px;

    h1 {
        font-size: 16px;
    }

    p {
        margin-top: -10px;
    }

    @media (max-width: $screen-xs-max) {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        padding: 10px;
    }
}

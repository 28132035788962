


.robin-agent-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .btn[disabled] {
        background-color: #b8b8b8;
        border-color: #b8b8b8;
        color: #ffffff;
    }

    .close-modal-btn{
        right: -41px;

        .btn.btn-link.sidewalk{
            color: #ffffff;
            &:hover{
                color: #ffffff;
            }
        }

        @media(max-width: $screen-xs-max) {

            right: 15px;
            top: 22px;
            z-index: 9999999;
            .btn.btn-link.sidewalk{
                color: #b4bbbf;
                &:hover{
                    color: #b4bbbf;
                }
            }
        }
    }



    .modal-dialog {
        border: none;
        height: 100%;
        margin: 0px auto;

        @media (max-width: $screen-xs-max) {
            overflow-y: scroll;
            width: auto;
        }
    }

    &.standard-modal{
        .modal-content {
            height: 100%;
            padding: 0;
        }
        @media (max-width: $screen-xs-max) {
            border: none;
            box-shadow: none;
        }
    }

    .select-agent-header{
        padding: 0 16px;
        text-align: center;
        border-bottom: 1px solid #D5DFE3;
        margin-bottom: 32px;
        position: relative;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;

        .select-agent-header-prev{
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .select-agent-header-confirm{
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            width: 82px;
            height: 42px;

            .select-agent-header_btn{
                min-width: 83px;
                padding: 0;
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }

        }

        label{
            margin: 0;
        }
    }

    .select-agent-content{
        max-width: 496px;
        margin: 0 auto 32px;
        padding: 0 16px 32px;
    }

    &.standard-modal .select-agent-title{
        font-size: 28px;
        line-height: 30px;
        margin: 0 0 16px;
        text-align: center;
    }

    .select-agent-desc{
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 24px;
        padding: 0 16px;
        text-align: center;
    }

    .select-agent-box{
        width: 100%;
        border: 1px solid #D5DFE3;
        border-radius: 4px;
        padding: 16px 16px 0;
        display: flex;
        position: relative;
        margin-bottom: 24px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .select-agent-details{
        display: flex;
        margin-bottom: 16px;
    }

    .select-agent-img{
        width: 60px;
        height: 60px;
        margin-right: 16px;
    }

    .select-agent-robin{
        font-size: 10px;
        color: $defaultSecondaryTextColor;
        line-height: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .select-agent-action{
        margin-bottom: 16px;
        .btn-small {
            min-width: 72px;
            padding: 11px 16px;
        }
    }
    .select-agent-check{
        color: $defaultFontColor;
        font-size: 32px;
        margin-bottom: 16px;
    }

    .select-agent-small-text{
        font-size: 12px;
        margin-bottom: 16px;
    }

    .select-agent-img-content{
        position: relative;
        .user-robin {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 43px;
            top: 38px;
            background: url('/images/robin_logo_round.svg');
        }
    }

    .select-agent-detail{
        max-width: 214px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }

    .select-agent-footer{
        padding: 16px;
        border-top: 1px solid #D5DFE3;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
        z-index: 99;
        display: none;
        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    .select-agent-footer_btn {
        min-width: 100%;
    }

    .search-input {
        margin-bottom: 24px;

        @media(max-width: $screen-xs-max) {
            margin-bottom: 16px;
        }
    }

    .select-agent-select-more{
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .search-input{
        position: relative;

        .search{
            position: relative;
            padding-right: 0;
            padding-left: 0;

            .form-control:focus{
                border-color: #d5dfe3;
            }
        }

        .results{
            position: absolute;
            top: 42px;
            left: 0;
            right: 0;
            background: white;
            z-index: 2;
            @media (max-width: $screen-xs-max) {
                top: 36px;
            }
        }

         .search .icon {
            position: absolute;
            top: 50%;
            margin-top: -14px;
            right: 5px;
            color: #ddd;
        }

        .search i.lr {
            position: absolute;
            right: 8px;
            top: 8px;
        }
        .autocomplete-search {
            width: 100%;
        }

        .search-result {
            margin-top: -1px;
            border-style: solid;
            border-color: #d5dfe3;
            border-width: 1px;
            padding: 10px 10px 0;
        }
    }
}

@import '../../../modals/select-robin-agent/_select-robin-agents-styles';

wistia-video-player {
    display: block;

    .video-wrap {
        position: relative;

        .wistia-video-player {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.intro-video {
    padding: 75px;
    font-size: 16px;

    &.signup {
        padding: 0 50px;

        @media (max-width: $screen-xs-max) {
            padding: 0;
        }
    }

    header {
        margin-bottom: 40px;
        border: 0;

        p {
            margin: 0;

            @media (max-width: $screen-xs-max) {
                font-size: 14px;
            }
        }
    }

    .video-player-wrap {
        margin-bottom: 40px;

        #intro_video {
            width: 640px;
            height: 340px;

            @media (min-width: $screen-xs-max) and (max-width: $screen-sm-max) {
                width: 480px;
                height: 265px;
            }

            @media (max-width: $screen-xs-max) {
                width: 300px;
                height: 164px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 15px;
        font-size: 14px;
    }
}

active-buyer-network-property-confirmation {
    display: block;
    max-width: 1000px;

    .header-holder {
        margin-top: 32px;
    }

    abn-header {
        h1 {
            margin-top: 0;
        }
    }

    .content {
        @media (min-width: $screen-md) {
            padding: 60px !important;
        }
    }

    .title {
        margin: 0 0 10px;
    }

    .subtitle {
        display: inline-block;
        max-width: 530px;
        margin: 0 auto;
    }

    .gif-holder {
        img {
            width: 100%;
            max-width: 270px;
        }
    }

    .button-holder {
        margin-top: 20px;
        margin-bottom: 0;

        .btn {
            @media(max-width: $screen-xs-max) {
                display: block;
                width: 100%;
            }
        }
    }
}

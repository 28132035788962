.unsubscribe-all-modal {
    margin: 0 auto;
    text-align: center;

    .text-container {
        margin-bottom: 16px !important;
    }

    .confirm-button-container {
        padding: 16px 0 !important;
    }

    .confirmed-container {
        .confirmed-title {
            font-size: 21px;
        }
    
        .confirmed-text {
            font-size: 16px;
            margin-bottom: 12px;
        }

        .close-button {
            padding: 16px 0;
        }
    }


}

nestme-share-engagement {
    display: block;

    > section {
        @extend .subsection;

        position: relative;
    }

    .nodata {
        color: $ice-ice-baby;

        label {
            color: $ice-ice-baby;
        }

        .lr {
            margin-bottom: 10px;
        }
    }

    .stat-holder {
        position: relative;
        border-right: 1px solid #e0e0df;

        &:last-child {
            border-right: 0;
        }

        .stat {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 32px;
            font-weight: bold;

            label {
                font-size: 18px;

                @media (max-width: $screen-xs-max) {
                    font-size: 14px;
                    margin-bottom: -5px;
                }
            }

            @media (max-width: $screen-xs-max) {
                font-size: 24px;
            }
        }
    }

    .relative {
        position: relative;
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .90);
        text-align: center;
    }

    .no-data {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .90);
        text-align: center;
    }
}

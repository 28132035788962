.login-idp {
    h1 {
        font-size: 24px;
        margin-bottom: 5px;

        @media (max-width: $screen-sm) {
            font-size: 18px;
        }
    }

    h3 a {
        @media (max-width: $screen-sm) {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .footer-text {
        font-size: 14px;
    }

    .submit-btn{
        width: 98px;
        height: 48px;
        border-radius: 2px;
        color:#FFF!important;
        font-weight: bold!important;
    }

    .submit-btn:hover{
        background-color: #6C41B5;
    }

    .sign-up-btn{
        color:#0D2121;
        font-weight: 500;
        font-size: 16px;
    }

    .form-control:focus{
        border-color: #0D2121;
    }

    .link-hover:hover{
        color:#6C41B5!important;
        a:hover{
            color:#6C41B5!important;
        }
    }

    .highway-link{
        color:#6C41B5;
        cursor: pointer;
        font-weight: bold;
        a:hover{
            color:#6C41B5;
        }
    }

    .highway-error-box {
        display: block;
        margin: 0 0 16px;
        padding: 16px 0 0;
    }
}



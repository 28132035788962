.loan-office-card  {
    padding-top: 32px;
    height: 100%;

    .bar {
        width: 25px;
        // height: 2px;
        // background: $listreports-blue;
        margin: 12px auto;
        border: 1px solid #D5DFE3;
    }

    .card {
        background: #FFF;
        width: 232px;
        border: 1px solid #D5DFE3;
        border-radius: 6px;
        padding-bottom: 16px;
        padding-left: 8px;
        padding-right: 8px;
        
        .name {
            h3 {
               margin: 0px 0 4px 0px
            }
        }

        .instant-access {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 4px;
                font-size: 16px;
            }
        }

        p {
            margin: 0;
        }

        .avatar {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
            left: 0;
            right: 0;
            margin: 0 auto;
            margin-top: -32px;

            img {
                width: 64px !important;
                height: 64px !important;
            }
        }

        .action {
            margin-top: 12px;
            text-align: center;
        }

    }

}
.resources-university-card {
    height: 100%;
    width: 100%;

    .card {
        border: 1px solid $ice-ice-baby;
        height: 100%;

        .hero-container {
            position: relative;
            width: 100%;
        }

        .hero-container:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }

        img {
            position:  absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .body {
            padding: 20px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {

                .headline {
                    font-weight: bold;
                    color: $defaultFontColor;
                    margin-bottom: 0;
                    text-transform: uppercase;
                }

                .title {
                    line-height: 23pt;
                }

                .subtitle {
                    margin: 0;
                    font-weight: 500;
                }
            }

            .separator {
                width: 37px;
                height: 3px;
                margin: 20px auto;
                background-color: $listreports-blue;
            }

            .action {
                padding: 20px 0px;
            }

            .register-btn {
                padding-top: 20px;
            }

        }

        .footer {
            padding: 0px 20px 20px 20px;
            font-size: 10px;
            text-transform: uppercase;
            text-align:right;
        }

    }

}

.loan-scenario-rejected {
    .container {
        padding: 100px;

        @media (max-width: $screen-sm) {
            padding: 80px 20px;
        }
    }

    .row.text {
        max-width: 600px;
        margin: auto;
    }

    p {
        font-size: 16px;
    }

    .lr-circle {
        color: $listreports-red;
    }

    .lr-times {
        color: #fff;
    }
}

rusc-locating {
    line-height: 1.2;

    h1 {
        font-size: 36px;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;

        @media (max-width: $screen-xs-max) {
            font-size: 24px;
        }
    }

    p {
        margin: 0;
    }

    .subheader {
        font-size: 28px;
        margin: 0;
        padding: 0;

        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }
    }

    .icon {
        // margin: 75px 0 0;

        img {
            width: 168px;

            @media (max-width: $screen-xs-max) {
                width: 111px;
            }
        }
    }

    .btn-link {
        font-size: 18px;
    }
}

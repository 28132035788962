.create-password {
    margin-top: -20px;
    p {
        font-size: 18px;
        max-width: 475px;
        margin: auto;

        @media (max-width: $screen-sm) {
            max-width: 100%;
            font-size: 16px;
        }
    }
    form {
        margin: 12px auto;
    }

    .user-data {
        margin: 36px auto 48px;
        .headshot-wrap {
            padding-right: 14px;
        }
        .headshot {
            min-width: 100px;
            max-width: 100px;
            min-height: 100px;
            max-height: 100px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .details-wrap {
            font-size: 16px;
            &.pad-left {
                padding-left: 14px;
            }
        }
        .name {
            font-size: 20px;
        }

        .vendor-logo-wrap {
            margin-top: 8px;
            overflow: hidden;
        }

        .vendor-logo {
            height: 45px;
        }
    }

    .hsubtitle {
        padding-bottom: 36px;
    }

    .continue-btn {
        padding-left: 16px;
        padding-right: 16px;
        min-width: 0;
        width: 120px;
    }

    .label {
        margin: 0;
        font-size: 14px;

        a {
            font-size: 14px;
        }
    }

    .checkbox-container {

        .error-box {
            margin: 0;
            margin-top: 1px;
            font-size: 12px;
            max-width: 100%;
        }
    }
}

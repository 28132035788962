@import '../../../../styles/colors';
@import '../../bootstrap/variables';

agent-nestme-banner {
    .banner2 {
        background: $listreports-blue;

        banner {
            max-width: 385px;
            min-width: 345px;

            @media (max-width: $screen-xs-max) {
                padding-bottom: 14px;
            }
        }

        .link {
            padding: 24px;

            .lr {
                @media (max-width: $screen-xs-max) {
                    margin: 0;
                }
            }

            @media (max-width: $screen-xs-max) {
                padding-top: 0;
            }
        }
    }
}

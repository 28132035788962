@import '../../../../styles/colors';
@import '../../bootstrap/variables';

invite-comarketer {
    h3 {
        margin-bottom: 15px;
    }

    .invite-lender {
        background: $listreports-red;
        color: #fff;
        padding: 25px 15px;

        h3 {
            margin: 10px 0;
        }
    }

    ul {
        display: block;
        margin-right: 35px;

        li {
            margin-bottom: 15px;

            &:before {
                content: '\25CF';
                color: $ice-ice-baby;
                display: inline-block;
                margin-right: 10px;
                font-size: 18px;
                position: relative;
                top: 1px;
            }
        }
    }
}

.modal-container {
    width: 100%;
    padding-top: 125%;
    position: relative;
}

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    color: dimgray;
}

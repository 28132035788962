.application-footer-idp {
    $backgroundColor: #113A3F;
    $mobileBackgroundColor: #113A3F;

    background: $backgroundColor;
    color: #FFFFFF;
    padding-inline: 50px;
    padding-top: 50px;
    padding-bottom: 64px;
    font-size: 14px;

    @media (max-width: $screen-xs-max) {
        background: $mobileBackgroundColor;
    }

    .registed-symbol{
        position:relative;
        top:13px;
        right:5px;
    }

    a {
        color: #FFFFFF;
    }

    .social {
        font-size: 30px;

        a {
            color: #FFFFFF;
            transition: all 200ms ease-out;

            &:hover {
                color: rgba(255, 255, 255, .8);
            }
        }
    }

    .info {
        max-width: 392px;
        @media (max-width: $screen-sm) {
            max-width: 100%;
        }
    }

    .footnote {
        $footnoteBackgroundColor: $backgroundColor;
        $mobileFootnoteBackgroundColor: $backgroundColor;

        margin: 50px -50px -50px;
        padding: 25px;
        text-align: center;
        background: $footnoteBackgroundColor;

        @media (max-width: $screen-sm) {
            margin: 25px -25px -25px;
            background: $mobileFootnoteBackgroundColor;
        }
    }

    @media (max-width: $screen-sm) {
        padding: 25px;
        text-align: center;
    }

    .instagram-footer-icon{
        // icons/listreports/svgs/
        background-image: url("/images/icons/instagram-icon.svg");
        display:inline-block;
        background-repeat:no-repeat;
        background-position:center;
        width:30px;
        height: 30px;
    }
}

.edit-report-feedback-form {
    .error {
        border-color: $errorRed;
    }
    
    select.error {
        border-color: $errorRed !important;
    }

    .red {
        color: $errorRed;
    }

    .blue {
        color: $listreports-blue;
    }

    .btn-link {
        top: -1px;
    }

    .open-house-form {
        .form-group {
            .lr {
                margin-bottom: 8px;
            }
        }
    }

    .allow-wrap {
        white-space: normal;
    }

    md-content {
        padding: 0 !important;
        border: 1px solid #e0e0df;

        @media (max-width: $screen-sm) {
            margin: 0;
        }
    }

    photo-selection-preview {
        width:100%;
    }

    section {
        font-size: 18px;
        color: $defaultSecondaryTextColor;

        header {
            padding: 40px;
            font-size: 16px;

            h2 {
                margin: 0;
                margin-top: 16px;
                margin-bottom: 25px;
                font-size: 24px;
                font-weight: bold;

                @media (max-width: $screen-sm) {
                    font-size: 24px;
                }
            }

            .time-holder {
                $borderRadius: 15px;

                background: $listreports-blue;
                color: #fff;
                margin-left: 50px;
                border-radius: $borderRadius;

                .title {
                    background: #2980b9;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 15px 40px;
                    border-top-right-radius: $borderRadius;
                    border-top-left-radius: $borderRadius;
                }

                .body {
                    padding: 20px 40px 30px;
                }

                small {
                    font-size: 12px;
                    line-height: 13px;
                    display: block;
                }

                @media (max-width: $screen-sm) {
                    margin-left: 0;
                    margin-top: 20px;
                    width: 100%;
                }
            }

            .time {
                font-size: 36px;
                font-weight: bold;
            }

            @media (max-width: $screen-sm) {
                padding: 15px;
                font-size: 18px;
            }
        }
    }

    .main-form {
        .form-body {
            padding-left: 0;
            border-left: 0;

            > .form-group {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .edit-agent-button {
                top: 24px;
                right: 24px;
                @media (max-width: $screen-sm) {
                    top: 16px;
                    right: 16px
                }
            }

            &.agent-edit-info-form {
                border: 1px solid #d5dfe3;
                padding: 24px;
                @media (max-width: $screen-sm) {
                    padding: 24px 16px;
                }

            }

            .checkbox-container {
                display: block;
                position: relative;
                padding-left: 26px;
                cursor: pointer;
                font-weight: normal;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }

            .checkbox-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 18px;
                width: 18px;
                border: 1px solid #cccccc;
                border-radius: 2px;
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .checkbox-container input:checked ~ .checkmark:after {
                display: block;
            }

            .checkbox-container .checkmark:after {
                left: 6px;
                top: 2px;
                width: 5px;
                height: 10px;
                border: solid #007780;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .boxed-in {
            padding: 16px;
            border: 1px solid #d5dfe3;
            border-radius: 2px;

            .field-label {
                margin-bottom: 16px;

                strong {
                    display: inline;
                }
            }
        }

        strong,
        p {
            display: block;
            margin-bottom: 10px;

            &.subtitle {
                margin-bottom: 0;
            }
        }

        .stats {
            .form-group {
                padding-right: 5px;

                &.no-pad {
                    padding-right: 0;

                    @media (max-width: $screen-sm) {
                        padding-right: 5px;
                    }
                }

                &.no-pad-bottom {
                    margin-bottom: 0;
                }
            }
        }
    }

    .image-holder-agentInfo {
        border: 1px solid #ccc;
        padding: 30px;
        border-radius: 4px;
    }

    .company-logo {
        max-height: 200px;
    }

    .agent-photo {
        width: 100px;

        @media (max-width: $screen-sm) {
            width: 75px;
        }
    }

    .agent-photo-v2 {
        width: 64px;
        @media (max-width: $screen-sm) {
            width: 42px;
        }
    }

    .spacer {
        width: 100%;
        height: 24px;
        @media (max-width: $screen-sm) {
            height: 16px;
        }
    }

    .card-wrap {
        .img-col {
            @media (max-width: $screen-sm) {
                padding: 16%;
            }

            padding: 4% 4% 4% 14%;
        }

        .light-text {
            &.margin-top {
                margin-top: 8px;
            }

            color: #a4aaaf;
        }

        .img-holder {
            &.branding {
                margin-top: 28px;
            }

            &.branding-agent {
                max-width: 170px;
                margin-top: 16px;
            }

            &.agent-headshot {
                max-width: 56px;
                margin: 24px 16px 24px 24px;
                @media (max-width: $screen-sm) {
                    max-width: 42px;
                    margin: 16px;
                }
            }

            img {
                max-width: 100%;
            }
        }

        .info {
            @media (max-width: $screen-sm) {
                text-align: center;
                margin-bottom: 32px;
            }
            text-align: left;
        }

        .info-agent {
            margin: 24px 0;
            @media (max-width: $screen-sm) {
                margin: 0 0 16px 16px;
            }
        }
    }

    .body {
        margin-top: 0 !important;
    }

    .line-separator {
        position: relative;
        width: 100%;
        height: 1px;
        margin: 36px auto;
        border-bottom: 1px solid #ced1d5;

        .line-separator-text {
            position: relative;
            width: 50px;
            margin: 0 auto;
            text-align: center;
            transform: translateX(0);
            transform: translateY(-50%);
            background-color: #fff;
        }
    }

    .caption {
        font-size: 14px;
    }

    .error-box-wrap.inline-field {
        @media (min-width: $screen-sm) {
            margin-bottom: 0;
            margin-top: 15px;
        }
    }


    fancy-checkbox.not-bold label {
        font-size: 14px;
        font-weight: normal;
    }

    .fa-question-circle {
        color: $listreports-blue;
    }

    .replacing {
        margin-top: -12px;
        margin-bottom: 8px;
    }

    .color-themes-select {
        .dropdown-toggle {
            padding: 12px;
            border-radius: 2px;
            height: 42px;
            border: 1px solid $ice-ice-baby;
        }

        .dropdown-btn {
            height: 14px;
        }

        .dropdown-menu {
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        .dropdown-menu-item {
            padding: 12px;

            &:hover {
                background-color: $frosty;
            }
        }

        .color-theme-palette {
            margin-right: 4px;

            .color-theme-palette-dot {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                border-radius: 50%;
            }
        }
    }

    .edit-schools {
        .btn-default {
            color: $listreports-blue;
        }

        .grades {
            @media (min-width: $screen-sm) {
                width: 96px;
                padding-left: 0;
                padding-right: 16px;
            }
        }

        .rating {
            @media (min-width: $screen-sm) {
                width: 80px;
            }
        }

        .mobile-spacer {
            margin: 45px 0;
            height: 1px;
            background: $ice-ice-baby;
        }
    }

    .edit-outdoors {

        .validation-error {
            margin-top: 10px;
        }

        .feature-type {
            @media (max-width: $screen-xs-max) {
                padding-right: 16px;
            }
        }

        .feature-name {
            @media (min-width: $screen-sm) {
                min-width: 180px;
            }

            @media (min-width: $screen-md) {
                min-width: 300px;
            }

            @media (max-width: $screen-xs-max) {
                padding-right: 16px;
            }
        }

        .distance {
            @media (min-width: $screen-sm) {
                min-width: 80px;
                max-width: 90px;
            }

            position: relative;

            .input-unit-label {
                position: absolute;
                right: 12px;
                top: 38px;
            }
        }

        .count {
            @media (min-width: $screen-sm) {
                min-width: 60px;
                max-width: 80px;
            }

            @media (min-width: $screen-md) {
                min-width: 80px;
                max-width: 90px;
            }
        }

        .mobile-spacer {
            margin: 45px 0;
            height: 1px;
            background: $ice-ice-baby;
        }
    }

    .edit-area {
        .category {
            width: 200px;
        }

        .distance {
            position: relative;

            .input-unit-label {
                position: absolute;
                right: 12px;
                top: 37px;
            }

            @media (max-width: $screen-xs-max) {
                max-width: 120px;
            }
        }
    }

    .form-select {
        border-bottom: 1px solid $ice-ice-baby;
        padding-bottom: 50px;
        margin-bottom: 50px;

        @media (max-width: $screen-xs-max) {
            padding-bottom: 35px;
            margin-bottom: 35px;
        }
    }

    .expl {
        margin-top:50px;
        margin-bottom: 15px;

        h3 {
            margin-bottom: 10px;
        }
    }

    edit-report-category-selection {
        display: block;
        margin: 0 auto;
        max-width: 700px;
    }

    .suport-phone-policy {
        background-color: #F8FAFB;
        padding: 16px;

        .suport-phone-policy-phone-icon {
            margin-right: 12px;
            @media(max-width: $screen-xs-max) {
                text-align: center;
                margin-bottom: 12px;
            }
        }

        .suport-phone-policy-copy {
            @media(max-width: $screen-xs-max) {
                text-align: center;
            }
        }

        p {
            margin-top: 0;
        }
    }

    .text-area-reset-wrap {
        @media(max-width: $screen-xs-max) {
            margin-top: -10px;
            justify-content: flex-end;
        }
        margin-top: -34px;
    }

    md-switch {
        margin: -5px 0 0 8px;
    }

    .edit-restaurants {
        .restaurants-summary-counts {
            align-items: center;

            .label {
                min-width: 300px;
                margin-right: 24px;

                @media(max-width: $screen-xs-max) {
                    min-width: auto;
                }
            }

            label {
                text-align: center;
            }

            input {
                max-width: 75px;
                margin-bottom: 0;
            }
        }

        .category-count-container {
            margin: 8px -8px;
        }
        
        .category-count {
            margin: 16px 8px 0;

            .category-input {
                max-width: 116px;
            }

            .count-input {
                max-width: 50px;
                margin: 0 0 0 8px;
            }
        }
    }

    .btn-default {
        color: $listreports-blue;

        &.dropdown-toggle {
            padding: 12px;
        }
    }

    .dropdown-toggle {
        .lr {
            font-size: 12px;
        }
    }

    .dropdown-menu {
        .suggestion-list-item {
            padding: 12px 16px;
            border-bottom: 1px solid $ice-ice-baby;
            cursor: pointer;

            .name {
                margin-right: 12px;
            }


            &:hover {
                background-color: $frosty;
            }
        }

        margin-bottom: 20px;
    }

}

@import './category-selection/styles';
@import './accordion/styles';
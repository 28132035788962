.set-password-modal {
    max-width: 100%;

    .lr-lock-closed {
        font-size: 50px;
        margin-bottom: 16px;
    }

    .header {
        max-width: 100%;
        margin: 0 auto;
        width: 370px;
    }

    .body {
        max-width: 100%;
        text-align: center;
        margin: 0 auto;
        width: 410px;
    }

    .input-container {
        border: 1px solid #d5dfe3;
        padding: 6px 12px;
        height: 42px;

        &.error {
            border-color: $listreports-red;
        }

        .password-input {
            height: 100%;
            border: 0;
            margin: 0;
            padding: 0;
        }

        .btn-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $defaultFontColor;

            &.error {
                color: $listreports-red;
            }
        }
    }

    .checkbox-container {
        margin-top: 16px;
        margin-left: 2px;

        &.error {
            .fancy-checkbox {
                border-color: $listreports-red;
            }
        }

        .checkbox-text {
            text-align: left;

            &.error {
                color: $listreports-red;

                .btn-link {
                    color: $listreports-red;
                }
            }
        }
    }

    .error-text {
        margin-top: 12px;
        text-align: left;
        color: $listreports-red;
    }

    .btn-link {
        top: -1px;
        font-weight: normal;
    }

    .btn-container {
        width: fit-content;
        margin: 0 auto;
        margin-top: 24px;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 0;
            width: 110px;
            max-width: 100%;

            & > * {
                display: flex;
                justify-content: center;
            }
        }

        .btn-link {
            height: 42px;
        }
    }
}
status-badge {
    $size: 28px;
    $sizeMobile: 22px;

    display: inline-block;
    min-width: $size;
    height: $size;

    .status-badge {
        display: flex;
        align-items: center;
        min-width: $size;
        overflow: hidden;
        background: transparent;

        i.status-icon {
            opacity: 1;
            font-size: 22px;

            &.green {
                color: $listreports-green;
            }

            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }

        @media (max-width: $screen-xs-max) {
            min-width: $sizeMobile;
            height: $sizeMobile;
            top: -3px;
        }
    }

    @media (max-width: $screen-xs-max) {
        min-width: $sizeMobile;
        height: $sizeMobile;
    }
}

agent-details {
    max-width: 1400px;

    .breadcrumbs {
        margin-bottom: 14px;
    }

    .agent-details {
        max-width: 1400px;
    }

    .main-content {
        margin-right: 15px;

        &.full-width {
            margin-right: 0;
        }

        @media (max-width: $screen-sm-max) {
            margin-right: 0;
        }
    }

    .tab-agent-list-side{
        content: url('/images/icons/agent-list-side.svg');
        vertical-align: sub;
    }

    .tab-agent-buy-side{
        content: url('/images/icons/agent-buy-side.svg');
        vertical-align: sub;
    }

    .tab-agent-provider{
        content: url('/images/icons/agent-provider.svg');
        vertical-align: sub;
    }
    .tab-agent-activity{
        content: url('/images/icons/agent-activity.svg');
        vertical-align: sub;
    }

    .tab-agent-intel{
        content: url('/images/agentfarm/insights.svg');
        vertical-align: sub;
    }

    tabbar tablabel.selected {
        .tab-agent-list-side{
            content: url('/images/icons/agent-list-side-selected.svg');
        }

        .tab-agent-buy-side {
            content: url('/images/icons/agent-buy-side-selected.svg');
        }

        .tab-agent-provider{
            content: url('/images/icons/agent-provider-selected.svg');
        }
        .tab-agent-activity{
            content: url('/images/icons/agent-activity-selected.svg');
        }

        .tab-agent-intel{
            content: url('/images/agentfarm/insights-selected.svg');
        }
    }

    tabbar tablabel{
        text-transform: inherit;
    }

    .agent-info {
        position: relative;
        .btn.btn-link.btn-dark{
            color: dimgray;
        }

        .contact-info-email {
            @media (max-width: $screen-xs-max) {
                max-width: 59%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .agent-profile-card {
            .headshot {
                min-width: 100px;
                max-width: 100px;
                height: 100px;
                margin-right: 15px;
                @media (max-width: $screen-xs-max) {
                    min-width: 60px;
                    max-width: 60px;
                    height: 60px;
                    margin-bottom: 14px;
                }
            }

            .initials {
                color: #fff;
                font-size: 36px;
            }

            .name-wrap {
                @media (max-width: $screen-xs-max) {
                    min-width: 60px;
                    max-width: 60px;
                    height: 60px;
                    margin-bottom: 14px;
                    padding-right: 0;
                }
                padding-right: 100px;
            }

            .name {
                margin: 0;
                padding-right: 8px;
                display: inline-flex;
                align-items: center;
                @media (max-width: $screen-xs-max) {
                   font-size: 18px;
                }
            }

            .user-robin-icon{
                width: 24px;
                height: 24px;
                display: inline-block;
                margin-left: 8px;
                background: url('/images/robin_logo_round.svg');
            }

            .company {
                font-size: 18px;
                margin-bottom: 6px;
                @media (max-width: $screen-xs-max) {
                    font-size: 14px;
                }
            }

            .pairing-status,
            .invite-status {
                margin-bottom: 8px;
            }



            .smallcaps {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: bold;
                vertical-align: top;
                line-height: 14px;
                letter-spacing: 1.5px;
            }
            .contact-info-label{
                width: 95px;
                margin-right: 12px;
                display: inline-block;
            }



            .contact-info-robin-activation{
                color: #007780;
                font-weight: bold;
                cursor: pointer;
            }

            .contact-info {
                flex-wrap: wrap;

                > div {
                    min-width: 105px;
                    padding: 2px 0;

                    @media (max-width: $screen-xs-max) {
                        padding: 0;
                    }
                }

                .smallcaps {
                    color: $defaultSecondaryTextColor;
                    margin-bottom: 2px;
                }

                .vertical-separator {
                    min-width: 1px;
                    max-width: 1px;
                    min-height: 100%;
                    margin: 0 14px 0 12px;
                    border-left: 1px solid $ice-ice-baby;
                }

                @media (max-width: $screen-xs-max) {
                    display: block;
                }
            }

            .follow-btns {
                margin: 12px 0;
                max-width: 320px;
                .btn {
                    padding: 10px 32px;
                }
                .btn-follow {
                    line-height: 1;
                    padding: 6px 32px;
                }
            }

            .list-side-btn-wrap {
                margin-right: 6px;
            }

            .buy-side.btn-unfollow {
                min-width: 100%;
            }
        }

        .primary-cta {
            position: absolute;
            top: 25px;
            right: 25px;
        }

        @media (min-width: $screen-sm) {
            font-size: 14px;
        }

        @media (max-width: $screen-sm-max) {
            margin-bottom: 15px;
        }
    }

    .listings-info {
        @media (max-width: $screen-sm-max) {
            margin-bottom: 15px;
        }
    }
    .agent-stats {
        padding: 16px;
        border-bottom: 1px solid $ice-ice-baby;

        .column-wrap {
            margin-left: -1px;
            margin-top: -1px;
            margin-bottom: -1px;

            &:last-child {
                margin-right: -1px;
            }

            > div:last-child {
                @media (min-width: $screen-sm) {
                    margin-bottom: -2px;
                }
            }
        }

        .square {
            padding: 12px 16px;
            border: 1px solid #D5DFE3;
            border-left: none;
            &:first-child{
                border: 1px solid #D5DFE3;
            }
            @media (max-width: $screen-xs-max) {
                padding: 8px;
                border: none;
                &:first-child{
                    border: none;
                }
            }
        }

        h2,
        h3 {
            margin: 0;
        }

        h3 {
            color: $defaultFontColor;
            font-size: 14px;
            line-height: 16px;
        }

        .lr {
            font-size: 25px;

            &.lr-lock {
                font-size: 35px;
                margin-bottom: 10px;
            }
        }
    }

    .listing-filters-content{
        padding: 16px;
        border-bottom: 1px solid #D5DFE3;
        position: relative;
    }

    .listing-filters-label{
        position: absolute;
        top: 28px;
        left: 33px;
        pointer-events: none;
    }

    select.form-control.listing-filters-select{
        max-width: 192px;
        margin-bottom: 0;
        padding-left: 67px;
        padding-right: 10px !important;
        background-image: url('/images/icons/select-arrow-dark.png') !important;
        background-position: 170px center;
        background-size: inherit;
    }

    .providers {
        padding: 16px;
    }

    .listings {
        background: #fff;
        padding: 16px 6px;


        @media (max-width: $screen-md-max) {
            padding: 15px;
        }

        @media (max-width: $screen-xs-max) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .listing-wrap {
        position: relative;
        padding: 10px;

        @media (max-width: $screen-xs-max) {
            padding: 0 25px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        listing-card {
            @media (max-width: $screen-xs-max) {
                border-left: 1px solid $ice-ice-baby;
                border-right: 1px solid $ice-ice-baby;
                border-radius: 4px;
            }
        }
    }

    .embed-responsive-wrap {
        @media (min-width: $screen-sm) {
            padding: 25px 25px 0;
        }
    }

    notes {
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;

        header {
            h2 {
                height: 1px;
                overflow: hidden;
            }
        }
    }

    .activity-feed-wrap {
        padding: 30px 0;
    }

    activity-feed {
        max-height: 400px;
        overflow: auto;

        .feed {
            .card-wrapper .card {
                border: none;
                padding: 10px 20px 10px 24px;
            }
            .group.first {
                border: none;
                margin-top: -30px;
            }
        }


    }

    .no-listings {
        padding: 15px;

        .img-holder {
            margin-right: 15px;

            @media (max-width: $screen-xs-max) {
                margin: 7px auto 0;
                text-align: center;

                > img {
                    display: inline-block;
                }
            }
        }

        .copy-holder {
            margin-left: 15px;
            max-width: 330px;

            @media (max-width: $screen-xs-max) {
                margin-top: 22px;
                margin-right: 0;
                max-width: 100%;
            }
        }

        h2 {
            margin-top: 0;
            margin-bottom: 18px;

            @media (max-width: $screen-xs-max) {
                margin-right: 0;
            }
        }

        > h3 {
            max-width: 505px;
            margin: 0 auto;

            @media (max-width: $screen-xs-max) {
                max-width: 340px;
            }
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 15px;
        }
    }

    .listing-not-found {
        margin: 30px 15px 15px;
        text-align: center;

        .span-center {
            max-width: 480px;
            display: inline-block;
        }
        h3 {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .agent-details-actions-footer{
        position: fixed;
        z-index: 999;
        padding: 16px;
        background : #ffffff;
        width: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        border-top: 1px solid #D5DFE3;
    }

    react-agent-details-table{
        width: 100%;
    }

    .agent-intel-wrapper {
        max-width: 600px;
    }
}

purchase-buyside-credits-modal-manage-subscription-flow {
    display: block;

    .ctas,
    .root.error-box {
        margin-top: 24px;
    }

    .error-box {
        margin-top: 8px;
    }
}

.area-manager {
    .layout-wrap {
        > .flex-33 {
            flex: 1 1 33.33% !important;
        }

        > .flex-25 {
            flex: 1 1 25% !important;
        }
    }

    .ls-input-group {
        input.form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            z-index: 2;
            height: 40px;
        }

        .btn {
            height: 40px;
            width: 40px;
            font-size: 30px;
            line-height: 1px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .input-group-btn {
        width: auto;
    }

    .pills {
        max-width: 100%;
    }

    .pill-wrap {
        padding: 10px;
    }

    .info {
        font-weight: bold;
        padding-left: 10px;
    }

    &.modal-view {
        padding: 15px;
        margin: 0;

        h1 {
            font-size: 18px;
            margin-top: 0;
        }

        .pill-wrap {
            padding: 0 2px 0 0;
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }

        .divider {
            display: block;
            height: 1px;
            background: $ice-ice-baby;
            margin: 20px -38px;
        }
    }
}

.btn .lr.add-icon {
    top: 1px;
    @media (max-width: $screen-xs-max) {
        top: 0px;
    }
}

@import './search-by-office-modal/styles';


transactions-fre {
  .contentContainer {
    padding-top: 64px;
    padding-bottom: 64px;
    @media (max-width: $screen-xs-max) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }

  .welcomeTitle {
    margin-top: 0px;
    margin-bottom: 0px;
    @media(max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .contentContainer-left {
    width: 280px;
    background-color: $frosty;

    @media(max-width: $screen-xs-max) {
      width: 100%;
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;

      img {
        width: 100%;
      }
    }
  }

  .contentContainer-leftContent {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .bulletList {
    list-style-type: disc;
    padding-inline-start: 16px;
    margin-bottom: 24px;

    li {
      // Used to set the color of the list bullets
      color: $ice-ice-baby;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        color: $listreports-grey;
      }
    }
  }

  .contentContainer-right {
    padding-left: 32px;
    padding-right: 32px;
    @media(max-width: $screen-xs-max) {
      padding-top: 16px;
    }

    .title {
      margin-top: 0;
    }
  }

  .howItWorksTitle {
    margin-top: 0;
  }

  .small-separator {
    border-bottom: 4px solid $ice-ice-baby;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 50px;
    @media(max-width: $screen-xs-max) {
      margin: 32px auto;
    }
  }

  .separator {
    border-bottom: 1px solid $ice-ice-baby;
    margin-bottom: 16px;
  }

  .startButtonContainer {
    margin-top: 32px;
    margin-bottom: 32px;
    @media(max-width: $screen-xs-max) {
      text-align: center;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .lightbulbIconContainer {
    margin-right: 32px;
  }
}
